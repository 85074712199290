import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OppoLawyerListScreen from './screens/OppoLawyerListScreen';
import OppoLawyerDetailsScreen from './screens/OppoLawyerDetailsScreen';



const Stack = createStackNavigator();

const OppoLawyerNavigator = () => (
  <Stack.Navigator
  screenOptions={{
    headerStyle: {
      backgroundColor: '#0C0D07', // Set your desired color here
    },
    headerTintColor: '#fff', // Set the color of the header text and icons
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }} 
  >
    <Stack.Screen name="OppoLawyerList" component={OppoLawyerListScreen} options={{ headerShown: false }} />
    <Stack.Screen name="OppoLawyerDetails" component={OppoLawyerDetailsScreen} options={{ title: 'بطاقة تعريف محامي خصم ' }} />
  </Stack.Navigator>
);

export default OppoLawyerNavigator;
