import React, { useState, useEffect } from 'react';
import { Modal, View, Text, TouchableOpacity, FlatList, StyleSheet, TextInput } from 'react-native';

const ClientModal = ({ visible, onClose, data, selectedFirstAccount, handleSecondAccountChange }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        const lowercasedFilter = searchQuery.toLowerCase();
        const filtered = data.filter(item => {
            let label = '';
            if (selectedFirstAccount === 'bank' || selectedFirstAccount === 'supplier') {
                label = item.title.toLowerCase();
            } else if (selectedFirstAccount === 'lawyer' || selectedFirstAccount === 'employee' || selectedFirstAccount === 'client') {
                label = `${item.first_name} ${item.middle_name} ${item.last_name}`.toLowerCase();
            } else if (selectedFirstAccount === 'syndicate') {
                label = item.description.toLowerCase();
            }
            return label.includes(lowercasedFilter);
        });
        setFilteredData(filtered);
    }, [searchQuery, data, selectedFirstAccount]);

    const renderItem = ({ item }) => {
        let label = '';
        if (selectedFirstAccount === 'bank' || selectedFirstAccount === 'supplier') {
            label = item.title;
        } else if (selectedFirstAccount === 'lawyer' || selectedFirstAccount === 'employee' || selectedFirstAccount === 'client') {
            label = `${item.first_name} ${item.middle_name} ${item.last_name}`;
        } else if (selectedFirstAccount === 'syndicate') {
            label = item.description;
        }
        return (
            <TouchableOpacity
                style={styles.item}
                onPress={() => {
                    handleSecondAccountChange(item.financial_acc_id || item.financial_acc_nb || item.id);
                    onClose();
                }}
            >
                <Text style={styles.itemText}>{label}</Text>
            </TouchableOpacity>
        );
    };

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.fullScreenView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalTitle}>اختر موكل</Text>
                    <TextInput
                        style={styles.searchInput}
                        placeholder="بحث ..."
                        value={searchQuery}
                        onChangeText={setSearchQuery}
                    />
                    <FlatList
                        data={filteredData}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => index.toString()}
                        contentContainerStyle={styles.flatListContent}
                    />
                    <TouchableOpacity style={styles.cancelButton} onPress={onClose}>
                        <Text style={styles.cancelButtonText}>الغاء</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    fullScreenView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalView: {
        flex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        paddingTop: 20,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    searchInput: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        width: '90%',
        alignSelf: 'center',
    },
    flatListContent: {
        flexGrow: 1,
        width: '100%',
    },
    item: {
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
    },
    itemText: {
        fontSize: 16,
    },
    cancelButton: {
        width: '100%',
        padding: 15,
        backgroundColor: '#ff4d4d',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cancelButtonText: {
        color: 'white',
        fontSize: 18,
    },
});

export default ClientModal;
