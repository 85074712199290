import React, { useState } from 'react';
import { View, StyleSheet, Modal, TouchableOpacity } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { SpeedDial } from '@rneui/themed';
import ClientNavigator from './ClientNavigator';
import OppoNavigator from './OppoNavigator';
import ClientFormWizard from './components/AddNewClient';
import { Ionicons } from '@expo/vector-icons';
import OppoFormWizard from './components/AddNewOppo';
import { useNavigation } from '@react-navigation/native';
import OppoLawyerFormWizard from './components/AddNewOppoLawyer';
import OppoLawyerNavigator from './OppoLawyerNavigator';




const UsersTabs = createMaterialTopTabNavigator();

export default function UsersTabsTabNavigator() {
  const [open, setOpen] = useState(false);
  const [isAddNewOppoVisible, setIsAddNewOppoVisible] = useState(false);
  const [isAddNewClientVisible, setisAddNewClientVisible] = useState(false);
  const [SelectedItem, SetSelectedItem] = useState('');
  const [fileLinks, setFileLinks] = useState([]);
  const navigation = useNavigation();
  const [isAddNewOppoLawyerVisible,setIsAddNewOppoLawyerVisible] = useState(false);


  const handleAddNewOppo = () => {
    setIsAddNewOppoVisible(true);
    setOpen(!open);
    navigation.navigate("محاموالخصوم");
  };

  const handleAddNewOppoLawyer = () => {
    setIsAddNewOppoLawyerVisible(true);
    setOpen(!open);
    navigation.navigate("الموكلون");

  };

  const onClientFormSubmit = (result) => {
    // console.log("Form submitted with result:", result);
    // Additional actions after form submission
    setisAddNewClientVisible(false); // Close the modal after submission
    navigation.navigate("الموكلون");
    //fetchClients();
  };

  const onOppoFormSubmit = (result) => {
    console.log("Form submitted with result:", result);
    // Additional actions after form submission
    setIsAddNewOppoVisible(false); // Close the modal after submission
    navigation.navigate("الخصوم");
   // fetchOpponentDetails();
};

const onOppoLawyerFormSubmit = (result) => {
  console.log("Form submitted with result:", result);
  // Additional actions after form submission
  setIsAddNewOppoLawyerVisible(false); // Close the modal after submission
  navigation.navigate("محاموالخصوم");
 // fetchOpponentDetails();
};



  return (
    <View style={{ flex: 1 }}>
      <UsersTabs.Navigator>
        <UsersTabs.Screen
          name="الموكلون"
          key="ClientNavigatorScreen"
          component={ClientNavigator}
        />
        <UsersTabs.Screen
          name="الخصوم"
          key="LawsuitsDetailsOpponentScreen"
          children={OppoNavigator}
        />

<UsersTabs.Screen
          name="محاموالخصوم"
          key="LawsuitsDetailsOpponentScreen"
          children={OppoLawyerNavigator}
        />
      </UsersTabs.Navigator>

      <SpeedDial
        isOpen={open}
        icon={{ name: 'edit', color: '#fff' }}
        openIcon={{ name: 'close', color: '#fff' }}
        onOpen={() => setOpen(!open)}
        onClose={() => setOpen(!open)}
        style={{ position: 'absolute'}}
      >

<SpeedDial.Action
            icon={{ name: 'create', color: '#fff' }}
            title=" اضافة موكل"
            onPress={() => { setisAddNewClientVisible(true), setOpen(!open), navigation.navigate('محاموالخصوم') }}
          />
          
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة خصم"
          onPress={handleAddNewOppo}
        />

<SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة محامي خصم"
          onPress={handleAddNewOppoLawyer}
        />
      </SpeedDial>


      {isAddNewClientVisible && <Modal
        transparent={false}
        visible={isAddNewClientVisible}
        animationType="slide"
      >
        <ClientFormWizard onSubmit={onClientFormSubmit} editData={SelectedItem} images={fileLinks} />
        <TouchableOpacity
          style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
          onPress={() => { setisAddNewClientVisible(false); SetSelectedItem(''); navigation.navigate("الموكلون");
          }}
        >
          <Ionicons name="close" size={24} color="black" />
        </TouchableOpacity>
      </Modal>
}

{isAddNewOppoLawyerVisible &&
<Modal
        transparent={false}
        visible={isAddNewOppoLawyerVisible}
        animationType="slide"
      >
<OppoLawyerFormWizard onSubmit={onOppoLawyerFormSubmit}/>
<TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => {setIsAddNewOppoLawyerVisible(false),  navigation.navigate("محاموالخصوم")}}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
        </Modal>
}

{isAddNewOppoVisible &&
<Modal
        transparent={false}
        visible={isAddNewOppoVisible}
        animationType="slide"
      >
<OppoFormWizard onSubmit={onOppoFormSubmit}/>
<TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => {setIsAddNewOppoVisible(false), navigation.navigate("الخصوم")}}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
        </Modal>
}




    </View>
  );
}

const styles = StyleSheet.create({
  speedDial: {
    position: 'absolute',
    bottom: 30,
    right: 30,
  },
});
