import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { SpeedDial } from 'react-native-elements';
import LawyersNavigator from './screens/LawyersList';
import SuppliersNavigator from './screens/Suppliers';
import BanksNavigator from './screens/Banks';
import SyndicatsNavigator from './screens/Syndicats';
import BankModal from './components/BankModal';
import SupplierModal from './components/SupplierModal';
import SyndicateModal from './components/SyndicateModal';
import LawyerModal from './components/LawyerModal'; // Import the LawyerModal
import { fetchData } from '../../utils/api';
import EmployeesNavigator from './screens/Employees';
import EmployeeModal from './components/EmployeeModal';

const Tab = createMaterialTopTabNavigator();

function OfficeSettingsMainScreen() {
  const [open, setOpen] = useState(false);
  const [bankModalVisible, setBankModalVisible] = useState(false);
  const [supplierModalVisible, setSupplierModalVisible] = useState(false);
  const [syndicateModalVisible, setSyndicateModalVisible] = useState(false);
  const [lawyerModalVisible, setLawyerModalVisible] = useState(false); // State for lawyer modal
  const [initialData, setInitialData] = useState(null); // State to hold initial data for editing
  const navigation = useNavigation();

  const handleAddBank = async (bankDetails) => {
    const url = initialData
      ? { method: 'update_bank', params: { ...bankDetails, id: initialData.id } }
      : { method: 'insert_bank', params: { ...bankDetails } };

    try {
      const response = await fetchData(url);

      if (response === true) {
        setBankModalVisible(false);
        setInitialData(null);
        navigation.navigate('البنوك');
      } else {
        console.error('Failed to add/edit bank');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddSupplier = async (supplierDetails) => {
    const url = initialData
      ? { method: 'update_supplier', params: { ...supplierDetails, id: initialData.id } }
      : { method: 'insert_supplier', params: { ...supplierDetails } };

    try {
      const response = await fetchData(url);

      if (response === true) {
        setSupplierModalVisible(false);
        setInitialData(null);
        navigation.navigate('الموردون');
      } else {
        console.error('Failed to add/edit supplier');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddSyndicate = async (syndicateDetails) => {
    const url = initialData
      ? { method: 'update_syndicate', params: { ...syndicateDetails, id: initialData.id } }
      : { method: 'insert_syndicate', params: { ...syndicateDetails } };

    try {
      const response = await fetchData(url);

      if (response === true) {
        setSyndicateModalVisible(false);
        setInitialData(null);
        navigation.navigate('النقابات');
      } else {
        console.error('Failed to add/edit syndicate');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddLawyer = async (lawyerDetails) => {
    const url = initialData
      ? { method: 'update_user', params: { ...lawyerDetails, id: initialData.id } }
      : { method: 'insert_user', params: { ...lawyerDetails, id: null } };

    try {
      const response = await fetchData(url);

      if (response) {
        setLawyerModalVisible(false);
        setInitialData(null);
        navigation.navigate('المحامون');
      } else {
        console.error('Failed to add/edit lawyer');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEditBank = (data) => {
    setInitialData(data);
    setBankModalVisible(true);
  };

  const handleEditSupplier = (data) => {
    setInitialData(data);
    setSupplierModalVisible(true);
  };

  const handleEditSyndicate = (data) => {
    setInitialData(data);
    setSyndicateModalVisible(true);
  };

  const handleEditLawyer = (data) => {
    setInitialData(data);
    setLawyerModalVisible(true);
  };

  


  return (
    <View style={styles.container}>
      <Tab.Navigator screenOptions={{ lazy: true }}>
        <Tab.Screen name="المحامون">
          {(props) => <LawyersNavigator {...props} modalVisible={lawyerModalVisible} />}
        </Tab.Screen>
        <Tab.Screen name="الموظفون" >
        {(props) => <EmployeesNavigator {...props} modalVisible={EmployeeModal} />}

</Tab.Screen>
        <Tab.Screen name="الموردون">
          {(props) => <SuppliersNavigator {...props} modalVisible={supplierModalVisible} />}
        </Tab.Screen>
        <Tab.Screen name="البنوك">
          {(props) => <BanksNavigator {...props} modalVisible={bankModalVisible} />}
        </Tab.Screen>
        <Tab.Screen name="النقابات">
          {(props) => <SyndicatsNavigator {...props} modalVisible={syndicateModalVisible} />}
        </Tab.Screen>
      </Tab.Navigator>

      <SpeedDial
        isOpen={open}
        icon={{ name: 'edit', color: '#fff' }}
        openIcon={{ name: 'close', color: '#fff' }}
        onOpen={() => setOpen(!open)}
        onClose={() => setOpen(!open)}
        style={styles.speedDial}
      >
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة محامي"
          onPress={() => { setLawyerModalVisible(true); setOpen(!open); setInitialData(null); }}
        />
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة موظف"
          onPress={() => { setOpen(!open); }}
        />
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة مورد"
          onPress={() => { setSupplierModalVisible(true); setOpen(!open); setInitialData(null); }}
        />
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة بنك"
          onPress={() => { setBankModalVisible(true); setOpen(!open); setInitialData(null); }}
        />
        <SpeedDial.Action
          icon={{ name: 'create', color: '#fff' }}
          title=" اضافة نقابة"
          onPress={() => { setSyndicateModalVisible(true); setOpen(!open); setInitialData(null); }}
        />
      </SpeedDial>

      <LawyerModal
        visible={lawyerModalVisible}
        onClose={() => setLawyerModalVisible(false)}
        onSubmit={handleAddLawyer}
        initialData={initialData}
      />
      <SupplierModal
        visible={supplierModalVisible}
        onClose={() => setSupplierModalVisible(false)}
        onSubmit={handleAddSupplier}
        initialData={initialData}
      />
      <BankModal
        visible={bankModalVisible}
        onClose={() => setBankModalVisible(false)}
        onSubmit={handleAddBank}
        initialData={initialData}
      />
      <SyndicateModal
        visible={syndicateModalVisible}
        onClose={() => setSyndicateModalVisible(false)}
        onSubmit={handleAddSyndicate}
        initialData={initialData}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  speedDial: {
    position: 'absolute',
   // bottom: 20,
    //right: 20,
  },
});

export default OfficeSettingsMainScreen;
