import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { getToken } from '../utils/api';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [detailsFetched, setDetailsFetched] = useState(false);
  const [transactionsFetched, setTransactionsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = Constants.expoConfig.extra.apiUrl;

  const fetchLws = async () => {
    setIsLoading(true);
    try {
      const jsonString = await AsyncStorage.getItem('select_lawsuits');
      if (jsonString !== null) {
        const jsonData = JSON.parse(jsonString);
        let dataToSet = jsonData.results ? jsonData.results : jsonData;

        // Sort data by l_nb in descending order
        dataToSet.sort((a, b) => {
          const aNb = isNaN(a.l_nb) ? a.l_nb : +a.l_nb;
          const bNb = isNaN(b.l_nb) ? b.l_nb : +b.l_nb;
          return bNb - aNb;
        });

        setData(dataToSet);
        setFilteredData(dataToSet);
        console.log('fetchLws data set', dataToSet);
      } else {
        setData([]);
        setFilteredData([]);
        console.log('fetchLws no data found');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const fetchReminders = async (filters) => {
    try {
      const token = await getToken();

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(filters),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      console.log('fetchReminders data', data);
      return data;

    } catch (error) {
      console.error('Error fetching reminders:', error);
    }
  };

  const fetchItemDetails = async () => {
    console.log('fetchItemDetails triggered');
    if (!detailsFetched && filteredData.length > 0) {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(startDate.getDate() + 15);

      const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day} 00:00:00`;
      };

      const updatedData = await Promise.all(filteredData.map(async (item) => {
        const newFilters = {
          method: 'select_grouped_reminders',
          params: {
            date_start: formatDateString(startDate),
            date_end: formatDateString(endDate),
            lawsuit_id: item.id,
          },
        };

        try {
          const details = await fetchReminders(newFilters);
          const hasReminders = details && Object.keys(details).length > 0;
          return { ...item, hasReminder: hasReminders };
        } catch (error) {
          console.error('Error fetching item details:', error);
          return { ...item, hasReminder: false };
        }
      }));

      setFilteredData(updatedData);
      setDetailsFetched(true);
      console.log('fetchItemDetails updatedData', updatedData);
    }
  };

  const fetchTransactionsAndUpdateItems = async () => {
    console.log('fetchTransactionsAndUpdateItems triggered');
    if (!transactionsFetched && filteredData.length > 0) {
      const updatedData = await Promise.all(filteredData.map(async (item) => {
        try {
          const token = await getToken();
          const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
              method: 'select_transactions',
              params: { fin_id: item.financial_acc_id },
            }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

          const transactions = await response.json();

          if (transactions) {
            const fin_id_str = item.financial_acc_id.toString();

            const calculateBalance = (currency, targetIdKey) => transactions.reduce((sum, transaction) => {
              if (transaction.currency.toLowerCase() === currency && transaction[targetIdKey].id === fin_id_str) {
                return sum + parseFloat(transaction.amount);
              }
              return sum;
            }, 0);

            const usdTo = calculateBalance('usd', 'to');
            const usdFrom = calculateBalance('usd', 'from');
            const lbpTo = calculateBalance('lbp', 'to');
            const lbpFrom = calculateBalance('lbp', 'from');

            const usdBalance = usdFrom - usdTo;
            const lbpBalance = lbpFrom - lbpTo;

            return { ...item, hasBalance: usdBalance < 0 || lbpBalance < 0 };
          }

          return item;
        } catch (error) {
          console.error('Error fetching transactions:', error);
          return item;
        }
      }));

      setFilteredData(updatedData);
      setTransactionsFetched(true);
      console.log('fetchTransactionsAndUpdateItems updatedData', updatedData);
    }
  };
/*
  useEffect(() => {
    console.log('fetchLws useEffect triggered');
    fetchLws();
  }, []);
*/
  useEffect(() => {
    console.log('fetchItemDetails useEffect triggered');
    if (filteredData.length > 0 && !detailsFetched) {
      fetchItemDetails();
    }
  }, [filteredData, detailsFetched]);

  useEffect(() => {
    console.log('fetchTransactionsAndUpdateItems useEffect triggered');
    if (detailsFetched && !transactionsFetched) {
      fetchTransactionsAndUpdateItems();
    }
  }, [detailsFetched, transactionsFetched]);

  return (
    <DataContext.Provider value={{ data, filteredData, setFilteredData, fetchLws, fetchItemDetails, fetchTransactionsAndUpdateItems, detailsFetched, transactionsFetched }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataProvider, DataContext };
