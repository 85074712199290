import React, { useState, useEffect, useContext } from 'react';
import { FlatList, ActivityIndicator, TextInput, TouchableOpacity, Linking, Modal, StyleSheet } from 'react-native';
import { View, Text, Image, ExpandableSection, Colors } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { fetchData, fetchFileLink } from '../../../utils/api';
import Styles from '../../../Styles/Styles';
import { SearchContext } from '../../../contexts/SearchContext';
import { SpeedDial } from '@rneui/themed';
import OppoFormWizard from '../../Parties/components/AddNewOppo';
import { TextField } from 'react-native-ui-lib';
import EditOppoModal from '../../Parties/components/EditOppoModal';
import FlipCard from 'react-native-flip-card';




/**
 * Component that displays the details of an opponent in a lawsuit.
 * @param {object} route The route object containing the lawsuit and opponent IDs.
 * @returns {JSX.Element} The rendered opponent details.
 */
const LawsuitsDetailsOpponent = ({ route, navigation, All = false, isNested = true}) => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [oppoData, setOppoData] = useState({
    first_name:'',


  });


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, lawsuit_info_id } = route?.params ?? {};
  const [activeItem, setActiveItem] = useState(null); // Store the ID of the expanded item
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility
  const [search, setSearch] = useState('');
  //const [filteredData, setFilteredData] = useState([]);


  const { searchQuery } = useContext(SearchContext);
  
  const [filteredData, setFilteredData] = useState([]);
const [isAddNewOppoVisible, setisAddNewOppoVisible] = useState(false);

console.log('oppo ');


  useEffect(() => {
    setFilteredData(data);
  }, [data]);


  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        data.filter(item => {
          return item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 item.middle_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 item.last_name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);


  const toggleItem = (id) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
      const oppo = data.find(oppo => oppo.id === id);
      if (oppo) {
        fetchImagesForClient(oppo);
      }
    }
  };

  useEffect(() => {
   

    fetchOpponentDetails();
  }, [id, lawsuit_info_id]);

  const fetchOpponentDetails = async () => {
    try {
      const opponents = All 
      ? await fetchData({method:'select_opponents', params :{}})
      : await fetchData({ method: 'select_opponents', params: { group: true, id, lawsuit_info_id } });
      //console.log('API Opponent Response:', opponents);
      const sortedData = opponents.sort((a, b) => b.id - a.id);
    
      setData(sortedData);
            setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  /*
    useEffect(() => {
      navigation.setOptions({ title: `خصم (${data.length})` });
    }, [data]);
  */
  const handleCall = (item) => {
    Linking.openURL(`tel:${item}`);
  };

  const handleWhatsApp = (item) => {
    Linking.openURL(`https://wa.me/${item}?text=`);
  };

  const onOppoFormSubmit = (result) => {
    console.log("Form submitted with result:", result);
    // Additional actions after form submission
    setisAddNewOppoVisible(false); // Close the modal after submission
    fetchOpponentDetails();
};


const handleEdit = (editData) => {
  if (!isEditMode) {
    // Populate clientData with editData
    setOppoData(editData);
  } else {
    // Save changes when in edit mode
    saveChanges();
  }
  setIsEditMode(!isEditMode);
};



const saveChanges = async (oppoData) => {
//console.log(oppoData);
  try {
    // Prepare updateData object
    const updateData = {
      method: "update_opponent",
      params: {
        ...oppoData,
              },
    };

    const response = await fetchData(updateData);
    //console.log("Raw API Response:", response);
 if (response === 1) {
      // Handle the success case
    } else {
      // Handle unexpected response
    }
  
} catch (error) {
  console.error("Error updating client data:", error);
  // Handle error
}
// Exit edit mode
setIsEditMode(false);
fetchOpponentDetails();
};



const updateField = (field, value) => {
  setOppoData(prevData => ({
    ...prevData,
    [field]: value
  }));
};

const handleOpenEditModal = (client) => {
  setOppoData(client); // Set the client data to be edited
  setIsEditModalVisible(true);
};

const handleCloseEditModal = () => {
  setIsEditModalVisible(false);
};

const handleSaveEdit = async (editedData) => {
  saveChanges(editedData);
  setIsEditModalVisible(false);
};


const fetchImagesForClient = async (client) => {
  const imageIds = JSON.parse(client.images);
  const updatedClient = { ...client };

  for (const key in imageIds) {
    if (imageIds[key] !== -1) {
      try {
        const base64String = await fetchFileLink(imageIds[key]);
        if (base64String) {
          updatedClient[key + '_base64'] = base64String;
        }
      } catch (error) {
        console.error("Error fetching image with ID", imageIds[key], ":", error);
      }
    }
  }

  // Update the specific client in your state
  setData(prevData => prevData.map(item => item.id === client.id ? updatedClient : item));
};



  const LawSuiteDetailsOpponentView = ({ item }) => {

    return (

  <View style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>

    

        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الاسم الأول</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.first_name}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأب</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.middle_name}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الشهرة</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.last_name}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأم</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mother_name}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>محل الولادة</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_place}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم السجل</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_number}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ الولادة</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>
  {item.dob !== "1970-01-01" ? item.dob : ""}
</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الجوال</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mobile}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>البريد الالكتروني</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.email}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>العنوان</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.address}</Text>
          </View>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>ملاحظات</Text>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.notes}</Text>
          </View>
        </View>


        <View style={Styles.LawSuiteDetailsClientOpponentStyles.row1}>
     <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
         <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>صورة الهوية</Text>
       </View>
   
      {  item.civil_id_photo_front_base64 ? (
        <View style={{flex:1, marginTop:5}} >
      <FlipCard 
             flip={false} 
             flipHorizontal={true}
             flipVertical={false}
             perspective={1000}
           >
             {/* Front Side */}
             <View>
            
        <Image
          source={{ uri: item.civil_id_photo_front_base64 }} style={{ width: '100%', height: 100 }} />
             
             </View>
             {/* Back Side */}
             <View>
           
        <Image
          source={{ uri: item.civil_id_photo_back_base64}} style={{ width: '100%', height: 100 }} />
             
                
             </View>
           </FlipCard>
           <TouchableOpacity 
        style={styles.magnifierIcon} 
     onPress={() => openFlipCardModal(item)}>

        <Ionicons name="ios-search" size={24} color="black" />
    </TouchableOpacity>
    </View>
    ): 
    <Text>
      لا يوجد
      </Text>}
   
      
   
     </View>






        </View>
 )}
    


  const renderListItem = (item) => (
<View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleContainer} key={item.id.toString()}>
              <ExpandableSection
                down
                expanded={activeItem === item.id}
                sectionHeader={
                  <View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleHeader}>
                    <Image source={{ uri: 'https://lh3.googleusercontent.com/-cw77lUnOvmI/AAAAAAAAAAI/AAAAAAAAAAA/WMNck32dKbc/s181-c/104220521160525129167.jpg' }} style={{ width: 50, height: 50, borderRadius: 25 }} />
                    <View style={{ marginLeft: 10, flex: 1 }}>
                      <Text grey10 text70>{item.first_name} {item.middle_name} {item.last_name}</Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={[Styles.LawSuiteDetailsClientOpponentStyles.value, { flex: 1 }]}>{item.mobile}</Text>
                        <TouchableOpacity onPress={() => handleCall(item.mobile)}>
                          <Ionicons name="call" size={24} color="blue" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleWhatsApp(item.mobile)} style={{ marginLeft: 10 }}>
                          <Ionicons name="logo-whatsapp" size={24} color="green" />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                }
                onPress={() => toggleItem(item.id)}
              >
                <View style={Styles.LawSuiteDetailsClientOpponentStyles.container}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                  <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
                    <Ionicons name="expand-outline" size={18} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity style={{marginHorizontal:15}} onPress={() => handleOpenEditModal(item)}>
  <Ionicons name={isEditMode ? "checkmark" : "create-outline"} size={18} color={isEditMode ? "green" : "red"} />
</TouchableOpacity>
</View>
                  <Modal
                    transparent={false}
                    visible={isModalVisible}
                    animationType="slide"
                  >
                    <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                      <View>
                        <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>بطاقة تعريف خصم </Text>

                      </View>
                      <LawSuiteDetailsOpponentView item={item} />
                      <TouchableOpacity
                style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
                onPress={() => setIsModalVisible(false)}
              >
                <Ionicons name="close" size={24} color="black" />
              </TouchableOpacity>
                    
                    </View>
                  </Modal>

                  <LawSuiteDetailsOpponentView item={item} />

                </View>

              </ExpandableSection>
            </View>
          );


 return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        isNested ? (
          <View>
            {data.map(item => renderListItem(item))}
          </View>
        ) : (
          <View style={{ flex: 1 }}>
           { /*<TextInput
              style={{ height: 40, borderColor: 'gray', borderWidth: 1, paddingLeft: 10, margin: 5, backgroundColor:'white' }}
              onChangeText={text => handleSearch(text)}
              value={search}
              placeholder="بحث ..."
        />*/}
            <FlatList
              data={filteredData}
              renderItem={({ item }) => renderListItem(item)}
              keyExtractor={item => item.id.toString()}
            />
          </View>
        )
      )}

{isEditModalVisible &&
<EditOppoModal
      isVisible={isEditModalVisible}
      onClose={handleCloseEditModal}
      oppoData={oppoData}
      onSave={handleSaveEdit}
    />

}



      {!isNested ? 
      <SpeedDial
    isOpen={open}
    icon={{ name: 'edit', color: '#fff' }}
    openIcon={{ name: 'close', color: '#fff' }}
    onOpen={() => setOpen(!open)}
    onClose={() => setOpen(!open)}
    style={{position: 'absolute'}}
  >
  <SpeedDial.Action
      icon={{ name: 'create', color: '#fff' }}
      title=" اضافة خصم"
      onPress={() => {setisAddNewOppoVisible(true), setOpen(!open)}}
      />
   
  </SpeedDial>
  :
  null
      }

{isAddNewOppoVisible &&
<Modal
        transparent={false}
        visible={isAddNewOppoVisible}
        animationType="slide"
      >
<OppoFormWizard onSubmit={onOppoFormSubmit}/>
<TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setisAddNewOppoVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
        </Modal>
}
    </View>
  );
}
const styles = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10, // Adjust as needed
  },
  textInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'grey',
    marginRight: 10, // Space between text input and button
    paddingHorizontal: 10, // Inner spacing of text input
    height: 40, // Adjust as needed
  },
  iconButton: {
    // Style for the file select icon button
    padding: 10, // Adjust as needed
  },
  addButton: {
    // Style for the add new procuration button
    backgroundColor: '#007bff', // Example color
    padding: 10,
    alignItems: 'center',
  },
  addButtonText: {
    color: 'white', // Example text color
    fontSize: 16,
  },
  magnifierIcon: {
      position: 'absolute',
      right: 10,
      bottom: 10,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: 15,
      padding: 5,
  },
  modalView: {
      flex: 1,
      justifyContent: 'center',
     alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    //flex: 1,
    margin: 10,
    padding:10
},
  closeButton: {
      position: 'absolute',
      top: 20,
      right: 20,
  },
  imageContainer: {
    position: 'relative', // Required for absolute positioning of children
    width: '100%',
    height: 100,
    marginBottom: 15,
  },
  imageOverlay: {
    position: 'absolute', // Overlay takes the entire container space
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: {
    position: 'absolute',
    top: 5,   // Adjust top and right values as needed
    right: 5,
    // Add additional styling if needed
  },

});

export default LawsuitsDetailsOpponent;