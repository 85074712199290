import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TouchableOpacity, Modal, TextInput, Alert, ScrollView, StyleSheet } from 'react-native';
import { Text, Image, View } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { DELETE_FILE_BY_ID_API } from '../../../utils/api';
import Styles from '../../../Styles/Styles';
import { useNavigation } from '@react-navigation/native';

import { Camera } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import { TextField } from 'react-native-ui-lib';
import PhoneInput from 'react-native-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';



const EditClientModal = ({ isVisible, onClose, clientData, onSave, Lawsuits }) => {
    const [editData, setEditData] = useState(clientData);
    const [isProcurationAdded, setIsProcurationAdded] = useState(false);
    const phoneInput = useRef(null);
    const [procurationFiles, setProcurationFiles] = useState([]);
    const [procurationName, setProcurationName] = useState('');

    const navigation = useNavigation(); // Use the navigation hook


    useEffect(() => {
        setEditData(clientData); // Update local state when clientData changes
        console.log(clientData);
        console.log('Lawsuits:', Lawsuits);

    }, [clientData]);

    const handleInputChange = (fieldName, value) => {
        setEditData({ ...editData, [fieldName]: value });
    };

   /* const handleSaveUpdates = () => {
        setEditData(prevEditData => ({...prevEditData, ...procurationFiles}));
        onSave(editData); // This might need to use a state update callback or useEffect to ensure it has the latest editData
      };
     
      useEffect(() => {
        setEditData(prevEditData => ({
          ...prevEditData, 
          procurationFiles: { ...procurationFiles } // Adds procurationFiles as a nested object
        }));
      }, [procurationFiles]);
      */

    function getInternationalCode(phoneNumber) {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        return parsedNumber ? parsedNumber.countryCallingCode : '';
    }
    
    function removeInternationalCode(phoneNumber) {
        const dialCode = getInternationalCode(phoneNumber);
        if (phoneNumber.startsWith(`+${dialCode}`)) {
            return phoneNumber.substring(dialCode.length + 1);
        }
        return phoneNumber;
    }
    

    const handleSelectFile = async () => {
        try {
            const newFiles = await handlePress('procurationFiles');
            if (!newFiles || newFiles.length === 0) return;

            // Update the procurationFiles in editData
            setEditData(prevData => ({
                ...prevData,
                procurationFiles: [...prevData.procurationFiles, newFiles[0]]
            }));
        } catch (error) {
            console.error("Error in file selection:", error);
        }
    };



    const handleAddNewProcuration = (deletedIds, addedUris) => {
    // console.log(addedUris);
     
    setProcurationFiles(addedUris);
    console.log(addedUris);
      
     // console.log(procurationFiles);


       // setIsProcurationAdded(true); // Set flag to true after adding
    };




    const handleDeleteProcuration = (fileId) => {
        Alert.alert(
            "تأكيد الحذف",
            "هل أنت متأكد من رغبتك في حذف هذه الوكالة؟",
            [
                {
                    text: "كلا",
                    style: "cancel"
                },
                {
                    text: "نعم",
                    onPress: () => {
                        // Here we filter out the deleted procuration
                        setEditData(prevData => ({
                            ...prevData,
                            procurations: prevData.procurations.filter(procuration => procuration.file_id !== fileId)
                        }));

                        // You might want to call your DELETE_FILE_BY_ID_API here
                        DELETE_FILE_BY_ID_API('delete_client_file', { id: fileId });
                    }
                }
            ],
            { cancelable: false }
        );
    };



    const renderProcurationsItemForEdit = (item, index) => {
        return (
            <View key={`procuration-${item.file_id}-${index}`} style={{ marginBottom: 10, flexDirection: 'row' }}>
                <Text>{item.name}</Text>
                <TouchableOpacity onPress={() => handleDeleteProcuration(item.file_id)}>
                    <Ionicons name="remove-circle-outline" size={24} color="red" />
                </TouchableOpacity>
            </View>
        );
    };





  // const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = useCallback((deletedFileIds, addedUris) => {
        setProcurationFiles(addedUris);
      console.log(addedUris);
    }, []);



    const handlePress = (field) => {
        return new Promise((resolve, reject) => {
            Alert.alert(
                'اختر خيارًا',
                'حدد ملفًا من المعرض أو التقط صورة',
                [
                    { text: 'إلغاء', style: 'cancel', onPress: () => reject('Cancelled') },
                    { text: 'اختر من المعرض', onPress: () => pickPhoto(field).then(resolve).catch(reject) },
                    { text: 'التقاط صورة', onPress: () => captureFromCamera(field).then(resolve).catch(reject) },
                ],
                { cancelable: true }
            );
        });
    };


    const captureFromCamera = async (field) => {
        let { status } = await Camera.requestCameraPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera permissions to make this work!');
            return;
        }

        let result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled && result.assets) {
            handleAddImage(field, result.assets[0].uri);
        }
    };


    const pickPhoto = async (field) => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled && result.assets) {
            handleAddImage(field, result.assets[0].uri);
        }
    };

    // In the handleAddImage function
    const handleAddImage = (field, uri) => {
        handleInputChange([field], uri);
    };

    function getNumberAfterPossiblyEliminatingZero(text) {
        let number = text.replace(/^0+/, ''); // Remove leading zeros
        let formattedNumber = number; // Format number as needed
        return { number, formattedNumber };
    }


    const handleSaveAndNavigate = () => {
        onSave(editData); // Save data
        onClose(); // Close the modal
        navigation.navigate('ClientDetails', { item: editData }); // Navigate to the desired screen with data
    }; 


    return (
        <Modal
            transparent={false}
            visible={isVisible}
            onRequestClose={onClose}
            animationType="slide"
        >
            <View style={{ flex: 1}}>
                {/* Header */}
                <View style={{ padding: 10, backgroundColor: '#f8f8f8', borderBottomWidth: 1, borderColor: '#e7e7e7' }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>تعديل معلومات موكل</Text>
                </View>

                {/* Scrollable Content */}
                <ScrollView style={{ flex: 1 }}>


                <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الرقم الداخلي"
                value={editData.c_nb}
                onChangeText={(text) => handleInputChange('c_nb', text)}
            />
 
 <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الاسم الاول"
                value={editData.first_name}
                onChangeText={(text) => handleInputChange('first_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الشهرة"
                value={editData.last_name}
                onChangeText={(text) => handleInputChange('last_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الاب"
                value={editData.middle_name}
                onChangeText={(text) => handleInputChange('middle_name', text)}
            />


          
<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الام وشهرتها"
                value={editData.mother_name}
                onChangeText={(text) => handleInputChange('mother_name', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="محل الولادة"
                value={editData.register_place}
                onChangeText={(text) => handleInputChange('register_place', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="تاريخ الولادة"
                value={editData.dob}
                onChangeText={(text) => handleInputChange('dob', text)}
            /> 

<TextField 
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="رقم السجل"
                value={editData.register_number}
                onChangeText={(text) => handleInputChange('register_number', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="العنوان"
                value={editData.address}
                onChangeText={(text) => handleInputChange('address', text)}
            />

<View>
<Text style={{ margin:10, marginTop: 30, fontSize:12, color: 'grey'}}>رقم الهاتف</Text>

<PhoneInput
                            ref={phoneInput}
                            defaultValue={removeInternationalCode(editData.mobile)}
                            defaultCode="LB"
                            layout="first"
                            placeholder="ادخل رقم الهاتف"
                            textContainerStyle={[styles.textContainer, {
                               // borderColor: mobileValidationStatus ? 'green' : 'red',
                                direction: 'rtl',
                            }]}
                            containerStyle={{
                                direction: 'rtl'
                            }}
                            onChangeFormattedText={(text) => {
                                const { number, formattedNumber } = getNumberAfterPossiblyEliminatingZero(text);
   
                                
                                handleInputChange('mobile', formattedNumber)
                            }}
                            
                        />


</View>



<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="البريد الالكتروني"
                value={editData.email}
                onChangeText={(text) => handleInputChange('email', text)}
            />
            
            
 {/*
                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الرقم الداخلي</Text>
                        </View>


                        <TextInput
                            value={editData.c_nb}
                            onChangeText={(text) => handleInputChange('c_nb', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>

                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الاسم الأول</Text>
                        </View>

                        <TextInput
                            value={editData.first_name}
                            onChangeText={(text) => handleInputChange('first_name', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>


                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأب</Text>
                        </View>
                        <TextInput
                            value={editData.middle_name}
                            onChangeText={(text) => handleInputChange('middle_name', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>



                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الشهرة</Text>
                        </View>

                        <TextInput
                            value={editData.last_name}
                            onChangeText={(text) => handleInputChange('last_name', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>


                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأم</Text>
                        </View>
                        <TextInput
                            value={editData.mother_name}
                            onChangeText={(text) => handleInputChange('mother_name', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>


                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>محل الولادة</Text>
                        </View>

                        <TextInput
                            value={editData.register_place}
                            onChangeText={(text) => handleInputChange('register_place', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>

                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم السجل</Text>
                        </View>



                        <TextInput
                            value={editData.register_number}
                            onChangeText={(text) => handleInputChange('register_number', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>



                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ الولادة</Text>
                        </View>

                        <TextInput
                            value={editData.dob}
                            onChangeText={(text) => handleInputChange('dob', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />
                    </View>




                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الجوال</Text>
                        </View>


                        <TextInput
                            value={editData.mobile}
                            onChangeText={(text) => handleInputChange('mobile', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />

                    </View>



                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>البريد الالكتروني</Text>
                        </View>



                        <TextInput
                            value={editData.email}
                            onChangeText={(text) => handleInputChange('email', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />

                    </View>




                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>العنوان</Text>
                        </View>



                        <TextInput
                            value={editData.address}
                            onChangeText={(text) => handleInputChange('address', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />

                    </View>



                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>ملاحظات</Text>
                        </View>
                        <TextInput
                            value={editData.notes}
                            onChangeText={(text) => handleInputChange('notes', text)}
                            style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
                        />

                    </View>
 
                        */}


                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row1, { paddingRight: 15, flexDirection:'column' }]}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>صورة الهوية</Text>
                        </View>

                        {editData.civil_id_photo_front_base64 ? (
                            <View style={{ flex: 1, marginTop: 5 }}>

                                {/* Container for Front Image and Overlay */}
                                <View style={styles.imageContainer}>
                                    <Image
                                        source={{ uri: editData.civil_id_photo_front_base64 }}
                                        style={{ width: '100%', height: 100, marginBottom: 15 }}
                                    />
                                    <View style={styles.imageOverlay}>
                                        <TouchableOpacity
                                            style={styles.editIcon}
                                            onPress={() => handlePress('civil_id_photo_front_base64')}
                                        >
                                            <Ionicons name="create-outline" size={24} color="white" />
                                        </TouchableOpacity>
                                    </View>
                                </View>

                                {/* Container for Back Image and Overlay */}
                                <View style={styles.imageContainer}>
                                    <Image
                                        source={{ uri: editData.civil_id_photo_back_base64 }}
                                        style={{ width: '100%', height: 100 }}
                                    />
                                    <View style={styles.imageOverlay}>
                                        <TouchableOpacity
                                            style={styles.editIcon}
                                            onPress={() => handlePress('civil_id_photo_back_base64')}
                                        >
                                            <Ionicons name="create-outline" size={24} color="white" />
                                        </TouchableOpacity>
                                    </View>
                                </View>






                            </View>


                        ) : (
                            <View >

                                <TouchableOpacity

                                    onPress={() => handlePress('civil_id_photo_front_base64')}
                                    style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}

                                >
                                    <Text>اضف الجهة الامامية للهوية</Text>
                                    <Ionicons name="create-outline" size={24} color="black" />
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => handlePress('civil_id_photo_back_base64')}
                                    style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}
                                >
                                    <Text>اضف الجهة الخلفية للهوية</Text>
                                    <Ionicons name="create-outline" size={24} color="black" />
                                </TouchableOpacity>

                            </View>
                        )}

                    </View>



{/*

                    <View style={Styles.LawSuiteDetailsClientOpponentStyles.row1}>
                        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
                            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الوكالات</Text>
                        </View>

                        <View style={{ flexDirection: 'column' }}>
                            {!isProcurationAdded && ( // Render these only if a procuration hasn't been added
                                <View style={{ flexDirection: 'column', width:'95%', alignContent:'center' }}>
                                   
                                        <TextInput
                                            value={procurationName}
                                            onChangeText={(text) => setProcurationName(text)}
                                            placeholder="اسم الملف ..."
                                            style={[styles.textInput, {width:'100%'}]}
                                        />

<FilePhotoPicker 
  initialUris={(editData.procurations || []).map(item => item.file_id)} 
  onFileChange={handleAddNewProcuration}
/>





                                      
                                 
                                   
                                </View>
                            )}

                           
                        </View>
                        
                    </View>
*/}

                </ScrollView>








                {/* Footer */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', padding: 10, backgroundColor: '#f8f8f8', borderTopWidth: 1, borderColor: '#e7e7e7' }}>

                    <TouchableOpacity onPress={onClose}>
                        <Text style={{ fontSize: 16, color: '#007bff' }}>الغاء</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={handleSaveAndNavigate}>
                        <Text style={{ fontSize: 16, color: '#007bff' }}>حفظ</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};


const styles = StyleSheet.create({
    input: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    inputRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10, // Adjust as needed
    },
    textInput: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'grey',
        marginRight: 10, // Space between text input and button
        paddingHorizontal: 10, // Inner spacing of text input
        height: 40, // Adjust as needed
    },
    iconButton: {
        // Style for the file select icon button
        padding: 10, // Adjust as needed
    },
    addButton: {
        // Style for the add new procuration button
        backgroundColor: '#007bff', // Example color
        padding: 10,
        alignItems: 'center',
    },
    addButtonText: {
        color: 'white', // Example text color
        fontSize: 16,
    },
    magnifierIcon: {
        position: 'absolute',
        right: 10,
        bottom: 10,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 15,
        padding: 5,
    },
    modalView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalContent: {
        //flex: 1,
        margin: 10,
        padding: 10
    },
    closeButton: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
    imageContainer: {
        position: 'relative', // Required for absolute positioning of children
        width: '100%',
        height: 100,
        marginBottom: 15,
    },
    imageOverlay: {
        position: 'absolute', // Overlay takes the entire container space
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background
        justifyContent: 'center',
        alignItems: 'center',
    },
    editIcon: {
        position: 'absolute',
        top: 5,   // Adjust top and right values as needed
        right: 5,
        // Add additional styling if needed
    },

});

export default EditClientModal;
