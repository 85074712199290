import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Modal, TextInput, Platform, TouchableOpacity, Text, Button, View, StyleSheet, Alert } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { ActionSheet } from 'react-native-ui-lib';
import { Picker } from '@react-native-picker/picker';
import { DELETE_ITEM_API, DELETE_FILE_BY_ID_API, ADD_FILE_API } from '../../../utils/api';
import { Ionicons } from '@expo/vector-icons';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import * as FileSystem from 'expo-file-system';



const ReminderEdit = ({ isVisible = true, onClose, onEntryUpdated, initialValue, initialID, initialType, initialDate, initialTime, TypeValues, initialFiles }) => {
  const [value, setValue] = useState(initialValue);
  const [
    selectedType, setSelectedType] = useState(initialType);
  const [iosPickerVisible, setIosPickerVisible] = useState(false);
  const [date, setDate] = useState(initialDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [time, setTime] = useState(initialTime);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [isTypeActionSheetVisible,setIsTypeActionSheetVisible] = useState(false);
  const [combinedDateTime, setCombinedDateTime] = useState(`${initialDate} ${initialTime}`);
const [selectedID, setSelectedID] = useState(initialID);
const [imageUris, setImageUris] = useState([]);
const [deletedFiles, setDeletedFiles] = useState([]);
const [addedFiles, setAddedFiles] = useState([]);
const defaultTypeText = TypeValues.find(item => item.id === initialType)?.text || '';
  const [selectedTypeText, setSelectedTypeText] = useState(defaultTypeText);
  const [isSubmitting, setIsSubmitting] = useState(false);

//console.log('INITISL TYPE:', initialType)

const formatDate = (dateObj) => {
  let day = dateObj.getDate();
  let month = dateObj.getMonth() + 1; // Months are zero-based
  let year = dateObj.getFullYear();

  // Ensuring two digits for day and month
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return `${year}-${month}-${day}`;
};

const formatTime = (timeObj) => {
  let hours = timeObj.getHours();
  let minutes = timeObj.getMinutes();
  let seconds = timeObj.getSeconds();

  // Ensuring two digits for hours, minutes, and seconds
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

// When you set the combinedDateTime state
useEffect(() => {
  const formattedDate = formatDate(date);
  const formattedTime = formatTime(time);
  const newCombinedDateTime = `${formattedDate} ${formattedTime}`;
  setCombinedDateTime(newCombinedDateTime);
  //console.log(formattedDate);
 //console.log(formattedTime);
}, [date, time]);

  
  /*
  const handleSubmit = async () => {
    // Make your API call here
  //console.log(selectedID);
      const onSuccess = (responseData) => {
         
          //console.log("Item updated successfully:", responseData);
        
        
       
       
      };
    
      const onFailure = (errorText) => {
        Alert.alert('Error', 'Failed to update item');
      };
    
      DELETE_ITEM_API(
        
        'update_reminder',
        { id: selectedID, description:value, type: selectedType, deadline: combinedDateTime },
        onSuccess,
        onFailure
      );
    
    onEntryUpdated(selectedID, value, selectedType, combinedDateTime); // This will inform the parent component about the update
    onClose(); // Close the modal
  };
  */

 /*

  const handleSubmit = async () => {
    setIsSubmitting(true); // Show submission status
  
    try {
      // Simplified call to just perform the update operation
      const updateResult = await DELETE_ITEM_API('update_reminder', {
        id: "261",
        description: "Value",
        type: "3",
        deadline: "2024-02-17 09:07:03",
        status: "1"
      });
  
      // Log the raw response for debugging
      console.log('Raw update result:', updateResult);
  
      // Assuming the successful response should be { result: 1 }
      if (updateResult && updateResult.result === 1) {
        console.log('Update operation was successful.');
        // Here, you can call onEntryUpdated and onClose as needed
      } else {
        console.error('Unexpected update result:', updateResult);
        // Handle unexpected result
      }
    } catch (error) {
      console.error('Update operation failed with error:', error);
      // Handle error (e.g., show error message to the user)
    } finally {
      setIsSubmitting(false); // Reset submission status
    }
  };
  */

  const handleSubmit = async () => {
    // Array to hold all promises for deletion and addition of files
   // setIsSubmitting(true); // Start submission

    const fileOperationPromises = [];
  
    // Process deletion if there are files to delete
    if (deletedFiles.length > 0) {
      for (const fileId of deletedFiles) {
        //console.log('Delete:', fileId);
        fileOperationPromises.push(DELETE_FILE_BY_ID_API(
          'delete_reminder_file',      
          {id:fileId}));
      }
    }
  
    // Process addition if there are files to add
    if (addedFiles.length > 0) {
      const imagesBase64 = await Promise.all(addedFiles.map(async uri => {
        if (!uri) {
            console.warn('Undefined URI found');
            return null;
        }
        // Get the file extension and MIME type
        const fileExtension = uri.split('.').pop();
        let mimeType;
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
                mimeType = 'image/jpeg';
                break;
            case 'png':
                mimeType = 'image/png';
                break;
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            default:
                mimeType = 'application/octet-stream';
        }
        const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
        return `data:${mimeType};base64,${data}`;
      }));
  
      for (const base64Data of imagesBase64) {
        if (base64Data) {
          fileOperationPromises.push(ADD_FILE_API(
            'insert_reminder_files',
            {id: initialID, files: [base64Data]}));
        }
      }
    }
  console.log({id: selectedID, description: value, type: selectedType, deadline: combinedDateTime, status:"1"});
    // Add the update procedure promise to the array
    const updateProcedurePromise = DELETE_ITEM_API(
      'update_reminder',
      { id: selectedID, description: value, type: selectedType, deadline: combinedDateTime, status:'1' }
    );
    fileOperationPromises.push(updateProcedurePromise);
  
    try {
      // Execute all promises
      const results = await Promise.all(fileOperationPromises);
  
      // results now contain the outcomes of all the file deletions, additions, and the update procedure
      //console.log("All operations completed successfully:", results);
  
      // Call the onEntryUpdated with all the necessary arguments
    onEntryUpdated(selectedID, value, selectedTypeText, combinedDateTime, deletedFiles, addedFiles);
  
      // Close the modal
     onClose();
    } catch (error) {
      // If there's an error in any operation, handle it
      console.error("An error occurred during the file operation or update process:", error);
      Alert.alert('Error', 'Failed to complete operations');
    }
   // setIsSubmitting(false); // End submission

  };

  const handleSelectType = (typeId, typeText) => {
    setSelectedType(typeId);
    setSelectedTypeText(typeText);
  };


  const openIOSPicker = () => {
    setIosPickerVisible(true);
  };

  return (
    <Modal visible={isVisible} transparent={true} animationType="slide">

<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.7)' }}>
{isSubmitting ? (
        // View when submitting
        <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size="large" color="#0000ff" />
          <Text>Submitting...</Text>
        </View>
      ) : (
        // Original form view
            <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> تعديل استحقاق</Text>
                    <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />

      <TextInput
        placeholder="Enter text"
        value={value}
        onChangeText={setValue}
        style={styles.inputStyle}
      />

<View style={{ width:'100%'}}>
<TouchableOpacity onPress={() => setIsTypeActionSheetVisible(true)} style={styles.iconButton}>
    {
        (() => {
            const typeItem = TypeValues.find(item => item.id === selectedType);
            return <Text>{selectedType && typeItem ? typeItem.text : 'نوع الاستحقاق'}</Text>;
        })()
    }
</TouchableOpacity>


<ActionSheet
    title="اختار نوع"
    message="الرجاء اختيار نوع من القائمة ادناه"
    cancelButtonIndex={TypeValues.length}
    useNativeIOS
    options={[
        ...TypeValues.map((item) => ({
            label: item.text,
            onPress: () => handleSelectType(item.id, item.text)
        })),
        { label: 'Cancel', onPress: () => {} }  // You can adjust the cancel action as needed
    ]}
    visible={isTypeActionSheetVisible}
    onDismiss={() => setIsTypeActionSheetVisible(false)}
/>
</View>

      {/* Date Picker */}
      <TouchableOpacity onPress={() => setShowDatePicker(true)} style={styles.iconButton}>
      <Ionicons name="calendar-outline" size={24} color="black" />

        <Text>{date.toDateString()}</Text>
      </TouchableOpacity>
      {showDatePicker && (
        <DateTimePicker
          value={date}
          mode="date"
          display={Platform.OS === 'android' ? 'default' : 'spinner'}
          onChange={(event, selectedDate) => {
            setShowDatePicker(false);
            if (selectedDate) {
              setDate(selectedDate);
            }
          }}
        />
      )}

      {/* Time Picker */}
      <TouchableOpacity onPress={() => setShowTimePicker(true)} style={styles.iconButton}>
      <Ionicons name="time-outline" size={24} color="black" />

        <Text>{time.toTimeString().split(' ')[0]}</Text>
      </TouchableOpacity>
      {showTimePicker && (
        <DateTimePicker
          value={time}
          mode="time"
          display={Platform.OS === 'android' ? 'default' : 'spinner'}
          onChange={(event, selectedTime) => {
            setShowTimePicker(false);
            if (selectedTime) {
              setTime(selectedTime);
            }
          }}
        />
      )} 

<FilePhotoPicker
  initialUris={initialFiles || []}
  onFileChange={(deletedIds, addedUris) => {
    setDeletedFiles(deletedIds || []);
    setAddedFiles(addedUris || []);
   //console.log('on file change:', deletedIds);
  }}
/>


<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop:10 }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={onClose}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="حفظ" 
      onPress={handleSubmit}
      color="#28a745" /* Green color */
    />
  </View>

                      
                        </View>






      </View>
       )}
      </View>

    </Modal>
      
  );
};
const styles = StyleSheet.create({
    actionButtonDisabled: {
        backgroundColor: '#b0b0b0', // A grayed-out color when disabled
    },
    picker: {
        //  height: 50,
        width: '40%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    inputStyle: {
      width: '100%',
      marginTop:10,
      padding: 5,
      borderColor: '#ccc',
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10
  },
    pickerStyle: {
        height: 50,
        width: '100%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    dateTimePickerContainer: {
        width:'100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        //width: '100%',
        padding: 10,
        borderRadius: 5,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10
    },
    imagePreview: {
        width: 100,
        height: 100,
        margin: 5,
    },
    filePreview: {
        width: 100,
        height: 100,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileName: {
        marginTop: 10,
        fontSize: 12,
    },
    actionButton: {
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'blue',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 5
    },
    iconButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 10,
      marginVertical: 10,
      borderRadius: 10,
      borderColor: '#ccc',
      borderWidth: 1,
      textAlign: 'center',
  },
});



export default ReminderEdit;
