import React, { useState, useEffect, useContext, useCallback } from 'react';
import {  TouchableOpacity, FlatList,  ActivityIndicator, StyleSheet, Modal, Alert, Platform } from 'react-native';
import { Overlay } from 'react-native-elements';
import { useIsFocused } from '@react-navigation/native';

import {Calendar, LocaleConfig, CalendarList} from 'react-native-calendars';
import moment from 'moment';
import { getToken,DELETE_ITEM_API, encodeImagesWithMimeType, executePostRequest, FETCH_LOADING_DATA } from '../../../utils/api';
import { View, Text, Button, Colors, Chip, Carousel, Drawer } from 'react-native-ui-lib';
//import 'moment/locale/ar'; // Import Arabic locale
import { Ionicons } from '@expo/vector-icons';
import * as SQLite from 'expo-sqlite/legacy';
//import { SpeedDial } from 'react-native-elements';
import { SpeedDial } from "@rneui/base";
import { FlashList } from '@shopify/flash-list';
import AddAppointment from '../components/AddAppointment';
import AddReminderModal from '../components/AddReminderModal';
import ImageCarouselModal from '../../../SharedComponents/ImageCarouselModal';
import ReminderEdit from '../components/ReminderEdit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ProcedureModal from '../../Procedures/components/ProcedureModal';
import LawsuitFilterModal from '../../../SharedComponents/LawsuitFilterModal';
import { AuthContext } from '../../../contexts/AuthContext';
import BadgeCountContext from '../../../contexts/BadgeCountContext';
import { useNavigation } from '@react-navigation/native';
import { useIndicator } from '../../../contexts/IndicatorContext';
import { SearchContext } from '../../../contexts/SearchContext';
import CustomChip from '../../../SharedComponents/CustomChip';
import { useLoading } from '../../../contexts/LoadingContext';
import { useFocusEffect } from '@react-navigation/native';
import { executeSqlWithRetry, unlockDatabase } from '../../../utils/api';
import RefreshContext from '../../../contexts/RefreshContext';

const CalendarScreen = () => {
  //moment.locale('ar'); // Set moment to use Arabic locale
  const {isAuthenticated} = useContext(AuthContext);
  const { showLoading, showSuccess, showError } = useIndicator();
  const isFocused = useIsFocused(); // Check if the screen is focused

  const [open, setOpen] = useState(false);
  const [ReminderModalVisible, setReminderModalVisible] = useState(false);
  const [fromItem, setFromItem] = useState(false); // or true, as required
  const [ItemDate, setItemDate] = useState(''); // or another initial date value
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [weekDays, setWeekDays] = useState([]);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [events, setEvents] = useState({});
  const [loading, setLoading] = useState(true);

// Open the SQLite database for non-web platforms, otherwise set to null
const db = Platform.OS !== 'web' ? SQLite.openDatabase('localAvocado.db') : null;

  const [AddNewAppoinntmentVisible, setAddNewAppoinntmentVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false);
  const [fileLinks, setFileLinks] = useState([]);
  const checkmark = require('../../../assets/briefcase-66-32.png');
  const checkmark2 = require('../../../assets/defaultuser.png');
  const [IsEditModalVisible,setIsEditModalVisible]=useState(false);
  const [typeValues, setTypeValues] = useState([]);
  const [Lawsuits, setLawsuits] = useState([]);
  const [ProcedureModalVisible, setProcedureModalVisible] = useState(false);
  const [activeItem, setActiveItem] = useState(null);  // State to track the active item
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const [filters, setFilters] = useState(null);
  const [showReportBars, setshowReportBars] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  //const [searchModalVisible, setSearchModalVisible] = useState(false);
  const { searchQuery } = useContext(SearchContext);

  const { badgeCount, setBadgeCount } = useContext(BadgeCountContext);  
  const navigation=useNavigation();
  const [flatListData, setFlatListData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

 
  const { refreshCalendar, setRefreshCalendar } = useContext(RefreshContext);

  useFocusEffect(
    useCallback(() => {
      const refreshData = async () => {
        if (refreshCalendar) {
          await fetchRemindersForMonth();
          setRefreshCalendar(false); // Reset the flag after refreshing
          console.log('Data refreshed and flag reset');
        }
      };
      refreshData();
      console.log('Data refreshed and flag reset:', refreshCalendar);
    }, [refreshCalendar])
  );
  
  const [searchModalVisible, setSearchModalVisible] = useState(false);

// Function to handle closing the search modal
const closeSearchModal = () => {
  setSearchModalVisible(false);
  setFilters(null); // Reset the filters
  fetchRemindersForMonth(); // Re-fetch reminders for the current month
};



  const [followupReminder, setFollowupReminder] = useState(false);
/*
  useFocusEffect(
    React.useCallback(() => {
      const fetchData = async () => {
        setIsLoading(true);
        await fetchRemindersForMonth();
        setIsLoading(false);
      };
  
      fetchData();
    }, [])
  );

  */

  useEffect(() => {

    console.log('TRANSFORMED DATA:', events);
    const transformedData = Object.keys(events).reduce((acc, date) => {
      // Add all events under each date to the accumulator
      return [...acc, ...events[date].map(event => ({ ...event, date }))];
    }, []);
  
    setFlatListData(transformedData);
    fetchReminderTypesFromStorage();
  }, [events]);

  const fetchTodayRemindersCount = () => {
    try {
        const today = new Date().toISOString().split('T')[0];
        //console.log('Today:', today);  // Log today's date

        const todayReminders = events[today] || [];
        //console.log('Today reminders:', todayReminders);  // Log today's reminders

        setBadgeCount(todayReminders.length);
    } catch (error) {
        console.error('Error fetching today\'s reminders:', error);
    }
};

useEffect(() => {
  fetchTodayRemindersCount();
}, [events]);



// This useEffect runs when the filters change
useEffect(() => {
  console.log('Filters changed. Fetching reminders with:', filters);
  if (filters) {
    setEvents({});
    fetchRemindersForMonth(false); // Fetch only from server when filters are applied
  }
}, [filters]);



  LocaleConfig.locales['ar'] = {
    monthNames: [
      'كانون الثاني',
      'شباط',
      'آذار',
      'نيسان',
      'ايار',
      'حزيران',
      'تموز',
      'آب',
      'أيلول',
      'تشرين الأول',
      'تشرين الثاني',
      'كانون الأول'
    ],
    monthNamesShort: ['ك2', 'شباط', 'اذار', 'نيسان', 'ايار', 'حزيران', 'تموز.', 'آب', 'أيلول', 'ت1', 'ت2', 'ك1'],
    dayNames: ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'],
    dayNamesShort: ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'],
    today: "اليوم"
  };

  LocaleConfig.defaultLocale = 'ar';

  const handleAddNewAppointment = async () => {

    setSelectedItem('');
    setAddNewAppoinntmentVisible(true);
  };
  
/*
  const handleModalClose = (newRecord) => {
    const formattedDate = newRecord.deadline.split(' ')[0];
  console.log(newRecord);
    setEvents(prevData => {
      const updatedData = { ...prevData };
  
      if (updatedData[formattedDate]) {
        // Check if the reminder already exists (edit case)
        const existingIndex = updatedData[formattedDate].findIndex(item => item.id === newRecord.id);
        if (existingIndex !== -1) {
          // Update the existing reminder
          updatedData[formattedDate][existingIndex] = newRecord;
        } else {
          // Add as a new reminder
          updatedData[formattedDate] = [...updatedData[formattedDate], newRecord];
        }
      } else {
        // Date doesn't exist, so create a new array with the newRecord
        updatedData[formattedDate] = [newRecord];
      }
  
      // Sorting logic
      updatedData[formattedDate].sort((a, b) => {
        const timeA = a.deadline.split(' ')[1];
        const timeB = b.deadline.split(' ')[1];
        return timeA.localeCompare(timeB);
      });
  
      return updatedData;
    });
  };
  */
// Define a mapping of month numbers to Arabic month names
const arabicMonthNames = {
  '01': 'كانون الثاني',
  '02': 'شباط',
  '03': 'آذار',
  '04': 'نيسان',
  '05': 'ايار',
  '06': 'حزيران',
  '07': 'تموز',
  '08': 'آب',
  '09': 'أيلول',
  '10': 'تشرين الأول',
  '11': 'تشرين الثاني',
  '12': 'كانون الأول'
};

// Function to get the current month in Arabic
const getCurrentMonthName = (date) => {
  const monthNumber = moment(date).format('MM'); // Extract month number as '01', '02', etc.
  return arabicMonthNames[monthNumber]; // Use the mapping to get the month name in Arabic
};

 
  


// Function to execute the POST request
const executePost = async (newRecord) => {
  let imagesBase64 = [];

  // Encode images if they exist
  if (newRecord.files && newRecord.files.length > 0) {
    imagesBase64 = await encodeImagesWithMimeType(newRecord.files);
  }

  // Prepare the body for the POST request
  const body = {
    method: 'insert_reminder',
    params: {
      ...newRecord,
      id: '',
      files: imagesBase64,
    }
  };

  try {
    const response = await executePostRequest(body);
    //console.log('Post request response:', response);
    showSuccess();
    return newRecord; // Return newRecord for further processing
  } catch (error) {
    console.error('Error in post request:', error);
    showError();
    throw error; // Re-throw error to handle it in the calling function
  }
};

// Function to add newRecord to the local array
const addToLocalArray = (newRecord) => {
  const formattedDate = newRecord.deadline.split(' ')[0];
  setEvents(prevData => {
    const updatedData = { ...prevData };

    if (updatedData[formattedDate]) {
      updatedData[formattedDate] = [...updatedData[formattedDate], newRecord];
      updatedData[formattedDate].sort((a, b) => {
        const timeA = a.deadline.split(' ')[1];
        const timeB = b.deadline.split(' ')[1];
        return timeA.localeCompare(timeB);
      });
    } else {
      updatedData[formattedDate] = [newRecord];
    }

    // Update the FlatList data
    const transformedData = Object.keys(updatedData).reduce((acc, date) => {
      return [...acc, ...updatedData[date].map(event => ({ ...event, date }))];
    }, []);
    setFlatListData(transformedData);

    return updatedData;
  });
};


// Modified handleModalClose function
const handleModalClose = async (newRecord) => {
  try {
    const processedRecord = await executePost(newRecord);
    addToLocalArray(processedRecord);
    setFollowupReminder(false);
  } catch (error) {
    // Error handling if needed
  }
};


  



/*
  
  const handleModalClose = (newRecord, newImageUris) => {
    console.log('Received new record:', newRecord);

    if (newRecord.files.length >0) {
fetchRemindersForMonth();
    }else{
    const formattedDate = newRecord.deadline.split(' ')[0];

    setEvents(prevData => {
        const updatedData = { ...prevData };

        // Check if the date already exists in the data
        if (updatedData[formattedDate]) {
            // Add the new record
            updatedData[formattedDate] = [...updatedData[formattedDate], newRecord];

            // Sort the array based on the time part of the deadline
            updatedData[formattedDate].sort((a, b) => {
                // Extracting time and converting it to seconds since midnight for comparison
                const getTimeInSeconds = (timeString) => {
                    const [hours, minutes, seconds] = timeString.split(':').map(Number);
                    return hours * 3600 + minutes * 60 + seconds;
                };

                const timeAInSeconds = getTimeInSeconds(a.deadline.split(' ')[1]);
                const timeBInSeconds = getTimeInSeconds(b.deadline.split(' ')[1]);

                return timeAInSeconds - timeBInSeconds;
            });
        } else {
            // If the date does not exist, just add the new record
            updatedData[formattedDate] = [newRecord];
        }

        return updatedData;
    });
   
    
    }
};
  */
  /**
   * Displays a confirmation dialog for deleting the item with the given ID.
   * If the user confirms the deletion, deletes the item from the API and updates the state.
   * @param {number} id - The ID of the item to delete.
   */
  const showDeleteConfirmation = (id) => {
    Alert.alert(
      "حذف استحقاق", // Title
      "هل انت متأكد من رغبتك في حذف هذا الاستحقاق؟", // Message
      [
        {
          text: "كلا",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "نعم",
          onPress: () => deleteItem(id)
        }
      ],
      { cancelable: false } // This makes it so the user has to select one of the options
    );
  };

  const deleteItem = async (id) => {
    const onSuccess = (responseData) => {
      const idToRemove = id; // Replace with the actual id you want to remove
  
      const newData = Object.fromEntries(
        Object.entries(events).map(([key, value]) => [
          key,
          value.filter(item => item.id !== idToRemove)
        ])
      );
  
      // Remove empty days
      Object.keys(newData).forEach(date => {
        if (newData[date].length === 0) {
          delete newData[date];
        }
      });
  
      setEvents(newData);
    };
  
    const onFailure = (errorText) => {
      Alert.alert('Error', 'Failed to delete item');
    };
  
    try {
      const responseData = await DELETE_ITEM_API('delete_reminder', { id });
      onSuccess(responseData);
    } catch (error) {
      onFailure(error.message);
    }
  };
  

  const showDeleteConfirmationSQlite = (id) => {
    Alert.alert(
      "حذف موعد", // Title
      "هل انت متأكد من رغبتك في حذف هذا الموعد؟", // Message
      [
        {
          text: "كلا",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "نعم",
          onPress: () => deleteItemSQlite(id)
        }
      ],
      { cancelable: false } // This makes it so the user has to select one of the options
    );
  };


  const showDoneConfirmationSQlite = (item) => {
    Alert.alert(
      "انجاز موعد", // Title
      "هل انت متأكد من رغبتك في انجاز هذا الموعد؟", // Message
      [
        {
          text: "كلا",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "نعم",
          onPress: () => handleSQliteDonePress(item)
        }
      ],
      { cancelable: false } // This makes it so the user has to select one of the options
    );
  };


  const deleteItemSQlite = async (id) => {
    const onSuccess = () => {
      const idToRemove = id;
      const newData = Object.fromEntries(
        Object.entries(events).map(([key, value]) => [
          key,
          value.filter(item => item.id !== idToRemove)
        ])
      );
  
      // Remove empty days
      Object.keys(newData).forEach(date => {
        if (newData[date].length === 0) {
          delete newData[date];
        }
      });
  
      setEvents(newData);
    };
  
    const onFailure = (errorText) => {
      Alert.alert('Error', 'Failed to delete item: ' + errorText);
    };
  
    try {
      await unlockDatabase(db);
      await executeSqlWithRetry(db, 'DELETE FROM reminders WHERE id = ?;', [id]);
      onSuccess();
    } catch (error) {
      onFailure(error.message);
    }
  };
  
  
  
  
  
  
  const fetchSQLiteReminders = async (startDate, endDate) => {
    console.log(startDate, endDate);
    let query = 'SELECT * FROM reminders WHERE deadline BETWEEN ? AND ? AND status = 1;';
    let params = [startDate, endDate];
  
    if (filters && filters !== '-1') {
      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        return `${year}-${month}-${day} 00:00:00`;
      };
      const startDate = formatDate(filters.params.date_start);
      const endDate = formatDate(filters.params.date_end);
      params = [startDate, endDate];
    }
  
    try {
      await unlockDatabase(db); // Unlock the database before executing the query
      const result = await executeSqlWithRetry(db, query, params);
      console.log('Parametrized Filtered Reminders:', result.rows._array);
      return result.rows._array;
    } catch (error) {
      console.error('Query error:', error);
      throw error;
    }
  };
  
  
  
  
  
  
  

  const fetchRemindersForMonth = async (includeSQLite = true) => {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD');
  
    try {
      const serverData = await fetchReminders(startDate, endDate);
      let transformedServerData = transformServerData(serverData);
      let mergedData = transformedServerData;
  
      if (includeSQLite && Platform.OS !== 'web') {
        const sqliteData = await fetchSQLiteReminders(startDate, endDate);
        const transformedSQLiteData = transformSQLiteData(sqliteData);
        mergedData = mergeData(transformedServerData, transformedSQLiteData);
        console.log('SQLITE: ', sqliteData);
      }
  
      if (Object.keys(mergedData).length === 0) {
        setEvents({});
        console.log('No records found');
      } else {
        Object.keys(mergedData).forEach(day => {
          mergedData[day].sort((a, b) => {
            const timeA = a.reminder_time;
            const timeB = b.reminder_time;
            return timeA.localeCompare(timeB);
          });
        });
  
        setEvents(mergedData);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };
  
  
  
  
  

  /*
  
  const onMonthChange = (visibleMonths) => {
    // Assuming you want to fetch reminders for the first visible month
    console.log("Visible months:", visibleMonths);
    const firstVisibleMonth = visibleMonths[0];
    const startDate = moment(firstVisibleMonth.dateString).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(firstVisibleMonth.dateString).endOf('month').format('YYYY-MM-DD');
  
    fetchRemindersForMonth(startDate, endDate);
  };
  */

  const handleSQliteItemPress = async (item) => {

    setSelectedItem(item);
    setAddNewAppoinntmentVisible(true);
  };

  // Function to fetch reminders from the server
  const fetchReminders = async (startDate, endDate ) => {
   // console.log(filters); // Log the filters to check their values
    try {
        const token = await getToken();
  
        const requestBody = (filters && filters !== '-1') ? JSON.stringify(filters) : JSON.stringify({
        method:'select_grouped_reminders',
          params:{
        date_start: startDate, // Update with your desired start date
        date_end: endDate,  // Update with your desired end date
        status: 1  
          }
      });    
  
    //  console.log("Request Body:", requestBody); // Log the request body
  
      const response = await fetch('https://maktabi.info/includes/model.php', {
        method: 'POST',
        body: requestBody,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',     
            Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  
      const data = await response.json();
      console.log(data);
      return data;
  
    } catch (error) {
      console.error('Error fetching reminders:', error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (isAuthenticated) {
      updateWeekDaysFromCalendar(selectedDate);
      fetchRemindersForMonth(); // Use default true here
      updateWeekDays(selectedDate);
      FETCH_LOADING_DATA(setIsLoading);
    }
  }, [isAuthenticated]);


  const updateWeekDays = (date) => {
    const startOfWeek = moment(date).startOf('isoWeek');
    const days = Array.from({ length: 7 }).map((_, i) => 
      startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD')
    );
    setWeekDays(days);
  };
  

  const formatDateToHumanReadable = (dateString) => {
  const date = new Date(dateString);
  return date.getFullYear() + '-' +
    String(date.getMonth() + 1).padStart(2, '0') + '-' +
    String(date.getDate()).padStart(2, '0') + ' ' +
    String(date.getHours()).padStart(2, '0') + ':' +
    String(date.getMinutes()).padStart(2, '0') + ':' +
    String(date.getSeconds()).padStart(2, '0');
};


  const transformSQLiteData = (sqliteData) => {
  const transformedData = {};

  sqliteData.forEach(item => {
    const dateKey = item.deadline.split(' ')[0]; // Extract the date part

    if (!transformedData[dateKey]) {
      transformedData[dateKey] = []; // Initialize array if not exists
    }

    transformedData[dateKey].push({
      // Transform the data as needed
      id: item.id.toString(),
      deadline: item.deadline,
      description: item.description,
      files: [], // SQLite data may not have files
      type_name: 'Unknown', // Default value or extract from data if available
      lawsuit_title: 'Unknown', // Default value or extract from data if available
      reminder_time: item.deadline.split(' ')[1], // Extract the time part
      Source: 'SQLite',
      // Add other necessary fields
      clientName: item.clientName,
      clientID: item.clientID
    });
  });

  return transformedData;
};



const mergeData = (serverData, sqliteData) => {
  const mergedData = { ...serverData }; // Start with server data

  Object.keys(sqliteData).forEach(date => {
    if (mergedData[date]) {
      mergedData[date] = mergedData[date].concat(sqliteData[date]);
    } else {
      mergedData[date] = sqliteData[date];
    }
  });

  return mergedData;
};

const transformServerData = (serverData) => {
  let transformedData = {};

  Object.keys(serverData).forEach(date => {
    if (Array.isArray(serverData[date])) {
      transformedData[date] = serverData[date].map(item => {
   
      return {
        ...item, // Existing fields from the server data
        clientID: null, // Add additional fields or default values
        clientName: null,
        Source: 'server',
        // Add other fields specific to the server data here
        // Example: adding a formatted date or a default value for missing fields
       
    }});
    }
  });

  return transformedData;
};


const formatEventsForCalendar = () => {
  let markedDates = {};
  Object.keys(events).forEach(date => {
    if (events[date].length > 0) {
      markedDates[date] = { marked: true };
    }
  });
  markedDates[selectedDate] = { ...markedDates[selectedDate], selected: true };
  return markedDates;
};

  

  


const onProcedureModalSubmit = async (newDataItem, newImageUris) => {
  if (activeItem) {
    try {
      // Mark the reminder as done
      await DELETE_ITEM_API('update_reminder', {
        id: activeItem.id,
        description: activeItem.description,
        type: activeItem.type,
        deadline: activeItem.deadline,
        status: 0
      });

      // Update the events state to remove the done item
      setEvents(prevEvents => {
        const dateKey = activeItem.deadline.split(' ')[0];
        const updatedEventsForDate = prevEvents[dateKey].filter(item => item.id !== activeItem.id);
        
        // Create a new events object without the done item
        const updatedEvents = { ...prevEvents, [dateKey]: updatedEventsForDate };

        // Remove the date key if there are no more events for that date
        if (updatedEvents[dateKey].length === 0) {
          delete updatedEvents[dateKey];
        }

        return updatedEvents;
      });

      // If new images are provided, handle the upload
      if (newImageUris.length > 0) {
        const imagesBase64 = await encodeImagesWithMimeType(newImageUris);
        const updatedDataItem = {
          ...newDataItem,
          id: '',
          files: imagesBase64
        };

        const body = {
          method: 'insert_procedure',
          params: updatedDataItem
        };

        const responseJson = await executePostRequest(body);

        if (responseJson && responseJson.id) {
          showAlert();
        } else {
          console.error('Upload failed');
        }
      } else {
        showAlert();
      }

    } catch (error) {
      console.error('Error during API call:', error);
    }
  }
};


  const showAlert = () => {
    Alert.alert(
        "تم الحفظ",
        "لقد تم تسجيل انجاز الاستحقاق بنجاح. هل ترغب بإضافة استحقاق اضافي؟",
        [
            {
                text: "كلا",
                onPress: () => console.log("User does not want to perform another action"),
                style: "cancel"
            },
            { 
                text: "نعم", onPress: () => {
                setFollowupReminder(true); setReminderModalVisible(true); setFromItem(false); 
                    // You can navigate to another screen or trigger another action here
                } 
            }
        ]
    );
};


  const handleDayPress = (day) => {
    setSelectedDate(day.dateString);
    updateWeekDaysFromCalendar(day.dateString); // Update week days based on the selected date
    setIsCalendarVisible(false);
  };
  

  const handleWeekRowDayPress = (dayString) => {
    setSelectedDate(dayString); // Only update the selected date
  };
  
    

  const updateWeekDaysFromCalendar = (date) => {
    const startOfWeek = moment(date).startOf('isoWeek');
    const days = Array.from({ length: 7 }).map((_, i) => 
        startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD')
    );
    setWeekDays(days);
  };
  

  
  const renderItem = (item) => {
    // Check the item's origin and render accordingly
    if (item.Source === 'SQLite') {
      // Render SQLite reminder
      return <SQLiteReminderItem item={item} />;
    } else {
      // Render server reminder
      return <ServerReminderItem item={item} />;
    }
  };
  const ServerReminderItem = ({item}) => {
    console.log('ITEM:', item)
    
    return (

      <View style={item.id <0 ? styles.disabledView : {}}
pointerEvents={item.id <0 ? 'none' : 'auto'}
>

      <Drawer
      rightItems={[{ text: 'تعديل', background: Colors.blue30, onPress: () => handleItemPress(item)}, { text: 'انجاز', background: Colors.green10, onPress: () => handleRemiderDone(item)}]}
      leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => {showDeleteConfirmation(item.id) }}}
      
    >
     

      <View
        style={styles.item}
        onPress={() => Alert.alert(item.description)}
      >
       
        <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text style={{fontSize:12, color: 'grey'}}>
        {!searchModalVisible ? 
  item.deadline.split(' ')[1].split(':').slice(0, 2).join(':'): item.deadline }
</Text>

          <View style={styles.lawsuitSection}>
          <Chip
            label={item.type_name}
            // iconSource={checkmark}
            borderRadius={22}
            containerStyle={{
              borderColor: Colors.red20,
              borderBottomLeftRadius: 0,
              // marginLeft: Spacings.s3
            }}
            />
            {item.files && item.files.length > 0 && (
            <View style={styles.circle}>
            <Ionicons
              name="attach"
              size={20}
              color="white"
              onPress={() => toggleCarouselModal(item.files || item.file_id)}
            />
          </View>
          )}
          </View>

        </View>
        
        <View style={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 5, marginBottom: 10 }}>

<CustomChip
label={item.lawsuit_title}
iconSource={checkmark}
onPress={() => {navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:item.lawsuit_id, lawsuit_info_id: item.lawsuit_info_id, fin_id: item.fin_id, HeaderTitle:item.lawsuit_title}}); setSearchModalVisible(false)}} 
tintColor="white" // Set the icon tint color here
/>

{/*
          <Chip
            label={item.lawsuit_title}
            iconSource={checkmark}
            iconStyle={{ width: 24, height: 24, marginRight: 10 }}
            labelStyle={{ maxWidth: '80%', fontSize: 14 }}
            iconProps={{ tintColor: 'blue' }}
            borderRadius={22}
            containerStyle={{
              borderColor: Colors.blue10,
              // borderBottomLeftRadius: 0,
              // marginLeft: Spacings.s3
            }}
            onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:item.lawsuit_id, lawsuit_info_id: item.lawsuit_info_id, fin_id: item.fin_id, HeaderTitle:item.lawsuitText}})} />
          
*/}

        </View>

        <Text style={{ fontSize: 12 }}>{item.description}</Text>
        
      {/*
        <Modal
          animationType="slide"
          transparent={true}
          visible={isCarouselModalVisible}
          onRequestClose={toggleCarouselModal}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Carousel
                containerStyle={{ height: 200 }}
              >
                {fileLinks.map((link, index) => (
                  <Image
                    key={index}
                    source={{ uri: link }}
                    style={{ width: '100%', height: '100%' }}
                    resizeMode="contain"
                  />
                ))}
              </Carousel>
              <Button
                title="Close"
                onPress={toggleCarouselModal}
              />
            </View>
          </View>
        </Modal>
                */}


        
        
      </View>
     
      </Drawer>
      </View>
      
    );
  };

  const handleSQliteDonePress = (item) => {
    const onSuccess = () => {
      const idToRemove = item.id;
      const newData = Object.fromEntries(
        Object.entries(events).map(([key, value]) => [
          key,
          value.filter(eventItem => eventItem.id !== idToRemove)
        ])
      );
  
      // Remove empty days
      Object.keys(newData).forEach(date => {
        if (newData[date].length === 0) {
          delete newData[date];
        }
      });
  
      setEvents(newData);
    };
  
    const onFailure = (errorText) => {
      Alert.alert('Error', 'Failed to update item: ' + errorText);
    };
  
    db.transaction(tx => {
      tx.executeSql(
        'UPDATE reminders SET status = 0 WHERE id = ?;',
        [item.id],
        (_, result) => {
          if (result.rowsAffected > 0) {
            onSuccess();
          } else {
            onFailure('No rows affected');
          }
        },
        (_, error) => {
          onFailure(error.message);
        }
      );
    });
  };
  
  


  const SQLiteReminderItem = ({ item }) => {
   console.log(item);
    return (
      <Drawer
        rightItems={[
          { text: 'تعديل', background: Colors.blue30, onPress: () => handleSQliteItemPress(item) },
          { text: 'انجاز', background: Colors.green10, onPress: () => showDoneConfirmationSQlite(item) } // Add Done button
       
        ]}
        leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => showDeleteConfirmationSQlite(item.id) }}
      >
        <View style={[styles.item, { height: item.height, backgroundColor: 'linen' }]}>
          <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text style={{ fontSize: 12, color: 'grey' }}>
            {!searchModalVisible ? 
  item.deadline.split(' ')[1].split(':').slice(0, 2).join(':'): item.deadline }
              
            </Text>
            <View style={styles.lawsuitSection}>
              <Chip
                label='موعد'
                borderRadius={22}
                containerStyle={{ borderColor: Colors.red20 }}
              />
            </View>
          </View>
  
          <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
            <Ionicons name="person" size={36} color="orange" />
            <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Text style={{ fontSize: 15, marginLeft: 8 }}>
              {item.clientName}
            </Text>
           
            <Text style={{ fontSize: 12 , marginLeft: 8 }}>
            {item.description}
              </Text>
              </View>
          </View>
  
          {/* Rest of your component */}
        </View>
      </Drawer>
    );
  };

  const getArabicDayName = (englishDayName) => {
    const dayNames = {
        'Sun': 'الأحد',
        'Mon': 'الاثنين',
        'Tue': 'الثلاثاء',
        'Wed': 'الأربعاء',
        'Thu': 'الخميس',
        'Fri': 'الجمعة',
        'Sat': 'السبت'
    };
    return dayNames[englishDayName] || englishDayName;
};

const fetchReminderTypesFromStorage = async () => {
  try {
    const json = await AsyncStorage.getItem('select_reminder_types');
    //console.log('Types from AsyncStorage:', json);
    
    if (json !== null) {
      const typesData = JSON.parse(json);
      //console.log('Parsed types data:', typesData);
      
      // Since typesData is already an array, you can set it directly
      setTypeValues([...typesData]);
    } else {
      //console.log('No data found in AsyncStorage for reminder types');
      setTypeValues([]);
    }
  } catch (error) {
    console.error('Error fetching reminder types from AsyncStorage:', error);
    setTypeValues([]);
  }
};
  

const updateSQLite = (newReminder) => {
  setEvents(prevEvents => {
    const updatedEvents = { ...prevEvents };
    const formattedDate = newReminder.deadline.split(' ')[0];

    let originalDateKey;
    for (const dateKey in updatedEvents) {
      if (updatedEvents[dateKey].some(item => item.id === newReminder.id)) {
        originalDateKey = dateKey;
        break;
      }
    }

    if (originalDateKey && originalDateKey !== formattedDate) {
      updatedEvents[originalDateKey] = updatedEvents[originalDateKey].filter(item => item.id !== newReminder.id);

      if (updatedEvents[originalDateKey].length === 0) {
        delete updatedEvents[originalDateKey];
      }
    }

    if (updatedEvents[formattedDate]) {
      const eventIndex = updatedEvents[formattedDate].findIndex(event => event.id === newReminder.id);

      if (eventIndex !== -1) {
        updatedEvents[formattedDate][eventIndex] = newReminder;
      } else {
        updatedEvents[formattedDate].push(newReminder);
      }
    } else {
      updatedEvents[formattedDate] = [newReminder];
    }

    updatedEvents[formattedDate] = sortItemsByTime(updatedEvents[formattedDate]);

    return updatedEvents;
  });
};


/*
const sortItemsByTime = (items) => {
  return items.sort((a, b) => {
    // Assuming 'deadline' is in the format 'YYYY-MM-DD HH:MM:SS'
    const timeA = a.deadline.split(' ')[1]; // Extract the time part
    const timeB = b.deadline.split(' ')[1]; // Extract the time part
    return timeA.localeCompare(timeB); // Compare the times as strings
  });
};
*/

const handleItemUpdate = (updatedItem, deletedIds = [], addedUris = []) => {
  if (!updatedItem) {
    console.error('No updated item provided.');
    return;
  }

  if (deletedIds.length > 0) {
    updatedItem.files = updatedItem.files.filter(fileId => !deletedIds.includes(fileId));
  }

  if (addedUris.length > 0) {
    fetchRemindersForMonth(); // Trigger only if files are added
  }

  setEvents((currentItems) => {
    const updatedItemDateKey = updatedItem.deadline.split(' ')[0];

    let originalDateKey;
    for (const dateKey in currentItems) {
      if (currentItems[dateKey].some(item => item.id === updatedItem.id)) {
        originalDateKey = dateKey;
        break;
      }
    }

    if (originalDateKey && originalDateKey !== updatedItemDateKey) {
      currentItems[originalDateKey] = currentItems[originalDateKey].filter(item => item.id !== updatedItem.id);

      if (currentItems[originalDateKey].length === 0) {
        delete currentItems[originalDateKey];
      }

      if (!currentItems[updatedItemDateKey]) {
        currentItems[updatedItemDateKey] = [];
      }
      currentItems[updatedItemDateKey].push(updatedItem);

      currentItems[originalDateKey] = sortItemsByTime(currentItems[originalDateKey]);
      currentItems[updatedItemDateKey] = sortItemsByTime(currentItems[updatedItemDateKey]);
    } else {
      currentItems[updatedItemDateKey] = currentItems[updatedItemDateKey].map(item => item.id === updatedItem.id ? updatedItem : item);
      currentItems[updatedItemDateKey] = sortItemsByTime(currentItems[updatedItemDateKey]);
    }

    return { ...currentItems };
  });

  setIsEditModalVisible(false);
};



const sortItemsByTime = (items) => {
  return items.sort((a, b) => {
    // Assuming 'deadline' is in the format 'YYYY-MM-DD HH:MM:SS'
    const timeA = a.deadline.split(' ')[1]; // Extract the time part
    const timeB = b.deadline.split(' ')[1]; // Extract the time part
    return timeA.localeCompare(timeB); // Compare the times as strings
  });
};


const handleRemiderDone = async(item) =>{
  setActiveItem(item); // Set the active item
  setProcedureModalVisible(true); // Show the modal
};
 

/*const handleSearch = () => {
  // Perform the search operation
  const filteredItems = filterItemsByDescription(searchQuery);

  // Update the state with the filtered items or you can pass them directly to the FlatList in the modal

  // Open the search results modal
  setSearchModalVisible(true);
};
*/
const filterItemsByDescription = (query) => {
  const lowercasedQuery = query.toLowerCase();

  const filteredItems = Object.keys(events).reduce((acc, date) => {
    const filteredForDate = events[date].filter(item => {
      // Check if any of the fields include the query string
      return (
        (item.description && item.description.toLowerCase().includes(lowercasedQuery)) ||
        (item.clientName && item.clientName.toLowerCase().includes(lowercasedQuery)) ||
        (item.lawsuit_title && item.lawsuit_title.toLowerCase().includes(lowercasedQuery))
      );
    });

    if (filteredForDate.length > 0) {
      acc[date] = filteredForDate;
    }
    return acc;
  }, {});

  const transformedData = Object.keys(filteredItems).reduce((acc, date) => {
    return [...acc, ...filteredItems[date].map(event => ({ ...event, date }))];
  }, []);

  // Sort the transformed data by date and time
  const sortedData = sortRemindersByDateAndTime(transformedData);
  setFlatListData(sortedData);
  setSearchModalVisible(true); // Show the search modal
};

 

const sortRemindersByDateAndTime = (reminders) => {
  return reminders.sort((a, b) => {
    const dateA = new Date(a.date + ' ' + a.reminder_time);
    const dateB = new Date(b.date + ' ' + b.reminder_time);
    return dateA - dateB;
  });
};


useEffect(() => {
  const transformedData = Object.keys(events).reduce((acc, date) => {
    return [...acc, ...events[date].map(event => ({ ...event, date }))];

  }, []);

  const sortedData = sortRemindersByDateAndTime(transformedData);
  setFlatListData(sortedData);

  fetchReminderTypesFromStorage();
}, [events]);








const renderDay = (dayString) => {
  const isSelected = dayString === selectedDate;
  const isToday = dayString === moment().format('YYYY-MM-DD'); // Check if it's today
  const hasEvents = events[dayString] != null;
  const englishDayName = moment(dayString).format('ddd');

  return (
    <TouchableOpacity
      key={dayString}
      onPress={() => handleWeekRowDayPress(dayString)}
      style={[
        { padding: 10, alignItems: 'center' },
        isSelected ? { backgroundColor: 'lightblue' } : {},
        isToday ? styles.todayHighlight : {} // Apply today's style
      ]}
    >
      <Text style={isToday ? styles.todayText : {}}>
        {getArabicDayName(englishDayName)}
      </Text>
      <Text style={[isToday ? styles.todayText : {}]}>
        {moment(dayString).format('DD')}
      </Text>
      {hasEvents && (
        <View style={{
          height: 5,
          width: 5,
          borderRadius: 2.5,
          backgroundColor: 'blue',
          marginTop: 2
        }}/>
      )}
    </TouchableOpacity>
  );
};


const renderFlatlistItem = (item) => {
  return (



    <View style={item.id <0 ? styles.disabledView : {}}
pointerEvents={item.id <0 ? 'none' : 'auto'}
>

      <Drawer
      rightItems={[{ text: 'تعديل', background: Colors.blue30, onPress: () => handleItemPress(item)}, { text: 'انجاز', background: Colors.green10, onPress: () => handleRemiderDone(item)}]}
      leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => {showDeleteConfirmation(item.id) }}}
      
    >
     

      <View
        style={styles.item}
        onPress={() => Alert.alert(item.description)}
      >
       
        <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text style={{fontSize:12, color: 'grey'}}>
  {item.deadline.split(' ')[1].split(':').slice(0, 2).join(':')}
</Text>

          <View style={styles.lawsuitSection}>
          <Chip
            label={item.type_name}
            // iconSource={checkmark}
            borderRadius={22}
            containerStyle={{
              borderColor: Colors.red20,
              borderBottomLeftRadius: 0,
              // marginLeft: Spacings.s3
            }}
            />
            {item.files && item.files.length > 0 && (
            <View style={styles.circle}>
            <Ionicons
              name="attach"
              size={20}
              color="white"
              onPress={() => toggleCarouselModal(item.files || item.file_id)}
            />
          </View>
          )}
          </View>

        </View>
        
        <View style={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 5, marginBottom: 10 }}>

<CustomChip
label={item.lawsuit_title}
iconSource={checkmark}
onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:item.lawsuit_id, lawsuit_info_id: item.lawsuit_info_id, fin_id: item.fin_id, HeaderTitle:item.lawsuitText}})} 
tintColor="white" // Set the icon tint color here
/>

{/*
          <Chip
            label={item.lawsuit_title}
            iconSource={checkmark}
            iconStyle={{ width: 24, height: 24, marginRight: 10 }}
            labelStyle={{ maxWidth: '80%', fontSize: 14 }}
            iconProps={{ tintColor: 'blue' }}
            borderRadius={22}
            containerStyle={{
              borderColor: Colors.blue10,
              // borderBottomLeftRadius: 0,
              // marginLeft: Spacings.s3
            }}
            onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:item.lawsuit_id, lawsuit_info_id: item.lawsuit_info_id, fin_id: item.fin_id, HeaderTitle:item.lawsuitText}})} />
          
*/}

        </View>

        <Text style={{ fontSize: 12 }}>{item.description}</Text>
        
      {/*
        <Modal
          animationType="slide"
          transparent={true}
          visible={isCarouselModalVisible}
          onRequestClose={toggleCarouselModal}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Carousel
                containerStyle={{ height: 200 }}
              >
                {fileLinks.map((link, index) => (
                  <Image
                    key={index}
                    source={{ uri: link }}
                    style={{ width: '100%', height: '100%' }}
                    resizeMode="contain"
                  />
                ))}
              </Carousel>
              <Button
                title="Close"
                onPress={toggleCarouselModal}
              />
            </View>
          </View>
        </Modal>
                */}


        
        
      </View>
     
      </Drawer>
      </View>
  );
}

const renderContent = () => {
  if (Platform.OS === 'web') {
    // Web-specific layout: Calendar on the left, event list on the right
    return (
      <View dir={'rtl'} style={styles.webContainer}>
        
        <View style={styles.eventListContainer}>
        <View style={styles.dateInfo}>
    <Text style={styles.selectedDate}>
        {moment(selectedDate).format('DD')}
    </Text>
    <Text style={styles.selectedDay}>
        {getArabicDayName(moment(selectedDate).format('ddd'))}
    </Text>
</View>

        <FlashList
            data={events[selectedDate] || []}
              keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => renderItem(item)}
            ListEmptyComponent={<View dir={'rtl'}><Text style={{ color: 'grey', margin: 10 }}>لا يوجد استحقاقات في هذا التاريخ</Text></View>}
           // style={styles.eventsList}
            estimatedItemSize={50}
          />
        </View>

        <View style={styles.calendarContainer}>
       
          <Calendar
         
            onDayPress={handleDayPress}
            markedDates={formatEventsForCalendar()}
            firstDay={1} // Set Monday as the first day of the week
          //  onVisibleMonthsChange={(months) => onMonthChange(months)}
            theme={{
              textSectionTitleColor: '#b6c1cd',
              textSectionTitleDisabledColor: '#d9e1e8',
              selectedDayBackgroundColor: '#00adf5',
              selectedDayTextColor: '#ffffff',
              todayTextColor: '#00adf5',
              dayTextColor: '#2d4150',
              textDisabledColor: '#d9e1e8',
              dotColor: '#00adf5',
              selectedDotColor: '#ffffff',
              arrowColor: 'orange',
              disabledArrowColor: '#d9e1e8',
              monthTextColor: 'blue',
              indicatorColor: 'blue',
              textDayFontFamily: 'monospace',
              textMonthFontFamily: 'monospace',
              textDayHeaderFontFamily: 'monospace',
              textDayFontWeight: '300',
              textMonthFontWeight: 'bold',
              textDayHeaderFontWeight: '300',
              textDayFontSize: 24, // Customize day number font size
              textMonthFontSize: 32, // Customize month title font size
              textDayHeaderFontSize: 14, // Customize day name font size
              fontFamily: 'DefFont', // Set your desired font family here

            }}
          />
        </View>
      </View>
    );
  } else {
    // Non-web layout: Week row and toggle button for the calendar
    return (
      <>
          {!isCalendarVisible && (
        <View dir={'rtl'} style={{ backgroundColor: 'white' }}>

 {/* Month Title */}
 <Text style={styles.monthTitle}>
          {getCurrentMonthName(selectedDate)}
        </Text>


          <View style={{ flexDirection: 'row', justifyContent: 'space-around', backgroundColor: 'white' }}>
            {weekDays.map((dayString) => renderDay(dayString))}
          </View>
          <TouchableOpacity
            onPress={toggleCalendar}
            style={{ height: 20 }}
          >
            <Button style={styles.toggleButton} onPress={toggleCalendar}

            ></Button>
          </TouchableOpacity>
        </View>
      )}  

      {isCalendarVisible && (
        <View dir={'rtl'} >
          <Calendar
            onDayPress={handleDayPress}
            markedDates={formatEventsForCalendar()}
            firstDay={1} // Set Monday as the first day of the week

          />
          <Button
            title={isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
            onPress={toggleCalendar}
            color={Colors.blue30}
            style={styles.toggleButton}
          />
        </View>
      )}

      {!isCalendarVisible && (
        <View dir={'rtl'} style={styles.dateAndEventsContainer}>
         <View style={styles.dateInfo}>
    <Text style={styles.selectedDate}>
        {moment(selectedDate).format('DD')}
    </Text>
    <Text style={styles.selectedDay}>
        {getArabicDayName(moment(selectedDate).format('ddd'))}
    </Text>
</View>



          <FlashList
            data={events[selectedDate] || []}
              keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => renderItem(item)}
            ListEmptyComponent={<View dir={'rtl'}><Text style={{ color: 'grey', margin: 10 }}>لا يوجد استحقاقات في هذا التاريخ</Text></View>}
           // style={styles.eventsList}
            estimatedItemSize={50}
          />
        </View>
      )}
      </>
    );
  }
};



useEffect(() => {
  if (searchQuery && isFocused) {
    filterItemsByDescription(searchQuery);
    setSearchModalVisible(true);
  } else if (isFocused) {
    fetchRemindersForMonth();
  }
}, [searchQuery, isFocused]);




const [filesId, setFilesId] = useState([]);


const toggleCarouselModal = (files) => {
  ////console.log("Toggling carousel modal"); // Add this
  setIsCarouselModalVisible(!isCarouselModalVisible);
  if (files && files.length > 0) {
    setFilesId(files);
  }
};


const fetchFileLink = async (fileId) => {
  // Get the user token
  const token = await getToken();

  // Make an API call to fetch the file link
  const response = await fetch('https://maktabi.info/includes/model.php', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      method: 'select_file',
      params: {
        id: fileId
      }
    }),
  });

  const responseData = await response.json();

  // Checking if responseData is a valid data URI
  if (typeof responseData === 'string' && responseData.startsWith('data:')) {
    return responseData;
  } else {
    console.error(`Error fetching file with ID ${fileId}: Invalid data URI format in the response.`);
    return null;
  }
};

// Function to fetch all file links for a given array of file IDs
const fetchFileLinks = async (fileIds) => {
  const links = await Promise.all(fileIds.map(id => fetchFileLink(id)));
  setFileLinks(links.filter(link => link !== null && link !== undefined));
  // Set imagesLoading to false once all file links have been fetched
  //setImagesLoading(false);
};


const handleItemPress = async (item) => {
  if (item.files && item.files.length > 0) {
    // Wait for fetchFileLinks to finish before continuing
    await fetchFileLinks(item.files);
  }
  setSelectedItem(item);
  setIsEditModalVisible(true);
  console.log(item);
};

const onActionSheetSubmit = (selectedValues) => {
  console.log(selectedValues);
  const newFilters = {
    method: 'select_grouped_reminders',
    params: {
      date_start: selectedValues.fromDate,
      date_end: selectedValues.toDate,
      lawyer_id: selectedValues.LawyerId,
      lawsuit_id: selectedValues.LawsuitId.split(',')[0],
      type_id: selectedValues.TypeId,
      phase_id: selectedValues.PhaseId,
      j_ref_id: selectedValues.JRefId,
      remtype_id: selectedValues.ReminderTypeId,
      status: 1  
    }
  };

  setFilters(newFilters);

  // Clear existing events to ensure no old data is mixed in
  setEvents({});

  // Fetch only from the server
  fetchRemindersForMonth(false).then(() => {
    if (Object.keys(events).length === 0) {
      // If no records found, show an alert or handle it accordingly
      console.log('No records found');
    }
  });
};





const toggleCalendar = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };
  

  return (
    <View style={styles.container}>

 {renderContent()}



{isActionSheetVisible &&
      <LawsuitFilterModal
        isVisible={isActionSheetVisible}
        toggleVisibility={() => setIsActionSheetVisible(!isActionSheetVisible)}
        onSubmit={onActionSheetSubmit}
        showOpponentPicker={false}
        showJRegionPicker={false}
        showIsActivePicker={false}
        showProcedureTypePicker={false}
        showClientPicker={false}
       // showDate={false}
        resetKey={resetKey}

      />
}

    {ReminderModalVisible &&
      <AddReminderModal
        key={Date.now()}
        modalVisible={ReminderModalVisible}
        setModalVisible={setReminderModalVisible}
        // addNewReminderHandler={addNewReminderHandler}
        route={followupReminder ? activeItem : null}
        fromItem={fromItem}
        itemDate={ItemDate}
        onClose={handleModalClose}


      //types={types} 
      //lawsuits={lawsuits} 
      />
}

{searchModalVisible && (
  <Modal
    animationType="slide"
    transparent={false}
    visible={searchModalVisible}
    onRequestClose={closeSearchModal} // Update this line
  >
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity onPress={() => setIsActionSheetVisible(true)}>
          <Ionicons name="filter" size={18} color="black" style={{ marginTop: 10, marginLeft: 10 }} />
        </TouchableOpacity>
        <Text style={{ alignSelf: 'center', margin: 10, fontSize: 20, fontWeight: 'bold' }}>تصفية الاستحقاقات</Text>
      </View>
      <FlatList
        style={{ flex: 1 }}
        data={flatListData}
        renderItem={({ item }) => renderItem(item)}
        keyExtractor={(item, index) => `${item.id}-${index}`}
      />
      <TouchableOpacity style={{ alignItems: 'center', marginBottom: 10 }} onPress={closeSearchModal}>
        <View style={{ backgroundColor: 'blue', paddingHorizontal: 20, paddingVertical: 10, borderRadius: 5 }}>
          <Text style={{ color: 'white' }}>خروج</Text>
        </View>
      </TouchableOpacity>
    </View>
  </Modal>
)}





      {AddNewAppoinntmentVisible &&
        <Modal
          transparent={true}
          visible={AddNewAppoinntmentVisible}
          animationType="slide"
        >
         
            <View style={styles.modalBackdrop} >
              <AddAppointment
                CancelAddAppointment={() => setAddNewAppoinntmentVisible(false)}
                OnSubmit={addToLocalArray}
                onUpdate={updateSQLite}
                editData={selectedItem}
                
              />
            </View>
         
        </Modal>
      }

      
{isCarouselModalVisible &&
      <Modal
        transparent={false}
        visible={isCarouselModalVisible}
        animationType="slide"
      >

        <ImageCarouselModal
          fileLinks={filesId}
        // onClose={() => setIsCarouselModalVisible(false)}
        />
        

        <TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setIsCarouselModalVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
      </Modal>
}


{selectedItem && (
  <ReminderEdit
  key={selectedItem.id}

      isVisible={IsEditModalVisible} 
     onClose={() => setIsEditModalVisible(false)} 
      onEntryUpdated={(selectedID, value, selectedTypeText, combinedDateTime, deletedIds, addedUris) => {
        const updatedItem = { ...selectedItem, description: value, deadline: combinedDateTime, type_name: selectedTypeText  };
        handleItemUpdate(updatedItem, deletedIds, addedUris);
      }}   
      initialValue={selectedItem.description}
      initialType={selectedItem.type}
      initialDate={new Date(selectedItem.deadline)}
      initialTime={new Date(selectedItem.deadline)}
      TypeValues={typeValues}
      initialID={selectedItem.id}
      initialFiles={selectedItem.files}
            
    />
)}

{ProcedureModalVisible &&
<ProcedureModal
  modalVisible={ProcedureModalVisible}
  setModalVisible={setProcedureModalVisible}
  route={null}
  onModalSubmit={onProcedureModalSubmit}
  prefillData={activeItem ? { 
    description: activeItem.description, 
    type: activeItem.type_ID,  
    lawsuit_id: activeItem.lawsuit_id, 
    lawsuit_info_id: activeItem.lawsuit_info_id
  } : {}}
/>
}

<SpeedDial
    isOpen={open}
    icon={{ name: 'edit', color: '#fff' }}
    openIcon={{ name: 'close', color: '#fff' }}
    onOpen={() => setOpen(!open)}
    onClose={() => setOpen(!open)}
  >
     <SpeedDial.Action
      icon={{ name: 'insert-invitation', color: '#fff' }}
      title="اضافة موعد"
      onPress={() => { handleAddNewAppointment();  setOpen(!open) }}
    />
    <SpeedDial.Action
      icon={{ name: 'add', color: '#fff' }}
      title=" اضافةاستحقاق"
      onPress={() => { setReminderModalVisible(true); setFromItem(false); setOpen(!open) }}
    />

<SpeedDial.Action
      icon={{ name: 'filter-list', color: '#fff' }}
      title="تصفية الاستحقاقات"
      onPress={() => {setSearchModalVisible(true); setOpen(!open)}}
    />

 
  </SpeedDial>

{/*
  <LoadingOverlay />
*/}
    </View>

    
  );
};

const styles = StyleSheet.create({
  disabledView: {
    opacity: 0.5,
  },
  item: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    marginVertical: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation: 2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  overlayButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '80%',
  },
  overlayButton: {
    padding: 10,
    backgroundColor: '#007AFF',
    color: 'white',
    borderRadius: 5,
    marginHorizontal: 5,
  },
  lawsuitSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: '#FF3B30',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
  },
  todayHighlight: {
    //backgroundColor: 'lightgrey', // Example background color
    //borderRadius: 100, // Example to make it rounded
    //padding: 5,
  },
  todayText: {
    color: 'red', // Example text color
  },
  circle: {
    height: 25, // Set the height
    width: 25, // Set the width
    borderRadius: 25, // Half the size of the height and width to make it a circle
    backgroundColor: 'green', // Set your desired background color
    justifyContent: 'center', // Center the icon vertically
    alignItems: 'center', // Center the icon horizontally
    marginLeft:10
  },
  container: {
    flex: 1,
    backgroundColor: '#f2f2f2'
  },
 dateAndEventsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start', // Align items to the top
    justifyContent: 'space-between',
    width: '100%',
    flex:1,
  },
  dateInfo: {
    alignItems: 'center',
    padding: 10,
  
  },
  selectedDate: {
    fontSize: 24,
    fontWeight: 'bold',
    color: Colors.red30,
  },
  selectedDay: {
    fontSize: 14,
    color: Colors.grey10
  },
  eventsList: {
    flex: 1
  },
  toggleButton: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 10,
    alignSelf: 'center',
    marginVertical: 10
  },
  item: {
    // marginBottom: 17, // Add this line
    borderBottomWidth: 0.5, // Add this line for a thin line
    borderBottomColor: '#ccc', // Add this line for a gray line
    backgroundColor: 'white',
    flex: 1,
    //borderRadius: 5,
    padding: 10,
    //marginRight: 10,
    //marginTop: 17,
    
  },

  FlatlistItem: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: 5,
    padding: 10,
    //marginRight: 10,
    marginTop: 17
  },
  FlatlistModal: {
    width: '90%',
    backgroundColor: 'white',
    padding: 40,
    alignItems: 'center',
    borderRadius: 10,
  },
  Flatlistcentered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  emptyDate: {
    height: 15,
    flex: 1,
    paddingTop: 30,
    //  backgroundColor:'#E6ECEF',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'
  },
  container: {
    flex: 1,
    height: '100vh', // 100% of the viewport height
    
  },
  searchContainer: {
    felx: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'white',
  },
  searchIcon: {
    marginLeft: 10
  },
  searchInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    marginRight: 10,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    marginLeft: 10
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  monthTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'DefFont',
    color: 'black',
    padding: 10,
  },

  clearIcon: {
    marginRight: 10,
  },
  lawsuitSection: {
      
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
 centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalBackdrop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  webContainer: {
    flexDirection: 'row', // Align calendar and event list side by side horizontally
    //flexWrap: 'wrap', // Allow items to wrap into the next row as needed
    //width: '100%', // Ensure the container takes the full width of the screen
    //height: '100%', // Take up all available space in the container
    flex: 1,
    alignContent:'space-between',
    justifyContent:'space-between'
  },
  calendarContainer: {
    width: '35%', // Calendar takes up half of the width
   // height: '50%', // Calendar takes up half of the height
 //  alignContent:'stretch'
   
},

  eventListContainer: {
   width: '65%', // Event list takes up half of the width
   // height: '50%', // Event list takes up half of the height
    // Additional styles for the event list container...
  },
});
export default CalendarScreen;
