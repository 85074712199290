// utils/api.js
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import * as SQLite from 'expo-sqlite/legacy';


const apiUrl = Constants.expoConfig.extra.apiUrl;



export const getToken = async () => {
    try {
      let token;

      if (Platform.OS === 'web') {
        // For web, use localStorage
        token = localStorage.getItem('token');
      } else {
        // For native platforms, use SecureStore (async)
        token = await SecureStore.getItemAsync('token');
      }
        return token || null;
    } catch (e) {
        console.error(e);
        return null;
    }
}; 

export const fetchData = async (param, handleResponse = 'json', doubleParseContent = false) => {
    try {
        const token = await getToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(param),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        let text = await response.text();
    //  console.log('API response text:', text);  // Log the raw response text
 
       
        if (text.length !== 0) {
            try {
                let json = JSON.parse(text);
              // console.log('Parsed JSON:', json);

              if (typeof json === 'number') {
                return json;
            }
        if (typeof json === 'boolean') {
          // Directly return the boolean value if the API returns true/false
          return json;
      }
      
                if (doubleParseContent && json && typeof json.content === "string") {
                    json.content = JSON.parse(json.content);
                }
        
                // Handle JSON object response
                if (json && typeof json === 'object') {
                    if (Array.isArray(json)) {
                     // console.log('JSON: ',json, 'param: ', param);
                        return json;  // Return JSON array as-is
                    }
                    return json.results || json;  // Return JSON object or its 'results' property
                } else {
                    throw new Error('Invalid API response structure');
                }
            } catch (error) {
                // If handleResponse is 'text', return the raw text response
                if (handleResponse === 'text') {
                    return text;
                }
        
                console.error('Error parsing JSON:', error);
                throw new Error('Error parsing JSON: ' + error.message);
            }
        } else {
            // If handleResponse is 'text', return empty string for empty response
            if (handleResponse === 'text') {
                return '';
            }
        
            console.error('Empty response from the server');
            return null;  // Return null for empty response
        }
        

    } catch (error) {
        console.error(error);
        throw error;  // Propagate the error to the caller
    }
};



// Function to fetch the file link for a given file ID
export const fetchFileLink = async (fileId) => {
    // Get the user token
    const token = await getToken();

    // Make an API call to fetch the file link
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        method: 'select_file',
        params: {
          id: fileId
        }
      }),
    });

    const responseData = await response.json();
    // Checking if responseData is a valid data URI
    if (typeof responseData === 'string' && responseData.startsWith('data:')) {
      return responseData;
    } else {
      console.error(`Error fetching file with ID ${fileId}: Invalid data URI format in the response.`);
      return null;
    }
  };

 {/*
 export const DELETE_ITEM_API = async ( method, params, onSuccess, onFailure) => {
    try {
        const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          method: method,
          params: params
        })
      });
  
      if (response.ok) {
        const responseData = await response.json();
        onSuccess(responseData);
      } else {
        const errorText = await response.text();
        console.error("Error during API call:", errorText);
        onFailure(errorText);
      }
    } catch (error) {
      console.error("API call failed:", error.message);
      onFailure(error.message);
    }
  };
*/}
  

export const DELETE_ITEM_API = async (method, params) => {
  //const retryDelete = () => DELETE_ITEM_API(method, params, showSuccess, showError);
  try {
      const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          method: method,
          params: params
        })
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
       // showSuccess();
        return responseData; // Resolve the promise with the response data
      } else {
        const errorText = await response.text();
        console.error("Error during API call:", errorText);
      // showError('Delete Item Failed');
        throw new Error(errorText); // Reject the promise with the error text
      }
    } catch (error) {
      console.error("API call failed:", error.message);
     // showError('Delete Item Failed');  
      throw error; // Reject the promise with the error
      
    }
  };
  
  export const DELETE_FILE_BY_ID_API = async (method, params) => {
    try {
      const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          method: method,
          params: params
        })
      });
  
      if (response.ok) {
        const responseData = await response.json();
        return responseData; // Resolve the promise with the response data
      } else {
        const errorText = await response.text();
        console.error("Error during API call:", errorText);
        throw new Error(errorText); // Reject the promise with the error text
      }
    } catch (error) {
      console.error("API call failed:", error.message);
      throw error; // Reject the promise with the error
    }
  };


  export const ADD_FILE_API = async (method, params) => {
    try {
      const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          method: method,
          params: params
        })
      });
  
      if (response.ok) {
        const responseData = await response.json();
        return responseData; // Resolve the promise with the response data
      } else {
        const errorText = await response.text();
        console.error("Error during API call:", errorText);
        throw new Error(errorText); // Reject the promise with the error text
      }
    } catch (error) {
      console.error("API call failed:", error.message);
      throw error; // Reject the promise with the error
    }
  };
  

  /*
  export const encodeImagesWithMimeType= async(imageUris)=> {
    return Promise.all(imageUris.map(async uri => {
        if (!uri) {
            console.warn('Undefined URI found');
            return null;
        }

        const fileExtension = uri.split('.').pop().toLowerCase();
        let mimeType;
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
                mimeType = 'image/jpeg';
                break;
            case 'png':
                mimeType = 'image/png';
                break;
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'docx':
                mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                break;
            default:
                mimeType = 'application/octet-stream';
        }

        const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
        return `data:${mimeType};base64,${data}`;
    }));
};
*/

export const encodeImagesWithMimeType = async (imageInput) => {
  // Check if the input is an array, if not, convert it to an array
  const imageUris = Array.isArray(imageInput) ? imageInput : [imageInput];

  return Promise.all(imageUris.map(async uri => {
    if (!uri) {
      console.warn('Undefined URI found');
      return null;
    }

    const fileExtension = uri.split('.').pop().toLowerCase();
    let mimeType;
    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg';
        break;
      case 'png':
        mimeType = 'image/png';
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'docx':
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      default:
        mimeType = 'application/octet-stream';
    }

    const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
    return `data:${mimeType};base64,${data}`;
  }));
};


export const executePostRequest=async(body) => {
  try {
    const token = await getToken();
    const response = await fetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          },
      });
      return await response.json();
  } catch (error) {
      console.error('Error executing POST request:', error);
      throw error;
  }
};

export const formatDateTime= async(date, time) => {
  const combinedDateTime = new Date(date);
  combinedDateTime.setHours(time.getUTCHours());
  combinedDateTime.setMinutes(time.getUTCMinutes());
  combinedDateTime.setSeconds(time.getUTCSeconds());

  return `${combinedDateTime.toISOString().split('T')[0]} ${String(combinedDateTime.getUTCHours()).padStart(2, '0')}:${String(combinedDateTime.getUTCMinutes()).padStart(2, '0')}:${String(combinedDateTime.getUTCSeconds()).padStart(2, '0')}`;
};


  
// utils/api.js

{/*
export const fetchReminders = async (startDate, endDate) => {
  const newReminders = await fetchData({method: "select_reminders", params: {date_start: '2023-08-01 00:00:00', date_end: '2023-12-31 23:59:59', hparams:{}}});
  return newReminders;
};
*/}


// utils/api.js

export const checkRemindersDueSoon = (remindersList) => {
  const currentTime = new Date();
  return remindersList.filter(reminder => {
    const dueTime = new Date(reminder.dueDate);
    const timeDifference = dueTime - currentTime;
    return timeDifference <= 3600 * 1000 && timeDifference > 0;
  });
};

export const executeSqlWithRetry = async (db, query, params, maxRetries = 10, retryDelay = 2000) => {
  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      return await new Promise((resolve, reject) => {
        db.transaction(tx => {
          tx.executeSql(
            query,
            params,
            (_, result) => resolve(result),
            (_, error) => reject(error)
          );
        });
      });
    } catch (error) {
      if (error.message.includes('database is locked')) {
        attempt++;
        console.log(`Attempt ${attempt} failed due to database lock. Retrying in ${retryDelay / 1000} seconds...`);
        await new Promise(resolve => setTimeout(resolve, retryDelay)); // Increased delay before retrying
      } else {
        throw error;
      }
    }
  }
  throw new Error('Max retries reached for executeSql');
};



export const unlockDatabase = async (db) => {
  if (db) {
    try {
      await new Promise((resolve, reject) => {
        db.exec([{ sql: 'PRAGMA busy_timeout = 2000;', args: [] }], false, (error) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
      });
      console.log('Database unlocked.');
    } catch (error) {
      console.error('Failed to unlock database:', error);
    }
  }
};





export const fetchAndStoreData = async (key, param, alwaysFetchFresh = false) => {
  try {
    const existingData = await AsyncStorage.getItem(key);
    if (!existingData || alwaysFetchFresh) {
      const data = await fetchData(param);
     // //console.log(data);
      await AsyncStorage.setItem(key, JSON.stringify(data));
      return data;  // return the fresh data
    } else {
      return JSON.parse(existingData);  // return the existing data
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;  // re-throwing the error for handling at the caller
  }
};

/**
 * This function to Fetch data after loading 
 */
export const FETCH_LOADING_DATA = async (setLoading) => {
  
  setLoading(true); // Start loading
  try {
     

      // ... rest of your data fetching code ...
      const datasetsToFetch = [
        { key: 'select_types', param: { method: "select_types", params: { select2: true } } , doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_phases', param: {method: "select_phases", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_judicial_references', param:{method: "select_judicial_references", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_judicial_regions' , param:{method: "select_judicial_regions", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_users' , param:{method: "select_users", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_clients', param:{method: "select_clients", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: true},
        { key: 'select_opponents', param:{method: "select_opponents", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        {key: 'select_procedure_types' , param:{method: "select_procedure_types", params: {}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_reminder_types' , param:{method: "select_reminder_types", params: {}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_lawsuits', param:{method: "select_lawsuits", params: {}}, doubleParseContent: false, alwaysFetchFresh: true},
       { key: 'fetch_credentials', param: {method: "fetch_credentials", params: {}}, doubleParseContent: false, alwaysFetchFresh: true},
      ];
      const fetchPromises = datasetsToFetch.map(dataset => fetchAndStoreData(dataset.key, dataset.param, dataset.alwaysFetchFresh));
      await Promise.all(fetchPromises);
      //console.log('Data fetch complete');
      //setLoading(false);

  } catch (error) {
      console.error('Error in the data fetching process:', error);
      
  } finally{
    
  setLoading(false); // End loading
  }
};

