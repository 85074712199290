import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ClientListScreen from './screens/ClientListScreen';
import ClientDetailsScreen from './screens/ClientDetailsScreen';


const Stack = createStackNavigator();

const ClientNavigator = () => (
  <Stack.Navigator
  screenOptions={{
    headerStyle: {
      backgroundColor: '#0C0D07', // Set your desired color here
    },
    headerTintColor: '#fff', // Set the color of the header text and icons
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }}
  >
    <Stack.Screen name="ClientList" component={ClientListScreen} options={{ headerShown: false }} />
    <Stack.Screen name="ClientDetails" component={ClientDetailsScreen} options={{ title: 'بطاقة تعريف موكل ' }} />
  </Stack.Navigator>
);

export default ClientNavigator;
