import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  Image,
  Alert,
  ActivityIndicator,
  Button,
  Platform
} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import { Picker } from '@react-native-picker/picker';
import moment from 'moment'; // don't forget to install moment
import * as Camera from 'expo-camera';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getToken } from '../../../utils/api';
import ModalPicker from '../../../SharedComponents/ModalPicker';
import * as ImageManipulator from 'expo-image-manipulator';
import * as DocumentPicker from 'expo-document-picker';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import { useIndicator } from '../../../contexts/IndicatorContext';


const AddReminderModal = ({
  modalVisible,
  setModalVisible,
  route,
  // types, 
  //lawsuits 
  fromItem,
  itemDate,
  onClose,
}) => {

  const { showLoading, showSuccess, showError } = useIndicator();
 
  const [date, setDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [time, setTime] = useState(new Date());
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [textInputValue1, setTextInputValue1] = useState('');
  const [selectedType, setSelectedType] = useState('-1');
  const [selectedLawsuit, setSelectedLawsuit] = useState('-1');
  const [imageUris, setImageUris] = useState([]);
  const [lawsuits, setlawsuits] = useState([]);
  const [types, setType] = useState([]);
  const [lawsuit_id, setLawsuit_id] = useState(route?.params?.id || route?.lawsuit_id);
  const [lawsuit_info_id, setLawsuit_info_id] = useState(route?.params?.lawsuit_info_id || route?.lawsuit_info_id);
  const [currentPickerLabel, setCurrentPickerLabel] = useState('');
  const [isIOSModalVisible, setIOSModalVisible] = useState(false);
  const [currentPickerData, setCurrentPickerData] = useState([]);
  const [currentPickerValue, setCurrentPickerValue] = useState('');
  const currentPickerSetterRef = useRef(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newImageUris, setNewImageUris] = useState([]);
const [newRecord, setNewrecord] = useState('');
const [lawSuitText,setLawSuitText] = useState(route?.lawsuit_title || '');
const [selectedTypeText, setSelectedTypeText] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const [deadline, setDeadline] = useState('');


const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed.
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const formatTime = (time) => {
  const hours = String(time.getHours()).padStart(2, '0');
  const minutes = String(time.getMinutes()).padStart(2, '0');
  const seconds = String(time.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}


useEffect(() => {
  // Assuming formatDate and formatTime are functions that format your date and time respectively
  const newDeadline = formatDate(date) + ' ' + formatTime(time);
  setDeadline(newDeadline);
}, [date, time]); // Dependencies: useEffect will run when `date` or `time` changes



  // Update the openPicker function:
  const openPicker = (data, value, setter, label) => {
    setCurrentPickerData(data);
    setCurrentPickerValue(value);
    currentPickerSetterRef.current = setter;
    setCurrentPickerLabel(label);
    setIOSModalVisible(true);
  };

  // Update the handlePickerValueChange function:
  const handlePickerValueChange = (value) => {
    if (currentPickerSetterRef.current) {
      currentPickerSetterRef.current(value);
    }
    setIOSModalVisible(false);
    // If the picker is for lawsuits, handle the lawsuit selection
    if (currentPickerLabel === "اسم الدعوى") {
      const selectedItem = lawsuits.find(item => item.id === value);
      if (selectedItem) {
        handleSelectLawsuit(selectedItem);
      }
    }
  };


 


  const addNewReminder = async () => {
    showLoading();
    const randomNegativeId = -Math.floor(Math.random() * 1000000);

    // Create newRecord object with necessary data
    const newRecord = {
      deadline: deadline, // formatted 'YYYY-MM-DD HH:MM:SS'
      description: textInputValue1,
      files: newImageUris, // assuming this is an array of file information
      fin_id: '', // or the appropriate value
      id: randomNegativeId, // assuming responseJson.id is the new unique ID
      lawsuit_title: lawSuitText, 
      lawsuit_id: lawsuit_id,
      lawsuit_info_id: lawsuit_info_id,
      type_name: selectedTypeText, 
      type: selectedType,
      updated: Date.now(), // Add a timestamp or some unique value
        clientID: null, // Add new field clientID
      clientName: null, // Add new field clientName
      Source: 'server', // Add new field Source
   
    };

    // Send newRecord to the parent component (CalendarScreen)
    if (onClose) {
      onClose(newRecord);
    }

    setModalVisible(false); // Close the modal
  };




/*

  const addNewReminder = async () => {
   
    //setIsSubmitting(true);
    showLoading();
   
    
   // setModalVisible(false);  // Close the modal using the prop function



    setNewrecord({
          
      deadline: deadline, // formatted 'YYYY-MM-DD HH:MM:SS'
      description: textInputValue1,
      files: [], // assuming this is an array of file information
      fin_id: '', // or the appropriate value
      id: '-6', // assuming responseJson.id is the new unique ID
      lawsuit_title: lawSuitText, 
      lawsuit_id: lawsuit_id,
      lawsuit_info_id: lawsuit_info_id,
      type_name: selectedTypeText, 
      type: selectedType,
      updated: Date.now(), // Add a timestamp or some unique value
        clientID: null, // Add new field clientID
      clientName: null, // Add new field clientName
      Source: 'server', // Add new field Source
   
    });



    ////console.log('Uploading files...');
    const token = await getToken();
    const imagesBase64 = await Promise.all(newImageUris.map(async uri => {

      if (!uri) {
        console.warn('Undefined URI found');
        return null;
      }
      // Get the file extension (you should store this info while picking files)
      const fileExtension = uri.split('.').pop();

      // Identify MIME type (this is a basic example; you might need a more comprehensive mapping)
      let mimeType;
      switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        default:
          mimeType = 'application/octet-stream';
      }

      const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
      return `data:${mimeType};base64,${data}`;
    }));

 
    const body = JSON.stringify({
      method: 'insert_reminder',
      params: {
        id: '',
        type: selectedType,
        deadline,
        description: textInputValue1,
        lawsuit_id,
        lawsuit_info_id,
        files: imagesBase64,
      }
    });
    ////console.log(body);
    ////console.log('Uploading files - S1...');
    try {
      const response = await fetch('https://maktabi.info/includes/model.php', {
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      ////console.log('Uploading files - S2...');

      const responseJson = await response.json();

     // console.log('RESPONSE: ', responseJson);
      ////console.log('Uploading files - S3...');

      if (responseJson && responseJson.id) {
        showSuccess();
        const newitem ={
...newRecord,
id: responseJson.id
        }
setNewrecord(newitem);

       
      } else {
        console.error('Upload failed');
        showError();
      }
    
    } catch (error) {
      console.error('Error uploading files:', error);
      showError();
    } finally {
     // setIsSubmitting(false);
     showSuccess();
    }
  };


*/


  useEffect(() => {
    if (modalVisible) {
      fetchtypes();
      console.log('ROUTE PARAM: ', route)
      if (!route?.params?.id && !route?.lawsuit_id) {
        fetchLawsuits();
      }
    }
  }, [modalVisible]);






  useEffect(() => {
    if (fromItem) {
      setDate(itemDate);
    }
  }, []);

  const fetchLawsuits = async () => {
    try {
      const json = await AsyncStorage.getItem('select_lawsuits');
      ////console.log('Lawsuits from AsyncStorage:', json);

      if (json !== null) {
        const lawsuitsData = JSON.parse(json);
        ////console.log('Parsed lawsuits data:', lawsuitsData);

        // Since lawsuitsData is already an array, you can set it directly
        setlawsuits([...lawsuitsData]);
      } else {
        ////console.log('No data found in AsyncStorage for lawsuits');
        setlawsuits([]);
      }
    } catch (error) {
      console.error('Error fetching lawsuits from AsyncStorage:', error);
      setlawsuits([]);
    }
  };

  useEffect(() => {
    if (fromItem) {
      setDate(itemDate);
      //console.log(itemDate);
    }
  }, [itemDate]);


  const fetchtypes = async () => {
    try {
      const json = await AsyncStorage.getItem('select_reminder_types');
      ////console.log('Types from AsyncStorage:', json);

      if (json !== null) {
        const typesData = JSON.parse(json);
        ////console.log('Parsed types data:', typesData);

        // Since typesData is already an array, you can set it directly
        setType([...typesData]);
      } else {
        ////console.log('No data found in AsyncStorage for reminder types');
        setType([]);
      }
    } catch (error) {
      console.error('Error fetching reminder types from AsyncStorage:', error);
      setType([]);
    }
  };




  const handleSelectLawsuit = (item) => {
//console.log('TITTTTTTTT:', item);
    if (item && item.id !== undefined && item.lawsuit_info_id !== undefined) {
     // setLawsuit_id(item.id);
     // setLawsuit_info_id(item.lawsuit_info_id);
      //setLawSuitText(item.title);
    } else {
      console.error('Invalid item:', item);
    }
  };

  const handleClose = () => {
    
   //console.log(newRecord);
   
    if (onClose) {  // Check if onClose prop exists
      onClose(newRecord);  // Call the callback from the parent component
    }
    
    setModalVisible(false);  // Close the modal using the prop function
  };


  useEffect(() => {
    if (newRecord && newRecord.updated) {
      handleClose();
    }
  }, [newRecord]); // Add newRecord as a dependency
  


  useEffect(() => {
    // Check if all required fields have valid values
    if (
      textInputValue1.trim() !== '' &&
      selectedType && selectedType !== '-1' &&
      lawsuit_id && lawsuit_id !== '-1' &&
      lawsuit_info_id && lawsuit_info_id !== '-1' &&
      date
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [textInputValue1, selectedType, selectedLawsuit, date, route]);

 

  return (

    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={handleClose}

    >
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.7)' }}>
      {isSubmitting ? (
        // View when submitting
        <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size="large" color="#0000ff" />
          <Text>Submitting...</Text>
        </View>
      ) : (
        // Original form view
        <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>

          <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>إضافة استحقاق</Text>
          <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom: 10 }} />
          <View style={{
            flexDirection: 'column',
            width: '100%',
            //padding: 5,
            borderRadius: 5,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10,
          }}
          >
            {route?.params?.id || route?.lawsuit_id ? null : (
              Platform.OS === 'ios' ? (
                <TouchableOpacity style={{ height: 30 }} onPress={() => openPicker(lawsuits, selectedLawsuit, setSelectedLawsuit, "اسم الدعوى")}>
                  <Text>{selectedLawsuit === '-1' ? " الدعوى" : lawsuits.find(item => item.id === selectedLawsuit)?.title}</Text>
                </TouchableOpacity>

              ) : (
                <Picker
                selectedValue={selectedLawsuit}
                style={styles.pickerStyle}
                onValueChange={(itemValue) => {
                  const selectedItem = lawsuits.find(item => item.id === itemValue);
                  if (selectedItem) {
                    setSelectedLawsuit(itemValue);  // Update the state
                    //handleSelectLawsuit(selectedItem);  // Handle the selected item
                    setLawSuitText(selectedItem.title);
                    setLawsuit_id(selectedItem.id);
                    setLawsuit_info_id(selectedItem.lawsuit_info_id);
                  }
                }}
              >
                <Picker.Item label=" الدعوى" value="-1" />
                {lawsuits.map((item, index) => (
                  <Picker.Item key={index} label={item.title} value={item.id} />
                ))}
              </Picker>
              
              )
            )}
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignContent: 'space-between', alignItems:'flex-start' }}>
            {Platform.OS === 'ios' ? (
              <DateTimePicker
                value={fromItem ? itemDate : date}
                mode="date"
                display="default"
                minimumDate={new Date()}  // Setting the minimum date to the current date
                onChange={(event, selectedDate) => {
                  const currentDate = selectedDate || (fromItem ? itemDate : date);
                  setShowDatePicker(false);
                  setDate(currentDate);
                }}
              />
            ) : (
              <View style={[styles.iconButton, {width:'52%', marginRight:10}]}>
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowDatePicker(true)}>
                  <Ionicons name="calendar-outline" size={24} color="black" />
                  <Text style={{ marginLeft: 8 }}>
                    {fromItem
                      ? itemDate.toLocaleDateString()
                      : date
                        ? date.toLocaleDateString()
                        : 'التاريخ'}
                  </Text>
                </TouchableOpacity>
                {showDatePicker && (
                  <DateTimePicker
                    value={fromItem ? new Date(itemDate) : date}
                    mode="date"
                    display="default"
                    minimumDate={new Date()}  // Setting the minimum date to the current date
                    onChange={(event, selectedDate) => {
                      const currentDate = selectedDate || (fromItem ? itemDate : date);
                      setShowDatePicker(false);
                      setDate(currentDate);
                    }}
                  />
                )}
              </View>
            )}


            {Platform.OS === 'ios' ? (


              <DateTimePicker
                value={time}
                mode="time"
                display="default"
                onChange={(event, selectedTime) => {
                  const currentTime = selectedTime || time;
                  setShowTimePicker(false);
                  setTime(currentTime);
                }}
              />
            ) : (

              <View style={[styles.iconButton]}>

                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowTimePicker(true)}>
                  <Ionicons name="time-outline" size={24} color="black" />
                  <Text style={{ marginLeft: 8 }}>{time ? time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'الوقت'}</Text>
                </TouchableOpacity>
                {showTimePicker && (
                  <DateTimePicker
                    value={time}
                    mode="time"
                    display="default"
                    onChange={(event, selectedTime) => {
                      const currentTime = selectedTime || time;
                      setShowTimePicker(false);
                      setTime(currentTime);
                    }}
                  />
                )}
              </View>
            )}

          </View>









          <View style={{
            flexDirection: 'column',
            width: '100%',
            //padding: 5,
            borderRadius: 5,
            borderColor: 'gray',
            borderWidth: 1,
            marginBottom: 10
          }}
          >
            {Platform.OS === 'ios' ? (
              <TouchableOpacity style={{ height: 30 }} onPress={() => openPicker(types, selectedType, setSelectedType, "نوع الاستحقاق")}>
                <Text>{selectedType === '-1' ? "نوع الاستحقاق" : types.find(item => item.id === selectedType)?.text}</Text>
              </TouchableOpacity>
            ) : (
              <Picker
                selectedValue={selectedType}
                style={styles.pickerStyle}
                onValueChange={(itemValue) => {
                  setSelectedType(itemValue);
                  const selectedItem = types.find(item => item.id === itemValue);
                  setSelectedTypeText(selectedItem ? selectedItem.text : '');
                }}
              >
                <Picker.Item label="نوع الاستحقاق" value="-1" />
                {types.map((item, index) => (
                  <Picker.Item key={index} label={item.text} value={item.id} />
                ))}
              </Picker>

            )}
          </View>
          <TextInput
            //autoCorrect={false}
            //keyboardType="visible-password"

            placeholder="تفاصيل الاستحقاق"
            //floatingPlaceholder 
            value={textInputValue1}
            onChangeText={setTextInputValue1}
            style={{
              // marginTop: 10,
              width: '100%',
              padding: 10,
              borderRadius: 5,
              borderColor: 'gray',
              borderWidth: 1,
              marginBottom: 10
            }}
          />



         

          <FilePhotoPicker
            initialUris={imageUris}
            onFileChange={(deletedIds, addedUris) => {
              setNewImageUris(addedUris);
            }}
          />

          {/*
          <TouchableOpacity style={[styles.iconButton, { width: '100%', marginTop: Platform.OS === 'ios' ? 10 : null }]} onPress={handlePress}>
            <Ionicons name="image-outline" size={24} color="black" />
            <Text style={{ marginLeft: 8 }}>اختار ملف او صورة ({imageUris.length}) </Text>
          </TouchableOpacity>
          <FlatList
            data={imageUris}
            horizontal={true}
            renderItem={({ item }) => (
              <Image source={{ uri: item }} style={styles.imagePreview} />
            )}
            keyExtractor={(item, index) => index.toString()}
            />*/}
<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={() => setModalVisible(false)}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="اضافة" 
      onPress={addNewReminder} 
      disabled={!isFormComplete}
      color="#28a745" /* Green color */
    />
  </View>

                      
                        </View>







         
        </View>
        )}
      </View>
      <ModalPicker
        isVisible={isIOSModalVisible}
        onValueChange={handlePickerValueChange}
        selectedValue={currentPickerValue}
        data={currentPickerData}
        onClose={() => setIOSModalVisible(false)}
        label={currentPickerLabel}
      />
    </Modal>

  );
};

const styles = StyleSheet.create({
  inputStyle: {
    width: '100%',
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10
  },
  pickerStyle: {
    //height: 50,
    // width: '100%',
    //marginBottom: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
  },
  imagePreview: {
    width: 100,
    height: 100,
    margin: 5,
    borderRadius: 5
  },
  actionButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 5
  },
  disabledButton: {
    backgroundColor: 'gray',  // or any other color you want for the disabled state
  }
});


export default AddReminderModal;
