
import React, { useState, useEffect, useContext } from 'react';
import { View, TextInput, Button, StyleSheet, Text, Image, TouchableOpacity, Alert, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import NetInfo from '@react-native-community/netinfo';
import { AuthContext } from '../../../contexts/AuthContext';
import * as SQLite from 'expo-sqlite/legacy';


  const LoginScreen = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [credentialsFound, setCredentialsFound] = useState(false);
    const [base64Image, setBase64Image] = useState('');
    const [imageUri, setimageUri] = useState(`data:image/jpeg;base64,${base64Image}`);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
const [loginError, setLoginError] = useState('');
const [isConnected, setIsConnected] = useState(true); // New state for internet connectivity
const { setIsAuthenticated } = useContext(AuthContext);


    useEffect(() => {
        const checkCredentials = async () => {
          let storedCredentials;

          if (Platform.OS === 'web') {
            // For web, use localStorage
            storedCredentials = localStorage.getItem('credentials');
            console.log(storedCredentials);
          } else {
            // For native platforms, use SecureStore (async)
            storedCredentials = await SecureStore.getItemAsync('credentials');
          }
            //const storedCredentials = await SecureStore.getItemAsync('credentials');
            if (storedCredentials) {
              
                const storedUserData = JSON.parse(storedCredentials);
                setUsername(storedUserData.username);
                // Retrieve user data from AsyncStorage here, if available
                // For example, setUserData({ name: 'User Name', photoUrl: 'path/to/photo' });
                fetchImage();
                setCredentialsFound(true);
            }
        };

        checkCredentials();
       // Check for internet connectivity
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
    });

    return () => unsubscribe();
  }, []);


  
    const fetchImage = async () => {
      try {
          const storedDataString = await AsyncStorage.getItem('fetch_credentials');

          if (!storedDataString) {
              //console.log("No data found in AsyncStorage for key 'fetch_credentials'.");
              return;
          }

          const storedData = JSON.parse(storedDataString);

          if (storedData) {
              if (storedData.photo) {
                  const base64String = storedData.photo.split(',').pop();
                  setBase64Image(base64String);
              }

              if (storedData.first_name) {
                  setFirstName(storedData.first_name);
              }

              if (storedData.last_name) {
                  setLastName(storedData.last_name);
              }
          } else {
              //console.log("Stored data is in an unexpected format.");
          }
      } catch (error) {
          console.error("Error fetching data from AsyncStorage:", error);
      }
  };


    const now = new Date();
    const currentDateTime = now.toISOString();
  
    
    const handleLogin = async () => {
      if (!isConnected) {
        Alert.alert("No Internet Connection", "Please check your internet connection and try again.");
        return;
      }
  
      try {
        const response = await fetch('https://maktabi.info/includes/model.php', {
          
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            method: 'login',
            params: {
              mobile: username,
              password: password,
            },
          }),
        });
    
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
        const data = await response.json();
    
        // Check if the response data is not false
        if (data !== false) {
          // Process the valid response data
          console.log(data);
          setIsAuthenticated(true);
          if (Platform.OS === 'web') {
            // For web, use localStorage
            localStorage.setItem('token', data.token);
            localStorage.setItem('LastLogin', currentDateTime);
            localStorage.setItem('credentials', JSON.stringify({ username }));
           // localStorage.setItem('role', data.logged_user.role);

            
          } else {
            // For native platforms, use SecureStore (async operations)
            await SecureStore.setItemAsync('token', data.token);
            await SecureStore.setItemAsync('LastLogin', currentDateTime);
            await SecureStore.setItemAsync('credentials', JSON.stringify({ username }));
          //  await SecureStore.setItemAsync('role', data.logged_user.role);

          }
          console.log('CRENDENTAILS:', data);
          // Notify the parent component that the user is authenticated
       //onLogin();
       initializeDatabase();
        

        } else {
          // Handle the false response (e.g., incorrect login credentials)
          setLoginError('فشل في تسجيل الدخول: الاسم او كلمة السر خطأ');
        }
    
      } catch (error) {
        // Displaying the error in an alert
        Alert.alert('Error', error.message);
      }
    };

    const initializeDatabase = () => {
      const db = SQLite.openDatabase('localAvocado.db');
    
      db.transaction(tx => {
        // Create Reminders Table
        tx.executeSql(
          "CREATE TABLE IF NOT EXISTS reminders (id INTEGER PRIMARY KEY AUTOINCREMENT, description TEXT, deadline TEXT, status INTEGER, clientID INTEGER, clientName TEXT);",
          [],
          () => console.log('Reminders table created successfully'),
          error => console.log('Error creating reminders table', error)
        );
    
        // Create History Table for Indicator
        tx.executeSql(
          "CREATE TABLE IF NOT EXISTS History (id INTEGER PRIMARY KEY AUTOINCREMENT, actionName TEXT, status TEXT, createdAt DATETIME DEFAULT CURRENT_TIMESTAMP);",
          [],
          () => console.log('History table created successfully'),
          error => console.log('Error creating history table', error)
        );
      });
    };
     

    return (
      <View dir={'rtl'} style={styles.container}>
        
    
        {credentialsFound ? (
          <View style={styles.loginWithCredentials}>
            <Image
              source={{ uri: `data:image/jpeg;base64,${base64Image}` }}
              style={styles.userPhoto}
              resizeMode="contain"
              defaultSource={require('../../../assets/defaultuser.png')} // Default user image if fetch fails
            />
            <Text style={styles.userName}>{`الاستاذ ${firstName} ${lastName}`}</Text>
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                placeholder="كلمة المرور"
                onChangeText={setPassword}
                value={password}
                secureTextEntry
                onSubmitEditing={() => {
                  handleLogin()
                }}
              />
            </View>
            
            <TouchableOpacity  disabled={!isConnected} style={[styles.loginButton, {backgroundColor: isConnected ? '#007aff' : '#aaa'}]} onPress={handleLogin}>
              <Text style={styles.loginButtonText}>تسجيل الدخول</Text>
            </TouchableOpacity>
            {!isConnected && (
        <Text style={styles.noConnectionText}>لا يوجد اتصال بالإنترنت</Text>
      )}
            <Text style={{color: 'red', fontWeight:'bold', marginTop:20}}>{loginError}</Text>
          </View>
        ) : (
          <View dir={'rtl'} style={styles.loginForm}>
            <Image
              source={require('../../../assets/icons/logo-type3x.png')}
              style={styles.logo}
              resizeMode="contain"
            />
            <View style={styles.inputContainer}>
              <TextInput
              dir={'rtl'} 
                style={styles.input}
                placeholder="رقم الجوال"
                onChangeText={setUsername}
                value={username}
                keyboardType="number-pad"
                

              />
              <TextInput
              dir={'rtl'} 
                style={styles.input}
                placeholder="كلمة المرور"
                onChangeText={setPassword}
                value={password}
                secureTextEntry
              />
            </View>
            <TouchableOpacity disabled={!isConnected} style={[styles.loginButton, {backgroundColor: isConnected ? '#007aff' : '#aaa'}]} onPress={handleLogin}>
              <Text style={styles.loginButtonText}>تسجيل الدخول</Text>
            </TouchableOpacity>
            {!isConnected && (
        <Text style={styles.noConnectionText}>لا يوجد اتصال بالإنترنت</Text>
      )}
            <View style={styles.linksContainer}>
              <Text style={styles.linkText} onPress={() => console.log('Forgot Password')}>
                نسيت كلمة المرور؟
              </Text>
              <Text style={styles.linkText} onPress={() => console.log('Help')}>
                مساعدة
              </Text>
            </View>
            <Text style={{color: 'red', fontWeight:'bold', marginTop:20}}>{loginError}</Text>

          </View>
        )}
      </View>
    );
  };
  
  const styles = StyleSheet.create({
    noConnectionText: {
      color: 'red',
      marginBottom: 10,
    },
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f2f2f2',
    },
    loginWithCredentials: {
      alignItems: 'center',
      
    },
    loginForm: {
      width: '80%',
      alignItems: 'center',
    },
    userPhoto: {
      width: 120,
      height: 120,
      borderRadius: 60,
      marginBottom: 20,
      borderWidth: 2,
      borderColor: '#007aff',
    },
    userName: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    logo: {
      width: 150,
      height: 150,
      marginBottom: 20,
    },
    inputContainer: {
      width: '100%',
      marginBottom: 20,
    },
    input: {
      borderWidth: 1,
      borderColor: '#007aff',
      borderRadius: 5,
      padding: 10,
      marginBottom: 10,
      backgroundColor: 'white',
    },
    loginButton: {
      backgroundColor: '#007aff',
      padding: 10,
      borderRadius: 5,
      width: '100%',
      alignItems: 'center',
    },
    loginButtonText: {
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'DefFont'
    },
    linksContainer: {
      marginTop: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    linkText: {
      color: '#007aff',
      textDecorationLine: 'underline',
    },
  });
  
  export default LoginScreen;