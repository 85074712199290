import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FlatList, TouchableOpacity, ActivityIndicator, StyleSheet, TextInput, Alert, RefreshControl, Modal,Vibration } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text, Spacings, View, Colors } from 'react-native-ui-lib';
import ProcedureModal from '../components/ProcedureModal';
import LawsuitFilterModal from '../../../SharedComponents/LawsuitFilterModal';
import FilterBar from '../../../SharedComponents/FilterBar';
import { fetchData, DELETE_ITEM_API, getToken, formatDateTime, encodeImagesWithMimeType, executePostRequest } from '../../../utils/api';
import ProcedureListItem from '../components/ProcedureListItem';
import Styles from '../../../Styles/Styles';
import Constants from 'expo-constants';
import ProcedureEdit from '../components/ProcedureEdit';
import { SearchContext } from '../../../contexts/SearchContext';
import { SpeedDial } from '@rneui/themed';
import { ScrollView, GestureHandlerRootView } from 'react-native-gesture-handler';
//import { Audio } from 'expo-av';
import { useIndicator } from '../../../contexts/IndicatorContext';
import { FlashList } from '@shopify/flash-list'; 
import { useFocusEffect } from '@react-navigation/native';
import RefreshContext from '../../../contexts/RefreshContext';
 
/**
 * Renders a screen that displays a list of procedures.
 */
const ProceduresScreen = ({ route }) => {
  const { showLoading, showSuccess, showError } = useIndicator();

  const [open, setOpen] = useState(false);
  const [sound, setSound] = useState(null);

  const apiUrl = Constants.expoConfig.extra.apiUrl;
  const [data, setData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  //const [searchQuery, setSearchQuery] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [typeValues, setTypeValues] = useState([]);
  const [Lawsuits, setLawsuits] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const [filters, setFilters] = useState(null);
  //const [filteredData, setFilteredData] = useState([]); // State variable to store the filtered list of lawsuits
  const [isLoading, setIsLoading] = useState(true); // New state variable to track loading state
  const [showReportBars, setShowReportBars] = useState(false);
  const [reportBarValues, setReportBarValues] = useState([]);
  const [shouldFetchData, setShouldFetchData] = useState(false);//after closing the filter bar
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility

  const Showfull = route.params.fullScreen ? true: false;
const isNested = route.params.fullScreen ? false: true;


const { searchQuery } = useContext(SearchContext);
const [filteredData, setFilteredData] = useState([]);
const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);


  //Reset Modal
  const [resetKey, setResetKey] = useState(0);


  const { refreshProcedures, setRefreshProcedures } = useContext(RefreshContext);

  useFocusEffect(
    useCallback(() => {
      const refreshData = async () => {
        if (refreshProcedures) {
          await loadData();
          setRefreshProcedures(false); // Reset the flag after refreshing
          console.log('Data refreshed and flag reset');
        }
      };
      refreshData();
     
    }, [refreshProcedures])
  );

/*
  const playSound = async () => {
    try {
      const soundObject = new Audio.Sound();
      await soundObject.loadAsync(require('../assets/Sounds/ping.mp3'));
      await soundObject.playAsync();
      // Optionally, unload the sound after it's played to free resources
      soundObject.setOnPlaybackStatusUpdate(async (status) => {
        if (status.didJustFinish) {
          await soundObject.unloadAsync();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    shouldFetchData ?
    playSound(): null;
  }, [data]); // Add dependencies that, when changed, should trigger the sound

*/

  {/*
  useEffect(() => {
    // This code will run whenever the `data` changes
    Vibration.vibrate();
  
    // Optional: You can customize the pattern of vibration
    // Vibration.vibrate([500, 1000, 500]); // Vibrate for 500ms, pause for 1000ms, then vibrate for 500ms
  }, [data]); // Dependency array with `data`
  
*/}



  const resetFilter = () => {
    setResetKey(prevKey => prevKey + 1);
    setShouldFetchData(true);
  };


  //////////////  

  useEffect(() => {
    if (shouldFetchData) {
      loadData();
      setShouldFetchData(false);
    }
  }, [shouldFetchData]);

 


  const handleFilterBarClose = () => {
    setFilters('');
    setShowReportBars(false);
    setReportBarValues([]);  // Reset the report bar values
    resetFilter();
  };



  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  // Function to fetch the file link for a given file ID
  const fetchFileLink = async (fileId) => {
    // Get the user token
    const token = await getToken();

    // Make an API call to fetch the file link
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        method: 'select_file',
        params: {
          id: fileId
        }
      }),
    });

    const responseData = await response.json();
    // Checking if responseData is a valid data URI
    if (typeof responseData === 'string' && responseData.startsWith('data:')) {
      return responseData;
    } else {
      console.error(`Error fetching file with ID ${fileId}: Invalid data URI format in the response.`);
      return null;
    }
  };

  // Function to fetch all file links for a given array of file IDs
  const fetchFileLinks = async (fileIds) => {
    const links = await Promise.all(fileIds.map(id => fetchFileLink(id)));
    setFileLinks(links.filter(link => link !== null && link !== undefined));
    // Set imagesLoading to false once all file links have been fetched
   // setImagesLoading(false);
  };




  /**
   * Handles the "onRefresh" event for the FlatList.
   * Fetches the procedures data and updates the state.
   */
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await loadData();
    setIsRefreshing(false);
  };





  /**
   * Defines the API request parameters for fetching the procedures data.
   */
  const currentDate = new Date();
const thirtyDaysBefore = new Date();

// Set thirtyDaysBefore to 30 days before the current date
thirtyDaysBefore.setDate(currentDate.getDate() - 30);

// Format the dates to 'YYYY-MM-DD HH:MM:SS'
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day} ${date.toTimeString().split(' ')[0]}`;
};

const param = {
    method: "select_procedures",
    params: {
        date_start: formatDate(thirtyDaysBefore),
        date_end: formatDate(currentDate),
        hparams: {}
    }
};


  const nestedParam = isNested 
  ? {
      method: 'select_procedures',
      params: { 
        group: true, 
        id: route?.params?.id, 
        lawsuit_info_id: route?.params?.lawsuit_info_id 
      }
    }
  : null;

  

  console.log('NestedParams: ', nestedParam);
  console.log('ShowFull:', Showfull);
  console.log('isNestes: ', isNested);
  console.log('Route: ', route);

/**
 * Toggles the expanded state of the item with the given ID.
 * If the item is already expanded, collapses it. Otherwise, expands it.
 * @param {number} id - The ID of the item to toggle.
 */
const toggleItem = (id) => {
  if (activeItem === id) {
    setActiveItem(null);
  } else {
    setActiveItem(id);
  }
};


/**
 * Fetches the procedures data from the API and updates the state.
 * If an error occurs, logs the error and updates the state with an empty array.
 */
const loadData = async () => {
 // setIsLoading(true); // Set loading state to true at the start of data fetching

  try {
    const requestParams = isNested ? nestedParam : (filters ? filters : param);
    const responseData = await fetchData(requestParams);

    if (responseData && Array.isArray(responseData)) {
      setData(responseData);
      setFilteredData(responseData);
     console.log('Procedures: ', responseData)
    } else {
      //console.log('Invalid API response structure');
      setData([]);
      setFilteredData([]);
    }
  } catch (error) {
    console.error(error);
    setData([]);
    setFilteredData([]);
  }

  setIsLoading(false); // Set loading state to false at the end of data fetching
};


const fetchLawsuits = async () => {
  try {
    const lawsuitsJSON = await AsyncStorage.getItem('select_lawsuits');

    if (lawsuitsJSON !== null) {
      const lawsuits = JSON.parse(lawsuitsJSON);
      setLawsuits(lawsuits);
      //console.log('Lawsuits fetched from AsyncStorage:', lawsuits);
    } else {
      //console.log('No lawsuits data found in AsyncStorage');
      setLawsuits([]);
    }
  } catch (error) {
    console.error('Error fetching lawsuits from AsyncStorage:', error);
  }
};

const fetchtypes = async () => {
  try {
    const typesJSON = await AsyncStorage.getItem('select_procedure_types');

    if (typesJSON !== null) {
      const types = JSON.parse(typesJSON);
      setTypeValues([...types]);
      //console.log('Procedure types fetched from AsyncStorage:', types);
    } else {
      //console.log('No procedure types data found in AsyncStorage');
      setTypeValues([]);
    }
  } catch (error) {
    console.error('Error fetching procedure types from AsyncStorage:', error);
  }
};


/**
 * Navigates to the EditScreen with the given item ID as a parameter.
 * @param {number} id - The ID of the item to edit.
 */
const editItem = (id) => {
  navigation.navigate('ScreenTwo', { id: id });
};

/**
 * Deletes the item with the given ID from the API and updates the state.
 * If an error occurs, logs the error and displays an error message.
 * @param {number} id - The ID of the item to delete.
 */
const deleteItem = async (id) => {
 // setIsLoadingModalVisible(true);
 showLoading();
 // Define the retry function
 const retryDelete = () => deleteItem(id);

 setData(currentData => currentData.filter(item => item.id !== id));

  try {
      // Wait for the DELETE_ITEM_API to complete
      const responseData = await DELETE_ITEM_API('delete_procedure', { id: id }, showSuccess, showError);

      // Proceed with onSuccess logic after DELETE_ITEM_API resolves
      const newData = data.filter(item => item.id !== id);
      setData(newData);
showSuccess();
      // Uncomment this to log the response data65  
      // console.log("Item deleted successfully:", responseData);
  } catch (error) {
      // onFailure logic if DELETE_ITEM_API rejects
      //Alert.alert('Error', 'Failed to delete item');
      //showError('Delete Item Failed', retryDelete); // Include retry function here
  }
 // showSuccess();
 // setIsLoadingModalVisible(false);
};



/**
 * Displays a confirmation dialog for deleting the item with the given ID.
 * If the user confirms the deletion, deletes the item from the API and updates the state.
 * @param {number} id - The ID of the item to delete.
 */
const showDeleteConfirmation = (id) => {
  Alert.alert(
    "حذف اجراء", // Title
    "هل انت متأكد من رغبتك في حذف هذا الاجراء؟", // Message
    [
      {
        text: "كلا",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel"
      },
      {
        text: "نعم",
        onPress: () => deleteItem(id)
      }
    ],
    { cancelable: false } // This makes it so the user has to select one of the options
  );
};

const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false);

const toggleCarouselModal = (files) => {
  setIsCarouselModalVisible(!isCarouselModalVisible);
  if (files && files.length > 0) {
    fetchFileLinks(files);
  }
};

useEffect(() => {
  if (filters) {
    loadData();
  }
}, [filters]);

const onActionSheetSubmit = (selectedValues) => {
  console.log(selectedValues);
  const newFilters = {
    method: "select_procedures",
    params: {
     
      hparams: {
        date_start: new Date(selectedValues.fromDate).toISOString().split('T')[0],
        date_end: new Date(selectedValues.toDate).toISOString().split('T')[0],        
        filter: true,
        lawyer_id: selectedValues.LawyerId,
        client_id: selectedValues.ClientId,
        lawsuit_id: selectedValues.LawsuitId,
        type_id: selectedValues.TypeId,
        phase_id: selectedValues.PhaseId,
        j_ref_id: selectedValues.JRefId,
        proctype_id: selectedValues.procedureTypeId

      }
    }
  };
  console.log('Filters ', newFilters);

  setFilters(newFilters);  // This will trigger the useEffect hook
  setShowReportBars(true);
};



/**
 * Renders the item for the FlatList.
 * @param {object} item - The item data.
 * @returns {JSX.Element} The rendered item.
 */
const renderItem = ({ item }) => (
  <ProcedureListItem
    item={item}
    Lawsuits={Lawsuits}
    typeValues={typeValues}
    toggleModal={toggleModal}
    toggleCarouselModal={toggleCarouselModal}
    showDeleteConfirmation={showDeleteConfirmation} 
  // Pass other necessary props
  /> 
);



const navigation = useNavigation();




useEffect(() => {
  loadData();
  fetchtypes();
  fetchLawsuits();
}, [route.params.id, route.params.lawsuit_info_id]);

 


const Separator = () => {
  return (
    <View style={{ height: 1, backgroundColor: '#ccc' }} />
  );
};

const   handleItemUpdate = (updatedItem, deletedIds, addedUris) => {
  // If there are deleted IDs, remove them from the files of the updated item
  //showLoading();
  if (deletedIds && deletedIds.length > 0) {
    updatedItem.files = updatedItem.files.filter(fileId => !deletedIds.includes(fileId));
  }

  setFilteredData(currentFilteredData =>
    currentFilteredData.map(item =>
      item.id === updatedItem.id ? updatedItem : item
    )
  );

  // If there are added URIs, refresh the data from the server
  if (addedUris && addedUris.length > 0) {
    setShouldFetchData(true);
  }
 // showSuccess();
};

{/*const onModalSubmit = (newDataItem) => {
  // Update the data state with the new item
  setData(currentData => [newDataItem, ...currentData]);
  setFilteredData(currentFilteredData => [newDataItem, ...currentFilteredData]);
  // Additional logic if needed
  setShouldFetchData(true);

};*/}

const onModalSubmit = async (newDataItem, newImageUris) => {

  setData(currentData => [newDataItem, ...currentData]);
  setFilteredData(currentFilteredData => [newDataItem, ...currentFilteredData]);
  // Additional logic if needed
  showLoading();

  const imagesBase64 = await encodeImagesWithMimeType(newImageUris);
  const updatedDataItem = {
    ...newDataItem,
    id: '',
    files: imagesBase64
  };

  // Prepare the body for the POST request
  const body = {
      method: 'insert_procedure',
      params: updatedDataItem
  };

  // Execute the POST request and handle the response
  try {
      const responseJson = await executePostRequest(body);

      if (responseJson && responseJson.id) {
          //showSuccess('Eshta');
          setShouldFetchData(true);
      } else {
          console.error('Upload failed');
          //showError();
      }
  } catch (error) {
      console.error('Error uploading files:', error);
  }
};




useEffect(() => {
  if (searchQuery && !isNested) {
    setFilteredData(
      data.filter(item => 
        item.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  } else {
    setFilteredData(data);
  }
}, [searchQuery, data]);


const sortedData = [...filteredData].sort((a, b) => {
  
  return new Date(b.date) - new Date(a.date);
});




return (
  
  <>
 




 
    {Showfull ? (
      <>
        <LawsuitFilterModal
          isVisible={isActionSheetVisible}
          toggleVisibility={() => setIsActionSheetVisible(!isActionSheetVisible)}
          onSubmit={onActionSheetSubmit}
          showOpponentPicker={false}
          showJRegionPicker={false}
          showIsActivePicker={false}
          showReminderTypePicker={false}
          resetKey={resetKey}

        />

{modalVisible && (
    <ProcedureModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onModalSubmit={onModalSubmit}
        />
)}
        <Modal
  transparent={true}
  visible={isLoadingModalVisible}
  //animationType="slide"
>
  <View style={styles.centeredView}>
    <View style={styles.modalView}>
      <ActivityIndicator size="large" color="#0000ff" />
      <Text>جاري الحذف ...</Text>

    </View>
  </View>
</Modal> 
       
  
        <FilterBar showReportBars={showReportBars} onClose={handleFilterBarClose} data={reportBarValues} />
      </>
    ) : null
    }




    <View style={styles.container}>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      ) : filteredData.length === 0 ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text>No records found</Text>
        </View>
      ) : (
        
        isNested ? (
          <View style={{backgroundColor:'white'}}>
          <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
            <Ionicons name="expand-outline" size={18} color="black" style={{marginTop:5, marginLeft:5}} />
          </TouchableOpacity>
  
          <Modal
  transparent={false}
  visible={isModalVisible}
  animationType="slide"
>
<GestureHandlerRootView style={{ flex: 1 }}>

  <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
    <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>قائمة الاجراءات </Text>

    <ScrollView style={styles.container}>
      {sortedData.map((item) => (
        <ProcedureListItem
          key={item.id.toString()}
          item={item}
          handleItemUpdate={handleItemUpdate}
          typeValues={typeValues}
          Lawsuits={Lawsuits}
          toggleModal={toggleModal}
          toggleCarouselModal={toggleCarouselModal}
          showDeleteConfirmation={showDeleteConfirmation}
          isNested = {isNested}
        />
      ))}
    </ScrollView>

    <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.closeButton} onPress={() => setIsModalVisible(false)}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.closeButtonText}>Close</Text>
    </TouchableOpacity>
  </View>
  </GestureHandlerRootView>
</Modal>

            {sortedData.map((item, index) => (
              <ProcedureListItem
                key={index}
                handleItemUpdate={handleItemUpdate}
                item={item}
                Lawsuits={Lawsuits}
                typeValues={typeValues}
                toggleModal={toggleModal}
                toggleCarouselModal={toggleCarouselModal}
                showDeleteConfirmation={showDeleteConfirmation}
              isNested = {isNested}
              />
            ))}
            
          </View>
        ) : (
          
          <FlashList
          data={sortedData}
            renderItem={({ item }) => (
              <ProcedureListItem
              handleItemUpdate={handleItemUpdate}
                item={item}
                typeValues={typeValues}
                Lawsuits={Lawsuits}
                toggleModal={toggleModal}
                toggleCarouselModal={toggleCarouselModal}
                showDeleteConfirmation={showDeleteConfirmation}
                isNested = {isNested}
              />
            )}
            keyExtractor={(item) => item.id.toString()}
            ItemSeparatorComponent={Separator}
            extraData={data}
            //style={styles.container}
            refreshControl={
              <RefreshControl
                refreshing={isRefreshing}
                onRefresh={handleRefresh}
              />
            }

            estimatedItemSize={100}

          />
          

        )
        

      )}
      {!isNested ?
<SpeedDial
    isOpen={open}
    icon={{ name: 'edit', color: '#fff' }}
    openIcon={{ name: 'close', color: '#fff' }}
    onOpen={() => setOpen(!open)}
    onClose={() => setOpen(!open)}
  >
    <SpeedDial.Action
      icon={{ name: 'add', color: '#fff' }}
      title="اضافة"
      onPress={() => {
        toggleModal();
        setOpen(!open);
      }}
      
      />
    <SpeedDial.Action
      icon={{ name: 'filter-list', color: '#fff' }}
      title="تصفية"
      onPress={() => {setIsActionSheetVisible(true); setOpen(!open)}}    />
  </SpeedDial>
  :
  null}

    </View>
    
  </>
)
          };        
const styles = StyleSheet.create({
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  dateSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  lawsuitSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentSection: {
    marginBottom: 10,
  },
  descriptionRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  chipSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  smallText: {
    fontSize: 12,
    marginLeft: 5,
  },
  normalText: {
    fontSize: 16,
    marginLeft: 5,
  },
  headerIcon: {
    marginRight: 5,
  },
  chipStyle: {
    borderColor: Colors.red20,
    borderBottomLeftRadius: 0,
    marginLeft: Spacings.s3,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'white'
  },
  searchBox: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc', marginRight: 10, paddingHorizontal: 10, backgroundColor: 'white', marginLeft: 10
  },
  container: {
    flex: 1,
    paddingBottom: 5, // or marginBottom: 50,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  }
});

export default ProceduresScreen;