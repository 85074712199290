import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, Alert, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import {Card, Chip, Text, Image, ListItem, Carousel, Button, Spacings, View, ExpandableSection, Drawer, Colors, Switch} from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { fetchData } from '../../../utils/api';
import Styles from '../../../Styles/Styles';
import { ScrollView } from 'react-native-gesture-handler';






function LawsuitsDetailsProcedures({ route }) {


  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility
  const [typeValues, setTypeValues] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id, lawsuit_info_id } = route.params;







  const showDeleteConfirmation = (id) => {
    Alert.alert(
      "حذف اجراء", // Title
      "هل انت متأكد من رغبتك في حذف هذا الاجراء؟", // Message
      [
        {
          text: "كلا",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "نعم",
          onPress: () => deleteItem(id)
        }
      ],
      { cancelable: false } // This makes it so the user has to select one of the options
    );
  };





  const deleteItem = async (id) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          method: 'delete_procedure',
          params: {
            id: id
          }
        })
      });

      if (response.ok) {
        const responseData = await response.json();
        // Remove item from state to refresh FlatList
        const newData = data.filter(item => item.id !== id);
        setData(newData);
        //console.log("Item deleted successfully:", responseData);
      } else {
        console.error("Error deleting item:", await response.text());
      }
    } catch (error) {
      console.error("API call failed:", error.message);
    }
  };

  const fetchtypes = async () => {
    try {
      const typesJSON = await AsyncStorage.getItem('select_procedure_types');
      
      if (typesJSON !== null) {
        const types = JSON.parse(typesJSON);
        setTypeValues([...types]);
        //console.log('Procedure types fetched from AsyncStorage:', types);
      } else {
        //console.log('No procedure types data found in AsyncStorage');
        setTypeValues([]);
      }
    } catch (error) {
      console.error('Error fetching procedure types from AsyncStorage:', error);
    }
  };  





  useEffect(() => {
    const fetchClients = async () => {
      try {
        const Procedures = await fetchData({ method: 'select_procedures', params: { group: true, id: route.params.id, lawsuit_info_id: route.params.lawsuit_info_id } });
        //console.log('API CLIENT Response:', Procedures);
        setData(Procedures);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchClients();
  }, [id, lawsuit_info_id]);
  /*
    useEffect(() => {
      navigation.setOptions({ title: `اجراء (${data.length})` });
    }, [data]);
  */




    const LawSuiteDetailsProceduresView = () => {
      return(
      data.map((item) => (
        <Drawer
          rightItems={[{ text: 'Read', background: Colors.blue30, onPress: () => console.log('read pressed') }]}
          leftItem={{ text: 'Delete', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
          key={item.id.toString()}
        >
          <View centerV padding-s4 bg-white style={{  borderBottomColor:'#ccc', borderBottomWidth:1 }}>
            <Text style={{fontSize:10, marginBottom:5}}>{item.date}</Text>
            <Text style={{fontWeight:'bold', fontSize:16 }}> {item.description}</Text>
          
             {/* Footer with Chips */}
  <View style={styles.chipSection}>
    <Chip label={item.procedure_type} borderRadius={22} containerStyle={styles.chipStyle} onPress={() => console.log('pressed')} />
    {item.file_id && item.file_id.length > 0 && (
      <Chip label={'ملف مرفق'} borderRadius={22} containerStyle={[styles.chipStyle, { borderColor: Colors.blue10 }]} onPress={() => toggleCarouselModal(item.files)} />
    )}
  </View>
          </View>
        </Drawer>
      ))
      )
    }

  return (
    <View style={{ flex: 1 }}>



      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{backgroundColor:'white'}}>
        <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
          <Ionicons name="expand-outline" size={18} color="black" style={{marginTop:5, marginLeft:5}} />
        </TouchableOpacity>

        <LawSuiteDetailsProceduresView/>



        {/* Step 5: Add a Modal component to your JSX */}
        <Modal
          transparent={false}
          visible={isModalVisible}
          animationType="slide"
        >
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
            <View>
              <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>قائمة الاجراءات </Text>

            </View>
            <ScrollView>
            <LawSuiteDetailsProceduresView/>
            </ScrollView>
            <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.closeButton} onPress={() => setIsModalVisible(false)}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </Modal>
          </View>
      )}
   
        </View>
  )};




const styles = StyleSheet.create({
  chipSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  chipStyle: {
    borderColor: Colors.red20,
    borderBottomLeftRadius: 0,
    marginLeft: Spacings.s3,
  },
  
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10
  },
  imagePreview: {
    width: 100,
    height: 100,
    margin: 5,
    borderRadius: 5
  },
  actionButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 5
  }
});

export default LawsuitsDetailsProcedures;
