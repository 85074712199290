import React, { useState, useEffect } from 'react';
import { Modal, View, Button, TouchableOpacity, StyleSheet, Text, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Picker } from '@react-native-picker/picker';
import { Ionicons } from '@expo/vector-icons';
import { fetchData } from '../../../utils/api';
import { ActionSheet } from 'react-native-ui-lib';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ClientModal from '../../Parties/components/ClientModal';


const AccoutningReportsFilter = ({ isVisible, onClose, onDone, onStmt }) => {
  const [ShowFromDatePicker, setShowFromDatePicker] = useState(false);
  const [ShowToDatePicker, setShowToDatePicker] = useState(false);
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());
  const [selectedFirstAccount, setSelectedFirstAccount] = useState(null);
  const [FirstAccountsData, setFirstAccountsData] = useState([]);

  const [selectedSecondAccount, setSelectedSecondAccount] = useState(null);
  const [SecondAccountsData, setSecondAccountsData] = useState('');

  const [selectedThirdAccoount, setSelectedThirdAccoount] = useState(null);
  const [ThirdAccountsData, setThirdAccountsData] = useState('');

  const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);

const [selectedFromData, setSelectedFromData] = useState({ content: {}, case: null });

const [isFirstActionSheetVisible, setIsFirstActionSheetVisible] = useState(false);
    const [isSecondActionSheetVisible, setIsSecondActionSheetVisible] = useState(false);
    const [isThirdActionSheetVisible, setIsThirdActionSheetVisible] = useState(false);
    const [myAccount, setMyAccount] = useState('');

    const [showDatePicker, setShowDatePicker] = useState(false);

    useEffect(() => {
      const fetchMyAccount = async () => {
          try {
              const MyAccountString = await AsyncStorage.getItem('fetch_credentials');
              const MyAccountObject = JSON.parse(MyAccountString);
              //console.log("Financial Account Number:", MyAccountObject.financial_acc_nb);
              setMyAccount(MyAccountObject.financial_acc_nb);
          } catch (error) {
              console.error('Failed to fetch and parse from AsyncStorage:', error);
          }
      };

      fetchMyAccount();
  }, []);


    useEffect(() => {
      if (SecondAccountsData.length > 0) {
          setIsSecondActionSheetVisible(true);
      }

  }, [SecondAccountsData]);

  useEffect(() => {
      if (ThirdAccountsData.length > 0) {
          setIsThirdActionSheetVisible(true);
      }

  }, [ThirdAccountsData]);


//################################## Picker selection handling

const handleFirstAccountChange = async (type) => {
  setSelectedFirstAccount(type);
  setSelectedSecondAccount([]);
  setSecondAccountsData('');
  setSelectedThirdAccoount([]);
  setThirdAccountsData('');
 

  // Fetch the item from the selectedFirstAccount array using the selected type
  const selectedItem = FirstAccountsData.find(item => item.type === type);

  // Update the selectedFromData with type and text attributes
  if (selectedItem) {
  setSelectedFromData({
      content: {
      ...selectedFromData.content, // Keep other attributes unchanged
      id: selectedItem.id,
      type: selectedItem.type,
      text: selectedItem.text
      },
      case: "to"
  });
  }

  if (selectedItem.id !== myAccount && selectedItem.id !== '50000' && selectedItem.id !== '51000'  && selectedItem.id !== '54000' && selectedItem.id !== '53000') {
    // Fetch subaccount data based on the selected Account
  const newParams = { type, case: "expand" };
  ////console.log(newParams);
  const subData = await fetchSecondAccounts('transactions_picker', newParams);
  //console.log('second accounts: ', subData)
  if (subData && Array.isArray(subData)) {
  setSecondAccountsData([...subData]);
  }
}
};




const handleSecondAccountChange = async (value) => {
  ////console.log("Selected subaccount value:", value);  // Log selected value

  setSelectedSecondAccount(value);

  // Try to find by both attributes since we don't know the type yet.
  const SecondAccountsDataItem = SecondAccountsData.find(item => item.financial_acc_id === value || item.financial_acc_nb === value || item.id === value);

  ////console.log("Found subaccount data:", SecondAccountsData);  // Log entire data
  ////console.log("Found subaccount item:", SecondAccountsDataItem);  // Log found item
  const type = selectedFirstAccount; //value of selectedfirstaccount is the type

  if (SecondAccountsDataItem) {
  
  ////console.log(type)
  ////console.log("true");
  ////console.log(SecondAccountsDataItem);
  
  //the below setselectedFromData is only useful here in case of Lawyer and Bank this is why we missed the other cases.
  setSelectedFromData({
      content: {
      ...selectedFromData.content,
      id: (type === 'bank' || type === 'supplier') ? SecondAccountsDataItem.financial_acc_id : SecondAccountsDataItem.financial_acc_nb,
      text: (type === 'lawyer' || type === 'employee') ? `${SecondAccountsDataItem.first_name} ${SecondAccountsDataItem.middle_name} ${SecondAccountsDataItem.last_name}` : SecondAccountsDataItem.title,

      },
      case: "to"
  });

  //  //console.log("Updated selected data:", selectedFromData);  // Log updated data
  
  (type !== 'lawyer' && type !== 'employee' && type !== 'supplier') ? //because lawyer has no id so the fetch will return an error.
  await fetchThirdAccounts(SecondAccountsDataItem.id):''
  } else {
  console.warn("No matching subaccount item found for value:", value);
  }

};



const handleThirdAccountChange = async (value) => {
  setSelectedThirdAccoount(value);

  const selectedThirdItem = ThirdAccountsData.find(item => item.financial_acc_id === value);
  if (selectedThirdItem) {
  setSelectedFromData({
      content: {
      ...selectedFromData.content, // Keep other attributes unchanged
      text: selectedThirdItem.title, // Update the text
      id: selectedThirdItem.financial_acc_id,
      type: 'lawsuit' //because this is the only case where we use the third picker and if we keep the type selected from the first picker as we did for the second one, it be 'client'
      },
      case: "to"
          });
  }
};



//##################################### Fetches

const fetchFirstAccounts = async (method, params) => {
  setLoading(true);

  try {
      const data = await fetchData({ method, params }, 'array');

      if (data && Array.isArray(data)) {
          setFirstAccountsData([...data]);
          //console.log('firstaccounts data:', data);
      } else {
          throw new Error('Data format is incorrect');
      }
  } catch (err) {
      setError(err.message);
  } finally {
      setLoading(false);
  }
};

const fetchSecondAccounts = async (method, params) => {
  try {
      const data = await fetchData({ method, params }, 'json', true);

      if (data && Array.isArray(data.content)) {
          return data.content;
      } else {
          return [];
      }
  } catch (err) {
    setError(err.message);
    console.error("Error in fetchSecondAccounts:", err);
    return [];
}

};

const fetchThirdAccounts = async (id) => {
  const newParams = { id, type: "lawsuit", case: "expand" };
  //console.log(newParams);
  const data = await fetchSecondAccounts('transactions_picker', newParams);

  if (data && Array.isArray(data)) {
      setThirdAccountsData([...data]);
  }
};


//################################################

useEffect(() => {
  if (isVisible) {
    fetchFirstAccounts('transactions_picker', { case: "from" });
  }
}, [isVisible]);




















  return (
    <Modal
    transparent={true}
    animationType="slide"
    visible={isVisible}
    onRequestClose={onClose}
  >
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <View style={{ width: 300, padding: 20, backgroundColor: 'white', borderRadius: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
      <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> تقرير - كشف حساب</Text>
                    <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />


      <TouchableOpacity onPress={() => setIsFirstActionSheetVisible(true)} style={[styles.iconButton, {marginVertical: 10}]}>
      <Text>{selectedFromData.content.text || 'اختيار حساب'}</Text>
        </TouchableOpacity>


      <ActionSheet
    title="Select an Account"
    message="Please select an account from the list below."
    cancelButtonIndex={FirstAccountsData.length}
    useNativeIOS
    options={[
        ...FirstAccountsData.map((item) => ({
            label: item.text,
            onPress: () => handleFirstAccountChange(item.type)
        })),
        { label: 'Cancel', onPress: () => {} }  // You can adjust the cancel action as needed
    ]}
    visible={isFirstActionSheetVisible}
    onDismiss={() => setIsFirstActionSheetVisible(false)}
/>

{SecondAccountsData.length > 0 && (
   SecondAccountsData.length < 7 ?
<ActionSheet
    title="Select a Subaccount"
    message="Please select a subaccount from the list below."
    cancelButtonIndex={SecondAccountsData.length}
    useNativeIOS
    options={[
        ...SecondAccountsData.map((item) => {
          if (selectedFirstAccount === 'bank' || selectedFirstAccount === 'supplier') {
            return { label: item.title, onPress: () => handleSecondAccountChange(item.financial_acc_id) };
          } else if (selectedFirstAccount === 'lawyer' || selectedFirstAccount === 'employee') {
            const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                return { label: fullName, onPress: () => handleSecondAccountChange(item.financial_acc_nb) };
            } else if (selectedFirstAccount === 'client') {
                const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                return { label: fullName, onPress: () => handleSecondAccountChange(item.id) };
            } else if (selectedFirstAccount === 'syndicate') {
                return { label: item.description, onPress: () => handleSecondAccountChange(item.id) };
            }
            return null;
        }),
        { label: 'Cancel', onPress: () => {} }  // You can adjust the cancel action as needed
    ]}
    visible={isSecondActionSheetVisible}
    onDismiss={() => setIsSecondActionSheetVisible(false)}
/>
:
<ClientModal
                           visible={isSecondActionSheetVisible}
                           onClose={() => setIsSecondActionSheetVisible(false)}
                           data={SecondAccountsData}
                           selectedFirstAccount={selectedFirstAccount}
                           handleSecondAccountChange={handleSecondAccountChange}
                       />
)}
{ThirdAccountsData.length > 0 && (
<ActionSheet
    title="Select a Third Option"
    message="Please select a third option from the list below."
    cancelButtonIndex={ThirdAccountsData.length}
    useNativeIOS
    options={[
        ...ThirdAccountsData.map((item) => ({
            label: item.title,
            onPress: () => handleThirdAccountChange(item.financial_acc_id)
        })),
        { label: 'Cancel', onPress: () => {} }  // You can adjust the cancel action as needed
    ]}
    visible={isThirdActionSheetVisible}
    onDismiss={() => setIsThirdActionSheetVisible(false)}
/>
)}


{/*
          <Picker
            selectedValue={selectedFirstAccount}
            onValueChange={(itemValue) => handleFirstAccountChange(itemValue)}
          >
            <Picker.Item label="Select an Account" value="" />
            {FirstAccountsData.map((item, index) => (
            <Picker.Item label={item.text} value={item.type} key={index} />
            ))}
          </Picker>


          {SecondAccountsData.length > 0 && (

          <Picker
            selectedValue={selectedSecondAccount}
            onValueChange={(itemValue) => handleSecondAccountChange(itemValue)}
          >
            <Picker.Item label="Select a Subaccount" value="" />
            {SecondAccountsData.map((item, index) => {
                // Check if it's a bank type
                if (selectedFirstAccount === 'bank') {
                return <Picker.Item label={item.title} value={item.financial_acc_id} key={index} />
                }
                // Check if it's a lawyer type
                else if (selectedFirstAccount === 'lawyer') {
                const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                return <Picker.Item label={fullName} value={item.financial_acc_nb} key={index} />
                }
                else if (selectedFirstAccount === 'client') {
                const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                return <Picker.Item label={fullName} value={item.id} key={index} />
                }
                else if (selectedFirstAccount === 'syndicate') {
                  
                  return <Picker.Item label={item.description} value={item.id} key={index} />
                  }
            })}
          </Picker>

          )}

{ThirdAccountsData.length > 0 && (

          <Picker
            selectedValue={selectedThirdAccoount}
            onValueChange={(itemValue) => handleThirdAccountChange(itemValue)}
          >
            <Picker.Item label="Select a Third Option" value="" />
            {ThirdAccountsData.map((item, index) => (
                <Picker.Item label={item.title} value={item.financial_acc_id} key={index} />
            ))}
          </Picker>

)}
  */}
           <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <TouchableOpacity style={styles.iconButton} onPress={() => Platform.OS === 'android' && setShowDatePicker(true)}>
                        
                            <Ionicons name="calendar-outline" size={24} color="black" />
                            <Text style={{ marginHorizontal: 8 }}>
                                   من  
                                </Text>
                            {Platform.OS === 'android' ? (
                                <Text style={{ marginLeft: 8 }}>
                                    {date1.toDateString()}  {/* format the date as you desire */}
                                </Text>
                            ) : (
                                <DateTimePicker
                                    value={date1}
                                    mode="date"
                                    display="default"
                                    onChange={(event, selectedDate) => {
                                      setDate1(selectedDate || date1);
                                      setShowDatePicker(false);
                                    }}
                                />
                            )}
                        </TouchableOpacity>

                        {Platform.OS === 'android' && showDatePicker && (
                            <DateTimePicker
                                value={date1}
                                mode="date"
                                display="default"
                                onChange={(event, selectedDate) => {
                                  setDate1(selectedDate || date1);
                                  setShowDatePicker(false);
                                }}
                            />
                        )}
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <TouchableOpacity style={styles.iconButton} onPress={() => Platform.OS === 'android' && setShowToDatePicker(true)}>
                            <Ionicons name="calendar-outline" size={24} color="black" />
                            <Text style={{ marginHorizontal: 8 }}>
                                   الى  
                                </Text>
                            {Platform.OS === 'android' ? (
                                <Text style={{ marginLeft: 8 }}>
                                 {date2.toDateString()}  {/* format the date as you desire */}
                                </Text>
                            ) : (
                                <DateTimePicker
                                    value={date2}
                                    mode="date"
                                    display="default"
                                    onChange={(event, selectedDate) => {
                                      setDate2(selectedDate || date1);
                                      setShowToDatePicker(false)
                                    }}
                                />
                            )}
                        </TouchableOpacity>

                        {Platform.OS === 'android' && ShowToDatePicker && (
                            <DateTimePicker
                                value={date2}
                                mode="date"
                                display="default"
                                onChange={(event, selectedDate) => {
                                  setDate2(selectedDate || date1);
                                  setShowToDatePicker(false)
                                }}
                            />
                        )}
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 70 }}> 
    <Button 
      title="الغاء" 
      onPress={onClose}

      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 70 }}>
    <Button 
      title="تصفية" 
      onPress={() => onDone({date1, date2, id: selectedFromData.content.id, text:selectedFromData.content.text})}
            color="#28a745" /* Green color */
            disabled={!date1 || !date2 || !selectedFromData.content.id || !selectedFromData.content.text}

    />
  </View>
  <View style={{ width: 70 }}>
    <Button 
      title="كشف" 
      onPress={() => onStmt({date1, date2, id: selectedFromData.content.id, text:selectedFromData.content.text})}
      color="#28a745" /* Green color */
            disabled={!date1 || !date2 || !selectedFromData.content.id || !selectedFromData.content.text}

    />
  </View>

                      
                        </View>
          

    </View>
  </View>
</Modal>
  );
};

const styles = StyleSheet.create({
  inputStyle: {
    width: '100%',
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10
  },
  pickerStyle: {
    height: 50,
    width: '100%',
    marginBottom: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10
  },
  imagePreview: {
    width: 100,
    height: 100,
    margin: 5,
    borderRadius: 5
  },
  actionButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 5
  },
  
});

export default AccoutningReportsFilter;
