import React, { useState, useEffect } from 'react';
import { Modal, View, StyleSheet, Text, Button, TextInput, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Ionicons, Entypo, FontAwesome5 } from '@expo/vector-icons';
import { fetchData, getToken } from '../../../utils/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActionSheet } from 'react-native-ui-lib';
import ClientModal from '../../Parties/components/ClientModal';

const AccountingModal = ({
    isVisible,
    setIsModalVisible,
    onClose,
    selectedFromData: initialSelectedFromData = null, // default value if not passed
    selectedToData: initialSelectedToData = null,     // default value if not passed
    IsLawsuitDefined = false,
    Lawsuit_id,
    Lawsuit_info_id,
}) => {

    const [selectedFromData, setselectedFromData] = useState(initialSelectedFromData || {
        content: {
            id: "",
            type: "",
            text: ""
        },
        case: "to"
    });

    const [selectedToData, setselectedToData] = useState(initialSelectedToData || {
        content: {
            id: "",
            type: "",
            text: ""
        },
        case: "to"
    });

    const [iosPickerVisible, setIosPickerVisible] = useState(false);




    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isFromModalVisible, setIsFromModalVisible] = useState(false);
    const [isToModalVisible, setIsToModalVisible] = useState(false);
    const [fromButtonText, setFromButtonText] = useState(' من حساب');
    const [toButtonText, settoButtonText] = useState('الى حساب ');
    const [selectedCurrency, setselectedCurrency] = useState('lbp');
    const [date, setDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [transactionValue, setTransactionValue] = useState('');
    const [transactionDescription, setTransactionDescription] = useState('')


    const [selectedFirstAccount, setSelectedFirstAccount] = useState([]);
    const [FirstAccountsData, setFirstAccountsData] = useState([]);

    const [selectedSecondAccount, setSelectedSecondAccount] = useState('');
    const [SecondAccountsData, setSecondAccountsData] = useState('');

    const [selectedThirdAccoount, setSelectedThirdAccoount] = useState([]);
    const [ThirdAccountsData, setThirdAccountsData] = useState('');

    const [selectedFirstTOAccount, setSelectedFirstTOAccount] = useState([]);
    const [FirstTOAccountData, setFirstTOAccountData] = useState([]);

    const [selectedSecondTOAccount, setSelectedSecondTOAccount] = useState('');
    const [SecondTOAccountsData, setSecondTOAccountsData] = useState('');

    const [selectedThirdTOAccoount, setSelectedThirdTOAccoount] = useState([]);
    const [ThirdTOAccountsData, setThirdTOAccountsData] = useState('');

    const [isFAActionSheetVisible, setIsFAActionSheetVisible] = useState(false);
    const [isSecondActionSheetVisible, setIsSecondActionSheetVisible] = useState(false);
    const [isThirdActionSheetVisible, setIsThirdActionSheetVisible] = useState(false);
    const [isFirstTOActionSheetVisible, setIsFirstTOActionSheetVisible] = useState(false);
    const [isSecondTOActionSheetVisible, setIsSecondTOActionSheetVisible] = useState(false);
    const [isThirdTOActionSheetVisible, setIsThirdTOActionSheetVisible] = useState(false);
    const [myAccount, setMyAccount] = useState('');
    const [isFAActionSheetVisible2, setIsFAActionSheetVisible2] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);


 
    useEffect(() => {
        const fetchMyAccount = async () => {
            try {
                const MyAccountString = await AsyncStorage.getItem('fetch_credentials');
                const MyAccountObject = JSON.parse(MyAccountString);
                //console.log("Financial Account Number:", MyAccountObject.financial_acc_nb);
                setMyAccount(MyAccountObject.financial_acc_nb);
            } catch (error) {
                console.error('Failed to fetch and parse from AsyncStorage:', error);
            }
        };

        fetchMyAccount();
    }, []);





    useEffect(() => {
        if (ThirdTOAccountsData.length > 0) {
            setIsThirdTOActionSheetVisible(true);
        }

    }, [ThirdTOAccountsData]);

    useEffect(() => {
        if (SecondTOAccountsData.length > 0) {
            setIsSecondTOActionSheetVisible(true);
        }

    }, [SecondTOAccountsData]);


    useEffect(() => {
        if (SecondAccountsData.length > 0) {
            setIsSecondActionSheetVisible(true);
        }

    }, [SecondAccountsData]);

    useEffect(() => {
        if (ThirdAccountsData.length > 0) {
            setIsThirdActionSheetVisible(true);
        }

    }, [ThirdAccountsData]);

    const openIOSPicker = () => {
        setIosPickerVisible(true);
    };

    useEffect(() => {
        if (selectedFromData && selectedFromData.content && selectedFromData.content.id) {
            setSelectedThirdAccoount(selectedFromData.id);
            // handleThirdAccountChange(selectedFromData);
            //fetchThirdAccounts(selectedFromData.content.id);
            handleFromAccountButton();
        }
        if (selectedToData && selectedToData.content && selectedToData.content.id) {
            setSelectedThirdTOAccoount(selectedToData.id);
            //console.log('LAWSUITID:', Lawsuit_id);
            //console.log('LAWSUITINFOID:', Lawsuit_info_id);
            (IsLawsuitDefined ? fetchFirstAccounts('transactions_picker', { case: 'reverse', lawsuit_id: Lawsuit_id, lawsuit_info_id: Lawsuit_info_id }) : null);
            //handleThirdTOAccountChange(selectedToData);
            handleToAccountButton();
        }
    }, [selectedFromData, selectedToData]);


    const resetStates = () => {
        setselectedFromData(initialSelectedFromData || {
            content: {
                id: "",
                type: "",
                text: ""
            },
            case: "to"
        });
        setselectedToData(initialSelectedToData || {
            content: {
                id: "",
                type: "",
                text: ""
            },
            case: "to"
        });
        setError(null);
        setLoading(false);
        setIsFromModalVisible(false);
        setIsToModalVisible(false);
        setFromButtonText('من حساب');
        settoButtonText('الى حساب');
        setselectedCurrency('lbp');
        setDate(new Date());
        setShowDatePicker(false);
        setTransactionValue('');
        setTransactionDescription('');
        setSelectedFirstAccount([]);
        setFirstAccountsData([]);
        setSelectedSecondAccount('');
        setSecondAccountsData('');
        setSelectedThirdAccoount([]);
        setThirdAccountsData([]);
        setSelectedFirstTOAccount([]);
        setFirstTOAccountData([]);
        setSelectedSecondTOAccount('');
        setSecondTOAccountsData([]);
        setSelectedThirdTOAccoount([]);
        setThirdTOAccountsData([]);
    }



    useEffect(() => {
        if (isVisible) {
            resetStates();

            fetchFirstAccounts('transactions_picker', { case: "from" });

        }
    }, [isVisible]);




    const handleFirstAccountChange = async (type) => {
        setSelectedFirstAccount(type);
        setSelectedSecondAccount([]);
        setSecondAccountsData('');
        setSelectedThirdAccoount([]);
        setThirdAccountsData('');
        setFirstTOAccountData([]);
        setSelectedFirstTOAccount('');
        setSecondTOAccountsData([]);
        setSelectedSecondTOAccount('');
        setThirdTOAccountsData([]);
        setSelectedThirdTOAccoount('');

        // Fetch the item from the selectedFirstAccount array using the selected type
        const selectedItem = FirstAccountsData.find(item => item.type === type);

        // Update the selectedFromData with type and text attributes
        if (selectedItem) {
            setselectedFromData({
                content: {
                    ...selectedFromData.content, // Keep other attributes unchanged
                    id: selectedItem.id,
                    type: selectedItem.type,
                    text: selectedItem.text
                },
                case: "to"
            });
        }

        if (selectedItem.id !== myAccount && !selectedItem.id.startsWith('50000') && selectedItem.id !== '53000') {

            // Fetch subaccount data based on the selected Account
            const newParams = { type, case: "expand" };
            ////console.log(newParams);
            const subData = await fetchSecondAccounts('transactions_picker', newParams);
            //console.log('second accounts: ', subData)
            if (subData && Array.isArray(subData)) {
                setSecondAccountsData([...subData]);
            }
        }
    };




    // Update subaccount selection to trigger third picker data fetch
    const handleSecondAccountChange = async (value) => {
        ////console.log("Selected subaccount value:", value);  // Log selected value

        setSelectedSecondAccount(value);

        // Try to find by both attributes since we don't know the type yet.
        const SecondAccountsDataItem = SecondAccountsData.find(item => item.financial_acc_id === value || item.financial_acc_nb === value || item.id === value);

        ////console.log("Found subaccount data:", SecondAccountsData);  // Log entire data
        ////console.log("Found subaccount item:", SecondAccountsDataItem);  // Log found item
        const type = selectedFirstAccount; //value of selectedfirstaccount is the type

        if (SecondAccountsDataItem) {

            ////console.log(type)
            ////console.log("true");
            ////console.log(SecondAccountsDataItem);

            //the below setselectedFromData is only useful here in case of Lawyer and Bank this is why we missed the other cases.
            
            setselectedFromData({
                content: {
                    ...selectedFromData.content,
                    id: (type === 'bank' || type === 'supplier') ? SecondAccountsDataItem.financial_acc_id : SecondAccountsDataItem.financial_acc_nb,
                    text: (type === 'lawyer' || type === 'employee') ? `${SecondAccountsDataItem.first_name} ${SecondAccountsDataItem.middle_name} ${SecondAccountsDataItem.last_name}` : SecondAccountsDataItem.title,

                },
                case: "to"
            });

            //  //console.log("Updated selected data:", selectedFromData);  // Log updated data

            (type !== 'lawyer' && type !== 'employee' && type !== 'supplier'  ) ? //because lawyer has no id so the fetch will return an error.
               type === 'syndicate' ?  await fetchThirdAccounts(SecondAccountsDataItem.id, isSyndicate= true) : await fetchThirdAccounts(SecondAccountsDataItem.id, isSyndicate= false) : ''
        } else {
            console.warn("No matching subaccount item found for value:", value);
        }

    };



    const handleThirdAccountChange = async (value) => {
        setSelectedThirdAccoount(value);

        const selectedThirdItem = ThirdAccountsData.find(item => item.financial_acc_id === value);
        if (selectedThirdItem) {
            setselectedFromData({
                content: {
                    ...selectedFromData.content, // Keep other attributes unchanged
                    text: selectedThirdItem.title, // Update the text
                    id: selectedThirdItem.financial_acc_id,
                    type: 'lawsuit' //because this is the only case where we use the third picker and if we keep the type selected from the first picker as we did for the second one, it be 'client'
                },
                case: "to"
            });
        }
    };

    const fetchFirstAccounts = async (method, params) => {
        setLoading(true);

        try {
            const data = await fetchData({ method, params }, 'array');

            if (data && Array.isArray(data)) {
                setFirstAccountsData([...data]);
                //console.log('firstaccounts data:', data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };





    const fetchSecondAccounts = async (method, params) => {
        try {
            const data = await fetchData({ method, params }, 'json', true);

            if (data && Array.isArray(data.content)) {
                console.log(data);
                return data.content;
                
            } else {
                return [];
            }
        } catch (err) {
            setError(err.message);
            console.error("Error in fetchSecondAccounts:", err);
            return [];
        }
    };


    const fetchThirdAccounts = async (id, isSyndicate, to=false) => {
        const newParams = isSyndicate ?  { id, type: "syndicate_acc", case: "expand" } : { id, type: "lawsuit", case: "expand" };
        //console.log('NEW PARAS:', newParams);
        const data = await fetchSecondAccounts('transactions_picker', newParams);

        if (!to && data && Array.isArray(data)) {
            setThirdAccountsData([...data]);
        } else if (to && data && Array.isArray(data)) {
            setThirdTOAccountsData([...data]);
        }
    };


    /*
        useEffect(() => {
           // if (!selectedFromData.content || !selectedFromData.content.id) {
            fetchFirstAccounts('transactions_picker', { case: "from" });
           // }
        }, []);
        
        */

    //############################ TO ACCOUNT #######################################################

    const handleFromAccountButton = async () => {
        const newParams = selectedFromData;
        //console.log('NEW PARAMS: ',newParams);
        const data = await fetchFirstTOAccounts('transactions_picker', newParams);
        //console.log('FIRST TO ASCCOUNTS: ', data)
        if (data && Array.isArray(data)) {
            setFirstTOAccountData([...data]);
        }
        setIsFromModalVisible(false)
        setFromButtonText(selectedFromData.content.text)
    }


    const handleToAccountButton = async () => {
        setIsToModalVisible(false)
        settoButtonText(selectedToData.content.text)
    }





    const fetchFirstTOAccounts = async (method, params) => {
        setLoading(true);
        try {
            const data = await fetchData({ method, params });

            if (data && Array.isArray(data)) {
                setFirstTOAccountData([...data]);
                console.log('firstTOaccounts data:', data);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };



    const handleFirstToAccountChange = async (type) => {
        setSelectedFirstTOAccount(type);
        setSelectedSecondTOAccount([]);
        setSecondTOAccountsData('');
        setSelectedThirdTOAccoount([]);
        setThirdTOAccountsData('');

        // Fetch the item from the selectedFirstAccount array using the selected type
        const selectedItem = FirstTOAccountData.find(item => item.type === type);

        // Update the selectedFromData with type and text attributes
        if (selectedItem) {
            setselectedToData({
                content: {
                    ...selectedFromData.content, // Keep other attributes unchanged
                    id: selectedItem.id,
                    type: selectedItem.type,
                    text: selectedItem.text
                },
                case: "to"
            });
        }
        if (selectedItem.id !== myAccount && selectedItem.id !== '50000' && selectedItem.id !== '51000'  && selectedItem.id !== '54000' && selectedItem.id !== '52000') {
            // Fetch subaccount data based on the selected Account
            const newParams = { type, case: "expand" };
            //console.log('NEWPARAM', newParams);
            const subData = await fetchSecondAccounts('transactions_picker', newParams);
            //console.log('second accounts: ', subData)
            if (subData && Array.isArray(subData)) {
                setSecondTOAccountsData([...subData]);
            }
        }
    }


    const handleSecondTOAccountChange = async (value) => {
        ////console.log("Selected subaccount value:", value);  // Log selected value

        setSelectedSecondTOAccount(value);

        // Try to find by both attributes since we don't know the type yet.
        const SecondTOAccountsDataItem = SecondTOAccountsData.find(item => item.financial_acc_id === value || item.financial_acc_nb === value || item.id === value);

        ////console.log("Found subaccount data:", SecondAccountsData);  // Log entire data
        ////console.log("Found subaccount item:", SecondAccountsDataItem);  // Log found item
        const type = selectedFirstTOAccount; //value of selectedfirstaccount is the type

        if (SecondTOAccountsDataItem) {

            ////console.log(type)
            ////console.log("true");
            ////console.log(SecondAccountsDataItem);

            //the below setselectedFromData is only useful here in case of Lawyer and Bank this is why we missed the other cases.
            setselectedToData({
                content: {
                    ...selectedFromData.content,
                    id: (type === 'bank' || type === 'supplier') ? SecondTOAccountsDataItem.financial_acc_id : SecondTOAccountsDataItem.financial_acc_nb,
                    text: (type === 'lawyer' || type === 'employee') ? `${SecondTOAccountsDataItem.first_name} ${SecondTOAccountsDataItem.middle_name} ${SecondTOAccountsDataItem.last_name}` : SecondTOAccountsDataItem.title,

                },
                case: "to"
            });

            //  //console.log("Updated selected data:", selectedFromData);  // Log updated data

            (type !== 'lawyer' && type !== 'employee' && type !== 'supplier'  ) ? //because lawyer has no id so the fetch will return an error.
            type === 'syndicate' ?  await fetchThirdAccounts(SecondTOAccountsDataItem.id, isSyndicate= true, to=true) : await fetchThirdAccounts(SecondTOAccountsDataItem.id, isSyndicate= false, to=true) : ''
     } else {
         console.warn("No matching subaccount item found for value:", value);
     }
    }


    const handleThirdTOAccountChange = async (value) => {
        setSelectedThirdTOAccoount(value);

        const selectedThirdItem = ThirdTOAccountsData.find(item => item.financial_acc_id === value);
        if (selectedThirdItem) {
            setselectedToData({
                content: {
                    ...selectedFromData.content, // Keep other attributes unchanged
                    text: selectedThirdItem.title, // Update the text
                    id: selectedThirdItem.financial_acc_id,
                    type: 'lawsuit' //because this is the only case where we use the third picker and if we keep the type selected from the first picker as we did for the second one, it be 'client'
                },
                case: "to"
            });
        }


    };


    //############################################################################################

    const handleInsertRecord = async () => {
      //  setIsSubmitting(true);
      setIsModalVisible(false);
      // Generate a random negative ID
 const randomNegativeId = -Math.floor(Math.random() * 1000000);


const newItem ={
    id: randomNegativeId,
    from: selectedFromData.content.id,
    fromTitle: fromButtonText,
    to: selectedToData.content.id,
    toTitle:toButtonText,
    description: transactionDescription,
    currency: selectedCurrency,
    amount: transactionValue,
    date_entered: date,


    sys_date: parseInt(Date.now())

};

onClose(newItem);

/*
        const token = await getToken();
        const param = {
            method: 'insert_transaction',
            params: newItem
           
        };
        //console.log(date);
    

        try {
            const response = await fetch('https://maktabi.info/includes/model.php', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(param),
            });

            const data = await response.text();
            //console.log('Response Data:', data);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            //console.log('Response Data:', data);

        } catch (error) {
            console.error('Error:', error);
        } finally {
          //  setIsSubmitting(false);
            onClose();
        }
        */
    };








    //#############################################################################################


    return (
        <Modal 
            animationType="slide"
            transparent={true}
            visible={isVisible}
            onRequestClose={()=> setIsModalVisible(false)}
        >
            <View style={styles.container}>
                {isSubmitting ? (
                    // View when submitting
                    <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <ActivityIndicator size="large" color="#0000ff" />
                        <Text>جاري الحفظ ...</Text>
                    </View>
                ) : (
                    <View style={styles.modalContent}>

                        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> اضافة قيد محاسبي</Text>
                        <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />


                        <TouchableOpacity onPress={() => setIsFAActionSheetVisible(true)} style={styles.button}>
                            <Text style={styles.buttonText}>{fromButtonText}</Text>
                        </TouchableOpacity>
 
                        <ActionSheet
                            title="اختر حساب"
                            message="الرجاء اختيار حساب من القائمة ادناه"
                            cancelButtonIndex={FirstAccountsData.length}
                            destructiveButtonIndex={0}
                            useNativeIOS
                            options={[
                                ...FirstAccountsData.map((item) => ({
                                    label: item.text,
                                    onPress: () => handleFirstAccountChange(item.type)
                                })),
                                { label: 'Cancel', onPress: () => console.log('Cancelled') }
                            ]}
                            visible={isFAActionSheetVisible}
                            onDismiss={() => {
                                setIsFAActionSheetVisible(false)
                            }}
                        />

                        {SecondAccountsData.length > 0 && (
                             SecondAccountsData.length < 7 ?

<ActionSheet

title="Select a Subaccount"
message="Please choose a subaccount from the list below"
cancelButtonIndex={SecondAccountsData.length} // Assuming the cancel button is the last option
useNativeIOS // Use the native action sheet for iOS
migrateDialog={true}
optionsStyle={{}}
options={[
    ...SecondAccountsData.map((item, index) => {
        let label = '';
        if (selectedFirstAccount === 'bank' || selectedFirstAccount === 'supplier') {
            label = item.title;
        } else if (selectedFirstAccount === 'lawyer' || selectedFirstAccount === 'employee') {
            label = `${item.first_name} ${item.middle_name} ${item.last_name}`;
        } else if (selectedFirstAccount === 'client') {
            label = `${item.first_name} ${item.middle_name} ${item.last_name}`;
        } else if (selectedFirstAccount === 'syndicate') {
            label = item.description;
        }
        return {
            label: label,
            onPress: () => handleSecondAccountChange(item.financial_acc_id || item.financial_acc_nb || item.id)
        };
    }),
    { label: 'Cancel', onPress: () => console.log('Cancelled') }
]}
visible={isSecondActionSheetVisible} // Control visibility with a state variable
onDismiss={() => setIsSecondActionSheetVisible(false)} // Close the ActionSheet when dismissed
/>

:
                           <ClientModal
                           visible={isSecondActionSheetVisible}
                           onClose={() => setIsSecondActionSheetVisible(false)}
                           data={SecondAccountsData}
                           selectedFirstAccount={selectedFirstAccount}
                           handleSecondAccountChange={handleSecondAccountChange}
                       />
                        )}
                        {ThirdAccountsData.length > 0 && (

                            <ActionSheet
                                title="Select a Third Option"
                                message="Please choose an option from the list below"
                                cancelButtonIndex={ThirdAccountsData.length +1} // Assuming the cancel button is the last option
                                useNativeIOS // Use the native action sheet for iOS
                                options={[
                                    ...ThirdAccountsData.map((item, index) => ({
                                        label : item.title,
                                         onPress: () => handleThirdAccountChange(item.financial_acc_id)
                                        })),

                                  //  { label: 'Cancel', onPress: () => console.log('Cancelled') }
                                ]}
                                visible={isThirdActionSheetVisible} // Control visibility with a state variable
                                onDismiss={() => setIsThirdActionSheetVisible(false)} // Close the ActionSheet when dismissed
                            />

                        )}



                        <TouchableOpacity onPress={() => setIsFirstTOActionSheetVisible(true)} style={styles.button}>
                            <Text style={styles.buttonText}>{toButtonText}</Text>
                        </TouchableOpacity>

                        <ActionSheet
                            title="Select an Option"
                            message="Please choose an option from the list below"
                            cancelButtonIndex={FirstTOAccountData.length} // Assuming the cancel button is the last option
                            useNativeIOS // Use the native action sheet for iOS
                            options={[
                                ...FirstTOAccountData.map((item, index) => {
                                    return {
                                        label: item.text,
                                        onPress: () => handleFirstToAccountChange(item.type)
                                    };
                                }),
                                { label: 'Cancel', onPress: () => console.log('Cancelled') }
                            ]}
                            visible={isFirstTOActionSheetVisible} // Control visibility with a state variable
                            onDismiss={() => setIsFirstTOActionSheetVisible(false)} // Close the ActionSheet when dismissed
                        />






                        {SecondTOAccountsData.length > 0 && (

SecondTOAccountsData.length < 7 ?
                            <ActionSheet
                                title="Select a Subaccount"
                                message="Please choose a subaccount from the list below"
                                cancelButtonIndex={SecondTOAccountsData.length + 1} // Assuming the cancel button is the last option
                                useNativeIOS // Use the native action sheet for iOS
                                options={[
                                    // { label: "Select a Subaccount", onPress: () => console.log('Header selected') },
                                    ...SecondTOAccountsData.map((item, index) => {
                                        if (selectedFirstTOAccount === 'bank') {
                                            return {
                                                label: item.title,
                                                onPress: () => handleSecondTOAccountChange(item.financial_acc_id)
                                            };
                                        }
                                        else if (selectedFirstTOAccount === 'supplier') {
                                            return {
                                                label: item.title,
                                                onPress: () => handleSecondTOAccountChange(item.financial_acc_id)
                                            };
                                        }
                                        else if (selectedFirstTOAccount === 'lawyer') {
                                            const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                                            return {
                                                label: fullName,
                                                onPress: () => handleSecondTOAccountChange(item.financial_acc_nb)
                                            };
                                        }
                                        else if (selectedFirstTOAccount === 'employee') {
                                            const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                                            return {
                                                label: fullName,
                                                onPress: () => handleSecondTOAccountChange(item.financial_acc_nb)
                                            };
                                        } else if (selectedFirstTOAccount === 'syndicate') {
                                            return {
                                                label: item.description,
                                                onPress: () => handleSecondTOAccountChange(item.financial_acc_nb)
                                            };
                                        }
                                        else if (selectedFirstTOAccount === 'client') {
                                            const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`;
                                            return {
                                                label: fullName,
                                                onPress: () => handleSecondTOAccountChange(item.id)
                                            };
                                        }
                                        return null;  // For any other cases not handled
                                    }).filter(Boolean), // Filter out nulls
                                    //  { label: 'Cancel', onPress: () => console.log('Cancelled') }
                                ]}
                                visible={isSecondTOActionSheetVisible} // Control visibility with a state variable
                                onDismiss={() => setIsSecondTOActionSheetVisible(false)} // Close the ActionSheet when dismissed
                            />
                            :
                            <ClientModal
                            visible={isSecondTOActionSheetVisible}  
                            onClose={() => setIsSecondTOActionSheetVisible(false)}
                            data={SecondTOAccountsData}
                            selectedFirstAccount={selectedFirstTOAccount}
                            handleSecondAccountChange={handleSecondTOAccountChange}
                        />
                        )}

                        {ThirdTOAccountsData.length > 0 && (
                            <ActionSheet
                                title="Select a Third Option"
                                message="Please choose an option from the list below"
                                cancelButtonIndex={ThirdTOAccountsData.length + 1} // Assuming the cancel button is the last option
                                useNativeIOS // Use the native action sheet for iOS
                                options={[
                                    //  { label: "Select a Third Option", onPress: () => console.log('Header selected') },
                                    ...ThirdTOAccountsData.map((item, index) => ({
                                        label: item.title,
                                        onPress: () => handleThirdTOAccountChange(item.financial_acc_id)
                                    })),
                                    // { label: 'Cancel', onPress: () => console.log('Cancelled') }
                                ]}
                                visible={isThirdTOActionSheetVisible} // Control visibility with a state variable
                                onDismiss={() => setIsThirdTOActionSheetVisible(false)} // Close the ActionSheet when dismissed
                            />
                        )}


                        <View style={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                            <TextInput
                                style={styles.input}
                                placeholder="المبلغ ..."
                                keyboardType="numeric"

                                onChangeText={(text) => setTransactionValue(text)}
                            />
                            {
                                Platform.OS === 'android' ? (
                                    <Picker
                                        style={styles.picker}
                                        selectedValue={selectedCurrency}
                                        onValueChange={(itemValue) => setselectedCurrency(itemValue)}
                                    >
                                        <Picker.Item label="LBP" value="lbp" />
                                        <Picker.Item label="USD" value="usd" />
                                        {/* ... other options */}
                                    </Picker>
                                ) : (
                                    <TouchableOpacity onPress={openIOSPicker}>
                                        <Text style={{ marginHorizontal: 20 }}>    {selectedCurrency ? selectedCurrency : 'اختر العملة'}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            }
                            {
                                iosPickerVisible && Platform.OS === 'ios' && (
                                    <ActionSheet

                                        title={'اختر العملة'}
                                        message={'الرجاء اختيار العملة'}
                                        visible={iosPickerVisible}
                                        cancelButtonIndex={3}
                                        destructiveButtonIndex={0}
                                        //useNativeIOS
                                        options={[
                                            { label: 'LBP', onPress: () => { setselectedCurrency('lbp'); setIosPickerVisible(false) } },
                                            { label: 'USD', onPress: () => { setselectedCurrency('usd'); setIosPickerVisible(false) } },
                                            { label: 'الغاء', onPress: () => { setIosPickerVisible(false) } },
                                        ]}
                                        onDismiss={() => setIosPickerVisible(false)} // Close the ActionSheet when dismissed


                                    />




                                )
                            }
                        </View>


                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <TouchableOpacity style={styles.iconButton} onPress={() => Platform.OS === 'android' && setShowDatePicker(true)}>
                                <Ionicons name="calendar-outline" size={24} color="black" />
                                {Platform.OS === 'android' ? (
                                    <Text style={{ marginLeft: 8 }}>
                                        {date.toDateString()}  {/* format the date as you desire */}
                                    </Text>
                                ) : (
                                    <DateTimePicker
                                        value={date}
                                        mode="date"
                                        display="default"
                                        onChange={(event, selectedDate) => {
                                            //console.log("Picker Changed", selectedDate);
                                            if (selectedDate) {
                                                setDate(selectedDate);
                                            }
                                        }}
                                    />
                                )}
                            </TouchableOpacity>

                            {Platform.OS === 'android' && showDatePicker && (
                                <DateTimePicker
                                    value={date}
                                    mode="date"
                                    display="default"
                                    onChange={(event, selectedDate) => {
                                        //console.log("Picker Changed", selectedDate);
                                        setShowDatePicker(false);
                                        if (selectedDate) {
                                            setDate(selectedDate);
                                        }
                                    }}
                                />
                            )}
                        </View>

                        <TextInput
                            style={[styles.input, { width: '80%' }]}
                            placeholder="الشرح ... "
                            onChangeText={(text) => setTransactionDescription(text)}

                        />


<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={() => {
        resetStates();
        setIsModalVisible(false);
    }}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
  <Button 
  title="تسجيل" 
  onPress={() => { 
    setIsModalVisible(false); 
    handleInsertRecord();
  }}
  color="#28a745" /* Green color */
  disabled={!selectedToData?.content?.id || !transactionValue || !transactionDescription || !selectedFromData?.content?.id} /* Disable button if id is empty or undefined */
/>
  </View>

                      
                        </View>

                    </View>
                )}
            </View>
        </Modal>
    );
};


const styles = StyleSheet.create({
    input: {
        width: '40%',
        padding: 10,
        marginVertical: 10,
        borderRadius: 10,
        borderColor: '#ccc',
        borderWidth: 1,
        textAlign: 'center',
    },
    picker: {
        //  height: 50,
        width: '40%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
    },
    modalContent: {
        width: 320,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        marginVertical: 10,
        width: '80%',
        backgroundColor: '#2196F3',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },

    iconButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        padding: 10,
        marginVertical: 10,
        borderRadius: 10,
        borderColor: '#ccc',
        borderWidth: 1,
        textAlign: 'center',
    },
    modalView: {
        width: '90%',
        backgroundColor: '#E4E3D3',
        borderRadius: 20,
        padding: 20,
        //  alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        shadowColor: "black",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        marginTop: 50
    },
});

export default AccountingModal;
