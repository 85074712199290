import React, { createContext, useState } from 'react';

const RefreshContext = createContext();

export const RefreshProvider = ({ children }) => {
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [refreshProcedures, setRefreshProcedures] = useState(false);
  const [refreshAccounting, setRefreshAccounting] = useState(false);

  const value = {
    refreshCalendar,
    setRefreshCalendar,
    refreshProcedures,
    setRefreshProcedures,
    refreshAccounting,
    setRefreshAccounting,
  };

  return (
    <RefreshContext.Provider value={value}>
      {children}
    </RefreshContext.Provider>
  );
};

export default RefreshContext;
