import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, View, FlatList, Modal, TouchableOpacity, Text, ScrollView } from 'react-native';
import * as FileSystem from 'expo-file-system';
import ImageViewer from 'react-native-image-zoom-viewer';
import * as Sharing from 'expo-sharing';
import { Ionicons } from '@expo/vector-icons';
import PdfIcon from '../assets/pdf.png';
import DocIcon from '../assets/doc.png';
import FileIcon from '../assets/files.png';
import { getToken } from '../utils/api';
import Constants from 'expo-constants';

 
const ImageCarouselModal =React.memo(({ fileLinks, isNested=false }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
const [files, setFiles] = useState([]);
const [imagesForViewer, setImageViewer] = useState([]);
const apiUrl = Constants.expoConfig.extra.apiUrl;
const [loadingStatus, setLoadingStatus] = useState([]);

useEffect(() => {
  // Normalize fileLinks to always be an array
  const normalizedFileLinks = Array.isArray(fileLinks) ? fileLinks : [fileLinks];
  fetchFileLinks(normalizedFileLinks);
}, [fileLinks]);
/*
useEffect(() => {
  fetchFileLinks(fileLinks);
}, [fileLinks]);
*/



const fetchFileLinks = async (fileLinks) => {
  setLoadingStatus(fileLinks.map(() => true));

  for (let i = 0; i < fileLinks.length; i++) {
    fetchFileLink(fileLinks[i], i);
  }
};

const fetchFileLink = async (fileId, index) => {
  // Get the user token
  const token = await getToken();

  // Make an API call to fetch the file link
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      method: 'select_file',
      params: {
        id: fileId
      }
    }),
  });

  const responseData = await response.json();
    if (typeof responseData === 'string' && responseData.startsWith('data:')) {
      setFiles(prevFiles => [...prevFiles, responseData]);
      setImageViewer(prevImages => [...prevImages, { url: responseData }]);
      setLoadingStatus((prevStatus) => prevStatus.map((status, idx) => idx === index ? false : status));
    } else {
    console.error(`Error fetching file with ID ${fileId}: Invalid data URI format in the response.`);
    return null;
  }
};












  // Convert fileLinks for ImageViewer format

  const shareFile = async (item, fileType) => {
    try {
      let fileUri = item;
  
      // Check if the item is a base64 string
      if (item.includes(";base64,")) {
        fileUri = await saveBase64ToBlob(item, fileType);
      }
  
      if (await Sharing.isAvailableAsync()) {
        await Sharing.shareAsync(fileUri);
      } else {
        console.error("Sharing is not available on this platform");
      }
    } catch (error) {
      console.error("Error sharing file:", error);
    }
  };


  const renderItem = ({ item, index }) => {
    const fileType = getFileType(item);
    const isImage = fileType && fileType.startsWith('image/');
  
    const handlePress = async () => {
      if (isImage) {
        setModalImageIndex(index);
        setModalVisible(true);
      } else {
        await openFile(item, fileType);
      }
    };
  
    const handleShare = async () => {
      await shareFile(item, fileType);
    };
  
    return (
      <View key={index} style={styles.itemContainer}>
      <TouchableOpacity onPress={handlePress} style={styles.imageContainer}>
        {loadingStatus[index] ? (
          <ActivityIndicator size="large" color="black" /> // Customize as needed
        ) : isImage ? (
          <Image style={styles.image} source={{ uri: item }} resizeMode="contain" />
        ) : (
          renderFileTypeIcon(fileType)
        )}
      </TouchableOpacity>
        <TouchableOpacity onPress={handleShare}>
          <Ionicons name="share-social" size={24} color="black" />
        </TouchableOpacity>
      </View>
    );
  };
  
  const renderFileTypeIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return <Image source={PdfIcon} style={styles.iconStyle} />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Image source={DocIcon} style={styles.iconStyle} />;
      // Add cases for other file types if needed
      default:
        return <Image source={FileIcon} style={styles.iconStyle} />;
    }
  };
  
  // Rest of your component code remains unchanged
  
 
  const getFileType = (base64Link) => {
    const match = base64Link.match(/data:\s*([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/);
    if (match && match.length > 1) {
      return match[1];
    }
    return null;
  };
  
  
  const saveBase64ToBlob = async (base64Data, fileType) => {
    const base64Body = base64Data.split(";base64,").pop();
    let extension;
  
    if (fileType.startsWith('image/')) {
      // Extract extension from image MIME type (e.g., image/jpeg -> .jpeg)
      extension = '.' + fileType.split('/')[1];
    } else {
      switch (fileType) {
        case "application/pdf":
          extension = ".pdf";
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          extension = ".docx";
          break;
        // Add more file type cases as needed
        default:
          extension = ".txt";
          break;
      }
    }
  
    const fileName = new Date().getTime() + extension;
    const fileUri = FileSystem.documentDirectory + fileName;
  
    await FileSystem.writeAsStringAsync(fileUri, base64Body, {
      encoding: FileSystem.EncodingType.Base64
    });
  
    return fileUri;
  };
  
  


  const openFile = async (base64Data, fileType) => {
    try {
      const filePath = await saveBase64ToBlob(base64Data, fileType);
      //console.log("File saved at:", filePath);
      
      const fileInfo = await FileSystem.getInfoAsync(filePath);
      if (fileInfo.exists) {
        //console.log("File exists with size:", fileInfo.size);
      } else {
        console.error("File doesn't exist!");
      }
      //console.log("Trying to open file...");
      
      if (await Sharing.isAvailableAsync()) {
        await Sharing.shareAsync(filePath);
      } else {
        console.error("Sharing is not available on this platform");
      }
      
      //console.log("File opening attempted");
    } catch (error) {
      console.error("Error opening file:", error);
    }
};
  
  


  
return (
  <View style={styles.container}>
    {!isNested ? (
      <View style={styles.header}>
        <Text style={styles.headerText}>الملفات المرفقة</Text>
      </View>
    ) : null}

    {isNested ? (
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={{ height: 100 }}>
        {files.map((item, index) => renderItem({ item, index }))}
      </ScrollView>
    ) : (
      <FlatList
        data={files}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        numColumns={3}
      />
    )}

    <View style={styles.footer}>
      <Text style={styles.footerText}>مجموع الملفات: {fileLinks.length}</Text>
    </View>

    <Modal visible={modalVisible} transparent={true} onRequestClose={() => setModalVisible(false)}>
      <ImageViewer imageUrls={imagesForViewer} index={modalImageIndex} onSwipeDown={() => setModalVisible(false)} enableSwipeDown={true} />
    </Modal>
  </View>
  );
});

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
    margin: 5,
  },
  iconStyle: {
    width: 50,    // Adjust the width as needed
    height: 50,   // Adjust the height as needed
    resizeMode: 'contain', // Ensures the icon's aspect ratio is maintained
  },
    container: {
        flex: 1,
        backgroundColor: '#EFEFEF', // Neutral background
        padding: 10,
      },
      imageContainer: {
        flex: 1,
        margin: 10,
       // borderRadius: 8,
     //   elevation: 5, // drop shadow for android
        shadowColor: '#000', // shadow for iOS
       // shadowOffset: { width: 0, height: 2 },
        //shadowOpacity: 0.25,
       // shadowRadius: 3.84,
        overflow: 'hidden',
        width: 50
      },
      image: {
        width: '100%',
        height: 70,
      },
      placeholder: {
        justifyContent: 'center',
        alignItems: 'center',
      //  backgroundColor: '#D0D0D0', // Light gray for non-image placeholders
      },
      fileExtensionText: {
        fontSize: 16,
      },
      header: {
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#D0D0D0',
      },
      headerText: {
        fontSize: 20,
        fontWeight: 'bold',
      },
      footer: {
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1,
        borderColor: '#D0D0D0',
      },
      footerText: {
        fontSize: 16,
      },
      closeIcon: {
        position: 'absolute',
        top: 40,
        right: 10,
        zIndex: 1,
      },
});

export default ImageCarouselModal;
