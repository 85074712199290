import React, { useContext } from 'react';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Platform, View, Text } from 'react-native';

import { Ionicons, Entypo, FontAwesome5 } from '@expo/vector-icons';
import { useTheme } from 'react-native-paper';

import BadgeCountContext from '../contexts/BadgeCountContext';
import TransactionFetcher from '../src/Accounting/screens/AccountingS';
import ProceduresScreen from '../src/Procedures/screens/ProceduresS';
import UsersTabsTabNavigator from '../src/Parties/UsersNavigator';

import LawSuiteDEtailsStackNavigation from '../src/Lawsuits/LawSuiteDetailsStackNavigation';
import CalendarScreen from '../src/Calendar/screens/CalendarScreen';
import AssistantAI from '../Misc_NotUsed/AssistantAI';
//import Chat from '../components/Chat';
import { SkeletonLoader } from '../Misc_NotUsed/SkeletonLoader';

const TopTab = createMaterialTopTabNavigator();

// Custom Tab Label component with Badge
const TabLabelWithBadge = ({ label, badgeCount, color }) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
     {badgeCount > 0 && (
      <View style={{
        backgroundColor: 'red',
        borderRadius: 8,
        width: 16,
        height: 16,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Text style={{ color: 'white', fontSize: 10, fontWeight: 'bold', fontFamily: 'DefFont'}}>
          {badgeCount}
        </Text>
      </View>
    )}

    <Text style={{ color, marginRight: 4, fontSize: 20, fontFamily: 'DefFont'}}>{label}</Text>
   
  </View>
);

function MyTopTabs() {
  const { badgeCount } = useContext(BadgeCountContext);
  // Theme and other setups similar to MyTabs

  return (
    <TopTab.Navigator
      // Customize your top tab bar options here
      initialRouteName="Appointments" // Set your default tab here
      lazy={true}

      tabBarOptions={{
        activeTintColor: 'white',
        inactiveTintColor: 'grey',
        style: { backgroundColor: '#0C0D07',   height: 80}, // Adjust the height as needed},
        indicatorStyle: { backgroundColor: 'green' },
        labelStyle: {
          fontSize: 20, // Set your desired font size here
         fontFamily: 'DefFont', // Set your desired font family here
        },
        tabStyle: {
          marginRight: 5, // Adjust the right margin as needed
          marginLeft: 5,  // Adjust the left margin as needed

        },
        
      }}

      screenOptions={{
        swipeEnabled: false, // This will disable the swipe gesture
      }}
    >
      

<Tab.Screen
        name="Principal"
        component={UsersTabsTabNavigator}
        options={{
          tabBarLabel: 'الاطراف',
          
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="people-outline" color={color} size={25} />
          ),
        }}
        
      />

<Tab.Screen
        name="Expenses"
        component={TransactionFetcher}
        options={{
          tabBarLabel: 'نفقات',
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="money-bill-alt" color={color} size={25} />
          ),
        }}
      />

<Tab.Screen
        name="Processes"
        component={ProceduresScreen}
        options={{
          tabBarLabel: 'اجراءات',
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="project-diagram" color={color} size={25} />
          ),
        }}
        initialParams={{ fullScreen: true }}

      />

<Tab.Screen
        name="Lawsuits"
        component={LawSuiteDEtailsStackNavigation}
        options={{
          tabBarLabel: 'دعاوى',
          tabBarIcon: ({ color, size }) => (
            <Entypo name="suitcase" color={color} size={25} />
          ),
        }}
      />

<TopTab.Screen
        name="Appointments"
        component={CalendarScreen}
        options={{
          tabBarLabel: ({ color }) => (
            <TabLabelWithBadge label="المفكرة" badgeCount={badgeCount} color={color} />
          ),
          tabBarIcon: ({ color }) => (
            <Ionicons name="calendar" color={color} size={25} />
          ),
        }}
      />
    </TopTab.Navigator>
  );
}







const Tab = createMaterialBottomTabNavigator();

function MyBottomTabs() {
  const { badgeCount } = useContext(BadgeCountContext);

  


const theme = useTheme();
theme.colors.secondaryContainer = "transperent"


  return (
    <Tab.Navigator
    barStyle={{ height: 65, backgroundColor: '#0C0D07', fontFamily:'DefFont'  }} // customize the height here
    lazy={true}
    activeColor="white" // color for the active tab
    inactiveColor="grey" // color for the inactive tabs
   //shifting={true}

   
 
    >  
   
{/* <Tab.Screen
        name="History"
        component={Principal}
        options={{
          tabBarLabel: 'الرئيسية',
          
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="home" color={color} size={25} />
          ),
        }}
          
      />*/}

<Tab.Screen
  name="Appointments"
  component={CalendarScreen}
  options={{
    tabBarLabel: 'المفكرة',
    tabBarIcon: ({ color, size }) => (
      <Ionicons name="calendar" color={color} size={25} />
    ),
    tabBarBadge: badgeCount > 0 ? badgeCount : null,
  }}
/>
      <Tab.Screen
        name="Lawsuits"
        component={LawSuiteDEtailsStackNavigation}
        options={{
          tabBarLabel: 'الدعاوى',
          tabBarIcon: ({ color, size }) => (
            <Entypo name="suitcase" color={color} size={25} />
          ),
        }}
      />
      <Tab.Screen
        name="Processes"
        component={ProceduresScreen}
        options={{
          tabBarLabel: 'الاجراءات',
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="project-diagram" color={color} size={25} />
          ),
        }}
        initialParams={{ fullScreen: true }}

      />
      <Tab.Screen
        name="Expenses"
        component={TransactionFetcher}
        options={{
          tabBarLabel: 'المالية',
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="money-bill-alt" color={color} size={25} />
          ),
        }}
      />


<Tab.Screen
        name="Principal"
        component={UsersTabsTabNavigator}
        options={{
          tabBarLabel: 'الاطراف',
          
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="people-outline" color={color} size={25} />
          ),
        }}
        
      />
      {/*
<Tab.Screen
        name="chat"
        
        
        //component={ServerPing}
        component={AssistantAI}
        //component={SkeletonLoader}
       // component={Reminders}
//component={GeminiTestScreen}

        options={{
          tabBarLabel: 'المساعد',
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="color-wand-outline" color={color} size={25} />
          ),
        }}
      /> 
      */}

     
    </Tab.Navigator>
  );
}

const MyTabs = () => {
  return (
    <>
      {Platform.OS === 'web' ? <MyTopTabs /> : <MyBottomTabs />}
    </>
  );
};
export default MyTabs;
