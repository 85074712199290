import React, { useState, useEffect } from 'react';
import { View, Modal, TextInput, Button, Text, StyleSheet } from 'react-native';

const EmployeeModal = ({ visible, onClose, onSubmit, initialData }) => {
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setJobTitle(initialData.jobTitle);
      setPhone(initialData.phone);
      setAddress(initialData.address);
    } else {
      setName('');
      setJobTitle('');
      setPhone('');
      setAddress('');
    }
  }, [initialData]);

  const handleSubmit = () => {
    onSubmit({
      name,
      jobTitle,
      phone,
      address,
    });
    onClose();
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>إضافة وتعديل موظف</Text>
          <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom: 20 }} />
          <Text>اسم الموظف</Text>
          <TextInput
            style={styles.input}
            value={name}
            onChangeText={setName}
          />
          <Text>الوظيفة</Text>
          <TextInput
            style={styles.input}
            value={jobTitle}
            onChangeText={setJobTitle}
          />
          <Text>رقم الهاتف</Text>
          <TextInput
            style={styles.input}
            value={phone}
            onChangeText={setPhone}
          />
          <Text>العنوان</Text>
          <TextInput
            style={styles.input}
            value={address}
            onChangeText={setAddress}
          />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <View style={{ width: 100 }}>
              <Button
                title="إلغاء"
                onPress={onClose}
                color="#ff7f50" /* Orange color */
              />
            </View>
            <View style={{ width: 100 }}>
              <Button
                title="تسجيل"
                onPress={handleSubmit}
                color="#28a745" /* Green color */
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
});

export default EmployeeModal;
