import React, { useState, useRef } from 'react';
import { View, TextInput, Button, Text, StyleSheet,ScrollView, TouchableOpacity, FlatList, Modal, ActivityIndicator } from 'react-native';
    import { ActionSheet } from 'react-native-ui-lib';
    import { Picker } from '@react-native-picker/picker'; // or any other suitable library
    import { useNavigation } from '@react-navigation/native';
    import ClientFormWizard from '../../Parties/components/AddNewClient';
    import { Ionicons } from '@expo/vector-icons';
    import { fetchData, getToken } from '../../../utils/api';
import { useEffect } from 'react';
import OppoFormWizard from '../../Parties/components/AddNewOppo';
import OppoLawyerFormWizard from '../../Parties/components/AddNewOppoLawyer';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Constants from 'expo-constants';

const apiUrl = Constants.expoConfig.extra.apiUrl;


const AddNewLawsuit = ({route}) => {
  const [data, setData] = useState({
    method: 'insert_lawsuit',
    params: {
      id: null,
      lawsuit_info_id: '',
      id_in_phase: '',
      is_active: '',
      topic: '',
      financial_acc_id: '',
      user_id: '',
      room: '',
      creation_date: '',
      title: '',
      l_nb: '',
      clients: {
        '': {
          id: '',
          text: '',
          status: ''
        }
      },
      opponents: {
        '': {
          id: '',
          lawyer_opponent_id: '',
          text: '',
          status: ''
        }
      }
    }
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [actionSheetVisible, setActionSheetVisible] = useState(false);
  const [actionSheetOptions, setActionSheetOptions] = useState([]);
  const [currentField, setCurrentField] = useState('');
const [users, setUsers] = useState('');
const [clients, setClients] = useState('');
const [clientNameToIdMap, setClientNameToIdMap] = useState(new Map());
const [oppo, setOppo] = useState('');
const [oppoNameToIdMap, setOppoNameToIdMap] = useState(new Map());
const [selectedClients, setSelectedClients] = useState([]);
const [selectedOpponents, setSelectedOpponents] = useState([]);
const [statuses, setStatuses] = useState([]);
const [selectedStatus, setSelectedStatus] = useState('');
const [statusButtonTitle, setStatusButtonTitle] = useState('Select Status');
const [statusButtonColor, setStatusButtonColor] = useState('#ff6347'); // Red color
const [activeClientIndex, setActiveClientIndex] = useState(null);
const [forceUpdate, setForceUpdate] = useState(0);
const [oppoLawyers, setOppoLawyers] = useState('');
const [Types,setTypes] = useState('');
const [selectedType, setSelectedType] = useState({ text: 'النوع', id: null });
const[phases, setPhases] = useState('');
const [selectedPhase, setSelectedPhase] = useState({ text: 'المرحلة', id: null });
const [selectedJdRef, setSelectedJdRef] = useState({ text: 'المحكمة', id: null });
const [jdRefs, setJdRefs] = useState([]);
const [selectedJdRegion, setSelectedJdRegion] = useState({ text: 'المنطقة', id: null });
const [jdRegions, setJdRegions] = useState([]);
const [selectedUser, setSelectedUser] = useState({ text: 'المحامي المعين', id: null });
const [reviewMode, setReviewMode] = useState(false);
const [defaultTitle, setDefaultTitle] = useState('');
const [lawsuit_info_id, setlawsuit_info_id] = useState('');
const [isLoading, setIsLoading] = useState(false);
const navigation=useNavigation();
const[isAddNewClientVisible, setIsAddNewClientVisible] = useState(false);
const[IsAddNewOppoVisible,setIsAddNewOppoVisible] = useState(false);
const [isAddNewOppoLawyerVisible,setIsAddNewOppoLawyerVisible] = useState(false);
const [searchTerm, setSearchTerm] = useState('');
const [LawsuitDetails, setLawsuitDetails] = useState('');
const [fetchTrigger, setFetchTrigger] = useState(false); // New state to trigger fetch

const [isTitleModified, setIsTitleModified] = useState(false);



const fetchLawsuitData = async () => {
  try {
    // Fetch data from multiple endpoints
    const [lawsuitData, opponentsResponse, clientsResponse, phasesResponse, JudicialRefResponse, JudicialRegResponse, LawsuitResponse] = await Promise.all([
      fetchData({method:"select_lawsuit_info",params:{id:route.params.lawsuit_info_id}} ),
     fetchData({method:"select_opponents",params:{group:true,id_only:true,id:route.params.id,lawsuit_info_id:route.params.lawsuit_info_id}}),
     fetchData({method:"select_clients",params:{group:true,id_only:true,id:route.params.id,lawsuit_info_id:route.params.lawsuit_info_id}}),
     fetchData({method:"select_phases",params:{select2: true}}),
     fetchData({method:"select_judicial_references",params:{select2: true}}),
     fetchData({method:"select_judicial_regions",params:{select2: true}}),
     fetchData({method:"select_lawsuit",params:{id: route.params.id, lawsuit_info_id: route.params.lawsuit_info_id}} ),



    ]);

    console.log('route.params:', route.params);

console.log('lawsuiteData:', lawsuitData);
console.log('lawsuite Details:', LawsuitResponse);

 
console.log('opponent:', opponentsResponse);

//console.log('clients:', clientsResponse);
//console.log('Phases:', phasesResponse);

//console.log('Judicials:', JudicialRefResponse);



  // Convert arrays to objects using id as keys
const clientsObj = {};
clientsResponse.forEach(client => {
  clientsObj[client.client_id] = {
    id: client.client_id,
    status: client.status_id
  };
});

const opponentsObj = {};
opponentsResponse.forEach(opponent => {
  opponentsObj[`${opponent.id}_${opponent.lawyer_opponent_id}`] = {
    id: opponent.id,
    lawyer_opponent_id: opponent.lawyer_opponent_id,
    //text: opponent.lo_first_name,
    status: opponent.status_id
  };
});
console.log('oppo obj:', opponentsObj);
console.log('client obj:', clientsObj);


    const opponentsArray = Object.values(opponentsObj);
    console.log('oppo Array:', opponentsArray);
    const clientsArray = Object.values(clientsObj);
    console.log('clients Array:', clientsArray);


    

      // Map opponentsArray to add the `name` field
      const updatedOpponentsArray = opponentsArray.map(opponent => {
        const matchingOppo = oppo.find(item => item.id.toString() === opponent.id.toString());
        return {
          ...opponent,
          name: matchingOppo ? matchingOppo.text: 'NA'
        };
      });

      // Map ClientsArray to add the `name` field
      const updatedClientsArray = clientsArray.map(client => {
        const matchingClient = clients.find(item => item.id.toString() === client.id.toString());
        return {
          ...client,
          name: matchingClient ? matchingClient.text: 'NA'
        };
      });

      const selectedType = Types.find(type => type.id.toString() === lawsuitData.type_id.toString());
      const selectedPhase = phasesResponse.find(phase => phase.id.toString() === lawsuitData.phase_id.toString());
      const selectedJudicialRef = JudicialRefResponse.find(JdRef => JdRef.id.toString() === lawsuitData.judicial_reference_id.toString());
      const selectedJudicialReg = JudicialRegResponse.find(JdReg => JdReg.id.toString() === lawsuitData.judicial_region_id.toString());
      const selectedUser = users.find(u => u.id.toString() === LawsuitResponse.user_id.toString());




console.log('Selected Type:', selectedType);

    // Populate the data object with the fetched data
    setData((prevState) => ({
      ...prevState,
      method: 'update_lawsuit',
      params: {
        ...prevState.params,
        id: LawsuitResponse.id,
        lawsuit_info_id: LawsuitResponse.lawsuit_info_id,
        id_in_phase: LawsuitResponse.id_in_phase,
        is_active: LawsuitResponse.is_active,
        topic: LawsuitResponse.topic,
        financial_acc_id: LawsuitResponse.financial_acc_id,
        user_id: LawsuitResponse.user_id,
        room: LawsuitResponse.room,
        creation_date: LawsuitResponse.creation_date,
        title: LawsuitResponse.title,
        l_nb: LawsuitResponse.l_nb,
        clients: clientsObj,
        opponents: opponentsObj
      }
    }));
    setSelectedOpponents(updatedOpponentsArray);
    setSelectedClients(updatedClientsArray);
    handleTypeSelection(selectedType);
    setSelectedPhase(selectedPhase);
    setSelectedJdRef(selectedJudicialRef);
    setSelectedJdRegion(selectedJudicialReg);
    setLawsuitDetails(LawsuitResponse);
    setSelectedUser(selectedUser);

    

  } catch (error) {
    console.error('Error fetching lawsuit data:', error);
  }
};


useEffect(() => {
  if (route.params && route.params.update) {
    
    if (oppo.length !==0 && clients.length !==0 && Types.length !==0, users.length!==0 ) {
      fetchLawsuitData();
    }
  }
}, [route.params, oppo, clients, Types, users]);


const handleUpdate = () => {
  console.log('UPDATE CLIENTS:', formattedOpponents);
  setData((prevState) => ({
    ...prevState,
    method: 'update_lawsuit',
    params: {
      ...prevState.params,
//lawsuit_info_id: lawsuit_info_id,
      user_id:selectedUser.id,
      clients: formattedClients,
      opponents: formattedOpponents
    }
  }));
  setFetchTrigger(true); // Set trigger to true after updating state
};

useEffect(() => {
  if (fetchTrigger) {
    const fetchData = async () => {
      console.log(data);
      try {
        const token = await getToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        console.log('update response: ', result);
      } catch (error) {
        console.error('Error fetching clients:', error);
        throw error;
      }
    };

    fetchData();
    setFetchTrigger(false); // Reset trigger after fetch
    navigation.navigate('Screen1',  {HeaderTitle: data.params.title, fin_id: data.params.financial_acc_id, id: data.params.id, l_nb: data.params.l_nb, lawsuit_info_id: data.params.lawsuit_info_id});
   // console.log('Screen1',  {HeaderTitle: data.title, fin_id: data.financial_acc_id, id: data.id, l_nb: data.l_nb, lawsuit_info_id: data.lawsuit_info_id});

  }
}, [fetchTrigger]); // Only run when fetchTrigger changes


// Add similar state variables for other selections like type, level, etc.
const generateTitle = () => {
  const clientNames = selectedClients.map(c => c.name).join(" - ");
  const opponentNames = selectedOpponents.map(o => o.name).join(" - ");
  const fullTitle = `${clientNames} / ${opponentNames} / ${data.params.topic} /${selectedJdRef.text} / ${selectedJdRegion.text} / ${data.params.room}/ ${data.params.l_nb}` ;
  setDefaultTitle(fullTitle);

  // Check if the current title matches the generated title
  if (route.params.update && fullTitle && (data.params.title !== fullTitle)) {
    setIsTitleModified(true);
    console.log(fullTitle);
  } else {
    setIsTitleModified(false);
  }

};

const showUsersActionSheet = () => {
  let formattedOptions = users.map(user => ({
    label: user.text,
    onPress: () => handleUserSelection(user)
  }));

  formattedOptions.push({ label: 'الغاء', onPress: () => setActionSheetVisible(false) });

  setActionSheetOptions(formattedOptions);
  setActionSheetVisible(true);
};

const handleUserSelection = (user) => {
  setSelectedUser(user);
  setActionSheetVisible(false);
};


  useEffect(() => {
    // logic that needs to be triggered on update
  }, [forceUpdate]);


  const selectClients = async () => {
    const params = {
      method: "select_clients",
      params: {select2: true}
    };
  
    try {
      const response = await fetchData(params);
      
      // Add an option to add a new client
      response.unshift({ id: 'new_client', text: 'اضافة موكل جديد ...' });
  
      setClients(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      throw error;
    }
  };
  
  const selectOppo = async () => {
    const params = {
      method: "select_opponents",
      params: {select2: true}
    };
  
    try {
      const response = await fetchData(params);
      
      // Optionally, add an option to add a new opponent
      response.unshift({ id: 'new_opponent', text: 'اضافة خصم جديد ...' });
  
      setOppo(response);
    } catch (error) {
      console.error('Error fetching opponents:', error);
      throw error;
    }
  };
  
  
  const selectStatuses = async () => {
    const params = {
        method: "select_statuses",
        params: {select2: true}
    };

    try {
        const response = await fetchData(params);
        // Handle the response as per your application logic
        //console.log('Response:', response);
       setStatuses(response);
    } catch (error) {
        console.error('Error fetching users:', error);
        // Handle the error as per your application logic
        throw error;
    }
};


const selectOppoLawyer = async () => {
  const params = {
    method: "select_lawyers_opponents",
    params: { select2: true }
  };

  try {
    const response = await fetchData(params);
    
    // Add an option to add a new lawyer opponent
    const updatedResponse = [...response, { id: 'new_OppoLawyer', text: 'اضافة محامي جديد ...' }];

    setOppoLawyers(updatedResponse);
  } catch (error) {
    console.error('Error fetching opponent lawyers:', error);
    throw error;
  }
};


const AddNewOppoLawyer = async()=>{
  setIsAddNewOppoLawyerVisible(false);
  selectOppoLawyer();
};
  
const selectTypes = async () => {
    const params = {
      method: "select_types",
      params: {select2: true}
    };
  
    try {
      const response = await fetchData(params);
      
      
  
      setTypes(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      throw error;
    }
  };

  const selectUsers = async () => {
    const params = {
      method: "select_users",
      params: {
        select2: true, 
        
      }
    };
  
    try { 
      const response = await fetchData(params);
      
      
  
      setUsers(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      throw error;
    }
  };

  useEffect(() => {
    selectClients();
    selectStatuses();
    selectOppo();
    selectOppoLawyer();
    selectTypes(); // Fetch types
    selectUsers();

  }, []);
  
  const handleTypeSelection = async (type) => {
    setSelectedType(type);
    setSelectedPhase({ text: 'المرحلة', id: null });
setSelectedJdRegion({text: 'المنطقة', id: null});
setSelectedJdRef({ text: 'المحكمة', id: null });
    setActionSheetVisible(false);
  
    // Fetch phases based on the selected type
    const params = {
      method: "select_phases",
      params: { select2: true, 
        conditional: true,
        type_id: type.id }
    };
  
    try {
      const response = await fetchData(params);
     // console.log(response);
     setPhases(response);
    } catch (error) {
      console.error('Error fetching phases:', error);
    }
  };

const renderOpponentItem = ({ item, index }) => (
    <View style={styles.listItemContainer}>
      {/* Delete Icon */}
      <Ionicons
        name="trash"
        size={30}
        color="red"
        onPress={() => deleteOpponent(item.id)}
        style={styles.icon}
      />
      <Text style={styles.listItem}>{item.name}</Text>
      {/* Status Picker */}
      <Picker
  selectedValue={item.status}
  style={{ height: 50, width: '100%' }}
  onValueChange={(itemValue) => handleOpponentStatusChange(index, itemValue)}
>
  <Picker.Item label="اختر صفة" value="-1" enabled={false} />
  {statuses.map(status => (
    <Picker.Item label={status.text} value={status.id} key={status.id} />
  ))}
</Picker>

      {/* Opponent Lawyer Picker */}
      <Picker
        selectedValue={item.lawyerId || item.lawyer_opponent_id}
        style={{ height: 50, width: '100%' }}
        onValueChange={(itemValue) => 
          handleOpponentLawyerChange(index, itemValue)}
      >
          <Picker.Item label="اختر محامي" value="-1" enabled={false} />

        {oppoLawyers.map(lawyer => (
          <Picker.Item label={lawyer.text} value={lawyer.id} key={lawyer.id} />
        ))}
      </Picker>
     { /*<Text style={styles.icon} onPress={() => deleteOpponent(item.id)}>X</Text>*/}
    </View>
  );

  const handleOpponentLawyerChange = (index, newLawyerId) => {
   // console.log(newLawyerId);
    if (newLawyerId === 'new_OppoLawyer') {
      // Show the modal to add a new lawyer
      setIsAddNewOppoLawyerVisible(true);
    } else {
    setSelectedOpponents(prevOpponents => {
      return prevOpponents.map((opponent, i) => {
        if (i === index) {
          return {
            ...opponent,
            lawyerId: newLawyerId
          };
        }
        return opponent;
      });
    });
  };
  };
  
  
  const handleClientStatusChange = (index, newStatusId) => {
    setSelectedClients(prevOpponents => {
      return prevOpponents.map((opponent, i) => {
        if (i === index) {
          return {
            ...opponent,
            status: newStatusId // Update status with the new value
          };
        }
        return opponent;
      });
    });
  };
  

  
  const handleClientSelection = (client) => {
    console.log('ADD CLIENT:', client);
    if (client.id !== 'new_client') {
      if (!selectedClients.some(selected => selected.id === client.id)) {
        const newClient = {
          id: client.id,
          name: client.text,
          status: '-1', // Assign a default status ID here
          statusTitle: 'Select Status',
          statusColor: '#ff6347', // Red color
        };
        setSelectedClients(prev => [...prev, newClient]);
        console.log('SELECTED CLIENTS:', selectedClients);
      }
    }else if (client.id === 'new_client') {
      console.log('Opening Add New Client Modal'); // Debugging log

      setIsAddNewClientVisible(true);
    }
    generateTitle(); // call this after updating selected clients
  };
  
  const handleOpponentSelection = (opponent) => {
    if (opponent.id === 'new_opponent') {
      console.log('Opening Add New Opponent Modal');
      setIsAddNewOppoVisible(true);
    }
    if (opponent.id !== 'new_opponent') {
      if (!selectedOpponents.some(selected => selected.id === opponent.id)) {
        const newOpponent = {
          id: opponent.id,
          name: opponent.text,
          status: '-1', // Assign a default status ID here
          statusTitle: 'Select Status',
          statusColor: '#ff6347', // Red color
          lawyerId: '-1'
        };
        setSelectedOpponents(prev => [...prev, newOpponent]);
      
    }
    }
    generateTitle(); // call this after updating selected opponents
  };
  
 
  
  
  useEffect(() => {
    generateTitle(); // call this when topic changes
}, [data.params.topic, selectedClients, selectedOpponents, selectedJdRef, selectedJdRegion, data.params.room, data.params.l_nb]);

  const deleteOpponent = (oppoId) => {
    setSelectedOpponents(selectedOpponents.filter(oppo => oppo.id !== oppoId));
  };

  
const showStatusActionSheet = (index) => {
    setActiveClientIndex(index);
    showActionSheet('status_id', statuses.map(s => s.text));
  };
  
  const handleNextStep = () => {
   
    if (currentStep === 1 && (!selectedClients.length || !selectedClients.every(client => client.status !== '-1'))) {
      alert("الرجاء اختيار موكل واحد على الاقل وتحديد صفته.");
      return;
    }
    
    if (currentStep === 2 && (!selectedOpponents.length || !selectedOpponents.every(o => o.status !== '-1' && o.lawyerId !== '-1'))) {
        alert("الرجاء اختيار خصم واحد على الاقل وتحديد صفته و محاميه");
        return;
    }
     
    if (currentStep === 3 && (!selectedPhase.id || !selectedType.id || !selectedJdRef.id || !selectedJdRegion.id)) {
      alert("الرجاء اتمام المعلومات الخاصة بالدعوى");
      return;
    }
    if (currentStep === 4 && !selectedUser.length) {
      alert("الرجاء تحديد المحامي المكلف بالملف");
      return;
    }
    setCurrentStep(currentStep + 1);
};

  
  
  
  const handleReview = () => {
    if (!data.params.title || selectedUser.id === null) {
      alert("Please ensure a title is set and a user is selected.");
      return;
    }
    setReviewMode(true);
  };
  
  const renderReviewContent = () => {
    return (
      <View>
        <Text>Title: {data.params.title}</Text>
        <Text>Selected User: {selectedUser.text}</Text>
        {/* Display other relevant information for review */}
        <Button title="Confirm Submission" onPress={handleSubmit} />
        <Button title="Go Back" onPress={() => setReviewMode(false)} />
      </View>
    );
  };
  

  const showPhasesActionSheet = () => {
    let formattedOptions = phases.map(phase => ({
      label: phase.text,
      onPress: () => handlePhaseSelection(phase)
    }));
  
    formattedOptions.push({ label: 'الغاء', onPress: () => setActionSheetVisible(false) });
  
    setActionSheetOptions(formattedOptions);
    setActionSheetVisible(true);
  };

  const handlePhaseSelection = async (phase) => {
    setSelectedPhase(phase);
    setActionSheetVisible(false);
  
    // Fetch judicial references
    const params = {
      method: "select_judicial_references",
      params: { 
        select2: true, 
        conditional: true,
        type_id: selectedType.id, 
        phase_id: phase.id 
      }
    };
  
    try {
      const response = await fetchData(params);
      setJdRefs(response);
    } catch (error) {
      console.error('Error fetching judicial references:', error);
    }
  };

  const showJdRefsActionSheet = () => {
    let formattedOptions = jdRefs.map(jdRef => ({
      label: jdRef.text,
      onPress: () => handleJdRefSelection(jdRef)
    }));
  
    formattedOptions.push({ label: 'الغاء', onPress: () => setActionSheetVisible(false) });
  
    setActionSheetOptions(formattedOptions);
    setActionSheetVisible(true);
  };

  const handleJdRefSelection = async (jdRef) => {
    setSelectedJdRef(jdRef);
    setActionSheetVisible(false);
  
    // Fetch judicial regions
    const params = {
      method: "select_judicial_regions",
      params: { 
        select2: true, 
        conditional: true,
        type_id: selectedType.id, 
        phase_id: selectedPhase.id, 
        judicial_reference_id: jdRef.id 
      }
    };
  
    try {
      const response = await fetchData(params);
      setJdRegions(response);
    } catch (error) {
      console.error('Error fetching judicial regions:', error);
    }
  };

  const showJdRegionsActionSheet = () => {
    let formattedOptions = jdRegions.map(jdRegion => ({
      label: jdRegion.text,
      onPress: () => handleJdRegionSelection(jdRegion)
    }));
  
    formattedOptions.push({ label: 'الغاء', onPress: () => setActionSheetVisible(false) });
  
    setActionSheetOptions(formattedOptions);
    setActionSheetVisible(true);
  };

  const handleJdRegionSelection = async (jdRegion) => {
    setSelectedJdRegion(jdRegion);
    setActionSheetVisible(false);
  
    // Call fetchLawsuitInfo with the selected ids
    await fetchLawsuitInfo(selectedType.id, selectedPhase.id, selectedJdRef.id, jdRegion.id);
  };
  

  
  const deleteClient = (clientId) => {
    setSelectedClients(selectedClients.filter(client => client.id !== clientId));
  };
  
  const renderClientItem = ({ item, index }) => (
    <View style={styles.listItemContainer}>
      <Ionicons
        name="trash"
        size={30}
        color="red"
        onPress={() => deleteClient(item.id)}
        style={styles.icon}
      />
      <Text style={styles.listItem}>{item.name}</Text>
      <Picker
  selectedValue={item.status}
  style={{ height: 50, width: 150 }}
  onValueChange={(itemValue) => handleClientStatusChange(index, itemValue)}
>
  <Picker.Item label="اختر صفة" value="-1" enabled={false} />
  {statuses.map(status => (
    <Picker.Item label={status.text} value={status.id} key={status.id} />
  ))}
</Picker>

    { /* <Text style={styles.icon} onPress={() => deleteClient(item.id)}>X</Text>*/}
    </View>
  );
  
  const  handleStatusChange = (index, newStatusId) => {
    setSelectedClients(prevClients => {
      // Map through the previous clients to find the one that needs to be updated
      return prevClients.map((client, i) => {
        if (i === index) {
          // Find the status text and color based on the newStatusId
          const status = statuses.find(s => s.id === newStatusId);
          const statusText = status ? status.text : 'Select Status';
          const statusColor = status ? '#00ff00' : '#ff6347'; // Assuming green for selected, red for default
  
          // Return an updated object for the matched client
          return {
            ...client,
            status: newStatusId,
            statusTitle: statusText,
            statusColor: statusColor
          };
        }
        // For all other clients, return them as is
        return client;
      });
    });
  };

  
  const handleOpponentStatusChange = (index, newStatusId) => {
    setSelectedOpponents(prevOpponents => {
      return prevOpponents.map((opponent, i) => {
        if (i === index) {
          return {
            ...opponent,
            status: newStatusId // Update status with the new value
          };
        }
        return opponent;
      });
    });
  };
  
  
  const handleSelect = (field, value) => {
    console.log("handleSelect called with", field, value);

    // Update the state based on the field type
    setData(prevData => {
        let updatedParams = { ...prevData.params };

        if (field === 'title'){
!isTitleModified ? setIsTitleModified(true): null;
}
        // Depending on the field, update the respective value
        if (field === 'title' || field === 'id_in_phase' || field === 'topic' || field === 'room' || field === 'l_nb') {
            updatedParams[field] = value;
        }

        return { ...prevData, params: updatedParams };
    });
};

  
  
  

  useEffect(() => {
    if (currentField === 'status_id') {
      setActionSheetVisible(false);
    }
  }, [selectedClients, currentField]);
  
  
  
  
  
  

  const handleSubmit = async () => {
    // Check if the user has not entered a title and the default title is also empty
    if ((!data.params.title || data.params.title === defaultTitle) && !defaultTitle) {
        alert("الرجاء تحديد اسم الدعوى");
        return;
    }

    if (selectedUser.id === null) {
        alert("الرجاء اختيار المحامي المكلف متابعة الملف");
        return;
    }
    setIsLoading(true); // Start loading

    // Format clients and opponents
    
    sendLawsuitData();

};

const formattedClients = selectedClients.reduce((acc, client) => {
  acc[client.id] = {
      id: client.id,
     // text: client.name,
      status: client.status
  };
  return acc;
}, {});

const formattedOpponents = route.params.update ?  selectedOpponents.reduce((acc, opponent) => {
  console.log('SELECTED OPPONENT:', selectedOpponents);
  acc[`${opponent.id}_${opponent.lawyer_opponent_id}`] = {
      id: opponent.id,
     // text: opponent.name,
      status: opponent.status,
      lawyer_opponent_id: opponent.lawyer_opponent_id ||opponent.lawyerId
  };
  return acc;
}, {})
 : selectedOpponents.reduce((acc, opponent) => {
  console.log('SELECTED OPPONENT:', selectedOpponents);
  acc[opponent.id] = {
      id: opponent.id,
     // text: opponent.name,
      status: opponent.status,
      lawyer_opponent_id: opponent.lawyerId
  };
  return acc;
}, {});

const sendLawsuitData = async () => {
  try {
const date=new Date();
const lawsuitData = {
  method:"insert_lawsuit",
  params:{
  id: "",
  lawsuit_info_id: lawsuit_info_id,
  id_in_phase: data.params.id_in_phase,
  is_active: "1", // Set this as needed
  topic: data.params.topic,
  financial_acc_id: "3",
  user_id: selectedUser.id,
  room: data.params.room,
  creation_date: Math.floor(date.getTime() / 1000),
  title: data.params.title || defaultTitle,
  clients: formattedClients,
  opponents: formattedOpponents,
  isMoved: false, // Set based on your application logic
  l_nb: data.params.l_nb
}};
console.log('Submitting Data:', lawsuitData);
// Add your submission logic here, possibly sending `submissionData` to the server
const response = await fetchData(lawsuitData);
 // Handle the response
 console.log('Response from server:', response);
} catch (error) {
console.error('Error sending data:', error);
// .
} finally {
  setIsLoading(false); // Stop loading
  navigation.navigate('Screen2', { shouldrefresh: true });

}
};
  



// Function to fetch lawsuit information
const fetchLawsuitInfo = async (typeId, phaseId, jdRefId, jdRegionId) => {
  try {
    const params = {
      method: "select_lawsuit_info",
      params: {
        conditional: true,
        type_id: typeId,
        phase_id: phaseId,
        judicial_reference_id: jdRefId,
        judicial_region_id: jdRegionId
      }
    };

    const response = await fetchData(params);
    // Assuming the response has the structure {"id": 135}
    if (response && response.id) {
      setlawsuit_info_id(response.id);
    }
  } catch (error) {
    console.error('Error fetching lawsuit info:', error);
    // Handle error
  }
};


  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  
  const handleCancel = () => {
  setCurrentStep(1);
 setSelectedClients([]);
  setSelectedClients([]);
    setSelectedOpponents([]);
     setSelectedStatus('');
      setSelectedType({ text: 'النوع', id: null });
       setSelectedPhase({ text: 'المرحلة', id: null });
    setSelectedJdRef({ text: 'المحكمة', id: null });
     setSelectedJdRegion({ text: 'المنطقة', id: null });
     setSelectedUser({ text: 'المحامي المعين', id: null });
navigation.navigate('Screen2');
  };
  
  

  const showActionSheet = (field, options) => {
    let formattedOptions = [];
  
    if (field === 'type') {
      formattedOptions = Types.map(type => ({
        label: type.text,
        onPress: () => handleTypeSelection(type)
      }));
    } else if (field === 'client_id') {
      formattedOptions = clients.map(client => ({
        label: client.text,
        onPress: () => handleClientSelection(client)
      }));
    } else if (field === 'opponent_id') {
      formattedOptions = oppo.map(opponent => ({
        label: opponent.text,
        onPress: () => handleOpponentSelection(opponent)
      }));
    
    } else {
      // ... handle other fields as necessary
    }
  // Filter options based on searchTerm
  const filteredOptions = searchTerm
    ? formattedOptions.filter(option => 
        option.label.toLowerCase().includes(searchTerm.toLowerCase()))
    : formattedOptions;


      // Add cancel option
      formattedOptions.push({ label: 'الغاء', onPress: () => setActionSheetVisible(false) });

      setActionSheetOptions(formattedOptions);
 setActionSheetVisible(true);
  };
  
  useEffect(() => {
    if (currentField && actionSheetOptions.length > 0) {
      setActionSheetVisible(true);
    }
  }, [currentField, actionSheetOptions]);
  
  const AddNewClient = async ()=>{
    selectClients();
    setIsAddNewClientVisible(false)
  };
  


  const AddNewOppo = async () => {
selectOppo();
setIsAddNewOppoVisible(false);
  };


  const LoadingModal = () => (
    <Modal
      visible={isLoading}
      transparent
      animationType="fade"
    >
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <Text>جاري الحفظ ...</Text>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      </View>
    </Modal>
  );
  
  

  


        const renderStepContent = () => {
          let stepTitle = '';

          if (reviewMode) {
            return renderReviewContent();
          }
            switch (currentStep) {
              case 1:
                stepTitle = 'اختيار موكل / موكلون';
                return (
                  <View style={styles.stepContainer}>
                      <Text style={styles.stepTitle}>{stepTitle}</Text>
                    <Modal
                      transparent={false}
                      visible={isAddNewClientVisible}
                      animationType="slide"
                      onRequestClose={() => {setIsAddNewClientVisible(false)}} // Handle Android back button

                    >
                      <ClientFormWizard onSubmit={AddNewClient}/>
                      <TouchableOpacity
                        style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
                        onPress={() => setIsAddNewClientVisible(false)}
                      >
                        <Ionicons name="close" size={24} color="black" />
                      </TouchableOpacity>
                    </Modal>
                    <Button
                      title="اضغط لاختيار موكل"
                      onPress={() => showActionSheet('client_id', clients)}
                      color={styles.button.backgroundColor}
                      disabled={!clients}
                    />
                    <FlatList
                      data={selectedClients}
                      extraData={selectedClients} // This ensures FlatList re-renders when selectedClients changes
                      keyExtractor={(item) => item.id}
                      renderItem={renderClientItem}
                    />

                  </View>
                );
                case 2:
                  stepTitle = 'اختيار خصم / خصوم';
                    return (
                      <View style={styles.stepContainer}>
                          <Text style={styles.stepTitle}>{stepTitle}</Text>
                        <Modal
                          transparent={false}
                          visible={IsAddNewOppoVisible}
                          animationType="slide"
                          onRequestClose={() => { setIsAddNewOppoVisible(false) }}
>
                      <OppoFormWizard onSubmit={AddNewOppo}/>
                      <TouchableOpacity
                        style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
                        onPress={() => setIsAddNewOppoVisible(false)}
                      >
                        <Ionicons name="close" size={24} color="black" />
                      </TouchableOpacity>
                    </Modal>

                    <Modal
                          transparent={false}
                          visible={isAddNewOppoLawyerVisible}
                          animationType="slide"
                          onRequestClose={() => { setIsAddNewOppoLawyerVisible(false) }}
>
                      <OppoLawyerFormWizard onSubmit={AddNewOppoLawyer}/>
                      <TouchableOpacity
                        style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
                        onPress={() => setIsAddNewOppoLawyerVisible(false)}
                      >
                        <Ionicons name="close" size={24} color="black" />
                      </TouchableOpacity>
                    </Modal>


                        <Button
                          title="اضغط لاختيار خصم"
                          onPress={() => showActionSheet('opponent_id', oppo)}
                          color={styles.button.backgroundColor}
                          disabled={!oppo}
                        />
                        <FlatList
                          data={selectedOpponents}
                          extraData={selectedOpponents}
                          keyExtractor={(item) => item.id}
                          renderItem={renderOpponentItem}
                        />
                      </View>
                    );
                  
      case 3:
        stepTitle = 'تفاصيل الدعوى - 1';
        return (
          <KeyboardAwareScrollView 
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="handled" // This ensures taps are not dismissed accidentally
          enableOnAndroid={true} // Enables the functionality on Android
          extraScrollHeight={20} // Adds extra height to the scroll when the keyboard is open
        
          >
              <Text style={styles.stepTitle}>{stepTitle}</Text>
<Button
        title={selectedType.text}
onPress={() => 
  showActionSheet('type', Types)
  
}
        color={styles.button.backgroundColor}
        disabled={route.params.update}

      />
      <Button
        title={selectedPhase.text}
        onPress={showPhasesActionSheet}
        color={styles.button.backgroundColor}
        disabled={!selectedType.id || !phases || route.params.update}
      />
      <Button
        title={selectedJdRef.text}
        onPress={showJdRefsActionSheet}
        color={styles.button.backgroundColor}
        disabled={!selectedPhase.id || jdRefs.length === 0 ||route.params.update } // Disable until a phase is selected
      />

<Button
        title={selectedJdRegion.text}
        onPress={showJdRegionsActionSheet}
        color={styles.button.backgroundColor}
        disabled={!selectedJdRef.id || jdRegions.length === 0 ||route.params.update

        } // Disable until a judicial reference is selected
      />

          <TextInput
  style={styles.input}
  placeholder="الغرقة"
  value={data.params.room}
  onChangeText={(text) => handleSelect('room', text)}
/>

     
      <TextInput
      style={styles.input}
        placeholder="رقم الاساس"
        value={data.params.id_in_phase}
        onChangeText={(text) => handleSelect('id_in_phase', text)}
      />
      <TextInput
      style={styles.input}
        placeholder="الموضوع"
        value={data.params.topic}
        onChangeText={(text) => handleSelect('topic', text)}
      />
        <TextInput
  style={styles.input}
  placeholder="الرقم الداخلي"
  value={data.params.l_nb}
  onChangeText={(text) => handleSelect('l_nb', text)}
  keyboardType="number-pad" // Add this line to show the numbers keyboard

/> 


           
          </KeyboardAwareScrollView>
        );

        case 4:
          stepTitle = 'تفاصيل الدعوى - 2';
  return (
    <View style={styles.stepContainer}>
        <Text style={styles.stepTitle}>{stepTitle}</Text>
        {isTitleModified && (
  <View style={styles.warningContainer}>
    <Text style={styles.warningText}>
      مكونات اسم الدعوى تم تعديلها. اضغط الزر التالي لتعديل اسم الدعوى ليصبح: {defaultTitle}
    </Text>
    <Button
      title="تعديل اسم الدعوى"
      onPress={() => {
        setData((prevData) => ({
          ...prevData,
          params: {
            ...prevData.params,
            title: defaultTitle,
          },
        }));
        setIsTitleModified(false);
      }}
      color="red"
    />
  </View>
)}
       <TextInput
  style={styles.input}
  placeholder="اسم الدعوى"
  value={isTitleModified ? data.params.title : defaultTitle}
  onChangeText={(text) => handleSelect('title', text)}
/>



      <Button
        title={selectedUser.text}
        onPress={showUsersActionSheet}
        color={styles.button.backgroundColor}
        disabled={!users}
      />
  
  

</View>
);
      default:
        return <Text>Invalid Step</Text>;
    }
  };

  

  const CustomModal = ({ visible, onDismiss, options }) => {
    const [localSearchTerm, setLocalSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  
    useEffect(() => {
      const timerId = setTimeout(() => {
        setDebouncedSearchTerm(localSearchTerm);
      }, 500); // Debounce time 500ms
  
      return () => clearTimeout(timerId);
    }, [localSearchTerm]);
  
    const filteredOptions = debouncedSearchTerm
      ? options.filter(option => option.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
      : options;
  
    return (
      <Modal
        visible={visible}
        transparent={false}
        animationType="slide"
        onRequestClose={onDismiss}
      >
        <View style={styles.fullScreenModalContainer}>
        <TextInput
        style={styles.searchInput}
        placeholder="ابحث ..."
        value={localSearchTerm}
        onChangeText={setLocalSearchTerm}
        autoFocus
      />
          <ScrollView style={styles.fullScreenModalContent}>
            {filteredOptions.map((option, index) => (
              <TouchableOpacity
                key={index}
                style={styles.modalOption}
                onPress={() => {
                  option.onPress();
                  onDismiss();
                }}
              >
                <Text
            style={[
              styles.modalOptionText,
              (option.label === 'اضافة موكل جديد ...' || option.label === 'اضافة خصم جديد ...') && { color: 'red' } // Conditional style
            ]}
          >
{option.label}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      </Modal>
    );
  };
  

  
  
  
  
  

  return (
    
    <View style={styles.container}>
    

      {renderStepContent()}
      <LoadingModal />
      {!reviewMode && (
      <>
       
  <CustomModal
    options={actionSheetOptions}
    visible={actionSheetVisible}
    onDismiss={() => setActionSheetVisible(false)}
  />
  

          <View style={styles.buttonContainer}>
            {currentStep < 4 ? (
              <View style={styles.buttonWrapper}>
                <Button title="التالي" onPress={handleNextStep} color={styles.button.backgroundColor} />
              </View>
            ) : ( route.params.update? 
              <View style={styles.buttonWrapper}>
              <Button title="تعديل" onPress={handleUpdate} color={styles.button.backgroundColor} />
            </View>
            :
              <View style={styles.buttonWrapper}>
                <Button title="حفظ" onPress={handleSubmit} color={styles.button.backgroundColor} />
              </View>
            )}
            {currentStep > 1 && (
              <View style={styles.buttonWrapper}>
                <Button title="السابق" onPress={handlePreviousStep} color={styles.button.backgroundColor} />
              </View>
            )}
            <View style={styles.buttonWrapper}>
              <Button title="الغاء" onPress={handleCancel} color={styles.cancelButton.backgroundColor} />
            </View>
          </View>

        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  warningContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#ffe4e1',
    borderRadius: 5,
  },
  warningText: {
    color: '#ff6347',
    marginBottom: 5,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalOption: {
    backgroundColor: '#fff',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  modalOptionText: {
    fontSize: 18,
  },
  modalCloseButton: {
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 10,
  },
  
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  activityIndicatorWrapper: {
    backgroundColor: 'white',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
    icon: {
        color: '#ff6347',
        padding: 5,
        fontSize: 16,
      },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  stepContainer: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: 20,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  buttonWrapper: {
    width: '30%', // Adjust the width as needed
  },
  cancelButton: {
    backgroundColor: '#ff6347', // Different color for the cancel button
    padding: 15,
    borderRadius: 5,
  },
  
  button: {
    backgroundColor: '#007bff',
    padding: 15,
    borderRadius: 5,
  },
  stepTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    alignSelf: 'center'
  },
  
  input: {
    borderWidth: 1,
    borderColor: '#cccccc',
    padding: 10,
    borderRadius: 5,
    marginVertical: 5,
  },
  listItem: {
    backgroundColor: '#ffffff',
    padding: 10,
    marginVertical: 3,
    borderRadius: 5,
  },
  listItemContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    padding: 10,
    marginVertical: 3,
    borderRadius: 5,
  },
  deleteButton: {
    backgroundColor: '#ff6347',
    padding: 5,
    borderRadius: 5,
  },
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'space-between', // Add this line
    backgroundColor: '#f5f5f5',
  },
  icon: {
    marginLeft: 10
  },

  stepContainer: {
    flex: 1, // Ensure this takes up all available space above the buttons
    justifyContent: 'center',
    marginBottom: 10,
  },
  fullScreenModalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff', // or any color of your choice
  },
  fullScreenModalContent: {
    flex: 1,
    width: '100%',
  },
  modalOption: {
    backgroundColor: '#f2f2f2',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  modalOptionText: {
    fontSize: 18,
  },
  modalCloseButton: {
    backgroundColor: '#ddd',
    padding: 15,
    alignItems: 'center',
  },
  searchInput: {
    height:50
  }
  
});

export default AddNewLawsuit;