import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';


const CustomChip = ({ label, iconSource, onPress, style, hasReminders, hasbalance }) => {
  return (
    <TouchableOpacity  dir={'rtl'} onPress={onPress} style={[styles.chip, style]}>
      {iconSource && (
        <Image
          source={iconSource}
          style={[styles.icon, { tintColor: '#007bff', alignSelf: 'center' }]}
        />
      )}
      <View style={styles.contentContainer}>
        <Text style={styles.label}>{label}</Text>
        {/* Spacer view to push conditional elements to the bottom */}
        <View style={{ flex: 1 }}></View>
        <View style={styles.conditionalContainer}>
          {/* Conditionally render reminder message/icon */}
          {hasReminders && (
            <View style={styles.conditionalElement}>
               <Ionicons name="warning" size={18} color="orange" style={{marginTop:5, marginLeft:5}} />
              <Text style={styles.conditionalLabel}>يوجد استحقاق قريب </Text>
            </View>
          )}
          {/* Conditionally render another conditional element */}
          {hasbalance && (
            <View style={styles.conditionalElement}>
             <Ionicons name="warning" size={18} color="red" style={{marginTop:5, marginLeft:5}} />
              <Text style={styles.conditionalLabel}>رصيد الدعوى سالب</Text>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  chip: {
    flexDirection: 'row',
    alignItems: 'flex-start', // Changed to 'flex-start' to allow content to align at the top
    padding: 10,
    //paddingVertical: 15, // Ensures a minimum vertical space inside the chip
    borderRadius: 22,
    borderWidth: 2,
    borderColor: '#007bff',
    margin: 10,
    marginRight: Platform.OS === 'web' ? 30 : 10,
    // Removed minHeight to allow the chip's height to grow with the content
  },
  icon: {
    width: 36,
    height: 36,
    marginRight: 10,
    alignSelf: 'center', // Center the icon vertically
  },
  contentContainer: {
    flex: 1, // Take up available space
    flexDirection: 'column',
    justifyContent: 'space-between', // Push items to top and bottom
  },
  label: {
    fontSize: 12,
   // color: 'white', // White font color
   fontFamily:'DefFont'
  },
  conditionalContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  conditionalElement: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5, // Spacing between conditional elements
backgroundColor:'ivory',
//width:'100%',
borderRadius:10,
//alignContent:'center'
  },
  conditionalText: {
    color: 'red', // Text color for the icons
  },
  conditionalLabel: {
    color: 'red', // Text color for the labels
    fontSize: 12, // Adjust font size as needed
    fontWeight:'bold',
    marginHorizontal: 10
  },
});

export default CustomChip;
