import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView, TouchableOpacity,Alert, Image } from 'react-native';
import { ActivityIndicator, Modal } from 'react-native';
import { TextField } from 'react-native-ui-lib';
import { getToken } from '../../../utils/api';
import Constants from 'expo-constants';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Camera } from 'expo-camera';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import PhoneInput from 'react-native-phone-number-input';




const ClientFormWizard = ({onSubmit, editData, images }) => {
    console.log(editData);
    const [showMessage, setShowMessage] = useState(false);
    const phoneInput = useRef(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const [formattedValue, setFormattedValue] = useState("");
    const [clientType, setClientType] = useState(null); // 'company' or 'individual'
    const [mobileValidationStatus, setMobileValidationStatus] = useState(null); // 'valid', 'invalid', or null
    const [clientData, setClientData] = useState(editData || {
        // Common fields

        mobile: '',
        dob: '',
        first_name: '',
        c_nb:'',
        last_name: '',
        middle_name: '',
        mother_name: '',
        register_number: '',
        register_place: '',
        address: '',
        company_capital: '',
        company_title: '',
        email: '',
        profile_id:'',
        civil_id_photo_front: '',
        civil_id_photo_back: '',
        photo: '',


        procurationTitle: '',
        procurationFiles: [],
        // Company fields
        companyFiles: [],
        // Individual fields
       
       


       


        
    });
    const apiUrl = Constants.expoConfig.extra.apiUrl;
    const [step1Title, setStep1Title] = useState("");
    const [isMobileEdited, setIsMobileEdited] = useState(false);


    useEffect(() => {
        if (editData) {
            setClientData(editData);
            setClientType(editData.is_company === "1" ? 'company' : 'individual');
            setIsMobileEdited(false); // Reset the edit flag when loading edit data
        }
    }, [editData]);
    


    const renderStepTitle = () => {
        const titles = {
            0: 'اضافة موكل',
            1: 'اختيار الشكل القانوني',
            2: clientType === 'company' ? 'معلومات الشركة / الجمعية' : 'معلومات الموكل',
            3: clientType === 'company' ? 'معلومات الممثل القانوني' :'اضافة الصورة والهوية',
            4: clientType === 'company' ? 'اضافة الصورة والهوية': 'اضافة الوكالة',
            5: 'اضافة الوكالة',
        };
        return (
            <View>
                <Text style={styles.stepTitle}>{titles[currentPage]}</Text>
                <View style={styles.titleUnderline} />
            </View>
        );
    };

    

    const validatePhoneNumber = async () => {
        const isFormatValid = phoneInput.current?.isValidNumber(clientData.mobile);
    
        if (isFormatValid) {
            try {
                const token = await getToken();
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ method: "check_mobile_client", params: { mobile: clientData.mobile } }),
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const resultText = await response.text();
                console.log("Response text:", resultText);
    
                if (resultText.trim() === 'true') {
                    console.log('Mobile number is valid and does not exist in the database');
                    return 'valid'; // Return validation result
                } else {
                    console.log('Mobile number is valid but already exists in the database');
                    return 'Valid-Existing'; // Return validation result
                }
            } catch (error) {
                console.error("Error in fetching data:", error);
                return 'invalid'; // Return validation result
            }
        } else {
            console.log('Mobile number format is not valid');
            return 'invalid'; // Return validation result
        }
    };
    
  
    

    const handleMobileNumberChange = (text) => {
        let formattedText = text.replace(/\D/g, '');
        if (formattedText.length > 2) {
            formattedText = formattedText.substring(0, 2) + '-' + formattedText.substring(2, 8);
        }
        updateField('mobile', formattedText);
         // Set isMobileEdited to true when the user modifies the mobile number
    setIsMobileEdited(true);
    };

  
    const getTextInputStyle = () => {
        switch (mobileValidationStatus) {
            case 'valid':
                return [styles.input, styles.inputValid];
            case 'invalid':
                return [styles.input, styles.inputInvalid];
            default:
                return styles.input;
        }
    };

    
    const captureFromCamera = async (field) => {
        let { status } = await Camera.requestCameraPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera permissions to make this work!');
            return;
        }
    
        let result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
        });
    
        if (!result.canceled && result.assets) {
            handleAddImage(field, result.assets[0].uri);
        }
    };
    
    
  const pickPhoto = async (field) => {
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        quality: 1,
    });

    if (!result.canceled && result.assets) {
        handleAddImage(field, result.assets[0].uri);
    }
};

  
    
const onFileChange = (deletedIds, addedUris) => {
    updateField('procurationFiles', addedUris);
    console.log(addedUris);
};



   
  
  const handlePress = (field) => {
    Alert.alert(
        'اختر خيارًا',
        'حدد ملفًا من المعرض أو التقط صورة',
        [
            { text: 'إلغاء', style: 'cancel' },
            { text: 'اختر من المعرض', onPress: () => pickPhoto(field) },
            { text: 'التقاط صورة', onPress: () => captureFromCamera(field) },
            // If you have a pickFile function, add it here as well.
        ],
        { cancelable: true }
    );
};

  

  const handleAddImage = (field, uri) => {
    setClientData({ ...clientData, [field]: uri });
};

  
  const compressImage = async (uri) => {
    const compressedImage = await ImageManipulator.manipulateAsync(
      uri,
      [],
      { compress: 0.5 }  // You can adjust the compression level as needed. 0.5 means 50% compression.
    );
    return compressedImage.uri;
  }
  
const handleSubmit = async () => {
    setIsLoading(true); // Start loading

    const convertToBase64 = async (uri) => {
        if (!uri) return null;

       // Get the file extension and MIME type
       const fileExtension = uri.split('.').pop();
       let mimeType;
       switch (fileExtension) {
           case 'jpg':
           case 'jpeg':
               mimeType = 'image/jpeg';
               break;
           case 'png':
               mimeType = 'image/png';
               break;
           case 'pdf':
               mimeType = 'application/pdf';
               break;
           default:
               mimeType = 'application/octet-stream';
       }
       const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
       return `data:${mimeType};base64,${data}`;
     
    };

    // Convert image URIs to base64
    const photoBase64 = clientData.photo ? await convertToBase64(clientData.photo) : null;
    const civilIdPhotoBackBase64 = clientData.civil_id_photo_back ? await convertToBase64(clientData.civil_id_photo_back) : null;
    const civilIdPhotoFrontBase64 = clientData.civil_id_photo_front ? await convertToBase64(clientData.civil_id_photo_front) : null;

   // Handle procuration files
   let procurationFilesBase64 = [];

   if (clientData.procurationFiles) {
       // Ensure procurationFiles is always treated as an array
       const filesArray = Array.isArray(clientData.procurationFiles) ? clientData.procurationFiles : [clientData.procurationFiles];
   
       // Convert each file to base64 and collect the results
       procurationFilesBase64 = await Promise.all(filesArray.map(fileUri => convertToBase64(fileUri)));
   
       // Filter out any null values that might occur if conversion fails
       procurationFilesBase64 = procurationFilesBase64.filter(file => file != null);
   }
   
   


let data;



     data = {
        method: "insert_client",
        params: {
            id: null,
            first_name: clientData.first_name,
            middle_name: clientData.middle_name,
            last_name: clientData.last_name,
            mother_name: clientData.mother_name,
            address: clientData.address,
            civil_id_photo_back: civilIdPhotoBackBase64,
            civil_id_photo_front: civilIdPhotoFrontBase64,
            company_capital: clientData.company_capital,
            company_title: clientData.company_title,
            creation_date: Math.floor(Date.now() / 1000), // Current timestamp
            dob: clientData.dob,
            email: clientType === 'company' ? clientData.email : clientData.email,
            first_name: clientData.first_name,
            is_company: clientType === 'company' ? "1" : "0",
            mobile: clientData.mobile,
            photo: photoBase64,
            procuration_file: procurationFilesBase64,   
            procuration_name: clientData.procurationTitle ? clientData.procurationTitle : null,
            register_number: clientData.register_number,
            register_place: clientData.register_place,
            c_nb: clientData.c_nb
        }
}


   
   // console.log(procurationFilesBase64);

    try {
        const token = await getToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Server response:", result);
        setIsLoading(false); // Stop loading
        onSubmit(); 

    } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false);     }
};

// Validation functions
const isMobileNumberValid = () => {
    return mobileValidationStatus === 'valid';
};

const isClientTypeSelected = () => {
    return clientType !== null;
};

const isCompanyDetailsValid = () => {
    return clientType === 'company' ? clientData.company_title.trim() !== '' : true;
};

const isIndividualDetailsValid = () => {
    return  (clientData.first_name.trim() !== '' && clientData.last_name.trim() !== '');
};


const handleNext = async () => {
    if (currentPage === 0) {
        const validationStatus = await validatePhoneNumber();
        setMobileValidationStatus(validationStatus);

        // Do not proceed to next step here. Let useEffect handle the transition.
    } else {
        proceedToNextStep();
    }
};

useEffect(() => {
    if (currentPage === 0 && mobileValidationStatus === 'valid') {
        proceedToNextStep();
    } else if (currentPage === 0 && mobileValidationStatus === 'invalid') {
        Alert.alert("خطأ", "رقم الجوال غير صحيح، الرجاء التصحيح");
    }    else if (currentPage === 0 && mobileValidationStatus === 'Valid-Existing') {
        Alert.alert("خطأ", "الرقم مستخدم سابقًا");

    }
}, [mobileValidationStatus, currentPage]);


    const proceedToNextStep = () => {

    switch (currentPage) {
       
        case 1: 
            if (!isMobileNumberValid() || !isClientTypeSelected()) {
                Alert.alert("خطأ", "الرجاء تحديد نوع الموكل المنوي اضافته");
                return;
            }
            break;
        case 2:
            if (clientType === 'company' && !isCompanyDetailsValid()) {
                Alert.alert("خطأ", "الرجاء ادخال اسم الشركة/الجمعية");
                return;
            }
            if (clientType === 'individual' && !isIndividualDetailsValid()) {
                Alert.alert("خطأ", "الرجاء ادخال اسم وشهرة الموكل");
                return;
            }
            break;

        case 3:
            if (clientType === 'company' && (!isCompanyDetailsValid() || !isIndividualDetailsValid())) {
                Alert.alert("خطأ", "الرجاء ادخال اسم وشهرة الممثل القانوني للشركة/الجمعية");
                return;
            }
    }

  if (!((clientType === 'individual' && currentPage >= 4) || (clientType === 'company' && currentPage >= 5))) {
    setCurrentPage(currentPage + 1);
}else {
    handleSubmit();
}
};

function getNumberAfterPossiblyEliminatingZero(text) {
    let number = text.replace(/^0+/, ''); // Remove leading zeros
    let formattedNumber = number; // Format number as needed
    return { number, formattedNumber };
}
    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const updateField = (field, value) => {
        setClientData({ ...clientData, [field]: value });
        console.log(value);
        console.log(clientData);
    };

    const renderMobileNumberStep = () => (
        <View style={styles.stepContainer}>
                    <Text>للبدء بإضافة موكل جديد يرجى ادخال رقم الجوال للتأكد من عدم وجوده في القائمة</Text>
                    

                    <View style={styles.phonecontainer}>

                        <PhoneInput
                            ref={phoneInput}
                            defaultValue={clientData.mobile}
                            defaultCode="LB"
                            layout="first"
                            placeholder="ادخل رقم الهاتف"
                            textContainerStyle={[styles.textContainer, {
                               // borderColor: mobileValidationStatus ? 'green' : 'red',
                                direction: 'rtl',
                            }]}
                            containerStyle={{
                                direction: 'rtl'
                            }}
                            onChangeFormattedText={(text) => {
                                const { number, formattedNumber } = getNumberAfterPossiblyEliminatingZero(text);

                                setClientData({ 
                                    ...clientData, 
                                    mobile: formattedNumber,  // Assuming you want to store the 'number' in 'mobile'
                                  //  formattedMobile: formattedNumber // Store the formatted number in a new field (if needed)
                                });                              
                                  setIsMobileEdited(true);
                            }}
                            autoFocus
                        />

                       
                   

                    </View>


                   
                </View>
            );        
  

    const renderClientTypeStep = () => (
 <View style={styles.radioContainer}>
    <View style={{flexDirection:'row', maxWidth:'70%'}}>
                        <TouchableOpacity
                            style={[styles.radio, clientType === 'individual' && styles.radioSelected]}
                            onPress={() => setClientType('individual')}
                        >
                            <Text>شخص طبيعي</Text>
                        </TouchableOpacity>

                        <Text style={{marginTop:20, padding: 5, alignSelf:'center'}}>المعلومات الالزامية لاضافة شخص طبيعي كموكل جديد: الاسم والشهرة</Text>
                        </View>
                        <View style={{flexDirection:'row', maxWidth:'70%'}}>

                        <TouchableOpacity
                            style={[styles.radio, clientType === 'company' && styles.radioSelected]}
                            onPress={() => setClientType('company')}
                        >
                            <Text>شخص معنوي</Text>
                        </TouchableOpacity>
                        <Text style={{marginTop:20, padding: 5, alignSelf:'center'}}>المعلومات الالزامية لاضافة شخص معنوي كموكل جديد: اسم الشركة او الجمعية، اسم وشهرة ممثلها القانوني </Text>
                        </View>
                    </View>        
    );

    const renderStep = () => {
        switch (currentPage) {
            case 0:
                return renderMobileNumberStep();
            case 1:
                return renderClientTypeStep();
               
            case 2:
                return clientType === 'company' ? renderCompanyStep1() : renderIndividualStep1();
                case 3:
                    return clientType === 'company' ? renderIndividualStep1() : renderIndividualStep2();
                  case 4:
                    return clientType === 'company' ? renderIndividualStep2() : renderProcuration();
                    case 5:
                        return clientType === 'company' ? renderProcuration() : null;

            default:
                return null;
        }
    };


    const renderProcuration = () =>(
       
            <View style={styles.stepContainer}>
                <TextInput
                    style={styles.input}
                    placeholder="عنوان الوكالة"
                    value={clientData.procurationTitle}
                    onChangeText={(text) => updateField('procurationTitle', text)}
                />
<FilePhotoPicker initialUris={[]} onFileChange={onFileChange}/>
            </View>
        );
    
    const renderCompanyStep1 = () => (
        <View style={styles.stepContainer}>
            <TextInput
                style={styles.input}
                placeholder="اسم الشركة / الجمعية"
                value={clientData.company_title}
                onChangeText={(text) => updateField('company_title', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="رأسمالها"
                value={clientData.company_capital}
                onChangeText={(text) => updateField('company_capital', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="عنوان البريد الالكتروني"
                value={clientData.email}
                onChangeText={(text) => updateField('email', text)}
            />
        </View>
    );

    const renderIndividualStep1 = () => (
        <View style={styles.stepContainer}>
        <Text>{step1Title}</Text>
        <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الرقم الداخلي"
                value={clientData.c_nb}
                onChangeText={(text) => updateField('c_nb', text)}
                keyboardType="number-pad" // Add this line to show the numbers keyboard

            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الاسم الاول"
                value={clientData.first_name}
                onChangeText={(text) => updateField('first_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الشهرة"
                value={clientData.last_name}
                onChangeText={(text) => updateField('last_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الاب"
                value={clientData.middle_name}
                onChangeText={(text) => updateField('middle_name', text)}
            />

            
 <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الام وشهرتها"
                value={clientData.mother_name}
                onChangeText={(text) => updateField('mother_name', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="محل الولادة"
                value={clientData.register_place}
                onChangeText={(text) => updateField('register_place', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="تاريخ الولادة"
                value={clientData.dob}
                onChangeText={(text) => updateField('dob', text)}
            /> 

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="رقم السجل"
                value={clientData.register_number}
                onChangeText={(text) => updateField('register_number', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="العنوان"
                value={clientData.address}
                onChangeText={(text) => updateField('address', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="البريد الالكتروني"
                value={clientData.email}
                onChangeText={(text) => updateField('email', text)}
            />
            
            
            
                    </View>
    );

    const renderCompanyStep2 = () => (
        <View style={styles.stepContainer}>
            <Text>Upload company files</Text>
            {/* Implement company file upload functionality */}
        </View>
    );

    const renderIndividualStep2 = () => (
        <View style={styles.stepContainer}>
           
    
            <TouchableOpacity onPress={() => handlePress('photo')}>
    <View style={styles.circlePlaceholder}>
        {images?.photo ? 
            <Image source={{ uri: images.photo }} style={styles.circleImage} /> :
            <Text style={{ alignSelf: 'center' }}>صورة شخصية</Text>
        }
    </View>
</TouchableOpacity>
    

            <TouchableOpacity onPress={() => handlePress('civil_id_photo_front')}>
                <View style={styles.rectanglePlaceholder} >
                {images?.civil_id_photo_front ? 
            <Image source={{ uri: images.civil_id_photo_front }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الامامية)</Text>
}
                </View>

            </TouchableOpacity>
    
            <TouchableOpacity onPress={() => handlePress('civil_id_photo_back')}>
                <View style={styles.rectanglePlaceholder} >
                    {images?.civil_id_photo_back ? 
                                <Image source={{ uri: images.civil_id_photo_back }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الخلفية)</Text>
                    }
                </View>

            </TouchableOpacity>
        </View>
    );
    

  

    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={styles.scrollViewContainer} >
                {renderStepTitle()}
                {renderStep()}
            </ScrollView>
            <View style={styles.buttonContainer}>
               
                <Button
                    title={clientType === 'individual' ? (currentPage === 4 ? 'حفظ' : 'التالي') : (currentPage === 5 ? 'حفظ' : 'التالي')}
                    onPress={handleNext}
                   // disabled={mobileValidationStatus === 'invalid' || (currentPage === 0 && mobileValidationStatus !== 'valid')}
                />
                 {currentPage > 0 && (
                    <Button title="السابق" onPress={handlePrevious} />
                )}
            </View>
            <Modal
            visible={isLoading}
            transparent
            animationType="fade"
        >
            <View style={styles.modalBackground}>
               
                <View style={styles.activityIndicatorWrapper}>
                <Text>جاري الحفظ</Text>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            </View>
        </Modal>
        </View>
    );
    
};

const styles = StyleSheet.create({
    textContainer: {
        paddingHorizontal: 10,
        borderWidth: 1, // Add border to see color change
    },
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    circleImage: {
        width: '100%',
        height: '100%',
        borderRadius: 50,
    },
    
    circlePlaceholder: {
        width: 100,
        height: 100,
        borderRadius: 50, // Half of width and height to make it circle
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'
    },
    rectanglePlaceholder: {
        width: 200,
        height: 120,
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'

    },
    inputValid: {
        borderColor: 'green',
    },
    inputInvalid: {
        borderColor: 'red',
    },
   
    stepContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    input: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    radioContainer: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 15,
        marginTop:20

    },
    
    radio: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
        marginTop:20,
        justifyContent:'center'

    },
    radioSelected: {
        backgroundColor: 'lightblue',
    },
    container: {
        flex: 1,
        justifyContent: 'space-between', // This will push the button container to the bottom
    },
    scrollViewContainer: {
        flexGrow: 1,
        padding: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: '#fff', // or any color that suits your design
        borderTopWidth: 1,
        borderColor: '#ddd',
    },
    stepTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 15,
    },
    titleUnderline: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        marginVertical: 5,
    },
    phonecontainer:{
        marginVertical:25
    }
});

export default ClientFormWizard;
