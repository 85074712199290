import React, {useState} from 'react';
import { StyleSheet, Modal, TouchableOpacity } from 'react-native';
import { Drawer, Colors, Chip, Spacings, View, Text } from 'react-native-ui-lib'; // import other necessary components
import { Ionicons } from '@expo/vector-icons';
import ImageCarouselModal from '../../../SharedComponents/ImageCarouselModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { fetchFileLink } from '../../../utils/api';
import ProcedureEdit from './ProcedureEdit';
import colorObject from 'react-native-ui-lib/src/style/colors';
import { useNavigation } from '@react-navigation/native';
import CustomChip from '../../../SharedComponents/CustomChip';


function ProcedureListItem({ isNested, item, typeValues, Lawsuits, showDeleteConfirmation, handleItemUpdate }) {
  const typeValue = typeValues.find(typeValue => typeValue.id === item.procedure_type || typeValue.id === item.type);
  const lawsuit = Lawsuits.find(lawsuit => parseInt(lawsuit.id) === parseInt(item.lawsuit_id));
const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false)
const [fileLinks, setFileLinks] = useState([]);
const [IsEditModalVisible, setIsEditModalVisible] = useState(false);
const navigation=useNavigation();
const [filesId, setFilesId] = useState([]);

console.log('Loadind procedures: ', lawsuit);

  const toggleCarouselModal = (files) => {
    setIsCarouselModalVisible(!isCarouselModalVisible);
    if (files && files.length > 0) {
      setFilesId(files);
      //fetchFileLinks(files);
    }
  };
  const checkmark = require('../../../assets/briefcase-66-32.png');

 
  const handleItemPress = async (item) => {
    
   // setSelectedItem(item);
    setIsEditModalVisible(true);
};

 
  // Function to fetch all file links for a given array of file IDs
  const fetchFileLinks = async (fileIds) => {
    const links = await Promise.all(fileIds.map(id => fetchFileLink(id)));
    setFileLinks(links.filter(link => link !== null && link !== undefined));
    // Set imagesLoading to false once all file links have been fetched
   // setImagesLoading(false);
  };

  const handleEntryUpdated = (selectedID, updatedDescription, selectedType, combinedDateTime) => {
    // You only need to update the item if the selectedID matches the item's ID
    if (item.id === selectedID) {
      setItem({
        ...item,
        description: updatedDescription,
        type: selectedType,
        date: combinedDateTime,
      });
    } else {
      console.error('The updated item does not match the current item.');
    }
  };
  




  
  return (

    
<View style={item.id <0 ? styles.disabledView : {}}
pointerEvents={item.id <0 ? 'none' : 'auto'}
>
       <Drawer
        rightItems={[{ text: 'تعديل', background: Colors.blue30, onPress: () => handleItemPress(item)}]}
        leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
        
      >




 
<View centerV padding-s4 bg-white style={isNested ? {borderBottomWidth:1, borderColor:'gray'}: null}>



 <View style={styles.chipSection}>

{!isNested ? 

<CustomChip
              label={lawsuit?.title || 'No Lawsuit Found'}
              iconSource={checkmark}
              onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:item.lawsuit_id, lawsuit_info_id: item.lawsuit_info_id, fin_id: lawsuit.financial_acc_id, HeaderTitle:lawsuit.title}})} 
tintColor="white" // Set the icon tint color here
/>

              :
              null}
  
 
</View>




    <View style={{ flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10}}>
            <Text style={{fontSize:12, color: 'grey'}}>{item.date.split(' ')[0]}</Text>
            
   
    {/* Lawsuit Section */}



    <View style={styles.lawsuitSection}>

    {
  ((item.file_id && item.file_id.length > 0) || (item.files && item.files.length > 0)) && (

    <View style={styles.circle}>
    <Ionicons
      name="attach"
      size={20}
      color="white"
      onPress={() => toggleCarouselModal(item.files || item.file_id)}
    />
  </View>
  )
}

      <Chip label={typeValue.text} borderRadius={22} containerStyle={styles.chipStyle} onPress={() => console.log('pressed')} />
     
    </View>
   
 </View>

  {/* Content Section */}
  
  <Text style={{ fontSize: 12 }}> {item.description}</Text>
   


 
  </View>

      </Drawer>


      <Modal
        transparent={false}
        visible={isCarouselModalVisible}
        animationType="slide"
      >

        <ImageCarouselModal
          fileLinks={filesId}
         // onClose={() => setIsCarouselModalVisible(false)}
        />

<TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setIsCarouselModalVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>

      </Modal>
      {IsEditModalVisible &&
      <ProcedureEdit
        isVisible={IsEditModalVisible} 
        onClose={() => setIsEditModalVisible(false)} 
        onEntryUpdated={(selectedID, updatedDescription, selectedType, combinedDateTime, deletedIds, addedUris) => {
          // Handle the updates here
          const updatedItem = { ...item, description: updatedDescription, type: selectedType, date: combinedDateTime };
      
          // Now you also have deletedIds and addedUris to work with
          handleItemUpdate(updatedItem, deletedIds, addedUris);
        }}
        initialValue={item.description}
        initialType={item.type || item.procedure_type}
        initialDate={new Date(item.date)}
        initialTime={new Date(item.date)}
        TypeValues={typeValues}
        initialID={item.id}
        initialFiles={item.files}
        //ProcedureId={item.id}
      
      />
}
    </View>
  );
};

const styles = StyleSheet.create({
  disabledView: {
    opacity: 0.3,
  },
  circle: {
    height: 25, // Set the height
    width: 25, // Set the width
    borderRadius: 25, // Half the size of the height and width to make it a circle
    backgroundColor: 'green', // Set your desired background color
    justifyContent: 'center', // Center the icon vertically
    alignItems: 'center', // Center the icon horizontally
    marginLeft:10
  },
    headerRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    dateSection: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    lawsuitSection: {
      
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    contentSection: {
      marginBottom: 10,
    },
    descriptionRow: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    chipSection: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
     // marginTop: 5,
      marginBottom: 15
    },
    smallText: {
      fontSize: 12,
      marginLeft: 5,
    },
    normalText: {
      fontSize: 16,
      marginLeft: 5,
    },
    headerIcon: {
      marginRight: 5,
    },
    chipStyle: {
      borderColor: Colors.red20,
      borderBottomLeftRadius: 0,
      marginLeft: Spacings.s3,
    },
    chipTYPEStyle: {
    //  borderColor: Colors.red20,
     // borderBottomLeftRadius: 0,
      marginLeft: Spacings.s3,
      backgroundColor: Colors.$backgroundInverted,
      
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      backgroundColor: 'white'
    },
    searchBox: {
      flex: 1,
      borderWidth: 1,
      borderColor: '#ccc', marginRight: 10, paddingHorizontal: 10, backgroundColor: 'white', marginLeft: 10
  },
  container: {
    flex: 1,
    paddingBottom: 5, // or marginBottom: 50,
  },
  });
export default ProcedureListItem;
