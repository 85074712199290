import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList, StyleSheet, Modal, ScrollView } from 'react-native';
import { Audio } from 'expo-av';
import * as FileSystem from 'expo-file-system';
import { Ionicons } from '@expo/vector-icons';
import Styles from '../../../Styles/Styles';

export default function NotesApp({ nested = false, ...props }) {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [recording, setRecording] = useState();
  const [recordingTime, setRecordingTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingUri, setPlayingUri] = useState(null);
  const flatListRef = useRef(null);
  const soundRef = useRef(null);
  const [notesOptionsVisible, setNotesOptionsVisible] = useState(false);
  const currentDate = new Date();
  const filename = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}.txt`;
  const [playbackPosition, setPlaybackPosition] = useState(0);
  const [soundDuration, setSoundDuration] = useState(1); // initializing with 1 to avoid division by zero

  const NOTES_DIR = FileSystem.documentDirectory + 'notes/' + props.id + '/';

  useEffect(() => {
    (async () => {
      await FileSystem.makeDirectoryAsync(NOTES_DIR, { intermediates: true });
      let fetchedMessages = [];
      try {
        const fileInfo = await FileSystem.getInfoAsync(NOTES_DIR + filename);
        if (fileInfo.exists) {
          const content = await FileSystem.readAsStringAsync(NOTES_DIR + filename);
          fetchedMessages = JSON.parse(content);
          setMessages(fetchedMessages);
          setTimeout(() => {
            flatListRef.current?.scrollToEnd({ animated: true });
          }, 100);
        } else {
          setMessages([]); // Initialize with an empty array
        }
      } catch (error) {
        console.error('Error reading text messages:', error);
      }
      const fetchAudioFiles = async () => {
        const files = await FileSystem.readDirectoryAsync(NOTES_DIR);
        const audioMessages = [];
        for (let file of files) {
          if (file.endsWith('.txt') && file !== filename) { // Not our text messages file
            const content = await FileSystem.readAsStringAsync(NOTES_DIR + file);
            audioMessages.push(JSON.parse(content));
          }
        }
        return audioMessages;
      };

      const combinedMessages = [...fetchedMessages, ...await fetchAudioFiles()];
      combinedMessages.sort((a, b) => a.date - b.date); // Sort by date
      setMessages(combinedMessages);
    })();
  }, [props.id]);

  const sendTextMessage = async () => {
    if (text) {
      const message = { type: 'text', content: text, date: Date.now() };

      let currentMessages = [];
      try {
        const existingContent = await FileSystem.readAsStringAsync(NOTES_DIR + filename);
        currentMessages = JSON.parse(existingContent);
      } catch (readError) {
        // File might not exist for the current month, which is fine.
      }

      const updatedMessages = [...currentMessages, message];
      await FileSystem.writeAsStringAsync(NOTES_DIR + filename, JSON.stringify(updatedMessages));
      setMessages([...messages, message]);
      flatListRef.current?.scrollToEnd({ animated: true });
      setText('');
    }
  };

  const startRecording = async () => {
    try {
      await Audio.requestPermissionsAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });
      const recording = new Audio.Recording();
      await recording.prepareToRecordAsync(Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY);
      await recording.startAsync();
      setRecording(recording);
    } catch (err) {
      console.error('Failed to start recording', err);
    }
    const id = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
    setIntervalId(id);
  };

  const stopRecording = async () => {
    setRecording(undefined);
    await recording.stopAndUnloadAsync();
    const uri = recording.getURI();
    const message = { type: 'audio', content: uri, date: Date.now() };

    let currentMessages = [];
    try {
      const existingContent = await FileSystem.readAsStringAsync(NOTES_DIR + filename);
      currentMessages = JSON.parse(existingContent);
    } catch (readError) {
      // File might not exist for the current month, which is fine.
    }

    const updatedMessages = [...currentMessages, message];
    await FileSystem.writeAsStringAsync(NOTES_DIR + filename, JSON.stringify(updatedMessages));
    setMessages([...messages, message]);
    flatListRef.current?.scrollToEnd({ animated: true });
  };

  const playSound = async (uri) => {
    if (soundRef.current) {
      await soundRef.current.unloadAsync();
    }
    soundRef.current = new Audio.Sound();
    try {
      setIsPlaying(true);
      setPlayingUri(uri);
      await soundRef.current.loadAsync({ uri });
      await soundRef.current.playAsync();
      soundRef.current.setOnPlaybackStatusUpdate((status) => {
        if (status.isPlaying) {
          setPlaybackPosition(status.positionMillis);
          setSoundDuration(status.durationMillis);
        }
        if (status.didJustFinish) {
          setIsPlaying(false);
          setPlayingUri(null);
        }
      });
    } catch (error) {
      console.log('Error playing sound', error);
    }
  };

  useEffect(() => {
    return () => {
      if (soundRef.current) {
        soundRef.current.unloadAsync();
      }
    };
  }, []);

  const MessageItem = ({ item }) => (
    <View style={{ marginBottom: 10 }}>
      {item.type === 'text' ? (
        <View style={styles.textMessage}>
          <Text>{item.content}</Text>
        </View>
      ) : (
        <TouchableOpacity onPress={() => playSound(item.content)} style={styles.voiceMessage}>
          <Text>{isPlaying && playingUri === item.content ? `Playing... ${new Date(soundDuration - playbackPosition).toISOString().substr(14, 5)}` : "🔊 Play Voice Note"}</Text>
        </TouchableOpacity>
      )}
      <Text style={{ fontSize: 10, color: 'gray' }}>
        {new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}
      </Text>
    </View>
  );

  return (
    <View style={styles.container}>
      {/* Header */}
      {!nested && (
        <View style={styles.header}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={styles.headerTitle}>ملاحظات</Text>
            <Text style={{ fontSize: 10, color: 'white' }}>
              هذه الملاحظات تحفظ على جهازك فقط ولا يمكنك الوصول اليها من المتصفح
            </Text>
          </View>
          <Ionicons name="close" size={24} color="white" onPress={props.onClose} />
        </View>
      )}
      {!nested ? (
        <>
          <FlatList
            ref={flatListRef}
            data={messages}
            contentContainerStyle={{ padding: 10 }}
            renderItem={({ item }) => <MessageItem item={item} />}
            keyExtractor={(item) => item.date.toString()}
          />
          <View style={{ flexDirection: 'row', alignItems: 'center', margin: 10 }}>
            <TouchableOpacity onPress={sendTextMessage} style={{ marginHorizontal: 5 }}>
              <Ionicons name="send" size={30} color="black" />
            </TouchableOpacity>
            {recording ? (
              <Text>{Math.floor(recordingTime / 60)}:{recordingTime % 60}</Text>
            ) : (
              <TextInput
                value={text}
                onChangeText={setText}
                style={{ flex: 1, borderWidth: 1, borderColor: 'gray', borderRadius: 5, padding: 10 }}
                placeholder="Type your note"
              />
            )}
            <TouchableOpacity onPress={recording ? stopRecording : startRecording} style={{ marginHorizontal: 5 }}>
              <Ionicons name={recording ? "square" : "mic"} size={30} color="black" />
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <ScrollView contentContainerStyle={{ padding: 10 }}>
          <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setNotesOptionsVisible(true)}>
            <Ionicons name="expand-outline" size={18} color="black" style={{ marginTop: 5, marginLeft: 5 }} />
          </TouchableOpacity>
          {messages.slice(-10).map((item) => (
            <MessageItem key={item.date.toString()} item={item} />
          ))}
          
        </ScrollView>
      )}
      {notesOptionsVisible && (
        <Modal transparent={false} visible={notesOptionsVisible} animationType="slide">
          <NotesApp id={props.id} onClose={() => setNotesOptionsVisible(false)} nested={false} />
        </Modal>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 0.5,
    borderColor: '#ccc',
    backgroundColor: '#6B0F1A',
  },
  headerTitle: {
    fontSize: 20,
    color: 'white'
  },
  textMessage: {
    padding: 10,
    borderRadius: 10,
    margin: 10,
    backgroundColor: '#e1ffc7',
    maxWidth: '80%',
    alignSelf: 'flex-start',
  },
  voiceMessage: {
    padding: 10,
    borderRadius: 10,
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff3e1',
    maxWidth: '80%',
    alignSelf: 'flex-start',
  },
  timestamp: {
    fontSize: 10,
    color: 'gray',
    marginTop: 5,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderTopWidth: 0.5,
    borderColor: '#ccc',
    backgroundColor: '#fff',
  },
  input: {
    flex: 1,
    padding: 10,
    borderRadius: 20,
    borderWidth: 0.5,
    borderColor: '#ccc',
  },
});
