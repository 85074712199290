import React, { useState, useEffect } from 'react';
import { StyleSheet, Modal, TouchableOpacity, ScrollView, Button } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { View, Text, Drawer, Colors } from 'react-native-ui-lib';
import AccountingModal from './AccountingModal';
import { fetchData } from '../../../utils/api';
import Styles from '../../../Styles/Styles';


const AccountStatement = ({ fin_id, date_start, date_end, text, isVisible, onClose, onDone }) => {
    const [transactions, setTransactions] = useState(null);
    const [error, setError] = useState(null);
    const [usdBalance, setUsdBalance] = useState(0);
const [lbpBalance, setLbpBalance] = useState(0);
const [isModalVisible, setIsModalVisible] = useState(false);
const [isAddViewVisible, setIsAddViewVisible] = useState(false);
const [initialFrom, setInitialFrom] = useState(null);
const [initialTo, setInitialTo] = useState(null);
const [initialFromTitle, setInitialFromTitle] = useState(null);
const [initialToTitle, setInitialToTitle] = useState(null);
//const fin_id = route.params.fin_id.toString();
const [selectedTransaction, setSelectedTransaction] = useState(null);
const [loading, setLoading] = useState(true); // Add this line





   
const getBackgroundColor = (item) => {
if (item.from.id === fin_id) {
    return 'lightcoral';
} else {
    return 'mediumseagreen';
}
};

const fetchDataFromAPI = () => {
    if (!fin_id || !date_start || !date_end) {
        setError('Missing required parameters');
        setLoading(false);
        return;
    }

    setLoading(true); // Start loading

    fetchData({ method: 'select_transactions', params: { fin_id, date_start, date_end } })
        .then(data => {
            setTransactions(data);
            setLoading(false);
        })
        .catch(err => {
            setError(`Failed to fetch transactions: ${err}`);
            setLoading(false);
        });
};

useEffect(() => {
    fetchDataFromAPI();
}, [fin_id, date_start, date_end, text]); // Depend on props


    const Separator = () => {
        return (
            <View style={{ height: 1, backgroundColor: '#ccc' }} />
        );
    };
    

    useEffect(() => {
        if (transactions) {
            const fin_id_str = fin_id.toString();

            const usdTo = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'usd' && transaction.to.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);
            
            // Do the same for usdFrom, lbpTo, and lbpFrom
            

            const usdFrom = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'usd' && transaction.from.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            const lbpTo = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'lbp' && transaction.to.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            const lbpFrom = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'lbp' && transaction.from.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            setUsdBalance(usdTo - usdFrom);
            setLbpBalance(lbpTo - lbpFrom);
        }
    }, [transactions]);


    

    const LawsuiteDetailsAccountingView = ({item}) => {
 // Function to parse the title and safely handle any errors
 const parseTitle = (titleString) => {
    try {
        const titleObject = JSON.parse(titleString);

        if (titleObject.result === 'lawfirm') {
            return 'الصندوق';
        }

        return titleObject.title || titleObject.result || `${titleObject.first_name || ''} ${titleObject.middle_name || ''} ${titleObject.last_name || ''}`.trim() || '';
    } catch (error) {
        console.error('Error parsing title:', error);
        return '';
    }
};

    // Determine the title to display based on whether item.id is present
    let displayTitle = '';
    if (fin_id !== item.from.id) {
        displayTitle = "من " + parseTitle(item.from.title);
    } else if (fin_id !== item.to.id) {
        displayTitle ="الى " +  parseTitle(item.to.title);
    }

        return(
            <Drawer
            key={item.id.toString()}
            rightItems={[{ background: 'transparent', onPress: () => {} }]}
            leftItem={{ text: 'Delete', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
        >
            <View centerV padding-s4 bg-white key={item.id.toString()} style={{flexDirection:'row', borderBottomColor:'grey', borderBottomWidth:1, backgroundColor: getBackgroundColor(item)}}>
        
                {/* Date and Description */}
                <View style={{flexDirection:'column', flex:1}}>
                <View style={{ flex: 1, flexDirection:'row' }}>
                    <Text style={{ fontSize: 12 }}>{item.date_entered}</Text>
                   

                    <Text style={{fontSize: 12, fontWeight: 'bold', marginHorizontal:5 }}>- {displayTitle}</Text>
                </View>

                <Text style={[styles.description, { fontSize: 16, fontWeight: 'bold', marginTop:5 }]}>◀ {item.description}</Text>
                </View>
                {/* Amount and Currency Box */}
                <View style={{ padding: 2, backgroundColor: 'white', borderRadius: 5, alignItems: 'center', backgroundColor: getBackgroundColor(item), width:'20%' }}>
                    <Text style={[
                        styles.cell,
                        {
                            color: 'white',
                        }
                        
                    ]}>
                        {item.from.id === fin_id ? `-${item.amount}` : `${item.amount}`}
                    </Text>
                    <Text style={[styles.cell, {color:'white'}]}>{item.currency}</Text>
                </View>
        
            </View>
        </Drawer>

        )
    };



    return (
      
           
                    <Modal
                        transparent={false}
                        visible={isVisible}
                        animationType="slide"
                        onRequestClose={onClose}

                    >

                         {/* Header */}
                <View style={{ padding: 10, backgroundColor: '#f8f8f8', borderBottomWidth: 1, borderColor: '#e7e7e7' }}>
                <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>كشف حساب {text} </Text>
                            <Text style={{ fontSize: 14, alignSelf: 'center', fontWeight: 'bold', marginBottom:20}}>من {date_start.toLocaleDateString()} الى {date_end.toLocaleDateString()}</Text>

                </View>
                <TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => onDone()}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
                        <ScrollView style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                           
                            {transactions && transactions.map(item => (
                                <View key={item.id} style={{backgroundColor:'white', marginRight: 10, marginLeft:10, flex:1}}>
                                    <LawsuiteDetailsAccountingView  item={item} />
                                </View>
                            ))}



                         
                        </ScrollView>
                        <View style={styles.balanceBar}>
                <Text style={styles.balanceText}>USD Balance: {usdBalance}</Text>
                <Text style={styles.balanceText}>LBP Balance: {lbpBalance}</Text>
            </View>
                    </Modal>
                   
        
    );
                    };






    const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        borderWidth: 1,
        borderColor: 'black',
        backgroundColor: 'lightgray'
    },
    headerText: {
        flex: 1,
        padding: 10,
        borderRightWidth: 1,
        borderColor: 'black'
    },
    fixedView: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: 10,
        backgroundColor: 'lightgray',
        alignItems: 'center'
    },
    row: {
        padding: 10,
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginBottom: 10,
    },
    innerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    date: {
        flex: 1,
    },
    cell: {
        flex: 1,
        textAlign: 'right',
    },
    description: {
        //flexShrink: 1,
        color: 'white',
        fontSize: 12,
    },
    balanceText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'white'
    },
    balanceBar: {
       // position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: '#006989',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
});

export default AccountStatement;
