import React, { useState, useContext, useEffect } from 'react';
import { FlatList, Platform, ScrollView } from 'react-native';
import { View, Text, Image, ExpandableSection, Colors, TouchableOpacity, ActionSheet, Modal } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import LawsuitsDetails from '../components/LawsuitDetails';
import LawsuitsDetailsClient from '../components/LawSuiteDetailsClient';
import LawsuitsDetailsOpponent from '../components/LawSuiteDetailsOpponent';
import LawsuitsDetailsProcedures from '../components/LawSuiteDetailsProcedures';
import LSRemindersScreen from '../components/LawSuitesDetailsReminders';
import TransactionFetcher from '../components/LawSuiteDetailsAccounting';
import DocumentExplorer from '../components/LawSuitesDetailsFiles';
import LSNotes from '../components/LawSuiteDetailsNotes';
import ProcedureModal from '../../Procedures/components/ProcedureModal';
import AccountingModal from '../../Accounting/coomponents/AccountingModal';
import AddReminderModal from '../../Calendar/components/AddReminderModal';
import Styles from '../../../Styles/Styles';
import ProceduresScreen from '../../Procedures/screens/ProceduresS';
import { SearchContext } from '../../../contexts/SearchContext';
import { SpeedDial } from '@rneui/themed';
import { encodeImagesWithMimeType, executePostRequest } from '../../../utils/api';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation, useRoute } from '@react-navigation/native';
import RefreshContext from '../../../contexts/RefreshContext';




const LawSuitDetailsMain = ({ route }) => {
  const {
    setRefreshCalendar,
    setRefreshProcedures,
    setRefreshAccounting
  } = useContext(RefreshContext); // Use the context
  const [open, setOpen] = useState(false);

  const [ProcedureModalVisible, setProcedureModalVisible] = useState(false);
  const [isAccountingModalVisible, setisAccountingModalVisible] = useState(false);
  const [activeItem, setActiveItem] = useState(null); // Store the ID of the expanded item
  const [ReminderModalVisible, setReminderModalVisible] = useState(false);
  const [AccountingOptionsVisible, setAccountingOptionsVisible] = useState(false);
  const [NotesOptionsVisible, setNotesOptionsVisible] = useState(false);


  const navigation = useNavigation();



  const toggleItem = (id) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  //console.log('Route in LawSuitDetailsMain:', route);
  const [selectedFromData, setselectedFromData] = useState('');

  const AddAccountingFrom = () => {
    setselectedFromData({
      "content": {
        "id": route.params.fin_id,
        "type": "lawsuit",
        "text": route.params.HeaderTitle,
      },
      "case": "to"
    });
    setselectedToData('');
    setisAccountingModalVisible(true);
  };

  const AddAccountingTO = () => {
    setselectedToData({
      "content": {
        "id": route.params.fin_id,
        "type": "lawsuit",
        "text": route.params.HeaderTitle,
      },
      "case": "to"
    });
    setselectedFromData('');
    setisAccountingModalVisible(true);
  }



  const [selectedToData, setselectedToData] = useState({
    "content": {
      "id": "31284",
      "type": "lawsuit",
      "text": "maza"
    },
    "case": "to"
  });


  const handleCloseModal = async (newItem) => {
    // console.log(newItem);
    //  setTransactions(currentTransactions => [newItem, ...currentTransactions]);

    // showLoading();

    const updatedDataItem = {
      ...newItem,
      id: '',
    };
    try {
      const param = {
        method: 'insert_transaction',
        params: updatedDataItem
      };
      const responseData = await executePostRequest(param);

      if (responseData && responseData.id) {
        console.log('Transaction created successfully:', responseData);
        setRefreshAccounting(true);

        // Handle the successful response
        // For example, update the UI or navigate to another screen
        // showSuccess();

      } else {
        // Handle the case where the response doesn't have an 'id' field
        console.error('Transaction creation failed:', responseData);
        // Show error message to the user
        // showError();
      }

    } catch (error) {
      console.error('Error:', error);
      // Show error message to the user
      // showError();
    } finally {
      // setIsSubmitting(false);
      //onClose();
      //  fetchTransactions();
    }

  }



  const onModalSubmit = async (newDataItem, newImageUris) => {

    //setData(currentData => [newDataItem, ...currentData]);
    // setFilteredData(currentFilteredData => [newDataItem, ...currentFilteredData]);
    // Additional logic if needed
    // showLoading();

    console.log(newDataItem);
    const imagesBase64 = await encodeImagesWithMimeType(newImageUris);
    const updatedDataItem = {
      ...newDataItem,
      id: '',
      files: imagesBase64
    };

    // Prepare the body for the POST request
    const body = {
      method: 'insert_procedure',
      params: updatedDataItem
    };

    // Execute the POST request and handle the response
    try {
      const responseJson = await executePostRequest(body);

      if (responseJson && responseJson.id) {
        //showSuccess('Eshta');
        //setShouldFetchData(true);
      } else {
        console.error('Upload failed');
        //showError();
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };



  // Modified handleModalClose function
  const handleModalClose = async (newRecord) => {
    try {
      const processedRecord = await executePost(newRecord);
      setRefreshCalendar(true); // Set the refreshCalendar flag to true
      console.log('REFRESH TRUE');

      addToLocalArray(processedRecord);

    } catch (error) {
      // Error handling if needed
    }
  };

  const handleProcedureModalClose = () => {
    setProcedureModalVisible(false);
    setRefreshProcedures(true); // Set the refreshProcedures flag to true
  };

  const handleAccountingModalClose = () => {
    setisAccountingModalVisible(false);
    setRefreshAccounting(true); // Set the refreshAccounting flag to true
  };

  const executePost = async (newRecord) => {
    let imagesBase64 = [];

    // Encode images if they exist
    if (newRecord.files && newRecord.files.length > 0) {
      imagesBase64 = await encodeImagesWithMimeType(newRecord.files);
    }

    // Prepare the body for the POST request
    const body = {
      method: 'insert_reminder',
      params: {
        ...newRecord,
        id: '',
        files: imagesBase64,
      }
    };

    try {
      const response = await executePostRequest(body);
      //console.log('Post request response:', response);
      //showSuccess();
      return newRecord; // Return newRecord for further processing
    } catch (error) {
      console.error('Error in post request:', error);
      //showError();
      throw error; // Re-throw error to handle it in the calling function
    }
  };

  console.log('ROUTEEEEEEEE:', route);
  const UsersTabs = createMaterialTopTabNavigator();


  const renderForWeb = () => {
    return (
      <UsersTabs.Navigator
        screenOptions={{
          tabBarStyle: {
            direction: 'rtl'
          },
          // Other screenOptions...
        }}
        tabBarOptions={{
          labelStyle: {
            fontFamily: 'DefFont', // Specify your font family

          },
          // Other tabBarOptions...
        }}
      >
        <UsersTabs.Screen name="تفاصيل الدعوى">
          {() => <LawsuitsDetails route={route} />}
        </UsersTabs.Screen>


        <UsersTabs.Screen name="الموكلون" >
          {() => <LawsuitsDetailsClient route={route} />}
        </UsersTabs.Screen>

        <UsersTabs.Screen name="الخصوم" >
          {() => <LawsuitsDetailsOpponent route={route} />}
        </UsersTabs.Screen>


        <UsersTabs.Screen name="الاجراءات" >
          {() => <ProceduresScreen route={route} />}
        </UsersTabs.Screen>

        <UsersTabs.Screen name="الاستحقاقات" >
          {() => <LSRemindersScreen route={route} />}
        </UsersTabs.Screen>

        <UsersTabs.Screen name="المالية" >
          {() => <TransactionFetcher route={route} />}
        </UsersTabs.Screen>

        <UsersTabs.Screen name="اللوائح والمستندات" >
          {() => <DocumentExplorer route={route} />}
        </UsersTabs.Screen>

      </UsersTabs.Navigator>


    );
  };

  const renderForMobile = () => {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView style={Styles.LawSuitDetailsMainStyles.container}>

          {ProcedureModalVisible &&
            <ProcedureModal
              modalVisible={ProcedureModalVisible}
              setModalVisible={setProcedureModalVisible}
              route={route}
              onModalSubmit={onModalSubmit}
              onClose={handleProcedureModalClose} // Use the new close handler
            />
          }

          {ReminderModalVisible &&
            <AddReminderModal
              modalVisible={ReminderModalVisible}
              setModalVisible={setReminderModalVisible}
              route={route}
              onClose={handleModalClose} // Use the new close handler
            />
          }

          {NotesOptionsVisible &&
            <Modal
              transparent={false}
              visible={NotesOptionsVisible}
              animationType="slide"
            >
              <LSNotes id={route.params.lawsuit_info_id} onClose={() => { setNotesOptionsVisible(false) }} nested={false} />
            </Modal>
          }

          <ActionSheet
            title={'نوع القيد'}
            message={'الرجاء اختيار نوع القيد'}
            visible={AccountingOptionsVisible}
            cancelButtonIndex={3}
            // destructiveButtonIndex={0}
            options={[
              { label: 'مدخول', onPress: () => AddAccountingFrom() },
              { label: 'مصروف', onPress: () => AddAccountingTO() },
              { label: 'الغاء', onPress: () => setAccountingOptionsVisible(false) },
            ]}
            onDismiss={() => setAccountingOptionsVisible(false)}

          />


          <AccountingModal
            isVisible={isAccountingModalVisible}
            setIsModalVisible={setisAccountingModalVisible}
            onClose={handleCloseModal} // Use the new close handler
            selectedFromData={selectedFromData}
            selectedToData={selectedToData}
            IsLawsuitDefined={true}
            Lawsuit_id={route.params.id}
            Lawsuit_info_id={route.params.lawsuit_info_id}
          />


          {/*<Text style={Styles.LawSuitDetailsMainStyles.iconsContainerOverall}>محتويات الملف</Text>*/}

          <ExpandableSection
            down
            expanded={activeItem === 'details'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="apps-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>تفاصيل الدعوى</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'details' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('details')}
          >
            {
              activeItem === 'details' &&
              <LawsuitsDetails route={route} />
            }

          </ExpandableSection>

          <ExpandableSection
            expanded={activeItem === 'clients'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="people-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>الموكلون</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'clients' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('clients')}
          >
            <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.UnderCollapsible}>
              {
                activeItem === 'clients' &&
                <LawsuitsDetailsClient route={route} />
              }
            </View>
          </ExpandableSection>

          <ExpandableSection
            expanded={activeItem === 'oppo'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst}>
                <Ionicons
                  name="people-circle-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>الخصوم</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'oppo' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('oppo')}
          >
            <View style={Styles.LawSuitDetailsMainStyles.UnderCollapsible}>

              {
                activeItem === 'oppo' &&
                <LawsuitsDetailsOpponent route={route} />
              }
            </View>
          </ExpandableSection>


          <ExpandableSection
            expanded={activeItem === 'procedures'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="pencil-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>الاجراءات</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'procedures' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('procedures')}
          >
            <View style={Styles.LawSuitDetailsMainStyles.UnderCollapsible}>
              {
                activeItem === 'procedures' &&
                <ProceduresScreen route={route} />}

              {/*  <LawsuitsDetailsProcedures route={route} />*/}
            </View>
          </ExpandableSection>

          <ExpandableSection

            expanded={activeItem === 'reminders'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="calendar-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>الاستحقاقات</Text>

                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  {route.params.hasReminder &&
                    <Ionicons
                      name={'warning'}
                      size={30}
                      color="orange"
                      style={{ marginHorizontal: 15 }}
                    />
                  }
                  <Ionicons
                    name={activeItem === 'reminders' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('reminders')}
          >
            {
              activeItem === 'reminders' &&
              <LSRemindersScreen route={route} />
            }

          </ExpandableSection>

          <ExpandableSection
            expanded={activeItem === 'acc'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="cash-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>المالية</Text>

                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  {route.params.hasBalance &&
                    <Ionicons
                      name={'warning'}
                      size={30}
                      color="red"
                      style={{ marginHorizontal: 15 }}
                    />
                  }
                  <Ionicons
                    name={activeItem === 'acc' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('acc')}
          >

            {
              activeItem === 'acc' &&
              <TransactionFetcher route={route} />
            }
          </ExpandableSection>


          <ExpandableSection
            expanded={activeItem === 'files'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} >
                <Ionicons
                  name="document-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>اللوائح والمستندات</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'files' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
            onPress={() => toggleItem('files')}
          >
            {
              activeItem === 'files' &&
              <DocumentExplorer route={route} />
            }
          </ExpandableSection>

          <ExpandableSection
            expanded={activeItem === 'notes'}
            sectionHeader={
              <View dir={'rtl'} style={Styles.LawSuitDetailsMainStyles.collapsibleFirst} onTouchEnd={() => toggleItem('notes')}>
                <Ionicons
                  name="bookmark-outline"
                  size={30}
                  color="#2274A5"
                  style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
                //onPress={toggleModal}
                />
                <Text>الملاحظات</Text>
                <View style={Styles.LawSuitDetailsMainStyles.chevronContainer}>
                  <Ionicons
                    name={activeItem === 'notes' ? "chevron-up" : "chevron-back"}
                    size={30}
                    color="#2274A5"
                    style={Styles.LawSuitDetailsMainStyles.chevronIcon}
                  />
                </View>
              </View>
            }
          //   onPress={() => toggleItem(item.id)}
          >
            {LSNotes && <LSNotes id={route.params.lawsuit_info_id} nested={true} />}

          </ExpandableSection>


        </ScrollView>

        <SpeedDial
          isOpen={open}
          icon={{ name: 'edit', color: '#fff' }}
          openIcon={{ name: 'close', color: '#fff' }}
          onOpen={() => { setOpen(!open); toggleItem(activeItem?.id) }}
          onClose={() => setOpen(!open)}
          style={{ position: 'absolute' }}
        >
          <SpeedDial.Action
            icon={{ name: 'create', color: '#fff' }}
            title=" اضافة اجراء"
            onPress={() => { setProcedureModalVisible(true); setOpen(!open) }}
          />
          <SpeedDial.Action
            icon={{ name: 'event', color: '#fff' }}
            title="اضافة استحقاق"
            onPress={() => { setReminderModalVisible(true); setOpen(!open) }}
          />
          <SpeedDial.Action
            icon={{ name: 'local-atm', color: '#fff' }}
            title="اضافة قيد مالي"
            onPress={() => { setAccountingOptionsVisible(true); setOpen(!open) }}
          />
          <SpeedDial.Action
            icon={{ name: 'local-offer', color: '#fff' }}
            title="اضافة ملاحظات"
            onPress={() => { setNotesOptionsVisible(true); setOpen(!open) }}
          />

          {<SpeedDial.Action
            icon={{ name: 'update', color: '#fff' }}
            title="تعديل تفاصيل الدعوى"
            onPress={() => {
              navigation.reset({
                index: 0,
                routes: [
                  { name: 'AddNewLawsuit', params: { update: true, lawsuit_info_id: route.params.lawsuit_info_id, id: route.params.id } }
                ],
              });
              setOpen(!open);
            }}
          />
          }


        </SpeedDial>
      </View>
    );
  };





  return (
    Platform.OS === 'web' ? renderForWeb() : renderForMobile()


  );
}


export default LawSuitDetailsMain;
