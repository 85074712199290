// IndicatorContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import * as SQLite from 'expo-sqlite/legacy';

export const IndicatorContext = createContext();

export const useIndicator = () => useContext(IndicatorContext);

export const IndicatorProvider = ({ children }) => {
  const [indicatorState, setIndicatorState] = useState({
    loading: false,
    success: false,
    error: false,
    history: [],
    isModalVisible: false,
  });

  const showModal = async () => {
    await loadHistory(); // Load the latest history before showing the modal
    setIndicatorState(prevState => ({ ...prevState, isModalVisible: true }));
  };
    const hideModal = () => setIndicatorState(prevState => ({ ...prevState, isModalVisible: false }));
  
  const [history, setHistory] = useState([]);

  const updateErrorState = () => {
    const hasError = indicatorState.history.some(item => item.status === 'error');
    setIndicatorState(prevState => ({ ...prevState, error: hasError }));
  };
  
  // Call `updateErrorState` whenever you update the history
  

    const loadHistory = async () => {
      const db = SQLite.openDatabase('localAvocado.db');
      db.transaction(tx => {
        tx.executeSql('SELECT * FROM History ORDER BY createdAt DESC LIMIT 50', [], 
          (_, { rows }) => {
            let history = [];
            for (let i = 0; i < rows.length; i++) {
              history.push(rows.item(i));
            }
            setIndicatorState(prevState => ({ ...prevState, history }));
          },
          error => console.error('Error loading history:', error)
        );
      });
    };
  
    /*
    useEffect(() => {
      loadHistory(); // Load history when the provider mounts
    }, []);
*/

  const addToHistory = async (actionName, status) => {
    const db = SQLite.openDatabase('localAvocado.db');
    db.transaction(tx => {
      tx.executeSql('INSERT INTO History (actionName, status) VALUES (?, ?)', [actionName, status],
        () => {
          // Successfully added to history, now update local state
          setHistory(prevHistory => [...prevHistory, { actionName, status }]);
        },
        error => console.log('Error adding to history', error)
      );
    });
  };


  const retryAction = async (actionId, actionFunction) => {
    try {
      await actionFunction();
      // Remove the retried action from history if successful
      const db = SQLite.openDatabase('localAvocado.db');
      db.transaction(tx => {
        tx.executeSql('DELETE FROM History WHERE id = ?', [actionId]);
      });
  
      // Update the error state based on remaining history items
      setIndicatorState(prevState => {
        const updatedHistory = prevState.history.filter(item => item.id !== actionId);
        const hasError = updatedHistory.some(item => item.status === 'error');
        return {
          ...prevState,
          history: updatedHistory,
          error: hasError // Update error state based on remaining history items
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

// Update the existing functions to use addToHistory
const showLoading = (action) => {
  //addToHistory(action, 'loading');
  setIndicatorState(prevState => ({ 
    ...prevState, 
    loading: true, 
    success: false, 
    error: false 
  }));
};


// Update the existing functions to use addToHistory
const showSuccess = (action) => {
  //addToHistory(action, 'success');
  setIndicatorState(prevState => ({ 
    ...prevState, 
    loading: false, 
    success: true, 
    error: false 
  }));
};


const showError = (actionName) => {
 // addToHistory(actionName, 'error');
  setIndicatorState(prevState => ({
    ...prevState,
    loading: false,
    success: false,
    error: true // Set error to true when an error occurs
  }));
};



  const resetIndicator = () => setIndicatorState({ loading: false, success: false, error: false });

  return (
    <IndicatorContext.Provider value={{
      indicatorState,
      showLoading,
      showSuccess,
      showError,
      resetIndicator,
      showModal,
      hideModal,
    //  retryAction, // and other necessary values
    }}>
      {children}
    </IndicatorContext.Provider>
    
  );
};


// Can be in IndicatorContext.js or a separate file

import { ActivityIndicator, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

/*
export const CustomIndicator = () => {
  const { indicatorState } = useIndicator();

  if (indicatorState.loading) {
    return <ActivityIndicator size="small" color="#0000ff" />;
  }

  if (indicatorState.success) {
    return <Ionicons name="checkmark-circle" size={24} color="green" />;
  }

  if (indicatorState.error) {
    return <Ionicons name="alert-circle" size={24} color="red" />;
  }

  return null;
};

*/
// CustomIndicator.js
import { TouchableOpacity } from 'react-native';

export const CustomIndicator = () => {
  const { indicatorState, showModal } = useIndicator();

  const handlePress = () => {
    showModal();
  };

  if (indicatorState.loading) {
    return (
      <TouchableOpacity onPress={handlePress}>
        <ActivityIndicator size="small" color="#0000ff" />
      </TouchableOpacity>
    );
  }
  

  if (indicatorState.success) {
    return (
      <TouchableOpacity onPress={handlePress}>
             <Ionicons name="checkmark-circle" size={24} color="green" />

      </TouchableOpacity>
    );
  }



  if (indicatorState.error) {
    return (
      <TouchableOpacity onPress={handlePress}>
<Ionicons name="alert-circle" size={24} color="red" />
      </TouchableOpacity>
    );
  }





};
