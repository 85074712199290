import React, { useState, useEffect, useContext } from 'react';
import { View, Text, FlatList, TouchableOpacity, TextInput, StyleSheet, Modal } from 'react-native';
import { useNavigation, useRoute  } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import LawSuiteDetailsNavigation from './LawSuiteDetailsNavigation';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { FlashList } from '@shopify/flash-list';
import LawsuitFilterModal from '../../../SharedComponents/LawsuitFilterModal';
import FilterBar from '../../../SharedComponents/FilterBar';
import Constants from 'expo-constants';
import { SearchContext } from '../../../contexts/SearchContext';
import { SpeedDial } from '@rneui/themed';
import AddNewLawsuit from './AddNewLawsuit';
import CustomChip from '../../../SharedComponents/CustomChip';
import { getToken, fetchData } from '../../../utils/api';
import { useLoading } from '../../../contexts/LoadingContext';
import { useFocusEffect } from '@react-navigation/native';


/**
 * Component that displays a list of active lawsuits and allows the user to search and filter the list.
 */
const LawS = () => {
  const [open, setOpen] = useState(false);
  const route = useRoute();
  const checkmark = require('../../../assets/briefcase-66-32.png');
  const [itemDetails, setItemDetails] = useState({}); // Additional details for each item


  const [data, setData] = useState([]); // State variable to store the list of lawsuits
  //const [searchQuery, setSearchQuery] = useState(''); // State variable to store the search query
  //const [filteredData, setFilteredData] = useState([]); // State variable to store the filtered list of lawsuits
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const [filters, setFilters] = useState(null);
  
const [showReportBars, setShowReportBars] = useState(false);
const [reportBarValues, setReportBarValues] = useState([]);

const [shouldFetchData, setShouldFetchData] = useState(true);//after closing the filter bar
const apiUrl = Constants.expoConfig.extra.apiUrl;

const { searchQuery } = useContext(SearchContext);
const [filteredData, setFilteredData] = useState([]);
const [isAddNewLawsuitVisible,setisAddNewLawsuitVisible] = useState(false);
const [detailsFetched, setDetailsFetched] = useState(false);
const [transactionsFetched, setTransactionsFetched] = useState(false);
const { isLoading } = useLoading();



  //Reset Modal
  const [resetKey, setResetKey] = useState(0);

  const resetFilter = () => {
    setResetKey(prevKey => prevKey + 1);
    setShouldFetchData(true);
  };
//////////////  






useEffect(() => {
  if (shouldFetchData && !isLoading) {
    fetchLws();
    setShouldFetchData(false);
  }
}, [shouldFetchData, isLoading]);




const handleFilterBarClose = () => {
  setFilters('');
  setShowReportBars(false);
  setReportBarValues([]);  // Reset the report bar values
  resetFilter();
};


  // API request parameters to fetch the list of active lawsuits
  const param = {
    method: "select_lawsuits",
    params: { select2: '1', is_active: '0' }
  };

  /**
   * Function to retrieve the user's authentication token from AsyncStorage.
   * @returns {string|null} The user's authentication token, or null if it does not exist.
   *//*
  const getToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token !== null) {
        return token;
      } else {
        //console.log('Token does not exist in AsyncStorage');
        return null;
      }
    } catch (e) {
      //console.log(e);
      return null;
    }
  };
*/
  /**
   * Function to fetch the list of active lawsuits from the API and update the state variable.
   */
  
  const fetchDataFromServer = async () => {
    try {
      const token = await getToken();
      const requestParams = filters ? filters : param;
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestParams),
      });
  
      const text = await response.text();
      //console.log(text);
  
      if (text.length !== 0) {
        try {
          const json = JSON.parse(text);
          let dataToSet = json.results ? json.results : json; // Setting dataToSet to json.results if it exists, else to json
          setData(dataToSet);
          setFilteredData(dataToSet);
          //console.log('DATATOSET: ', dataToSet);
        } catch (error) {
          console.error(error);
          setData([]);
          setFilteredData([]);
        }
      } else {
        //console.log('Empty response');
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  const fetchLws = async () => {
    try {
      const jsonString = await AsyncStorage.getItem('select_lawsuits');
      if (jsonString !== null) {
        const jsonData = JSON.parse(jsonString);
        let dataToSet = jsonData.results ? jsonData.results : jsonData;
  
        // Sort data by l_nb in descending order
        dataToSet.sort((a, b) => {
          // Assuming l_nb is a string - comparing as numbers if possible, or as strings otherwise
          const aNb = isNaN(a.l_nb) ? a.l_nb : +a.l_nb;
          const bNb = isNaN(b.l_nb) ? b.l_nb : +b.l_nb;
          if (aNb > bNb) return -1;
          if (aNb < bNb) return 1;
          return 0;
        });
  
        setData(dataToSet);
        setFilteredData(dataToSet);
        console.log('DATATOSET: ', dataToSet);
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };


    // Function to fetch reminders from the server
    const fetchReminders = async (filters) => {
      //console.log(filters); // Log the filters to check their values
      try {
          const token = await getToken();
    
          const requestBody =JSON.stringify(filters)
    
        console.log("Request Body:", requestBody); // Log the request body
    
        const response = await fetch('https://maktabi.info/includes/model.php', {
          method: 'POST',
          body: requestBody,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',     
              Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    
        const data = await response.json();
        console.log(data);
        return data;
    
      } catch (error) {
        console.error('Error fetching reminders:', error);
       // setLoading(false);
      }
    };
  
  
    const fetchItemDetails = async () => {
      if (!detailsFetched && filteredData.length > 0) {
        const startDate = new Date(); // Today
        const endDate = new Date(); // Create a new Date object for the end date
        endDate.setDate(startDate.getDate() + 15); // Set the end date to 15 days in the future
    
        // Format dates to 'YYYY-MM-DD 00:00:00'
        const formatDateString = (date) => {
          const year = date.getFullYear();
          const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are 0-indexed, add 1
          const day = (`0${date.getDate()}`).slice(-2);
          return `${year}-${month}-${day} 00:00:00`;
        };
    
        const updatedData = await Promise.all(filteredData.map(async (item) => {
          const newFilters = {
            method: 'select_grouped_reminders',
            params: {
              date_start: formatDateString(startDate),
              date_end: formatDateString(endDate),
              lawsuit_id: item.id,
            },
          };
    
          try {
            const details = await fetchReminders(newFilters);
            const hasReminders = details && Object.keys(details).length > 0;
            return { ...item, hasReminder: hasReminders }; // Add hasReminder flag to item
          } catch (error) {
            console.error('Error fetching item details:', error);
            return { ...item, hasReminder: false }; // In case of error, assume no reminders
          }
        }));
    
        setFilteredData(updatedData); // Update the state with the new data
        setDetailsFetched(true); // Indicate that details have been fetched to prevent further fetching
      }
    };
    
    const fetchTransactionsAndUpdateItems = async () => {
      if (!transactionsFetched && filteredData.length > 0) {
        const updatedData = await Promise.all(filteredData.map(async (item) => {
          // Fetch transactions for the current item
          try {
            const transactions = await fetchData({
              method: 'select_transactions',
              params: { fin_id: item.financial_acc_id },
            });
    
            // Perform calculations
            if (transactions) {
              const fin_id_str = item.financial_acc_id.toString();
    
              const calculateBalance = (currency, targetIdKey) => transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === currency && transaction[targetIdKey].id === fin_id_str) {
                  return sum + parseFloat(transaction.amount);
                }
                return sum;
              }, 0);
    
              const usdTo = calculateBalance('usd', 'to');
              const usdFrom = calculateBalance('usd', 'from');
              const lbpTo = calculateBalance('lbp', 'to');
              const lbpFrom = calculateBalance('lbp', 'from');
    
              const usdBalance = usdFrom - usdTo;
              const lbpBalance = lbpFrom - lbpTo;
    
              // Update item with hasBalance based on calculated balances
              return { ...item, hasBalance: usdBalance < 0 || lbpBalance < 0 };
            }
    
            return item; // Return item unchanged if no transactions
          } catch (error) {
            console.error('Error fetching transactions:', error);
            return item; // Return item unchanged in case of error
          }
        }));
    
        setFilteredData(updatedData); // Update the state with the new data
        setTransactionsFetched(true); // Indicate that details have been fetched
      }
    };
    
    useEffect(() => {
     if(detailsFetched){
      fetchTransactionsAndUpdateItems();
     }
    }, [filteredData, transactionsFetched]); // Add transactionsFetched to the dependency array
    
    
    useEffect(() => {
      fetchItemDetails();
    }, [filteredData, detailsFetched]); // Include detailsFetched in the dependency array
    

    

  useEffect(() => {
    if (filters) {
      fetchDataFromServer();
    }
  }, [filters]);
  
  const onActionSheetSubmit = (selectedValues) => {
    //console.log(selectedValues);
    const newFilters = {
      method: "select_lawsuits",
      params: {
        filter: true, 
        lawyer_id: selectedValues.LawyerId,
        client_id: selectedValues.ClientId, 
        opponent_id: selectedValues.OpponentId, 
        type_id: selectedValues.TypeId, 
        phase_id: selectedValues.PhaseId, 
        j_ref_id: selectedValues.JRefId, 
        j_region_id: selectedValues.JRegionId, 
        isActive: selectedValues.IsActive
      }};
    //console.log('Filters ', newFilters);
    
    setFilters(newFilters);  // This will trigger the useEffect hook
    setShowReportBars(true);
  };
  
  
 
  const renderItem = ({ item }) => (
    <CustomChip
      label={item.title || item.text}
      iconSource={checkmark}
      onPress={() => handlePress(item)}
      tintColor="#007bff"
     // style={{ height: 100 }}
      showDetails={true}
      hasReminders={item.hasReminder} // Use the hasReminder flag directly
      hasbalance={item.hasBalance}
    />
  );
  

  

  const navigation = useNavigation();

  /**
   * Function to handle when a lawsuit item is pressed.
   * @param {object} item The lawsuit item that was pressed.
   */
  const handlePress = async (item) => {
    let id1, id2;
    if (item.id && typeof item.id === 'string' && item.id.includes(',')) {
      const idValues = item.id.split(',');
      id1 = idValues[0];
      id2 = idValues[1];
    } else {
      id1 = item.id;
      id2 = item.lawsuit_info_id;
    }
  
    // Get the current history from AsyncStorage
    //let history = JSON.parse(await AsyncStorage.getItem('navigation_history')) || [];

  
    // Create a new navigation parameter object
    const newParam = {
      id: id1,
      lawsuit_info_id: id2,
      fin_id: item.financial_acc_id,
      HeaderTitle: item.title || item.text,
      timestamp: new Date().toISOString(), // Adding timestamp
      l_nb: item.l_nb,
      hasReminder: item.hasReminder,
      hasBalance: item.hasBalance
    }; 
    
    // If an object with the same id exists in the history, remove it
   // history = history.filter((historyItem) => historyItem.id !== id1);
  
    // If the history has 5 items, remove the oldest item
    //if (history.length === 5) {
     // history.shift();
   // }
  
    // Add the new navigation parameters to the history
    //history.push(newParam);
  
    // Save the updated history to AsyncStorage
    //await AsyncStorage.setItem('navigation_history', JSON.stringify(history));
  
    navigation.navigate('Screen1', newParam);
  };
   
  
  useEffect(() => {
    if (route.params?.shouldrefresh) {
      fetchDataFromServer();
    }
  }, [route.params]);

  /*

  useEffect(() => {
    fetchData();
  }, []);

  */

  useEffect(() => {
    setFilteredData(
      data.filter(item => {
        let filterProperty = item.text ? item.text : item.title;
        return filterProperty && filterProperty.toLowerCase().includes(searchQuery.toLowerCase());
      })
    );
  }, [searchQuery, data]);
  

  return (
    <View dir={'rtl'} style={styles.container}>
     {/* <View style={styles.searchContainer}>
       
        
        <TextInput
          value={searchQuery}
         // onChangeText={setSearchQuery}
          placeholder="بحث..."
          style={styles.searchInput}
        />

<Ionicons
          name="filter-outline"
          size={30}
          color="#000"
          style={styles.searchIcon}
          onPress={() => setIsActionSheetVisible(true)}
        />
  </View>*/}

      <FilterBar showReportBars={showReportBars} onClose={handleFilterBarClose} data={reportBarValues} />


      <LawsuitFilterModal
  isVisible={isActionSheetVisible}
  toggleVisibility={() => setIsActionSheetVisible(!isActionSheetVisible)}
  onSubmit={onActionSheetSubmit}
  showLawSuitePicker={false}
  showProcedureTypePicker={false}
  showReminderTypePicker={false}
  showDate={false}
  resetKey={resetKey} 

/>

<Modal
        transparent={false}
        visible={isAddNewLawsuitVisible}
        animationType="slide"
      >
<AddNewLawsuit />
  
  <TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setisAddNewLawsuitVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
        </Modal>

<FlashList
  data={filteredData}
  renderItem={renderItem}
  keyExtractor={(item) => (item.id && typeof item.id === 'string' ? item.id.split(',')[0] : item.id.toString())}
  estimatedItemSize={100}
/>
<SpeedDial
    isOpen={open}
    icon={<MaterialIcons name= "edit" color= "#fff" size={24}/>}  
    openIcon={{ name: 'close', color: '#fff' }}
    onOpen={() => setOpen(!open)}
    onClose={() => setOpen(!open)}
  >
   <SpeedDial.Action
      icon={{ name: 'add', color: '#fff' }}
      title="اضافة"
      onPress={()=>{navigation.navigate('AddNewLawsuit',{ existingLawsuitData: false }); setOpen(!open)}}
      />
    <SpeedDial.Action
      icon={{ name: 'filter-list', color: '#fff' }}
      title="تصفية"
      onPress={() => {setIsActionSheetVisible(true); setOpen(!open)}}
      />
  </SpeedDial>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'white',
    marginBottom: 10
  },
  searchIcon: {
    marginLeft: 10
  },
  searchInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    marginRight: 10,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    marginLeft: 10
  },
  lawsuiteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  lawsuiteTitle: {
    fontSize: 20
  },
  lawsuiteDate: {
    fontSize: 16,
    color: '#666'
  }
});

export default LawS;