import React, { useState, useEffect, useContext } from 'react';
import { FlatList, ActivityIndicator, TouchableOpacity, Modal, StyleSheet, Linking } from 'react-native';
import { View, Text, Image, Colors } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { fetchData } from '../../../utils/api';
import { SearchContext } from '../../../contexts/SearchContext';
import Styles from '../../../Styles/Styles';



const OppoListScreen = ({ navigation, All = false }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { searchQuery } = useContext(SearchContext);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        data.filter(item => {
          return item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.middle_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      ); 
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  useEffect(() => {
    fetchOpponentDetails();
  }, []);

  const fetchOpponentDetails = async () => {
    try {
      const opponents = await fetchData({method:'select_opponents', params :{}});
     
      const sortedData = opponents.sort((a, b) => b.id - a.id);
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCall = (item) => {
    Linking.openURL(`tel:${item}`);
  };

  const handleWhatsApp = (item) => {
    Linking.openURL(`https://wa.me/${item}?text=`);
  };

  const renderListItem = ({ item }) => (
    <TouchableOpacity 
      style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleContainer}
      onPress={() => navigation.navigate('OppoDetails', { item })}
      key={item.id.toString()}
    >
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleHeader}>
        <Image 
          source={{ uri: 'https://lh3.googleusercontent.com/-cw77lUnOvmI/AAAAAAAAAAI/AAAAAAAAAAA/WMNck32dKbc/s181-c/104220521160525129167.jpg' }}
          style={{ width: 50, height: 50, borderRadius: 25 }}
        />
        <View style={{ marginLeft: 10, flex: 1 }}>
          <Text grey10 text70BL>{item.first_name} {item.middle_name} {item.last_name}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={[Styles.LawSuiteDetailsClientOpponentStyles.value, { flex: 1 }]}>{item.mobile}</Text>
            {item.mobile &&
            <TouchableOpacity onPress={() => handleCall(item.mobile)}>
              <Ionicons name="call" size={24} color="blue" />
            </TouchableOpacity>
}
{item.mobile &&
            <TouchableOpacity onPress={() => handleWhatsApp(item.mobile)} style={{ marginLeft: 10 }}>
              <Ionicons name="logo-whatsapp" size={24} color="green" />
            </TouchableOpacity>
}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );


  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // Run the function when the screen is focused
      fetchOpponentDetails();
    });
  
    return unsubscribe;
  }, [navigation, fetchOpponentDetails]);



  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          data={filteredData}
          renderItem={renderListItem}
          keyExtractor={item => item.id.toString()}
        />
      )}
    </View>
  );
}

export default OppoListScreen;
