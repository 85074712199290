import React, { useEffect, useState, useCallback } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { FlatList, Text, View, StyleSheet, TouchableOpacity, Button } from 'react-native';
import { fetchData } from '../../../utils/api';
import BankModal from '../components/BankModal';
import { Ionicons } from '@expo/vector-icons';


const Stack = createStackNavigator();

function MainScreen({ navigation, modalVisible }) {
  const [banks, setBanks] = useState([]);
  const isFocused = useIsFocused();

  const fetchBanks = useCallback(async () => {
    try {
      const data = await fetchData({ method: 'select_banks', params: {} });
      setBanks(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchBanks();
    }
  }, [isFocused, fetchBanks]);

  useEffect(() => {
    if (!modalVisible && isFocused) {
      fetchBanks();
    }
  }, [modalVisible, isFocused, fetchBanks]);

  return (
    <View style={styles.container}>
      <FlatList
        data={banks}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => {
              navigation.navigate('Second', { bankId: item.id });
            }}
          >
            <Text style={styles.item}>{item.text}</Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}


function SecondScreen({ route, navigation }) {
  const { bankId } = route.params;
  const [bank, setBank] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchBank = async () => {
    try {
      const data = await fetchData({ method: 'fetch_bank', params: { id: bankId } });
      setBank(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching bank:', error);
    }
  };

  useEffect(() => {
    fetchBank();
  }, [bankId]);

  const handleEdit = () => {
    setModalVisible(true);
  };

  const handleSubmit = async (bankDetails) => {
    const url = { method: 'update_bank', params: { ...bankDetails, id: bankId } };

    try {
      const response = await fetchData(url);

      if (response === 1) {
        setModalVisible(false);
        fetchBank(); // Refresh bank details
       // navigation.navigate('Main'); // Navigate back to the bank list
      } else {
        console.error('Failed to update bank');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!bank) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.item}>اسم البنك: {bank.title}</Text>
      <Text style={styles.subItem}>الفرع: {bank.branch}</Text>
      <Text style={styles.subItem}>العنوان: {bank.address}</Text>
      <Text style={styles.subItem}>رقن الهاتف: {bank.phone}</Text>
     
      <TouchableOpacity onPress={handleEdit}>
          <Ionicons name="create" size={24} color="red" />
        </TouchableOpacity>


      <BankModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={(details) => handleSubmit({ ...details, name: details.title })}
        initialData={bank}
      />
    </View>
  );
}


function BanksNavigator({ modalVisible }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Main">
        {(props) => <MainScreen {...props} modalVisible={modalVisible} />}
      </Stack.Screen>
      <Stack.Screen name="Second" component={SecondScreen} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop:10,
    //justifyContent: 'center',
    // alignItems: 'center',
    // padding: 16,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  item: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subItem: {
    fontSize: 14,
    color: '#555',
  },
});

export default BanksNavigator;
