import React, { useEffect, useState, useCallback } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Text, View, StyleSheet, TouchableOpacity, Button } from 'react-native';
import { fetchData } from '../../../utils/api';
import LawyerModal from '../../../src/OfficeSettings/components/LawyerModal';
import { Ionicons } from '@expo/vector-icons';



const Tab = createMaterialTopTabNavigator(); // Define the Tab navigator
const Stack = createStackNavigator();


function DetailsTab({ route, navigation }) {
  const { lawyer } = route.params;
  const [modalVisible, setModalVisible] = useState(false);
  const [currentLawyer, setCurrentLawyer] = useState(lawyer);

  const handleEdit = () => {
    setModalVisible(true);
  };

  const fetchLawyerDetails = async () => {
    try {
      const updatedLawyer = await fetchData({ method: 'select_user', params: { id: lawyer.id } });
      setCurrentLawyer(updatedLawyer);
    } catch (error) {
      console.error('Error fetching lawyer details:', error);
    }
  };

  const handleSubmit = async (lawyerDetails) => {
    const url = { method: 'update_user', params: { ...lawyerDetails, id: lawyer.id } };

    try {
      const response = await fetchData(url);

      if (response) {
        setModalVisible(false);
        await fetchLawyerDetails(); // Refresh the lawyer details
      } else {
        console.error('Failed to update lawyer');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
       
        <TouchableOpacity onPress={handleEdit}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
      <Text style={styles.item}>الاسم: {currentLawyer.first_name} {currentLawyer.middle_name} {currentLawyer.last_name}</Text>
      <Text style={styles.subItem}>البريد الإلكتروني: {currentLawyer.email}</Text>
      <Text style={styles.subItem}>رقم الجوال: {currentLawyer.mobile}</Text>
      <Text style={styles.subItem}>تاريخ الميلاد: {currentLawyer.dob}</Text>
      <Text style={styles.subItem}>العنوان: {currentLawyer.address}</Text>
      <Text style={styles.subItem}>تاريخ الانضمام: {currentLawyer.date_of_affiliation}</Text>
      <Text style={styles.subItem}>المستوى التعليمي: {currentLawyer.level_of_education}</Text>
      <Text style={styles.subItem}>رقم التسجيل: {currentLawyer.register_number}</Text>
      <Text style={styles.subItem}>مكان التسجيل: {currentLawyer.register_place}</Text>

      <LawyerModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleSubmit}
        initialData={currentLawyer}
      />
    </View>
  );
}

function LawsuitsTab({ route }) {
  const { lawyer } = route.params;
  const [lawsuits, setLawsuits] = useState([]);

  useEffect(() => {
    const fetchLawsuits = async () => {
      try {
        const data = await fetchData({ method: 'select_lawsuits', params: { user_id: lawyer.id } });
        setLawsuits(data);
        // console.log(data);
      } catch (error) {
        // console.error("Error fetching lawsuits:", error);
      }
    };

    fetchLawsuits();
  }, [lawyer.id]);

  return (
    <View style={styles.container}>
      <FlatList
        data={lawsuits}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.item}>Lawsuit ID: {item.id}</Text>
            <Text style={styles.subItem}>Title: {item.title}</Text>
            <Text style={styles.subItem}>Status: {item.status}</Text>
            <Text style={styles.subItem}>Date: {item.date}</Text>
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function ProceduresTab({ route }) {
  const { lawyer } = route.params;
  const [procedures, setProcedures] = useState([]);

  useEffect(() => {
    const fetchProcedures = async () => {
      try {
        const data = await fetchData({ method: 'select_procedures', params: { user_id: lawyer.id } });
        setProcedures(data);
        // console.log(data);
      } catch (error) {
        // console.error("Error fetching procedures:", error);
      }
    };

    fetchProcedures();
  }, [lawyer.id]);

  return (
    <View style={styles.container}>
      <FlatList
        data={procedures}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.item}>Procedure ID: {item.id}</Text>
            <Text style={styles.subItem}>Description: {item.description}</Text>
            <Text style={styles.subItem}>Date: {item.date}</Text>
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function SecondScreen({ route }) {
  const { lawyerId } = route.params;
  const [lawyer, setLawyer] = useState(null);

  useEffect(() => {
    console.log(lawyerId);
    const fetchLawyerDetails = async () => {
      try {
        const data = await fetchData({ method: 'select_user', params: { id: lawyerId } });
        setLawyer(data);
        // console.log(data);
      } catch (error) {
        // console.error("Error fetching lawyer details:", error);
      }
    };

    fetchLawyerDetails();
  }, [lawyerId]);

  if (!lawyer) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <Tab.Navigator
    screenOptions={{
      lazy: true,
      tabBarIndicatorStyle: { backgroundColor: 'red' },
      tabBarStyle: { backgroundColor: 'gray' },
      tabBarLabelStyle:{color:'white'}
    }}
  >
      <Tab.Screen name="معلومات المحامي">
        {props => <DetailsTab {...props} route={{ ...props.route, params: { lawyer } }} />}
      </Tab.Screen>
      <Tab.Screen name="دعاوى المحامي">
        {props => <LawsuitsTab {...props} route={{ ...props.route, params: { lawyer } }} />}
      </Tab.Screen>
      <Tab.Screen name="اجراءات المحامي">
        {props => <ProceduresTab {...props} route={{ ...props.route, params: { lawyer } }} />}
      </Tab.Screen>
    </Tab.Navigator>
  );
}

function MainScreen({ navigation, modalVisible }) {
  const [lawyers, setLawyers] = useState([]);
  const isFocused = useIsFocused();


  const fetchLawyers = useCallback(async () => {
    try {
      const data = await fetchData({ method: 'select_users', params: { select2: true } });
      setLawyers(data);
      // console.log(data);
    } catch (error) {
      // console.error("Error fetching lawyers:", error);
    }
  }, []);

  useEffect(() => {
    fetchLawyers();
  }, [fetchLawyers]);

  useEffect(() => {
    if (!modalVisible && isFocused) {
      fetchLawyers();
    }
  }, [modalVisible, isFocused, fetchLawyers]);

  return (
    <View style={styles.container}>
      <FlatList
        data={lawyers}
        renderItem={({ item }) => (
          <TouchableOpacity 
            style={styles.itemContainer} 
            onPress={() => navigation.navigate('Second', { lawyerId: item.id })}
          >
            <Text style={styles.item}>{item.text}</Text>
          
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function LawyersNavigator({ modalVisible }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false, lazy: true }}>
      <Stack.Screen name="Main">
        {(props) => <MainScreen {...props} modalVisible={modalVisible} />}
      </Stack.Screen>
      <Stack.Screen name="Second" component={SecondScreen} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
   // justifyContent: 'center',
    // alignItems: 'center',
    // padding: 16,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  item: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 8,
  },
  subItem: {
    fontSize: 16,
    marginVertical: 4,
  },
});

export default LawyersNavigator;
