import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, Modal, Alert } from 'react-native';
import { fetchData, DELETE_ITEM_API, getToken, encodeImagesWithMimeType, executePostRequest } from '../../../utils/api';
import { Drawer, Colors, Chip, Spacings, View, Text } from 'react-native-ui-lib'; // import other necessary components
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../../../Styles/Styles';
import { Ionicons } from '@expo/vector-icons';
import ProcedureModal from '../../Procedures/components/ProcedureModal';
import ReminderEdit from '../../Calendar/components/ReminderEdit';
import ImageCarouselModal from '../../../SharedComponents/ImageCarouselModal';
import AddReminderModal from '../../Calendar/components/AddReminderModal';
import { ScrollView, GestureHandlerRootView } from 'react-native-gesture-handler';
import { useIndicator } from '../../../contexts/IndicatorContext';



const LSRemindersScreen = ({ route, navigation }) => {
  const [reminders, setReminders] = useState([]);
  const [displayReminders, setDisplayReminders] = useState([]);  // New state
  const [reminderTypes, setReminderTypes] = useState([]);
  const [error, setError] = useState(null);
  const { id, lawsuit_info_id } = route.params;
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility
const[refKey,setrefKey] = useState(0);
const [fileLinks, setFileLinks] = useState([]);
const [ReminderModalVisible, setReminderModalVisible] = useState(false);
const [fromItem, setFromItem] = useState(false); // or true, as required
const [ItemDate, setItemDate] = useState(''); // or another initial date value
const { showLoading, showSuccess, showError } = useIndicator();



const [activeItem, setActiveItem] = useState(null);  // State to track the active item
const [ProcedureModalVisible, setProcedureModalVisible] = useState(false);
const[IsEditModalVisible,setIsEditModalVisible]=useState('');
const [selectedItem, setSelectedItem] = useState(null);

const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false);

const toggleCarouselModal = (files) => {
  ////console.log("Toggling carousel modal"); // Add this
  setIsCarouselModalVisible(!isCarouselModalVisible);
  if (files && files.length > 0) {
    fetchFileLinks(files);
  }
};

/**
   * Displays a confirmation dialog for deleting the item with the given ID.
   * If the user confirms the deletion, deletes the item from the API and updates the state.
   * @param {number} id - The ID of the item to delete.
   */
const showDeleteConfirmation = (id) => {
  Alert.alert(
    "حذف اجراء", // Title
    "هل انت متأكد من رغبتك في حذف هذا الاجراء؟", // Message
    [
      {
        text: "كلا",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel"
      },
      {
        text: "نعم",
        onPress: () => deleteItem(id)
      }
    ],
    { cancelable: false } // This makes it so the user has to select one of the options
  );
};

const deleteItem = async (id) => {
  const onSuccess = async (responseData) => {
    try {
        // Update reminders state to reflect the deleted item
        const updatedReminders = reminders.filter(item => item.id !== id);
        setReminders(updatedReminders);
    } catch (error) {
        console.error('Error in onSuccess:', error);
    } finally {
        setrefKey(prevKey => prevKey + 1);
    }
};
 

  const onFailure = (errorText) => {
      Alert.alert('Error', 'Failed to delete item');
  };

  try {
    //  setIsLoadingModalVisible(true);
      const responseData = await DELETE_ITEM_API('delete_reminder', { id });
      onSuccess(responseData);
  } catch (error) {
      onFailure(error.message);
  } finally {
     // setIsLoadingModalVisible(false);
  }
};



  const fetchReminders = async () => {
    try {
      const data = await fetchData({
        method: "select_grouped_reminders",
        //params: { group: true, lawsuit_id: id, lawsuit_info_id: lawsuit_info_id }
        params:{
          lawsuit_id: id,
          status: '1'
            }
          });
      console.log('DATA: ',data);
      setDisplayReminders(data);
    } catch (err) {
      console.error("Failed to fetch reminders:", err);
      setError(err);
    }
  };

  const fetchReminderTypesFromStorage = async () => {
    try {
      const json = await AsyncStorage.getItem('select_reminder_types');
      //console.log('Types from AsyncStorage:', json);
      
      if (json !== null) {
        const typesData = JSON.parse(json);
        //console.log('Parsed types data:', typesData);
        
        // Since typesData is already an array, you can set it directly
        setReminderTypes([...typesData]);
      } else {
        //console.log('No data found in AsyncStorage for reminder types');
        setReminderTypes([]);
      }
    } catch (error) {
      console.error('Error fetching reminder types from AsyncStorage:', error);
      setReminderTypes([]);
    }
  };

  useEffect(() => {
    fetchReminders(); // Call fetchReminders when component mounts
   fetchReminderTypesFromStorage();
   // console.log(reminders);
  }, [route.params.id, route.params.lawsuit_info_id]);

  const handleItemUpdate = (updatedItem, deletedIds = [], addedUris = []) => {
    if (!updatedItem) {
        console.error('No updated item provided.');
        return;
    }

    setDisplayReminders(currentItems => {
        if (typeof currentItems !== 'object' || currentItems === null) {
            console.error('currentItems is not an object:', currentItems);
            return {}; // Initialize with an empty object or handle as needed
        }

        // Loop through each date key in currentItems
        Object.keys(currentItems).forEach(date => {
            // Find the index of the item to be updated in the array for the current date
            const itemIndex = currentItems[date].findIndex(item => item.id === updatedItem.id);

            // If the item is found, update it
            if (itemIndex !== -1) {
                // If there are deleted IDs, filter out the deleted files from the updatedItem
                if (deletedIds.length > 0 && updatedItem.files) {
                    updatedItem.files = updatedItem.files.filter(fileId => !deletedIds.includes(fileId));
                }

                // Update the item at the found index for the current date
                currentItems[date][itemIndex] = { ...currentItems[date][itemIndex], ...updatedItem };
            }
        });

        // Return a new object with the updated items
        return { ...currentItems };
    });

    setrefKey(prevKey => prevKey + 1);
};


// Modified handleModalClose function
const handleModalClose = async (newRecord) => {
  try {
    const processedRecord = await executePost(newRecord);
    addToLocalArray(processedRecord);
  } catch (error) {
    // Error handling if needed
  }
};
// Function to execute the POST request
const executePost = async (newRecord) => {
  let imagesBase64 = [];

  // Encode images if they exist
  if (newRecord.files && newRecord.files.length > 0) {
    imagesBase64 = await encodeImagesWithMimeType(newRecord.files);
  }

  // Prepare the body for the POST request
  const body = {
    method: 'insert_reminder',
    params: {
      ...newRecord,
      id: '',
      files: imagesBase64,
    }
  };

  try {
    const response = await executePostRequest(body);
    //console.log('Post request response:', response);
    showSuccess();
    return newRecord; // Return newRecord for further processing
  } catch (error) {
    console.error('Error in post request:', error);
    showError();
    throw error; // Re-throw error to handle it in the calling function
  }
};





 
 /*
useEffect(() => {
  if (reminders.length && reminderTypes.length) {
    console.log('REMINDERS', reminders);
    const updatedReminders = reminders.map(reminder => {
      // Find the matching type object
      const matchingType = reminderTypes.find(type => type.id === reminder.type);

      // If a matching type is found, update the reminder
      if (matchingType) {
        return {
          ...reminder,
          type: matchingType.text,     // Set the type field to the matching type's text
          type_ID: reminder.type       // Add a new field type_ID to store the original type id
        };
      }

      // If no matching type is found, return the reminder as is
      return reminder;
    });
    
    setDisplayReminders(updatedReminders);  // Update displayReminders state
    console.log(displayReminders);
  }
}, [reminders, reminderTypes]);

useEffect(() => {
  console.log('Reminders State:', reminders);
}, [reminders]);

*/
const handleRemiderDone = async(item) =>{
  setActiveItem(item); // Set the active item
  setProcedureModalVisible(true); // Show the modal
};


/*
const onProcedureModalSubmit = async () => {
  try {
    console.log(activeItem);
      // Calling the DELETE_ITEM_API with the required parameters
      await DELETE_ITEM_API('update_reminder', {
          id: activeItem.id,
          description: activeItem.description,
          type: activeItem.type,
          deadline: activeItem.deadline,
          status: 0
      });

      // Update state to remove the item if the API call is successful
     
      const updatedReminders = reminders.filter(item => item.id !== activeItem.id);
      setReminders(updatedReminders);  
    } catch (error) {
      console.error('Error during API call:', error);
      // Handle error as needed
  }
};
*/

const onProcedureModalSubmit =  async (newDataItem, newImageUris) => {
  console.log(activeItem);
  try {
      // Calling the DELETE_ITEM_API with the required parameters
      await DELETE_ITEM_API('update_reminder', {
          id: activeItem.id,
          description: activeItem.description,
          type: activeItem.type,
          deadline: activeItem.deadline, 
          status: 0
      }); 

      // Update state to remove the item if the API call is successful
     
      const updatedReminders = reminders.filter(item => item.id !== activeItem.id);
      setReminders(updatedReminders);  
    } catch (error) {
      console.error('Error during API call:', error);
      // Handle error as needed
  }
const imagesBase64 = await encodeImagesWithMimeType(newImageUris);
const updatedDataItem = {
  ...newDataItem,
  id: '',
  files: imagesBase64
};

// Prepare the body for the POST request
const body = {
    method: 'insert_procedure',
    params: updatedDataItem
};

// Execute the POST request and handle the response
try {
  const responseJson = await executePostRequest(body);

  if (responseJson && responseJson.id) {
      // If the POST request is successful, show the alert
      showAlert();
  } else {
      console.error('Upload failed');
      // showError();
  }

} catch (error) {
    console.error('Error uploading files:', error);
}
};

const showAlert = () => {
  Alert.alert(
      "تم الحفظ",
      "لقد تم تسجيل انجاز الاستحقاق بنجاح. هل ترغب بإضافة استحقاق اضافي؟",
      [
          {
              text: "كلا",
              onPress: () => console.log("User does not want to perform another action"),
              style: "cancel"
          },
          { 
              text: "نعم", onPress: () => {
                setReminderModalVisible(true); setFromItem(false);
                  // You can navigate to another screen or trigger another action here
              } 
          }
      ]
  );
};

const handleItemPress = async (item) => {
  if (item.files && item.files.length > 0) {
    // Wait for fetchFileLinks to finish before continuing
    await fetchFileLinks(item.files);
  }
  setSelectedItem(item);
  setIsEditModalVisible(true);
  console.log(selectedItem);
};

const fetchFileLink = async (fileId) => {
  // Get the user token
  const token = await getToken();

  // Make an API call to fetch the file link
  const response = await fetch('https://maktabi.info/includes/model.php', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      method: 'select_file',
      params: {
        id: fileId
      }
    }),
  });

  const responseData = await response.json();

  // Checking if responseData is a valid data URI
  if (typeof responseData === 'string' && responseData.startsWith('data:')) {
    return responseData;
  } else {
    console.error(`Error fetching file with ID ${fileId}: Invalid data URI format in the response.`);
    return null;
  }
};

// Function to fetch all file links for a given array of file IDs
const fetchFileLinks = async (fileIds) => {
  const links = await Promise.all(fileIds.map(id => fetchFileLink(id)));
  setFileLinks(links.filter(link => link !== null && link !== undefined));
  // Set imagesLoading to false once all file links have been fetched
  //setImagesLoading(false);
};



  const renderItem = ({item}) => {
    return (
      <View key={item.id.toString()}>
        <Drawer
      rightItems={[{ text: 'تعديل', background: Colors.blue30, onPress: () => handleItemPress(item)}, { text: 'انجاز', background: Colors.green10, onPress: () => handleRemiderDone(item)}]}
      leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
        >
          <View centerV bg-white style={{ padding: 10, flexDirection: 'row',  borderBottomColor: '#ccc', borderBottomWidth: 1 }}>
            <View style={styles.dateBox}>
              <Text style={styles.dateText}><Text style={styles.dateText}>{item.deadline.split(' ')[0]}</Text>
              </Text>
            </View>
            <View style={styles.descBox}>
              <Text style={styles.descText}>{item.description}</Text>
              </View>
              <View style={{justifyContent:'flex-end', flexDirection: 'row'}}>
              
                {item.files && item.files.length > 0 && (
            <View style={styles.circle}>
            <Ionicons
              name="attach"
              size={20}
              color="white"
             onPress={() => toggleCarouselModal(item.files || item.file_id)}
            />
          </View>
          )}

<Chip
                label={item.type_name}
                // iconSource={checkmark}
                borderRadius={22}
                containerStyle={{
                  borderColor: Colors.red20,
                  borderBottomLeftRadius: 0,
                  // marginLeft: Spacings.s3
                  alignSelf:'flex-start',
                  marginLeft:5
                 
                }}
                onPress={() => console.log('pressed')} />

              </View>
           
          </View>
        </Drawer>
      </View>
    
      
    );
  };

  const remindersArray = Object.keys(displayReminders).flatMap(date => {
    return displayReminders[date].map(reminder => ({ ...reminder, date }));
  });
  remindersArray.sort((a, b) => new Date(a.date) - new Date(b.date));


  const LawSuiteDetailsRemindersView = () => {
    return (
      <View style={{flex: 1, padding: 10}}>
      {remindersArray.map((reminder, index) => (
        renderItem({ item: reminder, index })
      ))}
    </View>
    
    );
  }
  

  return (
    <View style={{backgroundColor:'white', marginRight: 10, marginLeft:10}}>
    <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
      <Ionicons name="expand-outline" size={18} color="black" style={{marginTop:5, marginLeft:5}} />
    </TouchableOpacity>

                  {/* Step 5: Add a Modal component to your JSX */}
                  <Modal
                    transparent={false}
                    visible={isModalVisible}
                    animationType="slide"
                  >
                    <GestureHandlerRootView style={{ flex: 1 }}>

                    <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                      <View>
                        <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>قائمة الاستحقاقات </Text>

                    </View>
                    <ScrollView style={styles.container}>

                      <LawSuiteDetailsRemindersView />
                      </ScrollView>
                      <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.closeButton} onPress={() => setIsModalVisible(false)}>
                        <Text style={Styles.LawSuiteDetailsClientOpponentStyles.closeButtonText}>Close</Text>
                      </TouchableOpacity>
                    </View>
                    </GestureHandlerRootView>

                  </Modal>

                 
                  <LawSuiteDetailsRemindersView key={refKey} />

{ProcedureModalVisible &&
      <ProcedureModal
        modalVisible={ProcedureModalVisible}
        setModalVisible={setProcedureModalVisible}
        route={null}
        onModalSubmit={onProcedureModalSubmit}
        prefillData={activeItem ? {
          description: activeItem.description,
          type: activeItem.type_ID,
          lawsuit_id: activeItem.lawsuit_id,
          lawsuit_info_id: activeItem.lawsuit_info_id
        } : {}}
      />
      }
{IsEditModalVisible && <ReminderEdit
  key={selectedItem.id}

      isVisible={IsEditModalVisible} 
     onClose={() => setIsEditModalVisible(false)} 
      onEntryUpdated={(selectedID, value, selectedTypeText, combinedDateTime, deletedIds, addedUris) => {
        const updatedItem = { ...selectedItem, description: value, deadline: combinedDateTime, type: selectedTypeText  };
        handleItemUpdate(updatedItem, deletedIds, addedUris);
      }}   
      initialValue={selectedItem.description}
      initialType={selectedItem.type}
      initialDate={new Date(selectedItem.deadline)}
      initialTime={new Date(selectedItem.deadline)}
      TypeValues={reminderTypes}
      initialID={selectedItem.id}
      initialFiles={selectedItem.files}
            
    />}

{ReminderModalVisible &&
      <AddReminderModal
        key={Date.now()}
        modalVisible={ReminderModalVisible}
        setModalVisible={setReminderModalVisible}
        // addNewReminderHandler={addNewReminderHandler}
        route={route}
        fromItem={fromItem}
        itemDate={ItemDate}
        onClose={handleModalClose}


      //types={types} 
      //lawsuits={lawsuits} 
      />
}

{isCarouselModalVisible &&
      <Modal
        transparent={false}
        visible={isCarouselModalVisible}
        animationType="slide"
      >

        <ImageCarouselModal
          fileLinks={fileLinks}
        // onClose={() => setIsCarouselModalVisible(false)}
        />
        

        <TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setIsCarouselModalVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
  </TouchableOpacity>
      </Modal>
}

                </View>

 
     
     
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f7f7f7',
  },
  listItem: {
    flexDirection: 'row',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  dateBox: {
    //flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 10,
   borderRightWidth: 0.5,
    borderRightColor: '#e0e0e0',
    //width: '50'
  },
  dateText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  descBox: {
    flex: 1,
    paddingLeft: 10,
    justifyContent: 'center',   // This will push the content to the end (bottom in column direction)
    alignItems: 'flex-start',       // This will push the content to the end (right in row direction)
   // flexDirection: 'row'
   //borderWidth:2
  },
  
  descText: {
    fontSize: 14,
    //alignSelf: 'flex-end'
  },
  typeText: {
    fontSize: 12,
    alignSelf: 'flex-end',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginTop: 20,
  },
  circle: {
    height: 25, // Set the height
    width: 25, // Set the width
    borderRadius: 25, // Half the size of the height and width to make it a circle
    backgroundColor: 'green', // Set your desired background color
    justifyContent: 'center', // Center the icon vertically
    alignItems: 'center', // Center the icon horizontally
    marginLeft:10
  }
});

export default LSRemindersScreen;
