import React, { useState, useEffect, useRef } from 'react';
import { TouchableOpacity, Modal, TextInput, ScrollView, StyleSheet } from 'react-native';
import { Text, View } from 'react-native-ui-lib';
import Styles from '../../../Styles/Styles';
import { TextField } from 'react-native-ui-lib';
import PhoneInput from 'react-native-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';



const EditOppoModal = ({ isVisible, onClose, oppoData, onSave }) => {
    const [editData, setEditData] = useState(oppoData);
    const phoneInput = useRef(null);


    
    function getInternationalCode(phoneNumber) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      return parsedNumber ? parsedNumber.countryCallingCode : '';
  }
  
  function removeInternationalCode(phoneNumber) {
      const dialCode = getInternationalCode(phoneNumber);
      if (phoneNumber.startsWith(`+${dialCode}`)) {
          return phoneNumber.substring(dialCode.length + 1);
      }
      return phoneNumber;
  }
  
    useEffect(() => {
        setEditData(oppoData); // Update local state when clientData changes
    }, [oppoData]);

    const handleInputChange = (fieldName, value) => {
        setEditData({ ...editData, [fieldName]: value });
    };

    return (
        <Modal
            transparent={false}
            visible={isVisible}
            onRequestClose={onClose}
            animationType="slide"
        >


<View style={{ flex: 1}}>
                {/* Header */}
                <View style={{ padding: 10, backgroundColor: '#f8f8f8', borderBottomWidth: 1, borderColor: '#e7e7e7' }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>تعديل معلومات خصم</Text>
                </View>

                {/* Scrollable Content */}
                <ScrollView style={{ flex: 1 }}>



                <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الاسم الاول"
                value={editData.first_name}
                onChangeText={(text) => handleInputChange('first_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الشهرة"
                value={editData.last_name}
                onChangeText={(text) => handleInputChange('last_name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الاب"
                value={editData.middle_name}
                onChangeText={(text) => handleInputChange('middle_name', text)}
            />


          
<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الام وشهرتها"
                value={editData.mother_name}
                onChangeText={(text) => handleInputChange('mother_name', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="محل الولادة"
                value={editData.register_place}
                onChangeText={(text) => handleInputChange('register_place', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="تاريخ الولادة"
                value={editData.dob}
                onChangeText={(text) => handleInputChange('dob', text)}
            /> 

<TextField 
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="رقم السجل"
                value={editData.register_number}
                onChangeText={(text) => handleInputChange('register_number', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="العنوان"
                value={editData.address}
                onChangeText={(text) => handleInputChange('address', text)}
            />

<View>
<Text style={{ margin:10, marginTop: 30, fontSize:12, color: 'grey'}}>رقم الهاتف</Text>

<PhoneInput
                            ref={phoneInput}
                            defaultValue={removeInternationalCode(editData.mobile)}
                            defaultCode="LB"
                            layout="first"
                            placeholder="ادخل رقم الهاتف"
                            textContainerStyle={[styles.textContainer, {
                               // borderColor: mobileValidationStatus ? 'green' : 'red',
                                direction: 'rtl',
                            }]}
                            containerStyle={{
                                direction: 'rtl'
                            }}
                            onChangeFormattedText={(text) => {
                                const { number, formattedNumber } = getNumberAfterPossiblyEliminatingZero(text);
   
                                
                                handleInputChange('mobile', formattedNumber)
                            }}
                            
                        />


</View>



<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="البريد الالكتروني"
                value={editData.email}
                onChangeText={(text) => handleInputChange('email', text)}
            />
            

{/*

                    <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الاسم الأول</Text>
   </View>
  
   <TextInput
   
  value= {editData.first_name}
  onChangeText={(text) => handleInputChange('first_name', text)}
  containerStyle={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />


 </View>


 
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأب</Text>
   </View>
  
  
   <TextInput
  value= {editData.middle_name}
  onChangeText={(text) => handleInputChange('middle_name', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />


 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الشهرة</Text>
   </View>
   
   
   <TextInput
  value= {editData.last_name}
  onChangeText={(text) => handleInputChange('last_name', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />



 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأم</Text>
   </View>
   
   
   <TextInput
  value= {editData.mother_name}
  onChangeText={(text) => handleInputChange('mother_name', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />


 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>محل الولادة</Text>
   </View>
   
   
   <TextInput
  value= {editData.register_place}
  onChangeText={(text) => handleInputChange('register_place', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />




 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم السجل</Text>
   </View>
   
   

   <TextInput
  value= {editData.register_number}
  onChangeText={(text) => handleInputChange('register_number', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />



 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ الولادة</Text>
   </View>
  
  

   <TextInput
  value= {editData.dob}
  onChangeText={(text) => handleInputChange('dob', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />



 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الجوال</Text>
   </View>
   
   

   <TextInput
  value= {editData.mobile}
  onChangeText={(text) => handleInputChange('mobile', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />





 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>البريد الالكتروني</Text>
   </View>
  
  
   <TextInput
  value= {editData.email}
  onChangeText={(text) => handleInputChange('email', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />





 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>العنوان</Text>
   </View>
  
  
   <TextInput
  value= {editData.address}
  onChangeText={(text) => handleInputChange('address', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />




 </View>
 <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { paddingRight: 15 }]}>
   <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
     <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>ملاحظات</Text>
   </View>
 
 

   <TextInput
  value= {editData.notes}
  onChangeText={(text) => handleInputChange('notes', text)}
   style={Styles.LawSuiteDetailsClientOpponentStyles.inputvalue}
   />
</View>

                          */}








</ScrollView>

 {/* Footer */}
 <View style={{ flexDirection: 'row', justifyContent: 'space-around', padding: 10, backgroundColor: '#f8f8f8', borderTopWidth: 1, borderColor: '#e7e7e7' }}>

<TouchableOpacity onPress={onClose}>
    <Text style={{ fontSize: 16, color: '#007bff' }}>الغاء</Text>
</TouchableOpacity>

<TouchableOpacity onPress={() => onSave(editData)}>
    <Text style={{ fontSize: 16, color: '#007bff' }}>حفظ</Text>
</TouchableOpacity>
</View>


</View>

        </Modal>

    )
};


const styles = StyleSheet.create({
  input: {
      borderBottomWidth: 1,
      borderColor: '#ddd',
      padding: 10,
      borderRadius: 5,
      width: '100%',
      marginBottom: 15,
      marginTop: 15
  },
  inputRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 10, // Adjust as needed
  },
  textInput: {
      flex: 1,
      borderWidth: 1,
      borderColor: 'grey',
      marginRight: 10, // Space between text input and button
      paddingHorizontal: 10, // Inner spacing of text input
      height: 40, // Adjust as needed
  },
  iconButton: {
      // Style for the file select icon button
      padding: 10, // Adjust as needed
  },
  addButton: {
      // Style for the add new procuration button
      backgroundColor: '#007bff', // Example color
      padding: 10,
      alignItems: 'center',
  },
  addButtonText: {
      color: 'white', // Example text color
      fontSize: 16,
  },
  magnifierIcon: {
      position: 'absolute',
      right: 10,
      bottom: 10,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: 15,
      padding: 5,
  },
  modalView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
      //flex: 1,
      margin: 10,
      padding: 10
  },
  closeButton: {
      position: 'absolute',
      top: 20,
      right: 20,
  },
  imageContainer: {
      position: 'relative', // Required for absolute positioning of children
      width: '100%',
      height: 100,
      marginBottom: 15,
  },
  imageOverlay: {
      position: 'absolute', // Overlay takes the entire container space
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background
      justifyContent: 'center',
      alignItems: 'center',
  },
  editIcon: {
      position: 'absolute',
      top: 5,   // Adjust top and right values as needed
      right: 5,
      // Add additional styling if needed
  },

});
export default EditOppoModal;