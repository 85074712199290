import React from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { Text } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import Styles from '../../../Styles/Styles';
import LawsuitDetailsCLientView from '../../Lawsuits/components/LawsuitDetailsCLientView';


const ClientDetailsScreen = ({ route, navigation }) => {
  const { item } = route.params;


  return (
    <ScrollView style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
      
      <LawsuitDetailsCLientView item={item} />

      
    </ScrollView>
  );
};

export default ClientDetailsScreen;
