import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { GiftedChat } from 'react-native-gifted-chat';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Bubble } from 'react-native-gifted-chat';
import { Alert } from 'react-native';
import { Button } from 'react-native';
import { TouchableOpacity } from 'react-native';



const AssistantAI = () => {
 const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
   




    const clearChatHistory = async () => {
        try {
            await AsyncStorage.removeItem('@chat_history'); // Remove chat history from storage
            setMessages([
               
                {
                    _id: 1,
                    text: 'ما هو نص المادة 183 من قانون الموجبات والعقود اللبناني',
                    createdAt: new Date(),
                    user: {
                        _id: 2,
                        name: 'Suggestions',
                    },
                    quickReply: true,
                },
                {
                    _id: 2,
                    text: 'اعطني 3 اجتهادات لمحاكم لبنانية حول المادة 583 من قانون العقوبات',
                    createdAt: new Date(),
                    user: {
                        _id: 2,
                        name: 'Suggestions',
                    },
                    quickReply: true,
                },
                {
                    _id: 3,
                    text: 'ما هي النصوص القانونية الخاصة بإخلاء المأجور في القوانين اللبنانية',
                    createdAt: new Date(),
                    user: {
                        _id: 2,
                        name: 'Suggestions',
                    },
                    quickReply: true,
                },
                {
                    _id: 4,
                    text: "مرحبًا بك! أنا مساعدك المدعوم بالذكاء الاصطناعي! إسألني أي شيء او اختر من الأمثلة أدناه",
                    createdAt: new Date(),
                    user: {
                        _id: 2,
                        name: 'Assistant',
                    },
                    system: true,
                }
            ]);        } catch (e) {
            console.error("Error clearing chat history:", e);
        }
    };
    
    
    const onSuggestionTap = (messageText) => {
        askGPT(messageText); // Use your existing function to handle sending a message
    };


const promptClearChatHistory = () => {
    Alert.alert(
        "Clear Chat History",
        "Are you sure you want to clear the chat history?",
        [
            { text: "Cancel", style: "cancel" },
            { text: "Yes", onPress: clearChatHistory }
        ],
        { cancelable: false }
    );
};

    
useEffect(() => {
    const fetchMessages = async () => {
        try {
            const storedMessages = await AsyncStorage.getItem('@chat_history');
            if (storedMessages === null) {
                // No stored chat history, set default suggestion messages along with introductory message
                setMessages([
                   
                    {
                        _id: 1,
                        text: 'ما هو نص المادة 183 من قانون الموجبات والعقود اللبناني',
                        createdAt: new Date(),
                        user: {
                            _id: 2,
                            name: 'Suggestions',
                        },
                        quickReply: true,
                    },
                    {
                        _id: 2,
                        text: 'اعطني 3 اجتهادات لمحاكم لبنانية حول المادة 583 من قانون العقوبات',
                        createdAt: new Date(),
                        user: {
                            _id: 2,
                            name: 'Suggestions',
                        },
                        quickReply: true,
                    },
                    {
                        _id: 3,
                        text: 'ما هي النصوص القانونية الخاصة بإخلاء المأجور في القوانين اللبنانية',
                        createdAt: new Date(),
                        user: {
                            _id: 2,
                            name: 'Suggestions',
                        },
                        quickReply: true,
                    },
                    {
                        _id: 4,
                        text: "مرحبًا بك! أنا مساعدك المدعوم بالذكاء الاصطناعي! إسألني أي شيء او اختر من الأمثلة أدناه",
                        createdAt: new Date(),
                        user: {
                            _id: 2,
                            name: 'Assistant',
                        },
                        system: true,
                    }
                ]);
                
            } else {
                // If there's stored chat history, parse and set it
                const parsedMessages = JSON.parse(storedMessages);
                setMessages(parsedMessages);
            }
        } catch (e) {
            console.error("Error fetching data:", e);
        }
    };

    fetchMessages();
}, []);

    useEffect(() => {
        // Store messages whenever they change
        const storeMessages = async () => {
            try {
                await AsyncStorage.setItem('@chat_history', JSON.stringify(messages));
            } catch (e) {
                console.error("Error storing data:", e);
            }
        };
        storeMessages();
    }, [messages]);

    const askGPT = async (messageText) => {
        const userMessage = {
            _id: Date.now(),
            text: messageText,
            createdAt: new Date(),
            user: {
                _id: 1,
                name: 'User'
            }
        };
        setIsTyping(true);
        setMessages(prevState => [userMessage, ...prevState]);
    
        try {
            const response = await fetch('https://bardtrial.azurewebsites.net/api/bardapi', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ input: messageText })
            });
    
            if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
                const data = await response.json();
                const gptMessage = {
                    _id: Date.now() + 1,
                    text: data.message || "Sorry, I couldn't understand that.",
                    createdAt: new Date(),
                    user: {
                        _id: 2,
                        name: 'GPT'
                    }
                };
                setMessages(prevState => [gptMessage, ...prevState]);
                setIsTyping(false);
            } else {
                throw new Error('Non-JSON response received');
            }
        } catch (error) {
            console.error("There was an error:", error);
            // If the response is readable, log it or display a part of it for debugging
            if (error.response) {
                console.log('Status Code:', error.response.status);
                console.log('Response Body:', await error.response.text()); // Use .text() to read the response body without parsing as JSON
            }
            const errorMessage = {
                _id: Date.now() + 1,
                text: "عذرًا لم أتمكن من الاتصال بالانترنت للحصول على اجابة",
                createdAt: new Date(),
                user: {
                    _id: 2,
                    name: 'GPT'
                }
            };
            setMessages(prevState => [errorMessage, ...prevState]);
            setIsTyping(false);
        }
        
    };
    

const renderBubble = (props) => {
    // Check if the message is a suggestion
    if (props.currentMessage.quickReply) {
        return (
            <TouchableOpacity
                onPress={() => onSuggestionTap(props.currentMessage.text)}
                style={styles.suggestionBubble}
            >
                <Text style={styles.suggestionText}>{props.currentMessage.text}</Text>
            </TouchableOpacity>
        );
    }

    // Default bubble for regular messages
    return <Bubble {...props} />;
};


    const renderFooter = () => {
        if (isTyping) {
            return (
                <View style={styles.typingIndicatorContainer}>
                    <Text style={styles.typingIndicatorText}>GPT is typing...</Text>
                </View>
            );
        }
        return null;
    };


    return (
        <View style={styles.container}>
           { /*<View style={{ alignSelf: 'flex-end', margin: 10 }}>
                <Button title="Clear History" onPress={promptClearChatHistory} />
    </View>*/}
            <GiftedChat
    messages={messages}
    onSend={(newMessages) => askGPT(newMessages[0].text)}
    user={{ _id: 1 }}
    inverted={true}
    renderFooter={renderFooter}
    renderBubble={renderBubble}
/>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    typingIndicatorContainer: {
        alignItems: 'center',
        height: 30,
        justifyContent: 'center',
        marginBottom: 10,
    },
    typingIndicatorText: {
        color: '#666',
        fontSize: 14,
        fontStyle: 'italic'
    },
    suggestionBubble: {
        backgroundColor: '#efefef',
        padding: 10,
        borderRadius: 20,
        marginRight: 10,
        marginLeft: 10,
        marginTop: 5,
        maxWidth: '80%', // Prevents the bubble from taking up more than 80% of the screen width

    },
    suggestionText: {
        color: '#000',
        flexWrap: 'wrap', // Ensures text within the bubble wraps

    },
    
});

export default AssistantAI;
