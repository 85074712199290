import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Chip } from 'react-native-ui-lib'; // Import the Chip component from RNUI library
import { Ionicons } from '@expo/vector-icons'; // You can use any other icon library

const FilterBar = ({ showReportBars, onClose, showChips = false, data }) => {
    return showReportBars ? (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: 10, backgroundColor: '#f8f9fa' }}>
      {showChips?
        data.map((item, index) => (
      <Chip
          key={index}
          label={item}
          containerStyle={{ margin: 5 }}
          backgroundColor='#FFC857'
        />
        ))
        :
        <Text > انت تشاهد نسخة مصفاة ...</Text>
}
      <TouchableOpacity onPress={onClose}style={{alignItems: 'flex-end', flex: 1}}> 
        <Ionicons name="close" size={24} color="black" />
      </TouchableOpacity>
    </View>
  ) : null;
};

export default FilterBar;
