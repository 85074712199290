import React, { useState, useEffect, useRef} from 'react';
import { Modal, View, StyleSheet, Button, Text, ScrollView, TouchableOpacity, Platform   } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MaterialIcons, Octicons } from '@expo/vector-icons'; // Make sure to install expo/vector-icons
import { FloatingButton } from 'react-native-ui-lib'; // Import ActionSheet from react-native-ui-lib
import ModalPicker from '../SharedComponents/ModalPicker';
import DateTimePicker from '@react-native-community/datetimepicker';










const LawsuitFilterModal = ({ isVisible, toggleVisibility, onSubmit, showTypePicker = true,
    showPhasePicker = true, showJRefPicker = true, showJRegionPicker = true, showLawyerPicker = true,
    showClientPicker = true, showOpponentPicker = true, showIsActivePicker = true,
    showLawSuitePicker = true, showProcedureTypePicker = true, showReminderTypePicker = true, showDate = true, resetKey }) => {



    const [TypeId, setTypeId] = useState('-1');
    const [DataTypeId, setDataTypeId] = useState([]);


    const [PhaseId, setPhaseId] = useState('-1');
    const [DataPhaseId, setDataPhaseId] = useState([]);

    const [JRefId, setJRefId] = useState('-1');
    const [DataJRefId, setDataJRefId] = useState([]);

    const [JRegionId, setJRegionId] = useState('-1');
    const [DataJRegionId, setDataJRegionId] = useState([]);

    const [LawyerId, setLawyerId] = useState('-1');
    const [DataLawyerId, setDataLawyerId] = useState([]);

    const [ClientId, setClientId] = useState('-1');
    const [DataClientId, setDataClientId] = useState([]);

    const [fromDate, setFromDate] =useState(new Date());
    const [DataFromDate, setDataFromDate] = useState([]);
    const [showFromDate, setShowFromDate] = useState(false);

    const [toDate, setToDate] = useState(new Date());
    const [DataToDate, setDataToDate] = useState([]);
    const [showToDate, setShowToDate] = useState(false);



    const [OpponentId, setOpponentId] = useState('-1');
    const [DataOpponentId, setDataOpponentId] = useState([]);


    const [IsActive, setIsactive] = useState('1');

    const [LawsuitId, setLawsuitId] = useState('-1');
    const [DataLawsuitId, setDatalawsuitId] = useState([]);

    const [procedureTypeId, setProcedureTypeId] = useState('-1');
    const [DataprocedureTypeId, setDataProcedureTypeId] = useState([]);

    const [ReminderTypeId, setReminderTypeId] = useState('-1');
    const [DataReminderTypeId, setDataReminderTypeId] = useState([]);

    const [loading, setLoading] = useState(true);
    const [isDonePressed, setIsDonePressed] = useState(false);
    const [currentPickerLabel, setCurrentPickerLabel] = useState('');
    const [isModalVisible, setModalVisible] = useState(false);
    const [currentPickerData, setCurrentPickerData] = useState([]);
    const [currentPickerValue, setCurrentPickerValue] = useState('');
    const currentPickerSetterRef = useRef(null);

    // Update the openPicker function:
    const openPicker = (data, value, setter, label) => {
        setCurrentPickerData(data);
        setCurrentPickerValue(value);
        currentPickerSetterRef.current = setter;
        setCurrentPickerLabel(label);
        setModalVisible(true);
    };

    // Update the handlePickerValueChange function:
    const handlePickerValueChange = (value) => {
        if (currentPickerSetterRef.current) {
            currentPickerSetterRef.current(value);
        }
        setModalVisible(false);
    };




    useEffect(() => {

        setTypeId('-1');
        setPhaseId('-1');
        setJRefId('-1');
        setJRegionId('-1');
        setLawyerId('-1');
        setClientId('-1');
        setOpponentId('-1');
        setIsactive('1');
        setLawsuitId('-1');
        setProcedureTypeId('-1');
        setReminderTypeId('-1');

    }, [resetKey]);


    useEffect(() => {
        if (isDonePressed) {
            onSubmit({ TypeId, PhaseId, JRefId, JRegionId, LawyerId, ClientId, OpponentId, IsActive, LawsuitId, procedureTypeId, ReminderTypeId, fromDate, toDate });
            setIsDonePressed(false);
            toggleVisibility();
        }
    }, [TypeId, PhaseId, JRefId, JRegionId, LawyerId, ClientId, OpponentId, IsActive, LawsuitId, procedureTypeId, ReminderTypeId, isDonePressed]);


    useEffect(() => {
      
        // Function to fetch a dataset from AsyncStorage based on a given key
        const fetchDataFromStorage = async (key, setData) => {
            try {
                const storedData = await AsyncStorage.getItem(key);
                if (storedData) {
                    //console.log(`Stored data for ${key}:`, storedData);
                    setData(JSON.parse(storedData));
                } else {
                    //console.log(`No data found in storage for ${key}`);
                }
            } catch (error) {
                console.error(`Error fetching data from storage for ${key}:`, error);
            }
        };

        // Fetch multiple datasets from AsyncStorage
        fetchDataFromStorage('select_types', setDataTypeId);
        fetchDataFromStorage('select_phases', setDataPhaseId);
        fetchDataFromStorage('select_judicial_references', setDataJRefId);
        fetchDataFromStorage('select_judicial_regions', setDataJRegionId);
        fetchDataFromStorage('select_users', setDataLawyerId);
        fetchDataFromStorage('select_clients', setDataClientId);
        fetchDataFromStorage('select_opponents', setDataOpponentId);
        fetchDataFromStorage('select_lawsuits', setDatalawsuitId);
        fetchDataFromStorage('select_procedure_types', setDataProcedureTypeId);
        fetchDataFromStorage('select_reminder_types', setDataReminderTypeId);
        setLoading(false);
    }
, []);

const handleValueChange = (itemValue) => {
    // Find the selected item
    const selectedItem = DataLawsuitId.find(item => item.id === itemValue);

    if (selectedItem) {
      // Concatenate the desired values from the selected item
      const concatenatedValue = `${selectedItem.id},${selectedItem.lawsuit_info_id}`;
      setLawsuitId(concatenatedValue);
    } else {
      setLawsuitId(itemValue); // or handle the default case as needed
    }
  };

const formatDate = (date) => {
    // Example format: "01/01/2021"
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};


    return (
        <Modal
            transparent={true}
            animationType="slide"
            visible={isVisible}
            onRequestClose={toggleVisibility}
        >
            <ModalPicker
                isVisible={isModalVisible}
                onValueChange={handlePickerValueChange}
                selectedValue={currentPickerValue}
                data={currentPickerData}
                onClose={() => setModalVisible(false)}
                label={currentPickerLabel}
            />
            <View style={styles.modalView}>
                <Text style={styles.title}>تحديد الخيارات</Text>
                <ScrollView>


                {showDate && (
    <View style={[styles.pickerContainer, { backgroundColor: fromDate.getDate() !== new Date().getDate() ? '#DFD6A7' : 'white', height: 50 }]}>
        <MaterialIcons name="date-range" size={24} color="black" />
        <TouchableOpacity style={{ paddingLeft: 20 }} onPress={() => setShowFromDate(true)}>
            <Text>{fromDate ? formatDate(fromDate) : 'من تاريخ'}</Text>
        </TouchableOpacity>
    </View>
)}
                            {showFromDate && (
                                <DateTimePicker
                                    value={fromDate}
                                    mode="date"
                                    display="default"
                                    onChange={(event, selectedDate) => {
                                        setShowFromDate(Platform.OS === 'ios'); // Hide the picker on Android after selection
                                       // setFromDate(selectedDate || fromDate); // Update the date or keep the old one if null

                                        if (selectedDate) {
                                            // Create a new Date object to avoid mutating the original selectedDate
                                            const adjustedDate = new Date(selectedDate);
                            
                                            // Set the time to 23:59:59
                                            adjustedDate.setHours(0, 0, 1);
                            
                                            // Update toDate with the adjusted date
                                            setFromDate(adjustedDate);
                                        } else {
                                            // Keep the old toDate if selectedDate is null
                                            setFromDate(fromDate);
                                        }
                                    }}
                                />
                            )
                            
                        }
             

        
             {showDate && (
    <View style={[styles.pickerContainer, { backgroundColor: toDate.getDate() !== new Date().getDate() ? '#DFD6A7' : 'white', height: 50 }]}>
        <MaterialIcons name="date-range" size={24} color="black" />
        <TouchableOpacity style={{ paddingLeft: 20 }} onPress={() => setShowToDate(true)}>
            <Text>{toDate ? formatDate(toDate) : 'الى تاريخ'}</Text>
        </TouchableOpacity>
    </View>
)}
{showToDate && (
    <DateTimePicker
        value={toDate}
        mode="date"
        display="default"
        onChange={(event, selectedDate) => {
            setShowToDate(Platform.OS === 'ios'); // Hide the picker on Android after selection

            if (selectedDate) {
                // Create a new Date object to avoid mutating the original selectedDate
                const adjustedDate = new Date(selectedDate);

                // Set the time to 23:59:59
                adjustedDate.setHours(23, 59, 59);

                // Update toDate with the adjusted date
                setToDate(adjustedDate);
            } else {
                // Keep the old toDate if selectedDate is null
                setToDate(toDate);
            }
        }}
    />
)}







                    {showTypePicker && (

                        <View style={[styles.pickerContainer, { backgroundColor: TypeId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="category" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataTypeId, TypeId, setTypeId, "جميع انواع الدعاوى")}>
                                    <Text>{TypeId === '-1' ?  "جميع انواع الدعاوى" : DataTypeId.find(item => item.id === TypeId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={TypeId}
                                    onValueChange={(itemValue) => setTypeId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label= "جميع انواع الدعاوى" value="-1" />
                                    {DataTypeId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}

                    {showPhasePicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: PhaseId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="stairs" size={24} color="black"/>
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataPhaseId, PhaseId, setPhaseId, "الرجاء اختيار مرحلة")}>
                                    <Text style={{fontSize:20, marginLeft:20}}>{PhaseId === '-1' ? "جميع المراحل" : DataPhaseId.find(item => item.id === PhaseId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={PhaseId}
                                    onValueChange={(itemValue) => setPhaseId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع المراحل" value="-1" />
                                    {DataPhaseId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}



                    {showJRefPicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: JRefId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <Octicons name="law" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataJRefId, JRefId, setJRefId, "الرجاء اختيار المحكمة")}>
                                    <Text>{JRefId === '-1' ? "Select Type" : DataJRefId.find(item => item.id === JRefId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={JRefId}
                                    onValueChange={(itemValue) => setJRefId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع المحاكم" value="-1" />
                                    {DataJRefId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}


                    {showJRegionPicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: JRegionId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="location-on" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataJRegionId, JRegionId, setJRegionId, "Select Type")}>
                                    <Text>{JRegionId === '-1' ? "Select Type" : DataJRegionId.find(item => item.id === JRegionId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={JRegionId}
                                    onValueChange={(itemValue) => setJRegionId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع المناطق" value="-1" />
                                    {DataJRegionId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}



                    {showLawyerPicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: LawyerId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="person" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataLawyerId, LawyerId, setLawyerId, "Select Type")}>
                                    <Text>{LawyerId === '-1' ? "Select Type" : DataLawyerId.find(item => item.id === LawyerId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={LawyerId}
                                    onValueChange={(itemValue) => setLawyerId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع المحامين" value="-1" />
                                    {DataLawyerId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}



                    {showClientPicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: ClientId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="groups" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataClientId, ClientId, setClientId, "Select Type")}>
                                    <Text>{ClientId === '-1' ? "Select Type" : DataClientId.find(item => item.id === ClientId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={ClientId}
                                    onValueChange={(itemValue) => setClientId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع الموكلين" value="-1" />
                                    {DataClientId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}



                    {showOpponentPicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: OpponentId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="people-outline" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataOpponentId, OpponentId, setOpponentId, "Select Type")}>
                                    <Text>{OpponentId === '-1' ? "Select Type" : DataOpponentId.find(item => item.id === OpponentId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={OpponentId}
                                    onValueChange={(itemValue) => setOpponentId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع الخصوم" value="-1" />
                                    {DataOpponentId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}


                    {showLawSuitePicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: LawsuitId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="menu-book" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataLawsuitId, LawsuitId, setLawsuitId, "Select Type")}>
                                    <Text>{LawsuitId === '-1' ? "Select Type" : DataLawsuitId.find(item => item.id === LawsuitId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={LawsuitId}
                                    onValueChange={(itemValue) => handleValueChange(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع الدعاوى" value="-1" />
                                    {DataLawsuitId.map((item, index) => (
                                        <Picker.Item key={index} label={item.title} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}

                    {showProcedureTypePicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: procedureTypeId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="tune" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataprocedureTypeId, procedureTypeId, setProcedureTypeId, "Select Type")}>
                                    <Text>{procedureTypeId === '-1' ? "Select Type" : DataprocedureTypeId.find(item => item.id === procedureTypeId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={procedureTypeId}
                                    onValueChange={(itemValue) => setProcedureTypeId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع انواع الاجراءات" value="-1" />
                                    {DataprocedureTypeId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}


                    {showReminderTypePicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: ReminderTypeId !== '-1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="new-releases" size={24} color="black" />
                            {Platform.OS === 'ios' ? (
                                <TouchableOpacity onPress={() => openPicker(DataReminderTypeId, ReminderTypeId, setReminderTypeId, "Select Type")}>
                                    <Text>{ReminderTypeId === '-1' ? "Select Type" : DataReminderTypeId.find(item => item.id === ReminderTypeId)?.text}</Text>
                                </TouchableOpacity>
                            ) : (
                                <Picker
                                    selectedValue={ReminderTypeId}
                                    onValueChange={(itemValue) => setReminderTypeId(itemValue)}
                                    style={styles.picker}
                                >
                                    <Picker.Item label="جميع انواع الاستحقاقات" value="-1" />
                                    {DataReminderTypeId.map((item, index) => (
                                        <Picker.Item key={index} label={item.text} value={item.id} />
                                    ))}
                                </Picker>
                            )}
                        </View>
                    )}


                    {showIsActivePicker && (
                        <View style={[styles.pickerContainer, { backgroundColor: IsActive !== '1' ? '#DFD6A7' : 'white' }]}>
                            <MaterialIcons name="location-on" size={24} color="black" />
                            <Picker
                                selectedValue={IsActive}
                                onValueChange={(itemValue) => setIsactive(itemValue)}
                                style={styles.picker}
                            >
                                <Picker.Item label="جمبع الدعاوى" value="1" />
                                <Picker.Item label="دعاوى غير مفصولة" value="2" />
                                <Picker.Item label="دعاوى مفصولة" value="3" />
                            </Picker>
                        </View>
                    )}
                </ScrollView>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop:10 }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={() => {
        setIsDonePressed(false);
        toggleVisibility();
    }}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="تصفية" 
      onPress={() => {
        setIsDonePressed(true);
    }}

      color="#28a745" /* Green color */
    />
  </View>

                      
                        </View>









              
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        padding: 20,
        borderRadius: 10,

    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 10,
        marginVertical: 5,
        paddingHorizontal: 10,
       // height: Platform.OS === 'ios' ? 60: null
    },
    picker: {
        flex: 1,
    },
    buttonContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
});

export default LawsuitFilterModal;
