import React, { createContext } from 'react';

const BadgeCountContext = createContext();

export const BadgeCountProvider = ({ children }) => {
  const [badgeCount, setBadgeCount] = React.useState(0);

  return (
    <BadgeCountContext.Provider value={{ badgeCount, setBadgeCount }}>
      {children}
    </BadgeCountContext.Provider>
  );
};

export default BadgeCountContext;
 