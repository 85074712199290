import React, { useEffect, useState, useCallback } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { FlatList, Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { fetchData } from '../../../utils/api';
import EmployeeModal from '../components/EmployeeModal';
import { Ionicons } from '@expo/vector-icons';

const Stack = createStackNavigator();

function MainScreen({ navigation, modalVisible }) {
  const [employees, setEmployees] = useState([]);
  const isFocused = useIsFocused();

  const fetchEmployees = useCallback(async () => {
    try {
      const data = await fetchData({ method: 'select_employees_all', params: {} });
      setEmployees(data);

      console.log(data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchEmployees();
    }
  }, [isFocused, fetchEmployees]);

  useEffect(() => {
    if (!modalVisible && isFocused) {
      fetchEmployees();
    }
  }, [modalVisible, isFocused, fetchEmployees]);

  return (
    <View style={styles.container}>
      <FlatList
        data={employees}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => {
              navigation.navigate('Second', { employeeId: item.id });
            }}
          >
            <Text style={styles.item}>{item.text}</Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function SecondScreen({ route, navigation }) {
  const { employeeId } = route.params;
  const [employee, setEmployee] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchEmployee = async () => {
    try {
      const data = await fetchData({ method: 'select_employees', params: { id: employeeId } });
      setEmployee(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching employee:', error);
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, [employeeId]);

  const handleEdit = () => {
    setModalVisible(true);
  };

  const handleSubmit = async (employeeDetails) => {
    const url = { method: 'update_employee', params: { ...employeeDetails, id: employeeId } };

    try {
      const response = await fetchData(url);

      if (response === 1) {
        setModalVisible(false);
        fetchEmployee(); // Refresh employee details
      } else {
        console.error('Failed to update employee');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!employee) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.item}>اسم الموظف: {employee.text}</Text>
      
     
      <TouchableOpacity onPress={handleEdit}>
        <Ionicons name="create" size={24} color="red" />
      </TouchableOpacity>

      <EmployeeModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={(details) => handleSubmit({ ...details, name: details.name })}
        initialData={employee}
      />
    </View>
  );
}

function EmployeesNavigator({ modalVisible }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Main">
        {(props) => <MainScreen {...props} modalVisible={modalVisible} />}
      </Stack.Screen>
      <Stack.Screen name="Second" component={SecondScreen} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  item: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subItem: {
    fontSize: 14,
    color: '#555',
  },
});

export default EmployeesNavigator;
