import React, { useState, useEffect } from 'react';
import { View, Button, TextInput, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import Ionicons from '@expo/vector-icons/Ionicons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SQLite from 'expo-sqlite/legacy';
import { Picker } from '@react-native-picker/picker';
import { executeSqlWithRetry,unlockDatabase } from '../../../utils/api';

const db = Platform.OS !== 'web' ? SQLite.openDatabase('localAvocado.db') : null;

const AddAppointment = ({ CancelAddAppointment, OnSubmit, editData, onUpdate }) => {
    const [description, setDescription] = useState('');
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState('');
  const [newRecord, setNewrecord] = useState('');
  const [title, setTitle] = useState('إضافة موعد');

  useEffect(() => {
    const fetchClients = async () => {
      const clientsStr = await AsyncStorage.getItem('select_clients');
      const clientsData = clientsStr ? JSON.parse(clientsStr) : [];
      setClients(clientsData);
     
     
    };

    fetchClients();
    console.log(editData);
    if (editData) {
        setDescription(editData.description);
        setDate(new Date(editData.deadline));
        setTime(new Date(editData.deadline)); // Adjust if time is stored separately
        setClientID(editData.clientID);
        setTitle('تعديل موعد')
        // Set other fields as needed
      }
    }, [editData]);
  



  const readReminders = () => {
    db.transaction(tx => {
      tx.executeSql(
        'SELECT * FROM reminders;',
        [],
        (tx, results) => {
          const rows = results.rows;
          let reminders = [];
  
          for (let i = 0; i < rows.length; i++) {
            reminders.push(rows.item(i));
          }
  
          console.log('Reminders:', reminders);
        },
        error => {
          console.log('Error fetching reminders: ', error);
        }
      );
    });
  };

  const emptyRemindersTable = () => {
    db.transaction(tx => {
      tx.executeSql(
        'DELETE FROM reminders;',
        [],
        () => {
          console.log('Reminders table emptied successfully');
        },
        error => {
          console.log('Error emptying reminders table: ', error);
        }
      );
    });
  };


  const insertReminder = async () => {
    console.log(editData);
    let combinedDateTime = new Date(date);
    combinedDateTime.setHours(time.getHours(), time.getMinutes());
  
    const formattedDeadline = combinedDateTime.getFullYear() + '-' +
      String(combinedDateTime.getMonth() + 1).padStart(2, '0') + '-' +
      String(combinedDateTime.getDate()).padStart(2, '0') + ' ' +
      String(combinedDateTime.getHours()).padStart(2, '0') + ':' +
      String(combinedDateTime.getMinutes()).padStart(2, '0') + ':' +
      String(combinedDateTime.getSeconds()).padStart(2, '0');
  
    const newReminderRecord = {
      deadline: formattedDeadline,
      description: description,
      files: [],
      fin_id: '',
      id: editData.id || '',
      lawsuitText: null,
      lawsuit_id: null,
      lawsuit_info_id: null,
      type: null,
      type_name: null,
      clientID: clientID,
      clientName: clients.find(c => c.id === clientID)?.text,
      Source: 'SQLite'
    };
  
    try {
      await unlockDatabase(db); // Unlock the database before executing the query
  
      if (editData) {
        await executeSqlWithRetry(
          db,
          'UPDATE reminders SET description = ?, deadline = ?, clientID = ?, clientName = ? WHERE id = ?;',
          [description, formattedDeadline, clientID, clients.find(c => c.id === clientID)?.text, editData.id]
        );
        onUpdate(newReminderRecord);
      } else {
        const result = await executeSqlWithRetry(
          db,
          'INSERT INTO reminders (description, deadline, status, clientID, clientName) VALUES (?, ?, ?, ?, ?);',
          [description, formattedDeadline, 1, clientID, clients.find(c => c.id === clientID)?.text]
        );
        newReminderRecord.id = result.insertId; // Update id of new record
        OnSubmit(newReminderRecord); // Submit the record
      }
  
      CancelAddAppointment(); // Close the form
    } catch (error) {
      console.log('Error handling reminder', error);
    }
  };
  
  
  
  
  

  return (
    <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>{title}</Text>
          <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom: 10 }} />

          <Picker
        selectedValue={clientID}
        style={styles.picker}
        onValueChange={(itemValue) => setClientID(itemValue)}
      >
        <Picker.Item label="اختر موكل (اختياري) ..." value="-1" />
        {clients.map(client => (
          <Picker.Item key={client.id.toString()} label={client.text} value={client.id} />
        ))}
      </Picker>


      <TextInput
 style={{
    // marginTop: 10,
    width: '100%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10
  }}        placeholder="التفاصيل"
        value={description}
        onChangeText={setDescription}
      />

     

      <View style={styles.container}>
      {/* Your input fields and other components */}
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
        {/* Date Picker */}
        {Platform.OS === 'ios' ? (
          <DateTimePicker
            value={date}
            mode="date"
            display="default"
            minimumDate={new Date()}
            onChange={(event, selectedDate) => {
              const currentDate = selectedDate || date;
              setShowDatePicker(false);
              setDate(currentDate);
            }}
          />
        ) : (
          <View style={[styles.iconButton]}>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowDatePicker(true)}>
              <Ionicons name="calendar-outline" size={24} color="black" />
              <Text style={{ marginLeft: 8 }}>
                {date ? date.toLocaleDateString() : 'التاريخ'}
              </Text>
            </TouchableOpacity>
            {showDatePicker && (
              <DateTimePicker
                value={date}
                mode="date"
                display="default"
                minimumDate={new Date()}
                onChange={(event, selectedDate) => {
                  const currentDate = selectedDate || date;
                  setShowDatePicker(false);
                  setDate(currentDate);
                }}
              />
            )}
          </View>
        )}
        {/* Time Picker */}
        {Platform.OS === 'ios' ? (
          <DateTimePicker
            value={time}
            mode="time"
            display="default"
            onChange={(event, selectedTime) => {
              const currentTime = selectedTime || time;
              setShowTimePicker(false);
              setTime(currentTime);
            }}
          />
        ) : (
          <View style={[styles.iconButton]}>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowTimePicker(true)}>
              <Ionicons name="time-outline" size={24} color="black" />
              <Text style={{ marginLeft: 8 }}>
                {time ? time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'الوقت'}
              </Text>
            </TouchableOpacity>
            {showTimePicker && (
              <DateTimePicker
                value={time}
                mode="time"
                display="default"
                onChange={(event, selectedTime) => {
                  const currentTime = selectedTime || time;
                  setShowTimePicker(false);
                  setTime(currentTime);
                }}
              />
            )}
          </View>
        )}
      </View>
    </View>

    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={CancelAddAppointment} 
            color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="حفظ" 
      onPress={insertReminder}
     // disabled={!isFormComplete}
      color="#28a745" /* Green color */
    />
  </View>

                      
                        </View>
 
     
    
  
     
      
    </View>
  );
};

const styles = StyleSheet.create({
    iconButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        padding: 10,
        borderRadius: 5,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
      },
    container: {
       // flex: 1,
        //alignItems: 'center',
       // justifyContent: 'center',
        backgroundColor: 'white'
      },
      input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
        width: '80%',
      },
      picker: {
        height: 50,
        width: '80%',
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
      },
  dateText: {
    margin: 12,
    padding: 10,
  },
 
});

export default AddAppointment;
