import React, { useState, useEffect } from 'react';
import { Modal, TouchableOpacity, StyleSheet } from 'react-native';
import { View, Text } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import Styles from '../../../Styles/Styles';
import { useNavigation } from '@react-navigation/native';
import EditLawyerOppoModal from '../components/EditOppoLawyerModal';
import { fetchData } from '../../../utils/api';

const OppoLawyerDetailsScreen = ({ route }) => {
  const { item } = route.params;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const navigation = useNavigation();
  const [DetailedItem, setDedailedItem] = useState('');

  const openEditModal = () => {
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleSaveEdit = async (editedData) => {
    await saveChanges(editedData);
    setIsEditModalVisible(false);
    navigation.navigate('OppoLawyerDetails', { item: editedData });
  };

  useEffect(() => {
    const title = item.text;
    fetchOpponentLawyersDetails();
    navigation.setOptions({
      title: title,
      headerRight: () => (
        <View style={{ flexDirection: 'row', paddingRight: 10 }}>
          <TouchableOpacity onPress={openEditModal} style={{ marginRight: 15 }}>
            <Ionicons name="create-outline" size={25} color="#9acd32" />
          </TouchableOpacity>
        </View>
      )
    });
  }, [navigation, item]);

  const fetchOpponentLawyersDetails = async () => {
    try {
      const OppoLawyer = await fetchData({ method: "select_lawyer_opponent", params: { id: item.id }});
      setDedailedItem(OppoLawyer);
      console.log(OppoLawyer);
    } catch (error) {
      console.error(error);
    }
  };

  const saveChanges = async (oppoLawyerData) => {
    try {
      const updateData = {
        method: "update_opponent_lawyer",
        params: {
          ...oppoLawyerData,
        },
      };

      const response = await fetchData(updateData);
      if (response === 1) {
        // Handle the success case
      } else {
        // Handle unexpected response
      }
    } catch (error) {
      console.error("Error updating lawyer data:", error);
    }
  };

  return (
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>Name</Text>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{`${DetailedItem.first_name} ${DetailedItem.middle_name} ${DetailedItem.last_name}`.trim()}</Text>
        </View>
      </View>
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>Mobile</Text>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{DetailedItem.mobile}</Text>
        </View>
      </View>
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>Email</Text>
        </View>
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
          <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{DetailedItem.email}</Text>
        </View>
      </View>

      {isEditModalVisible && (
        <EditLawyerOppoModal
          isVisible={isEditModalVisible}
          onClose={closeEditModal}
          userId={DetailedItem.profile_id}  // Pass the userId to the modal
          onSave={handleSaveEdit}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  magnifierIcon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 15,
    padding: 5,
  },
  modalContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

export default OppoLawyerDetailsScreen;
