import React, { useState, useEffect, useRef } from 'react';
import { TouchableOpacity, Modal, ScrollView, StyleSheet } from 'react-native';
import { Text, View } from 'react-native-ui-lib';
import { TextField } from 'react-native-ui-lib';
import PhoneInput from 'react-native-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { fetchData } from '../../../utils/api';

const EditLawyerOppoModal = ({ isVisible, onClose, userId, onSave }) => {
  const [editData, setEditData] = useState(null);
  const phoneInput = useRef(null);

  useEffect(() => {
    const loadUserData = async () => {
      const param = { method: 'select_user', params: { id: userId } };
      const data = await fetchData(param);
      setEditData(data);
    };

    if (userId && isVisible) {
      loadUserData();
    }
  }, [userId, isVisible]);

  function getInternationalCode(phoneNumber) {
    if (typeof phoneNumber !== 'string') {
      return '';
    }
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    return parsedNumber ? parsedNumber.countryCallingCode : '';
  }

  function removeInternationalCode(phoneNumber) {
    if (typeof phoneNumber !== 'string') {
      return phoneNumber;
    }
    const dialCode = getInternationalCode(phoneNumber);
    if (phoneNumber.startsWith(`+${dialCode}`)) {
      return phoneNumber.substring(dialCode.length + 1);
    }
    return phoneNumber;
  }

  const handleInputChange = (fieldName, value) => {
    setEditData({ ...editData, [fieldName]: value });
  };

  const handleSave = () => {
    const updatedData = {
      method: "update_user",
      params: {
        id: editData.id,
        profile_id: editData.profile_id,
        first_name: editData.first_name,
        middle_name: editData.middle_name,
        last_name: editData.last_name,
        mother_name: editData.mother_name,
        register_place: editData.register_place,
        register_number: editData.register_number,
        dob: editData.dob,
        mobile: editData.mobile,
        email: editData.email,
        level_of_education: editData.level_of_education,
        date_of_affiliation: editData.date_of_affiliation,
        address: editData.address,
        creation_date: editData.creation_date,
        role: editData.role
      }
    };
    onSave(updatedData);
  };

  if (!editData) {
    return null; // Or a loading indicator
  }

  return (
    <Modal
      transparent={false}
      visible={isVisible}
      onRequestClose={onClose}
      animationType="slide"
    >
      <View style={{ flex: 1 }}>
        {/* Header */}
        <View style={{ padding: 10, backgroundColor: '#f8f8f8', borderBottomWidth: 1, borderColor: '#e7e7e7' }}>
          <Text style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>تعديل معلومات خصم</Text>
        </View>

        {/* Scrollable Content */}
        <ScrollView style={{ flex: 1 }}>
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="الاسم الاول"
            value={editData.first_name}
            onChangeText={(text) => handleInputChange('first_name', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="الشهرة"
            value={editData.last_name}
            onChangeText={(text) => handleInputChange('last_name', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="اسم الاب"
            value={editData.middle_name}
            onChangeText={(text) => handleInputChange('middle_name', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="اسم الام وشهرتها"
            value={editData.mother_name}
            onChangeText={(text) => handleInputChange('mother_name', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="محل الولادة"
            value={editData.register_place}
            onChangeText={(text) => handleInputChange('register_place', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="تاريخ الولادة"
            value={editData.dob}
            onChangeText={(text) => handleInputChange('dob', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="رقم السجل"
            value={editData.register_number}
            onChangeText={(text) => handleInputChange('register_number', text)}
          />
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="العنوان"
            value={editData.address}
            onChangeText={(text) => handleInputChange('address', text)}
          />
          <View>
            <Text style={{ margin: 10, marginTop: 30, fontSize: 12, color: 'grey' }}>رقم الهاتف</Text>
            <PhoneInput
              ref={phoneInput}
              defaultValue={removeInternationalCode(editData.mobile)}
              defaultCode="LB"
              layout="first"
              placeholder="ادخل رقم الهاتف"
              textContainerStyle={[styles.textContainer, {
                direction: 'rtl',
              }]}
              containerStyle={{
                direction: 'rtl'
              }}
              onChangeFormattedText={(text) => {
                handleInputChange('mobile', text);
              }}
            />
          </View>
          <TextField
            containerStyle={styles.input}
            floatingPlaceholder
            placeholder="البريد الالكتروني"
            value={editData.email}
            onChangeText={(text) => handleInputChange('email', text)}
          />
        </ScrollView>

        {/* Footer */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', padding: 10, backgroundColor: '#f8f8f8', borderTopWidth: 1, borderColor: '#e7e7e7' }}>
          <TouchableOpacity onPress={onClose}>
            <Text style={{ fontSize: 16, color: '#007bff' }}>الغاء</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleSave}>
            <Text style={{ fontSize: 16, color: '#007bff' }}>حفظ</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  input: {
    borderBottomWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    marginBottom: 15,
    marginTop: 15
  },
  textContainer: {
    borderColor: 'grey',
  },
});

export default EditLawyerOppoModal;
