import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;



export default {

    LawSuitDetailsMainStyles: {
        UnderCollapsible: {
            paddingLeft: 10,
            paddingRight: 10
        },
        container: {
            flex: 1,
            // padding: 10,
        },
        header: {
            backgroundColor: '#6B0F1A',
            //padding: 10,
            alignItems: 'flex-start',
            justifyContent: 'center',
            //height:100,
            paddingLeft: 10,
            paddingBottom: 5
        },
        iconsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // marginVertical: 10,
            padding: 10,
            paddingTop: 0,
            //backgroundColor: '#D1BEB0',
            borderRadius: 10,
            //margin: 5
        },
        iconsContainer1: {
            backgroundColor: '#346C23',
            padding: 15,
            borderRadius: 10,
            alignItems: 'center',
            width: windowWidth * 0.21,   // Set width as a percentage of the screen width
            height: windowWidth * 0.2,  // Set height as a percentage of the screen width


        },

        chevronContainer: {
            flex: 1,
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end'

        },
        chevronIcon: {
            color: '#4C6085'
        },
        iconText: {
            color: 'white',
            fontSize: windowWidth * 0.027,
        },
        icon: {
            width: 30,
            height: 30,
        },
        collapsible: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#DDDBF1',
            padding: 10,
            // borderRadius: 5,
            marginLeft: 10,
            marginRight: 10,
            borderBottomColor: '#D1BEB0',
            borderBottomWidth: 1

        },
        collapsibleFirst: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#F1EDEE',
            padding: 10,
            borderRadius: 5,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginLeft: 10,
            marginRight: 10,
            borderBottomColor: '#D1BEB0',
            borderBottomWidth: 1
        },
        iconsContainerOverall: {
            marginLeft: 10,
            marginTop: 20,
            marginBottom: 10,
            fontWeight: 'bold'
        },
        collapsibleIcon: {
            // width: 30,
            //  height: 30,
            marginRight: 5,
            height: 40, // Adjust as necessary
            width: 40, // Adjust as necessary
            borderRadius: 20, // Half of the width and height to get a perfect circle
            backgroundColor: 'white', // Your preferred background color
            textAlign: 'center', // To center the icon horizontally
            lineHeight: 40, // To center the icon vertically
            borderColor: 'red'
        }

    },
    LawSuiteDetailsClientOpponentStyles:{
        CollapsibleHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: 10,
            borderRadius: 5,
          },
        CollapsibleContainer: {
            backgroundColor: '#fff',
           // padding: 10,
            //margin: 10,
          //  borderRadius: 5,
            borderWidth: 1,
            borderColor: '#ddd',
            shadowColor: "#000",
          //  shadowOffset: {
            //  width: 0,
             // height: 2,
            //},
           // shadowOpacity: 0.25,
            //shadowRadius: 3.84,
            //elevation: 5,
          },
        container: {
            backgroundColor: '#f9f9f9',
            padding: 20,
            borderRadius: 10,
            margin: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          },
          innerContainer: {
            backgroundColor: '#f9f9f9',
            //padding: 20,
            borderRadius: 10,
            //margin: 10,
           // shadowColor: "#000",
           // shadowOffset: {
             // width: 0,
             // height: 2,
            //},
          //  shadowOpacity: 0.25,
            //shadowRadius: 3.84,
           // elevation: 5,
          },
          row: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 5,
          },
          row1: {
            //flexDirection: 'row',
           // alignItems: 'flex-start',
            marginBottom: 5,
            justifyContent:'flex-start'
          },
          labelBox: {
            width: '30%',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#ccc',
            borderRadius: 10,
            padding: 5,
            marginRight: 10,
          },
          valueBox: {
            width: '70%',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#ccc',
            borderRadius: 10,
            padding: 5,
          },
          inputvalue: {
            width: '70%',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#ccc',
            borderRadius: 10,
            paddingRight: 5,
            paddingLeft: 5
          },
          label: {
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#333',
            fontFamily: 'DefFont'
          },
          value: {
            textAlign: 'left',
            color: '#333',
          },
          modalContent: {
            flex: 1,
            //padding: 20,
          },
          closeButton: {
            padding: 10,
            backgroundColor: 'red',
            borderRadius: 5,
            alignItems: 'center',
            marginTop: 20,
          },
          closeButtonText: {
            color: 'white',
            fontSize: 16,
          },
          magnifierIcon: {
            position: 'relative',
            //top: 10,
           // right: 10,
           // padding: 10,
            //zIndex: 1,
            marginBottom:10,
            alignSelf:'flex-start'
          },
    }

}