import React, {useContext, useEffect} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Dimensions, View, Text, StyleSheet, Button, Platform } from 'react-native';
import LawSuitDetailsMain from './screens/LawSuitDetailsMain';
import LawS from './screens/LawS';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import NavigationStateContext from '../../contexts/NavigationStateContext';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import AddNewLawsuit from './screens/AddNewLawsuit';

const screenWidth = Dimensions.get('window').width;


const TwoLineHeaderTitle = ({ title }) => {
  return (
    <View dir={'rtl'} style={styles.container}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};


/*
* This is used for identifying the route name and hide the search header bar
*/
function getActiveRouteName(state) {
  const route = state.routes[state.index];

  // Check if this route has a nested state (i.e., if it's a navigator)
  if (route.state) {
    // Recursively get the active route name from the nested state
    return getActiveRouteName(route.state);
  }

  return route.name; // This is the focused route name
}


const LawSuiteDEtailsStackNavigation = ({ route }) => {
  const Stack = createStackNavigator();
  const navigation = useNavigation();

  /*
* This is used for identifying the route name and hide the search header bar
*/
  const { setCurrentRoute } = useContext(NavigationStateContext);
  useEffect(() => {
    const unsubscribe = navigation.addListener('state', () => {
      const state = navigation.getState();
      const routeName = getActiveRouteName(state);
      setCurrentRoute(routeName);
     // console.log("Current Route:", routeName);
    });
  
    return unsubscribe;
  }, [navigation]);
  
  
  return (
    <Stack.Navigator initialRouteName="Screen2" screenOptions={{
      headerStyle: {
        backgroundColor: '#6B0F1A',
        shadowColor: 'transparent',
        borderBottomWidth: 0,
        elevation: 0,
      },
      headerTintColor: '#fff',
      
    }}>
     <Stack.Screen 
  name="Screen1" 
  component={LawSuitDetailsMain}  
  initialParams={route.params}
  options={({ route }) => ({
    headerTitle: () => (
      <TwoLineHeaderTitle 
        title={route.params?.HeaderTitle ?? 'Default Title'}
      />
    ),
    
    headerRight: () => (
     
     Platform.OS === 'web' ? <Ionicons
      name="arrow-forward-outline"
      size={30}
      color="white"
     // style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
      onPress={() => navigation.navigate('Screen2')}    />
      :
      null
      
    ),
    headerLeft: () => (

      Platform.OS !== 'web' ?
      <Ionicons
      name="arrow-forward-outline"
      size={20}
      
      color="white"
     // style={Styles.LawSuitDetailsMainStyles.collapsibleIcon}
      onPress={() => navigation.navigate('Screen2')}    />
      :
      null
      
    ),
    headerTitleAlign: 'center' 
  })} 
/>

      <Stack.Screen 
        name="Screen2" 
        component={LawS} 
        options={{ headerShown: false }}
      />
   

<Stack.Screen name="AddNewLawsuit" component={AddNewLawsuit}  options={{ headerShown: false }}  />
</Stack.Navigator>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 12,
    fontFamily: 'DefFont',
    color: 'white',
    flexWrap: 'wrap',
    maxWidth: screenWidth - 60, // Subtract a value to account for padding/margins
  }
});


export default LawSuiteDEtailsStackNavigation;
