import React, { useState, useEffect } from 'react'; // Import useEffect
import { View, TextInput, Button, StyleSheet, TouchableOpacity, Text } from 'react-native';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';

const AddProcuration = ({ onSubmit, editData, onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [procurationName, setProcurationName] = useState('');

    // Initialize procurationName with editData on component mount or editData change
    useEffect(() => {
        if (editData?.procurationTitle) {
            setProcurationName(editData.procurationTitle);
        }
    }, [editData]);

    const onFileChange = (deletedFileIds, addedUris) => {
        setSelectedFiles(addedUris);
    };

    const onSave = () => {
        if (onSubmit) {
            onSubmit({
                procurationName,
                selectedFiles
            });
        }
    };

    return (
        <View style={styles.stepContainer}>
          
                {/* Header */}
                <View style={{ padding: 10, backgroundColor: '#f8f8f8', borderBottomWidth: 1, borderColor: '#e7e7e7' }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>اضافة وتعديل وكالة</Text>
                </View>

            <TextInput
                style={styles.input}
                placeholder="عنوان الوكالة"
                value={procurationName}
                onChangeText={setProcurationName}
            />
            <FilePhotoPicker 
              initialUris={(editData.procurations || []).map(item => item.file_id)} 

            onFileChange={onFileChange} />
       
             {/* Footer */}
             <View style={styles.footer}>

<TouchableOpacity onPress={onClose}>
    <Text style={{ fontSize: 16, color: '#007bff' }}>الغاء</Text>
</TouchableOpacity>

<TouchableOpacity  onPress={onSave}>
    <Text style={{ fontSize: 16, color: '#007bff' }}>حفظ</Text>
</TouchableOpacity>
</View>
        </View>
    );
};

const styles = StyleSheet.create({
    stepContainer: {
       flex:1
    },
    input: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    footer: {
        position: 'absolute', // Position footer absolutely
        bottom: 0, // Anchor it to the bottom
        left: 0,
        right: 0,
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
        backgroundColor: '#f8f8f8',
        borderTopWidth: 1,
        borderColor: '#e7e7e7',
    },});

export default AddProcuration;
