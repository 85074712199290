import React, {useState, useEffect} from 'react';
import { Modal, View, Button, Platform, Text } from 'react-native';
import { Picker } from '@react-native-picker/picker';

export default function ModalPicker({ isVisible, onValueChange, selectedValue, data, onClose, label }) {
    const [localSelectedValue, setLocalSelectedValue] = useState(selectedValue);
  
    useEffect(() => {
      setLocalSelectedValue(selectedValue);
    }, [selectedValue]);
  
    return (
      <Modal
        transparent={true}
        animationType="slide"
        visible={isVisible}
        onRequestClose={onClose}
        //style={{backgroundColor: 'red'}}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ width: '80%', backgroundColor: 'white', padding: 20, borderRadius: 10 }}>
            <Text>{label}</Text>
            <Picker
              selectedValue={localSelectedValue}
              onValueChange={(itemValue) => setLocalSelectedValue(itemValue)}
            >
              {data.map((item, index) => (
                <Picker.Item key={index} label={item.text||item.title} value={item.id} />
              ))}
            </Picker>
            <Button 
              title="Done" 
              onPress={() => {
                onValueChange(localSelectedValue);
                onClose();
              }} 
            />
          </View>
        </View>
      </Modal>
    );
  }
  