import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextInput, StyleSheet, FlatList, TouchableOpacity, SafeAreaView, Alert } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { Ionicons } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import AccountingModal from '../coomponents/AccountingModal';
import { View, TextField, Text, Button, Drawer, Colors } from 'react-native-ui-lib';
import AccoutningReportsFilter from '../coomponents/AccountingReportsModal';
import { fetchData, DELETE_ITEM_API, executePostRequest } from '../../../utils/api';
import FilterBar from '../../../SharedComponents/FilterBar';
import AccountEdit from '../coomponents/AccountingEdit';
import { SpeedDial } from '@rneui/themed';
import { SearchContext } from '../../../contexts/SearchContext';
import AccountStatement from '../coomponents/AccountStatement';
import { FlashList } from '@shopify/flash-list';
import { useIndicator } from '../../../contexts/IndicatorContext';
 import { useFocusEffect } from '@react-navigation/native';
 import RefreshContext from '../../../contexts/RefreshContext';

const TransactionFetcher = () => {
    const { showLoading, showSuccess, showError } = useIndicator();

    const [open, setOpen] = useState(false);
const [accountStatementVisible, setAccountStatementVisible] = useState(false);
    const [transactions, setTransactions] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    //const [searchQuery, setSearchQuery] = useState('');
    const navigation = useNavigation();
    const [isAddViewVisible, setIsAddViewVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isReportModalVisible, setReportModalVisible] = useState(false);
        const [fetchParam, setFetchParam] = useState('');
    const [showReportBars, setShowReportBars] = useState(false);
    const [reportBarValues, setReportBarValues] = useState([]);
    const [usdBalance, setUsdBalance] = useState(0);
    const [lbpBalance, setLbpBalance] = useState(0);
    const [showBalance, setShowBalance] = useState(false);
    const [IsEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const { searchQuery } = useContext(SearchContext);
    const [filteredData, setFilteredData] = useState([]);
    
    const [fin_id_Acc_Stmt, setFin_id_Acc_Stmt] = useState('');
    const [date_start_Acc_Stmt, setDate_start_Acc_Stmt] = useState('');
    const [date_end_Acc_Stmt, setDate_end_Acc_Stmt] = useState('');
const[text_Acc_Stmt, setText_Acc_Stmt] = useState('');
const[Balance_Fin_id, setBalance_Fin_id] = useState('');

// Function to get the formatted start and end dates
function getStartAndEndDates() {
    const endDate = new Date();
    const startDate = new Date();

    // Set the start date to 30 days before today
    startDate.setDate(startDate.getDate() - 30);

    // Format the dates to 'YYYY-MM-DD HH:MM:SS' format
    const format = date => 
        `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;

    return {
        startDate: format(startDate),
        endDate: format(endDate)
    };
}


const { refreshAccounting, setRefreshAccounting, } = useContext(RefreshContext);

  useFocusEffect(
    useCallback(() => {
      const refreshData = async () => {
        if (refreshAccounting) {
          await fetchTransactions();
          setRefreshAccounting(false); // Reset the flag after refreshing
          console.log('Data refreshed and flag reset');
        }
      };
      refreshData();
      console.log('Data refreshed and flag reset:', refreshAccounting);
    }, [refreshAccounting])
  );

useEffect(() => {
    const { startDate, endDate } = getStartAndEndDates();

    setFetchParam({
        method: 'select_transactions',
        params: {
            date_start: startDate,
            date_end: endDate,
        },
    });
}, []);



    useEffect(() => {
        //console.log("Inside useEffect with fetchParam:", fetchParam);
        if (fetchParam) {
            //console.log("Calling fetchTransactions");
            fetchTransactions();
        }
    }, [fetchParam]);
    



    //######################### Reporting Modal 

    const handleReportModalClose = () => {
       setReportModalVisible(false);
    };

    const handleReportModalDone = async (values) => {
        //console.log('Modal done with values:', values);
        setFetchParam({ 
            method: 'select_transactions', 
            params: { fin_id: values.id, date_start: values.date1, date_end: values.date2 } 
        });
        
        // Convert date objects to strings before setting the reportBarValues
        setReportBarValues([values.date1.toLocaleDateString(), values.date2.toLocaleDateString(), values.text]);
    setTransactions('');
        try {
            // Await the completion of fetchTransactions before proceeding
            await fetchTransactions();
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    
        setReportModalVisible(false);
        setShowReportBars(true);
        setShowBalance(true);
        setBalance_Fin_id(values.id);
        console.log('Value.id:' , values.id);

     
    };

    const onStmt = async (values) => {
      
        setReportModalVisible(false);

        setDate_end_Acc_Stmt(values.date2);
        setDate_start_Acc_Stmt(values.date1);
        setFin_id_Acc_Stmt(values.id);
        setAccountStatementVisible(true);
        setText_Acc_Stmt(values.text);
    };

    /*
     const handleReportModalDone = async (values) => {
        //console.log('Modal done with values:', values);
        setFetchParam({ 
            method: 'select_transactions', 
            params: { fin_id: values.id, date_start: values.date1, date_end: values.date2 } 
        });
        
        // Convert date objects to strings before setting the reportBarValues
        setReportBarValues([values.date1.toLocaleDateString(), values.date2.toLocaleDateString(), values.text]);
    setTransactions('');
        try {
            // Await the completion of fetchTransactions before proceeding
            await fetchTransactions();
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    
        setReportModalVisible(false);
        setShowReportBars(true);
        setShowBalance(true);
        calculateBalance(values.id);
    };
    
    */
    useEffect(() => {
        if (showBalance) {
            calculateBalance(Balance_Fin_id);
        }
    }, [showBalance, transactions]);

    const calculateBalance =(fin_id) =>{
        
            const fin_id_str = fin_id.toString();

            const usdTo = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'usd' && transaction.to.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);
            
            // Do the same for usdFrom, lbpTo, and lbpFrom
            

            const usdFrom = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'usd' && transaction.from.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            const lbpTo = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'lbp' && transaction.to.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            const lbpFrom = transactions.reduce((sum, transaction) => {
                if (transaction.currency.toLowerCase() === 'lbp' && transaction.from.id === fin_id_str) {
                    return sum + parseFloat(transaction.amount);
                }
                return sum;
            }, 0);

            setUsdBalance(usdTo - usdFrom);
            setLbpBalance(lbpTo - lbpFrom);
            //console.log(lbpBalance);
            //console.log(usdBalance);
        
        }
  //  }
    
    //############################



    const fetchTransactions = async () => {
        setLoading(true);
        setError(null);
    
        try {
            const data = await fetchData(fetchParam);
    
            console.log('KAZA:',data); // Log the API response
    
            if (data && Array.isArray(data)) {
                const sortedData = data.sort((a, b) => {
                    // Convert date strings to Date objects for comparison
                    const dateA = new Date(a.date_entered);
                    const dateB = new Date(b.date_entered);
    
                    if (dateB - dateA !== 0) {
                        // Sort by date in descending order
                        return dateB - dateA;
                    } else {
                        // If dates are equal, sort by ID in descending order
                        return b.id - a.id;
                    }
                });
    
                setTransactions(sortedData);
            } else {
                throw new Error('Data format is incorrect');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    
    

    const filteredTransactions = transactions 
    ? transactions.filter(transaction => {
        return (
            transaction.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            transaction.from.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            transaction.to.title.toLowerCase().includes(searchQuery.toLowerCase())
            // Add more fields if needed
        );
    }) 
    : [];


    const toggleCollapsible = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    const editItem = (id) => {
        // Your edit logic here
    };

  

    const Separator = () => {
        return (
            <View style={{ height: 1, backgroundColor: '#ccc' }} />
        );
    };

    const handleFilterBarClose = () => {
        const { startDate, endDate } = getStartAndEndDates();

        setShowReportBars(false);
        setReportBarValues([]);  // Reset the report bar values
        setShowBalance(false);
        setFetchParam({
            method: 'select_transactions',
            params: {
                date_start: startDate,
                date_end: endDate,
            },
        });
    };
    
    const handleCloseModal = async (newItem) => {
       // console.log(newItem);
           setTransactions(currentTransactions => [newItem, ...currentTransactions]);

        showLoading();

        const updatedDataItem = {
            ...newItem,
            id: '',
          };
        try { 
            const param = {
            method: 'insert_transaction',
            params: updatedDataItem
        };
        const responseData = await executePostRequest(param);

        if (responseData && responseData.id) {
            console.log('Transaction created successfully:', responseData);

            // Handle the successful response
            // For example, update the UI or navigate to another screen
            showSuccess();

        } else {
            // Handle the case where the response doesn't have an 'id' field
            console.error('Transaction creation failed:', responseData);
            // Show error message to the user
          showError();
        }

    } catch (error) {
        console.error('Error:', error);
        // Show error message to the user
        showError();
    } finally {
            // setIsSubmitting(false);
            //onClose();
            fetchTransactions();
        }
       
    }


    const handleItemPress = (item) => {
        //console.log(item);
        setSelectedItem(item);
        setIsEditModalVisible(true);
      };
    
      const handleItemUpdate = (updatedItem) => {
        setTransactions((currentFilteredData) =>
          currentFilteredData.map((item) => (item.id === updatedItem.id ? updatedItem : item))
        );
      };
    


      
      const deleteItem = async (id) => {
        showLoading();
        try {
            // Wait for the DELETE_ITEM_API to complete
            const responseData = await DELETE_ITEM_API('delete_transaction', { id: id });
    
            // Proceed with onSuccess logic after DELETE_ITEM_API resolves
            const newData = transactions.filter(item => item.id !== id);
            setTransactions(newData);
    showSuccess();
            // Uncomment this to log the response data
            // console.log("Item deleted successfully:", responseData);
        } catch (error) {
            // onFailure logic if DELETE_ITEM_API rejects
            Alert.alert('Error', 'Failed to delete item');
            showError();
        }
    };
    
      
      /**
       * Displays a confirmation dialog for deleting the item with the given ID.
       * If the user confirms the deletion, deletes the item from the API and updates the state.
       * @param {number} id - The ID of the item to delete.
       */
      const showDeleteConfirmation = (id) => {
        Alert.alert(
          "حذف اجراء", // Title
          "هل انت متأكد من رغبتك في حذف هذا الاجراء؟", // Message
          [
            {
              text: "كلا",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel"
            },
            {
              text: "نعم",
              onPress: () => deleteItem(id)
            }
          ],
          { cancelable: false } // This makes it so the user has to select one of the options
        );
      };
    



    return (
        
        <SafeAreaView style={{ flex: 1 }}>
          
           {/* <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: 'white' }}>
                <Ionicons
                    name="add-circle"
                    size={30}
                    color="#000"
                    style={{ marginLeft: 10 }}
                    onPress={() => setIsModalVisible(true)}
                />
               

                <TextInput
                    style={styles.searchBox}
                    value={searchQuery}
                    //onChangeText={setSearchQuery}
                    placeholder="بحث..."
                />
 <Ionicons
                    name="filter-outline"
                    size={30}
                    color="#000"
                    style={{ marginLeft: 10 }}
                    onPress={() => setReportModalVisible(true)}
                />

               

               



    </View>*/}
       
               
            <FilterBar showReportBars={showReportBars} onClose={handleFilterBarClose} data={reportBarValues} showChips={true} />
              

           
                
                <FlashList
               // style={{ flex: 1 }}

                data={filteredTransactions}
                //  style={{ padding: 20 }}
                    renderItem={({ item }) => {

                        const getTitle = (titleString) => {
                            try {
                                const titleObject = JSON.parse(titleString);
                                if (titleObject.title) {
                                    return titleObject.title;
                                }else if (titleObject.result) {
                                    if (titleObject.result === 'invoiceAcc') {
                                        return 'حساب الفواتير';
                                    }else if (titleObject.result === 'salariesAcc'){
                                        return 'حساب الرواتب';
                                    }else if (titleObject.result === 'miscAcc'){
                                        return 'حساب النفقات المختلفة';
                                    }else if (titleObject.result === 'lawfirm'){
                                        return 'الصندوق';
                                    }else if (titleObject.result === 'syndicate'){
                                        return 'النقابة';
                                    }else if (titleObject.result === 'expensesAcc'){
                                        return 'حساب الاتعاب والنفقات';
                                    }
                                    
                                    
                                }  
                                else if (titleObject.first_name || titleObject.middle_name || titleObject.last_name) {
                                    return `${titleObject.first_name || ''} ${titleObject.middle_name || ''} ${titleObject.last_name || ''}`.trim();
                                }
                               else {
                                    return '****';  // Default to returning the original string if no recognized properties are found
                                }
                            } catch (error) {
                                return titleString;
                            }
                        };
                        
                        const displayNameFrom = item.id <0 ? item.fromTitle : getTitle(item.from.title);
                        const displayNameTo = item.id <0 ? item.toTitle :  getTitle(item.to.title);
                        

                        return (
                            <View style={item.id <0 ? styles.disabledView : {}}
pointerEvents={item.id <0 ? 'none' : 'auto'}
>
                            <Drawer
                            rightItems={[{ text: 'تعديل', background: Colors.blue30, onPress: () => handleItemPress(item)}]}
                            leftItem={{ text: 'حذف', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
                            >

                                <View centerV padding-s4 bg-white >
                                    <View style={styles.innerRow}>
                                    <Text style={{ fontSize: 12, color: 'grey' }}>
    {item.id < 0 
        ? new Date(item.date_entered).toISOString().split('T')[0]
        : item.date_entered}
</Text>


                                        <Text style={styles.cell}>{item.amount} </Text>
                                        <Text style={styles.cell}>{item.currency === 'lbp' ? 'ل.ل' : 'دولار'}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                                        <View style={{
                                            backgroundColor: '#D36135',
                                            padding: 5,
                                            width: 150, // fixed width
                                            borderRadius: 10, // rounded corners
                                            borderWidth: 0, // border width
                                            borderColor: '#D36135', // border color

                                        }}>
                                            <Text style={styles.cellText}>{displayNameFrom}</Text>
                                        </View>
                                        <Text style={{ fontSize: 24 }}> ← </Text>

                                        <View style={{
                                            backgroundColor: '#7FB069',
                                            padding: 5,
                                            width: 150, // fixed width
                                            borderRadius: 10, // rounded corners
                                            borderWidth: 0, // border width
                                            borderColor: '#7FB069', // border color
                                        }}>
                                            <Text style={styles.cellText}>{displayNameTo}</Text>
                                        </View>
                                    </View>




                                    <Text style={{ fontSize: 15 }}>{item.description}</Text>
                                </View>


                            </Drawer>
                            </View>
                        );
                    }}
                    keyExtractor={(item) => item.id.toString()}
                    ItemSeparatorComponent={Separator}
                    estimatedItemSize={100}


                />


            
{selectedItem && (
<AccountEdit
    isVisible={IsEditModalVisible} 
    onClose={() => setIsEditModalVisible(false)} 
    onEntryUpdated={(selectedID, value, date) => {
        const updatedItem = { ...selectedItem, description: value, date: date };
        handleItemUpdate(updatedItem);
      }}
    initialValue={selectedItem.description}
    //initialType={selectedItem.type}
    initialDate={new Date(selectedItem.date_entered)}
    //initialTime={new Date(selectedItem.deadline)}
    //TypeValues={typeValues}
    initialID={selectedItem.id}
          
  />
)}


            {error && <Text style={{ color: 'red' }}>{error}</Text>}
            {showBalance && (
            <View style={styles.balanceBar}>
                <Text style={styles.balanceText}>USD Balance: {usdBalance}</Text>
                <Text style={styles.balanceText}>LBP Balance: {lbpBalance}</Text>
            </View>
        )}
        
<SpeedDial
    isOpen={open}
    icon={{ name: 'edit', color: '#fff' }}
    openIcon={{ name: 'close', color: '#fff' }}
    onOpen={() => setOpen(!open)}
    onClose={() => setOpen(!open)}
  >
    <SpeedDial.Action
      icon={{ name: 'add', color: '#fff' }}
      title="اضافة"
      onPress={() => {setIsModalVisible(true); setOpen(!open)}}
      />
    <SpeedDial.Action
      icon={{ name: 'filter-list', color: '#fff' }}
      title="تصفية"
      onPress={() => {setReportModalVisible(true); setOpen(!open)}}
      />
  </SpeedDial>

    {isModalVisible && (
        <AccountingModal 
            isVisible={isModalVisible}
            setIsModalVisible ={setIsModalVisible}
            onClose={handleCloseModal}
            selectedFromData={[null]}
            selectedToData={[null]}
        />
    )}

    {isReportModalVisible && (
        <AccoutningReportsFilter
            isVisible={isReportModalVisible}
            onClose={handleReportModalClose}
            onDone={handleReportModalDone}
            onStmt={onStmt}
        />
    )}

    {accountStatementVisible && (
        <AccountStatement
            isVisible={accountStatementVisible}
            onClose={handleReportModalClose}
            onDone={() => setAccountStatementVisible(false)}
            fin_id={fin_id_Acc_Stmt}
            date_end={date_end_Acc_Stmt}
            date_start={date_start_Acc_Stmt}
            text={text_Acc_Stmt}
        />
    )}
    



        </SafeAreaView>
    );
};


const styles = StyleSheet.create({
    disabledView: {
        opacity: 0.3,
      },
  balanceBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#006989',
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
},

    balanceText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'white'
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        borderWidth: 1,
        borderColor: 'black',
        backgroundColor: 'lightgray'
    },
    headerText: {
        flex: 1,
        padding: 10,
        borderRightWidth: 1,
        borderColor: 'black'
    },
    fixedView: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: 10,
        backgroundColor: 'lightgray',
        alignItems: 'center'
    },
    row: {
        padding: 10,
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        marginBottom: 10,
    },
    innerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10

    },
    date: {
        flex: 1,
        fontSize: 16,
        fontWeight: 'bold',
    },
    cell: {
        flex: 1,
        textAlign: 'right',
        fontSize: 16,
        fontWeight: 'bold',
    },
    description: {
        //flexShrink: 1,
        color: 'grey',
        fontSize: 12,
    },
    cellText: {
        //flexShrink: 1,
        color: 'white',
        fontSize: 12,
        alignSelf: 'center',

    },
    searchBox: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ccc', marginRight: 10, paddingHorizontal: 10, backgroundColor: 'white', marginLeft: 10
    },
});

export default TransactionFetcher;