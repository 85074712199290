import React, { useState, useEffect } from 'react';
import { Modal, TouchableOpacity, StyleSheet } from 'react-native';
import { View, Text, Image } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import Styles from '../../../Styles/Styles';
import FlipCard from 'react-native-flip-card';
import { useNavigation } from '@react-navigation/native';
import EditOppoModal from '../components/EditOppoModal';
import { fetchData } from '../../../utils/api';

const OppoDetailsScreen = ({ route }) => {
  const { item } = route.params;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const navigation = useNavigation();
  const [isEditMode, setIsEditMode] = useState(false);

  const [oppoData, setOppoData] = useState({
    first_name:'',
 

  });


  const openFlipCardModal = () => {
    setIsModalVisible(true);
  };

  const closeFlipCardModal = () => {
    setIsModalVisible(false);
  };


  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };
  
  const handleSaveEdit = async (editedData) => {
    saveChanges(editedData);
    setIsEditModalVisible(false);
    navigation.navigate('OppoDetails', { item: editedData }); // Navigate to the desired screen with data

  };

  const handleOpenEditModal = (client) => {
    setOppoData(client); // Set the client data to be edited
    setIsEditModalVisible(true);
  };

  useEffect(() => {
    const title = `${item.first_name} ${item.middle_name} ${item.last_name}`;

    navigation.setOptions({
      title: title,  // Set your dynamic title here

      headerRight: () => (
        <View style={{ flexDirection: 'row', paddingRight: 10 }}>
          
        <TouchableOpacity  onPress={()=>handleOpenEditModal(item)} style={{ marginRight: 15 }}>
        <Ionicons name="create-outline" size={25} color="#9acd32" />
      </TouchableOpacity>

    


      </View>
      )
    });
  }, [navigation, item]);

  


  const saveChanges = async (oppoData) => {
    //console.log(oppoData);
      try {
        // Prepare updateData object
        const updateData = {
          method: "update_opponent",
          params: {
            ...oppoData,
                  },
        };
    
        const response = await fetchData(updateData);
        //console.log("Raw API Response:", response);
     if (response === 1) {
          // Handle the success case
        } else {
          // Handle unexpected response
        }
      
    } catch (error) {
      console.error("Error updating client data:", error);
      // Handle error
    }
    // Exit edit mode
    setIsEditMode(false);
    //fetchOpponentDetails();
    };


  return (
   

  <View style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>

    

  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الاسم الأول</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.first_name}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأب</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.middle_name}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الشهرة</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.last_name}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأم</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mother_name}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>محل الولادة</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_place}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم السجل</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_number}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ الولادة</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>
{item.dob !== "1970-01-01" ? item.dob : ""}
</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الجوال</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mobile}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>البريد الالكتروني</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.email}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>العنوان</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.address}</Text>
    </View>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>ملاحظات</Text>
    </View>
    <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
      <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.notes}</Text>
    </View>
  </View>


  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row1}>
<View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
   <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>صورة الهوية</Text>
 </View>

{  item.civil_id_photo_front_base64 ? (
  <View style={{flex:1, marginTop:5}} >
<FlipCard 
       flip={false} 
       flipHorizontal={true}
       flipVertical={false}
       perspective={1000}
     >
       {/* Front Side */}
       <View>
      
  <Image
    source={{ uri: item.civil_id_photo_front_base64 }} style={{ width: '100%', height: 100 }} />
       
       </View>
       {/* Back Side */}
       <View>
     
  <Image
    source={{ uri: item.civil_id_photo_back_base64}} style={{ width: '100%', height: 100 }} />
       
          
       </View>
     </FlipCard>
     <TouchableOpacity 
  style={styles.magnifierIcon} 
onPress={() => openFlipCardModal(item)}>

  <Ionicons name="ios-search" size={24} color="black" />
</TouchableOpacity>
</View>
): 
<Text>
لا يوجد
</Text>}



</View>




{isEditModalVisible &&
<EditOppoModal
      isVisible={isEditModalVisible}
      onClose={handleCloseEditModal}
      oppoData={oppoData}
      onSave={handleSaveEdit}
    />

}

  </View>
  );
}

const styles = StyleSheet.create({
  magnifierIcon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 15,
    padding: 5,
  },
  modalContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

export default OppoDetailsScreen;
