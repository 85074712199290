import React, { useState, useEffect, useContext, useCallback } from 'react';
import { View, Image, Text } from 'react-native';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from '../contexts/AuthContext';
import * as SecureStore from 'expo-secure-store';
import { useFocusEffect } from '@react-navigation/native';



function CustomDrawerContent(props) {
    const [base64Image, setBase64Image] = useState('');
    const [imageUri, setimageUri] = useState(`data:image/jpeg;base64,${base64Image}`);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { setIsAuthenticated } = useContext(AuthContext);

    const [role, setRole] = useState(null);

     // Function to fetch credentials and handle polling
     const fetchCredentials = async () => {
        const MAX_RETRIES = 10;  // Maximum number of retries
        const RETRY_DELAY = 500; // Delay between retries in milliseconds

        let retries = 0;

        while (retries < MAX_RETRIES) {
            try {
                const storedCredentialsString = await AsyncStorage.getItem('fetch_credentials');
                if (storedCredentialsString) {
                    const storedCredentials = JSON.parse(storedCredentialsString);
                    setRole(storedCredentials.role);
                    return; // Exit function when credentials are found
                }
            } catch (error) {
                console.error('Failed to fetch the credentials from AsyncStorage', error);
            }

            retries += 1;
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        }

        console.warn('Credentials not found after multiple attempts');
    };

    // Use useFocusEffect to call fetchCredentials when the drawer opens
    useFocusEffect(
        useCallback(() => {
            fetchCredentials();
        }, [])
    );
    const handleLogout = async () => {
        try {
            await SecureStore.deleteItemAsync('token');
            await SecureStore.deleteItemAsync('credentials');
  
            await AsyncStorage.clear();
            
            setIsAuthenticated(false);
        } catch (e) {
            console.error("Error deleting token:", e);
        }
    };

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const storedDataString = await AsyncStorage.getItem('fetch_credentials');
                if (!storedDataString) {
                    return;
                }

                const storedData = JSON.parse(storedDataString);
                if (storedData) {
                    if (storedData.photo) {
                        const base64String = storedData.photo.split(',').pop();
                        setBase64Image(base64String);
                    }
                    if (storedData.first_name) {
                        setFirstName(storedData.first_name);
                    }
                    if (storedData.last_name) {
                        setLastName(storedData.last_name);
                    }
                }
            } catch (error) {
                console.error("Error fetching data from AsyncStorage:", error);
            }
        };

        fetchImage();
    }, [role]);

    return (
        <DrawerContentScrollView {...props}>
            <View style={{ alignItems: 'center', padding: 20 }}>
                <Image
                    source={{ uri: `data:image/jpeg;base64,${base64Image}` }}
                    style={{ width: 100, height: 100, borderRadius: 50, borderColor: '#CAD8DE', borderWidth: 1 }}
                    defaultSource={require('../assets/defaultuser.png')} // Default user image if fetch fails
                />
                <Text style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}>
                    الاستاذ {firstName} {lastName}
                </Text>
            </View>

            <DrawerItem 
                label="الرئيسية"
                onPress={() => props.navigation.navigate('Home')}
            />
            <DrawerItem 
                label="الاعدادات"
                onPress={() => { /* Navigate to settings screen */ }}
            />
            {role === '0' && (
                <DrawerItem 
                    label="اعدادت الاستاذ"
                    onPress={() => props.navigation.navigate('Tabs')}
                />
            )}
            <DrawerItem 
                label="تسجيل الخروج"
                onPress={handleLogout}
            />
        </DrawerContentScrollView>
    );
}

export default CustomDrawerContent;
