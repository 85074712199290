import React, { useState, useEffect } from 'react';
import { View, Text, ActivityIndicator, Modal, TouchableOpacity } from 'react-native';
import { fetchData } from '../../../utils/api';
import { Ionicons } from '@expo/vector-icons'; // Import the Icon library
import Styles from '../../../Styles/Styles';



function LawsuitsDetails({ route }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility

  console.log('LawsuitsDetails ');



  const fetchApiData = async (method, params) => {
    try {
      return await fetchData({ method, params });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  useEffect(() => {
    const fetchLawsuitData = async () => {
      try {
        const lawsuitData = await fetchApiData('select_lawsuit', { id: route.params.id, lawsuit_info_id: route.params.lawsuit_info_id });
        const lawsuitInfoData = await fetchApiData('select_lawsuit_info', { detailed: true, id: route.params.lawsuit_info_id });
        console.log(lawsuitData);
        const combinedData = {
          lawsuitData,
          lawsuitInfoData,
        };
        setData(combinedData);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchLawsuitData();
  }, [route.params.id, route.params.lawsuit_info_id]);

 




  const LawsuitDetailsView = () => {
    const lawsuit = data.lawsuitData;
    const lawsuitInfo = data.lawsuitInfoData;
    
    return (
      <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الرقم الداخلي</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.l_nb || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ مكننة الملف</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.creation_date || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>المحامي المعين</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.user_full_name || 'N/A'}</Text>
  </View>
</View>


<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الدعوى</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.title || 'N/A'}</Text>
  </View>
</View>
        
<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>موضوع الدعوى</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.topic || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>نوع الدعوى</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuitInfo.type || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>المرحلة</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuitInfo.phase || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>المحكمة</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuitInfo.judicial_reference || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>المنطقة</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuitInfo.judicial_region || 'N/A'}</Text>
  </View>
</View>

<View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الغرفة</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.room || 'N/A'}</Text>
  </View>
</View>

  <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
  <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الاساس</Text>
  </View>
  <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
    <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{lawsuit.id_in_phase || 'N/A'}</Text>
  </View>
</View>










</View>
    );
  };




  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  return (
    <View dir={'rtl'} style={Styles.LawSuiteDetailsClientOpponentStyles.container}>
      {/* Step 3: Add a button with magnifier icon at the top right */}
      <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
        <Ionicons name="expand-outline" size={18} color="black" />
      </TouchableOpacity>

      {/* Step 5: Add a Modal component to your JSX */}
      <Modal
        transparent={false}
        visible={isModalVisible}
        animationType="slide"
      >
        <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
          <View>
            <Text style={{fontSize:20, alignSelf:'center', fontWeight:'bold', margin:20}}>تفاصيل الدعوى</Text>
            
          </View>
          <LawsuitDetailsView />
          <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.closeButton} onPress={() => setIsModalVisible(false)}>
            <Text style={Styles.LawSuiteDetailsClientOpponentStyles.closeButtonText}>Close</Text>
          </TouchableOpacity>
        </View>
      </Modal>

      <LawsuitDetailsView />
    </View>
  );
}
  



export default LawsuitsDetails;
