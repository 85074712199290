import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView, TouchableOpacity,Alert, Image, Modal, ActivityIndicator } from 'react-native';
import { getToken } from '../../../utils/api';
import Constants from 'expo-constants';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Camera } from 'expo-camera';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import PhoneInput from 'react-native-phone-number-input';


const OppoLawyerFormWizard = ({onSubmit}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const phoneInput = useRef(null);

    const [OppoLawyerType, setOppoLawyerType] = useState(null); // 'company' or 'individual'
    const [mobileValidationStatus, setMobileValidationStatus] = useState(null); // 'valid', 'invalid', or null
    const [OppoLawyerData, setOppoLawyerData] = useState({
        // Common fields
        mobileNumber: '',
        // Company fields
        companyTitle: '',
        capital: '',
        companyEmail: '',
        companyFiles: [],
        // Individual fields
        name: '',
        surename: '',
        fatherName: '',
        motherName: '',
        dob: '',
        placeOfBirth: '',
        registerNb: '',
        address: '',
        personalEmail: '',
        personalPictureUri: '',
        idFaceUri: '',
        idBackUri: '',
    });
    const apiUrl = Constants.expoConfig.extra.apiUrl;
    const [step1Title, setStep1Title] = useState("");




    const renderStepTitle = () => {
        const titles = {
            0: 'معلومات محامي الخصم',
            1: 'معلومات محامي الخصم',
            2: 'اضافة الصورة والهوية',
        };
        return (
            <View>
                <Text style={styles.stepTitle}>{titles[currentPage]}</Text>
                <View style={styles.titleUnderline} />
            </View>
        );
    };



    useEffect(() => {
        const checkMobileNumber = async () => {
            if (OppoLawyerData.mobileNumber.length === 9) {
                console.log("Formatted Number:", OppoLawyerData.mobileNumber);
                try {
                    const token = await getToken(); // Assuming getToken() is available to fetch the token
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ method: "check_mobile_lawyer_opponent", params: { mobile: OppoLawyerData.mobileNumber } }),
                    });
    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
    
                    const resultText = await response.text();
                    console.log("Response text:", resultText);
    
                    if (resultText.trim() === 'true') {
                        console.log('Mobile number validation OK');
                        setMobileValidationStatus('valid');
                    } else {
                        console.log('Mobile number validation not OK');
                        setMobileValidationStatus('invalid');
                    }
                } catch (error) {
                    console.error("Error in fetching data:", error);
                    setMobileValidationStatus('invalid');
                }
            } else {
                setMobileValidationStatus(null); // Reset validation status when number is changed
            }
        };
    
        checkMobileNumber();
    }, [OppoLawyerData.mobileNumber]);
    
    

    const handleMobileNumberChange = (text) => {
        let formattedText = text.replace(/\D/g, '');
        if (formattedText.length > 2) {
            formattedText = formattedText.substring(0, 2) + '-' + formattedText.substring(2, 8);
        }
        updateField('mobileNumber', formattedText);
    };

  
    const getTextInputStyle = () => {
        switch (mobileValidationStatus) {
            case 'valid':
                return [styles.input, styles.inputValid];
            case 'invalid':
                return [styles.input, styles.inputInvalid];
            default:
                return styles.input;
        }
    };

    
    const captureFromCamera = async (field) => {
        let { status } = await Camera.requestCameraPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera permissions to make this work!');
            return;
        }
    
        let result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
        });
    
        if (!result.canceled && result.assets) {
            handleAddImage(field, result.assets[0].uri);
        }
    };
    
    
  const pickPhoto = async (field) => {
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        quality: 1,
    });

    if (!result.canceled && result.assets) {
        handleAddImage(field, result.assets[0].uri);
    }
};

  
  


   
  
  const handlePress = (field) => {
    Alert.alert(
        'اختر خيارًا',
        'حدد ملفًا من المعرض أو التقط صورة',
        [
            { text: 'إلغاء', style: 'cancel' },
            { text: 'اختر من المعرض', onPress: () => pickPhoto(field) },
            { text: 'التقاط صورة', onPress: () => captureFromCamera(field) },
            // If you have a pickFile function, add it here as well.
        ],
        { cancelable: true }
    );
};

  

  const handleAddImage = (field, uri) => {
    setOppoLawyerData({ ...OppoLawyerData, [field]: uri });
};

  
  const compressImage = async (uri) => {
    const compressedImage = await ImageManipulator.manipulateAsync(
      uri,
      [],
      { compress: 0.5 }  // You can adjust the compression level as needed. 0.5 means 50% compression.
    );
    return compressedImage.uri;
  }
  
const handleSubmit = async () => {
    setIsLoading(true); // Start loading

    const convertToBase64 = async (uri) => {
        if (!uri) return null;
       // Get the file extension and MIME type
       const fileExtension = uri.split('.').pop();
       let mimeType;
       switch (fileExtension) {
           case 'jpg':
           case 'jpeg':
               mimeType = 'image/jpeg';
               break;
           case 'png':
               mimeType = 'image/png';
               break;
           case 'pdf':
               mimeType = 'application/pdf';
               break;
           default:
               mimeType = 'application/octet-stream';
       }
       const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
       return `data:${mimeType};base64,${data}`;
     
    };

    // Convert image URIs to base64
    const photoBase64 = OppoLawyerData.personalPictureUri ? await convertToBase64(OppoLawyerData.personalPictureUri) : null;
    const civilIdPhotoBackBase64 = OppoLawyerData.civil_id_photo_back ? await convertToBase64(OppoLawyerData.idBackUri) : null;
    const civilIdPhotoFrontBase64 = OppoLawyerData.civil_id_photo_front ? await convertToBase64(OppoLawyerData.idFaceUri) : null;

    

    const data = {
        method: "insert_lawyer_opponent",
        params: {
            id: null,//
            first_name: OppoLawyerData.name,//
            middle_name: OppoLawyerData.surename,//
            last_name: OppoLawyerData.fatherName,//
            mother_name: OppoLawyerData.motherName,//
            address: OppoLawyerData.address,//
            civil_id_photo_back: civilIdPhotoBackBase64,
            civil_id_photo_front: civilIdPhotoFrontBase64,
            creation_date: Math.floor(Date.now() / 1000), //
            dob: OppoLawyerData.dob,//
            email: OppoLawyerData.personalEmail, //
            mobile: OppoLawyerData.mobileNumber,//
            photo: photoBase64,
            register_number: OppoLawyerData.registerNb,//
            register_place: OppoLawyerData.placeOfBirth,//

            date_of_affiliation:'',

        }
    };

    try {
        const token = await getToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Server response:", result);
        setIsLoading(false); // Stop loading
        onSubmit(); 

    } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false); // Stop loading
    }
};

// Validation functions
const isMobileNumberValid = () => {
    return mobileValidationStatus === 'valid';
};

const isOppoLawyerTypeSelected = () => {
    return OppoLawyerType !== null;
};

const isCompanyDetailsValid = () => {
    return OppoLawyerType === 'company' ? clientData.companyTitle.trim() !== '' : true;
};

const isIndividualDetailsValid = () => {
    return  (OppoLawyerData.name.trim() !== '' && OppoLawyerData.surename.trim() !== '');
};




const handleNext = () => {

    switch (currentPage) {
        case 1:
          
           
            if (!isIndividualDetailsValid()) {
                Alert.alert("خطأ", "الرجاء ادخال اسم وشهرة محامي الخصم");
                return;
            }
            break;

       
           
    }

   
        if (currentPage < 1) {
            setCurrentPage(currentPage + 1);
        } else {
            // Submit the form for individual
            handleSubmit();
        
        }
};


    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const updateField = (field, value) => {
        setOppoLawyerData({ ...OppoLawyerData, [field]: value });
    };
/*
    const renderStep = () => {
        switch (currentPage) {
            case 0:
            return (
                <View style={styles.stepContainer}>
                                        <Text>للبدء بإضافة  محامي خصم جديد يرجى ادخال رقم الجوال للتأكد من عدم وجوده في القائمة</Text>

                    <TextInput
                        style={getTextInputStyle()}
                        placeholder="Mobile Number"
                        value={OppoLawyerData.mobileNumber}
                        onChangeText={handleMobileNumberChange}
                        keyboardType="number-pad"
                    />
                    
                   
                </View>
            );
            case 1:
                return OppoLawyerType === 'company' ? renderCompanyStep1() : renderIndividualStep1();
                case 2:
                    return OppoLawyerType === 'company' ? renderIndividualStep1() : renderIndividualStep2();
                  case 3:
                    return OppoLawyerType === 'company' ? renderIndividualStep2() : null;
                    

            default:
                return null;
        }
    };
*/


function getNumberAfterPossiblyEliminatingZero(text) {
    let number = text.replace(/^0+/, ''); // Remove leading zeros
    let formattedNumber = number; // Format number as needed
    return { number, formattedNumber };
}


 const renderStep = () => {
        switch (currentPage) {
            case 0:
                return (
                    <View style={styles.stepContainer}>
                                            <Text>للبدء بإضافة  محامي خصم جديد يرجى ادخال رقم الجوال للتأكد من عدم وجوده في القائمة</Text>
    


                                            <View style={styles.phonecontainer}>

<PhoneInput
                           ref={phoneInput}
                           defaultValue={OppoLawyerData.mobileNumber}
                           defaultCode="LB"
                           layout="first"
                           placeholder="ادخل رقم الهاتف"
                           textContainerStyle={[styles.textContainer, {
                              // borderColor: mobileValidationStatus ? 'green' : 'red',
                               direction: 'rtl',
                           }]}
                           containerStyle={{
                               direction: 'rtl'
                           }}
                          // In PhoneInput component
onChangeFormattedText={(text) => {
   const { number, formattedNumber } = getNumberAfterPossiblyEliminatingZero(text);
   setOppoLawyerData({ 
       ...OppoLawyerData, 
       mobileNumber: formattedNumber // Updated to mobileNumber
   });
   // Rest of your code
}}
                           autoFocus
                       />
   </View>





                   
                        
                       
                    </View>
                );
            case 1:
                return renderIndividualStep1();
           
            default:
                return null;
        }
    };
    
    const renderCompanyStep1 = () => (
        <View style={styles.stepContainer}>
            <TextInput
                style={styles.input}
                placeholder="Company Title"
                value={OppoLawyerData.companyTitle}
                onChangeText={(text) => updateField('companyTitle', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="Capital"
                value={OppoLawyerData.capital}
                onChangeText={(text) => updateField('capital', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="Company Email"
                value={OppoLawyerData.companyEmail}
                onChangeText={(text) => updateField('companyEmail', text)}
            />
        </View>
    );

    const renderIndividualStep1 = () => (
        <View style={styles.stepContainer}>
      
            <TextInput
                style={styles.input}
                placeholder="الاسم الاول"
                value={OppoLawyerData.name}
                onChangeText={(text) => updateField('name', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="الشهرة"
                value={OppoLawyerData.surename}
                onChangeText={(text) => updateField('surename', text)}
            />
            <TextInput
                style={styles.input}
                placeholder="اسم الاب"
                value={OppoLawyerData.fatherName}
                onChangeText={(text) => updateField('fatherName', text)}
            />
<TextInput
                style={styles.input}
                placeholder="اسم الام وشهرتها"
                value={OppoLawyerData.motherName}
                onChangeText={(text) => updateField('mothername', text)}
            />
            
            <TextInput
                style={styles.input}
                placeholder="محل الولادة"
                value={OppoLawyerData.placeOfBirth}
                onChangeText={(text) => updateField('placeOfBirth', text)}
            />
            
            <TextInput
                style={styles.input}
                placeholder="تاريخ الولادة"
                value={OppoLawyerData.dob}
                onChangeText={(text) => updateField('dob', text)}
            />

<TextInput
                style={styles.input}
                placeholder="رقم السجل"
                value={OppoLawyerData.registerNb}
                onChangeText={(text) => updateField('registerNb', text)}
            />

<TextInput
                style={styles.input}
                placeholder="العنوان"
                value={OppoLawyerData.address}
                onChangeText={(text) => updateField('address', text)}
            />

<TextInput
                style={styles.input}
                placeholder="البريد الالكتروني"
                value={OppoLawyerData.personalEmail}
                onChangeText={(text) => updateField('personalEmail', text)}
            />
            
            
            
                    </View>
    );

    const renderCompanyStep2 = () => (
        <View style={styles.stepContainer}>
            <Text>Upload company files</Text>
            {/* Implement company file upload functionality */}
        </View>
    );

    const renderIndividualStep2 = () => (
        <View style={styles.stepContainer}>
           
    
            <TouchableOpacity onPress={() => handlePress('personalPictureUri')}>
    <View style={styles.circlePlaceholder}>
        {OppoLawyerData.personalPictureUri ? 
            <Image source={{ uri: OppoLawyerData.personalPictureUri }} style={styles.circleImage} /> :
            <Text style={{ alignSelf: 'center' }}>صورة شخصية</Text>
        }
    </View>
</TouchableOpacity>
    

            <TouchableOpacity onPress={() => handlePress('idFaceUri')}>
                <View style={styles.rectanglePlaceholder} >
                {OppoLawyerData.idFaceUri ? 
            <Image source={{ uri: OppoLawyerData.idFaceUri }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الامامية)</Text>
}
                </View>

            </TouchableOpacity>
    
            <TouchableOpacity onPress={() => handlePress('idBackUri')}>
                <View style={styles.rectanglePlaceholder} >
                    {OppoLawyerData.idBackUri ? 
                                <Image source={{ uri: OppoLawyerData.idBackUri }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الخلفية)</Text>
                    }
                </View>

            </TouchableOpacity>
        </View>
    );
    

  

    return (
        <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
            {renderStepTitle()}
            {renderStep()}
        </ScrollView>
        <View style={styles.buttonContainer}>
           
            <Button
                title={OppoLawyerType === 'individual' ? (currentPage === 1 ? 'حفظ' : 'التالي') : (currentPage === 1 ? 'حفظ' : 'التالي')}
                onPress={handleNext}
               // disabled={mobileValidationStatus === 'invalid' || (currentPage === 0 && mobileValidationStatus !== 'valid')}
            />
             {currentPage > 0 && (
                <Button title="السابق" onPress={handlePrevious} />
            )}
        </View>
        <Modal
        visible={isLoading}
        transparent
        animationType="fade"
    >
        <View style={styles.modalBackground}>
           
            <View style={styles.activityIndicatorWrapper}>
            <Text>جاري الحفظ</Text>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        </View>
    </Modal>
    </View>
    );
    
};

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    circleImage: {
        width: '100%',
        height: '100%',
        borderRadius: 50,
    },
    
    circlePlaceholder: {
        width: 100,
        height: 100,
        borderRadius: 50, // Half of width and height to make it circle
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'
    },
    rectanglePlaceholder: {
        width: 200,
        height: 120,
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'

    },
    inputValid: {
        borderColor: 'green',
    },
    inputInvalid: {
        borderColor: 'red',
    },
   
    stepContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    radioContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 15,
    },
    
    radio: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    radioSelected: {
        backgroundColor: 'lightblue',
    },
    container: {
        flex: 1,
        justifyContent: 'space-between', // This will push the button container to the bottom
    },
    scrollViewContainer: {
        flexGrow: 1,
        padding: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: '#fff', // or any color that suits your design
        borderTopWidth: 1,
        borderColor: '#ddd',
    },
    stepTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 15,
    },
    titleUnderline: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        marginVertical: 5,
    }
});

export default OppoLawyerFormWizard;
