import React, { useState, useEffect, useContext, useCallback } from 'react';
import { View, FlatList, TouchableOpacity, ActivityIndicator, StyleSheet, Linking } from 'react-native';
import { Text, Image } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { fetchData } from '../../../utils/api';
import { useNavigation } from '@react-navigation/native';
import { SearchContext } from '../../../contexts/SearchContext';
import Styles from '../../../Styles/Styles';

const ClientListScreen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { searchQuery } = useContext(SearchContext);
  const [filteredData, setFilteredData] = useState([]);
  const navigation = useNavigation();

  useEffect(() => {
    
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await fetchData({ method: 'select_clients', params: {} });
      const sortedData = response.sort((a, b) => b.c_nb - a.c_nb);

      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        data.filter(item => {
          return item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 item.middle_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                 item.last_name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);


  const handlecall = (item) => {
    Linking.openURL(`tel:${item}`);
  };
  
  
  const handleWhatsApp = (item) => {
    Linking.openURL(`https://wa.me/${item}?text=`);
  };
  
  async function shareContact(item) {
    // 1. Create a vCard string
    const vCard = `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${item.first_name}\r\nN:${item.last_name};${item.first_name};;;\r\nTEL:${item.mobile}\r\nEMAIL:${item.email}\r\nEND:VCARD`;
  
  
    // 2. Write the vCard string to a file
    const fileName = 'contact.vcf';
    const fileUri = FileSystem.documentDirectory + fileName;
  
    await FileSystem.writeAsStringAsync(fileUri, vCard);
  
    // 3. Check if sharing is available
    const isAvailable = await Sharing.isAvailableAsync();
    if (!isAvailable) {
      alert('Uh oh, sharing is not available on your platform');
      return;
    }
  
    // 4. Share the file
    try {
      await Sharing.shareAsync(fileUri);
    } catch (error) {
      console.error("Error while sharing:", error);
    }
  }



useEffect(() => {
  const unsubscribe = navigation.addListener('focus', () => {
    // Run the function when the screen is focused
    fetchClients();
  });

  return unsubscribe;
}, [navigation, fetchClients]);


  const renderListItem = ({ item }) => (
    <TouchableOpacity 
      style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleContainer}
      onPress={() => navigation.navigate('ClientDetails', { item })}
      key={item.id.toString()}
    >
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleHeader}>
        <Image 
          source={{ uri: 'https://lh3.googleusercontent.com/-cw77lUnOvmI/AAAAAAAAAAI/AAAAAAAAAAA/WMNck32dKbc/s181-c/104220521160525129167.jpg' }}
          style={{ width: 50, height: 50, borderRadius: 25 }}
        />
        <View style={{ marginLeft: 10, flex: 1 }}>
          <Text grey10 text70BL>{item.first_name} {item.middle_name} {item.last_name} - ({item.c_nb})</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text style={[Styles.LawSuiteDetailsClientOpponentStyles.value, { flex: 1 }]}>{item.mobile}</Text>
            <TouchableOpacity onPress={() => handlecall(item.mobile)}>
              <Ionicons name="call" size={24} color="blue" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handleWhatsApp(item.mobile)} style={{ marginLeft: 10 }}>
              <Ionicons name="logo-whatsapp" size={24} color="green" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => shareContact(item)} style={{ marginLeft: 10 }}>
              <Ionicons name="share-outline" size={24} color="black" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          data={filteredData}
          renderItem={renderListItem}
          keyExtractor={item => item.id.toString()}
        />
      )}
    </View>
  );
};

export default ClientListScreen;
