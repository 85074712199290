import React, { useState, useContext, useEffect, useRef } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Animated, View, Image, TouchableOpacity, Modal, StyleSheet, Text, TouchableHighlight, TextInput } from 'react-native';
import { Switch, color } from 'react-native-ui-lib';
import { StatusBar } from 'react-native';
import { Ionicons, Entypo, FontAwesome5 } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SearchProvider, SearchContext } from '../contexts/SearchContext';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';

import { AuthContext } from '../contexts/AuthContext';
import MyTabs from './MyTabs';
import CustomDrawerContent from './CustomDrawerContent';
import NavigationStateContext from '../contexts/NavigationStateContext';
import { CustomIndicator } from '../contexts/IndicatorContext';
import OfficeSettingsMainScreen from '../src/OfficeSettings/OfficeSettingsMainScreen';


const Drawer = createDrawerNavigator();

function AppStack() {
  const [modalVisible, setModalVisible] = useState(false);
  // const { isDarkTheme, toggleTheme } = useContext(ThemeContext); // Ensure ThemeContext is imported

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const animValue = useRef(new Animated.Value(0)).current; // Animated value for the transition
  const { setSearchQuery } = useContext(SearchContext);


  const [base64Image, setBase64Image] = useState('');
  const [imageUri, setimageUri] = useState(`data:image/jpeg;base64,${base64Image}`);
  const { currentRoute, setCurrentRoute } = useContext(NavigationStateContext);



  const SearchHeader = ({ onBackPress }) => {
    const { setSearchQuery } = useContext(SearchContext);
    const [inputValue, setInputValue] = useState(''); // Local state to handle text input

    const handleSearch = () => {
      setSearchQuery(inputValue.trim()); // Update the search query in context when "Enter" is pressed
    };

    return (
      <View style={{ flexDirection: 'row-reverse', alignItems: 'center', backgroundColor: 'white', height: 50 }}>
        <TextInput
          style={{ flex: 1, marginLeft: 10, marginRight: 10, fontSize: 18, backgroundColor: '#E6ECEF', borderRadius: 15, padding: 5, paddingLeft: 40 }}
          placeholder="بحث..."
          value={inputValue}
          onChangeText={setInputValue} // Update local state
          autoCorrect={false}
          returnKeyType="search"
          onSubmitEditing={handleSearch} // Handle the "Enter" event
        />
        <Ionicons
          name="arrow-forward" // Use 'arrow-forward' for the opposite direction arrow
          size={24}
          color="#000"
          style={{ position: 'absolute', right: 10, padding: 10 }} // Position the icon inside the TextInput
          onPress={() => {
            handleSearch();
            if (onBackPress) onBackPress(); // Call onBackPress if it exists
          }}
        />
      </View>

    );
  };

  // Slide the search bar into view
  const slideIn = () => {
    Animated.timing(animValue, {
      toValue: 1,
      duration: 300, // Duration of the animation
      useNativeDriver: true,
    }).start();
  };

  // Slide the search bar out of view
  const slideOut = () => {
    Animated.timing(animValue, {
      toValue: 0,
      duration: 300, // Duration of the animation
      useNativeDriver: true,
    }).start();
  };

  // This function toggles the search bar visibility
  const toggleSearch = () => {
    if (isSearchVisible) {
      slideOut();
    } else {
      slideIn();
    }
    setIsSearchVisible(!isSearchVisible);
  };





  function CustomHeader({ navigation, isVisible = true, showSearch = true }) {
    const translateX = animValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, -100], // Adjust these values for your desired slide distance
    });
  
    if (!isVisible) {
      return null; // If not visible, return null or an empty view
    }
  
    return (
      <Animated.View style={{ transform: [{ translateX }] }}>
        {showSearch ? (
          isSearchVisible ? (
            <SearchHeader onBackPress={toggleSearch} />
          ) : (
            <View dir={'rtl'} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
                  <View style={{ flexDirection: 'row' }}>
              <Ionicons
                name="ellipsis-vertical"
                size={24}
                color="#000"
                style={{ marginHorizontal: 10 }}
                onPress={() => navigation.openDrawer()}
              />
              <Ionicons
                name="search"
                size={24}
                color="#000"
                style={{ marginRight: 10 }}
                onPress={toggleSearch}
              />
              </View>
              <CustomIndicator />
              <Image
                style={{ width: 100, height: 50, marginRight: 10 }}
                source={require('../assets/icons/logo-type3x.png')}
                resizeMode="contain"
              />
            </View>
          )
        ) : (
          <View dir={'rtl'} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
            <Ionicons
              name="ellipsis-vertical"
              size={24}
              color="#000"
              style={{ marginHorizontal: 10 }}
              onPress={() => navigation.openDrawer()}
            />
            <Text style={{ marginRight: 10, fontSize: 18, fontWeight: 'bold' }}>اعدادات الاستاذ</Text>
            <CustomIndicator />
            <Image
              style={{ width: 100, height: 50, marginRight: 10 }}
              source={require('../assets/icons/logo-type3x.png')}
              resizeMode="contain"
            />
          </View>
        )}
      </Animated.View>
    );
  }





  return (


    <>
      <StatusBar backgroundColor="#6B0F1A" barStyle="light-content" />
      <Drawer.Navigator
        drawerContent={props => <CustomDrawerContent {...props} />}
        screenOptions={{
          drawerPosition:'right',
          swipeEnabled: false, // This disables the slide gesture for all screens
          headerStyle: {
            backgroundColor: 'white', // This sets the header color
            shadowOpacity: 0,  // This removes the shadow from the header
            elevation: 0,  // This removes the shadow on Android
          },

        }}
      >
      <Drawer.Screen
  name="Home"
  component={MyTabs}
  options={() => {
    const { currentRoute } = useContext(NavigationStateContext);
    const isVisible = currentRoute !== 'Screen10'; // Adjust the condition as needed

    return {
      header: (props) => <CustomHeader {...props} isVisible={isVisible} />,
    };
  }}
/>

<Drawer.Screen name="Tabs" component={OfficeSettingsMainScreen} 
  options={{
    header: (props) => <CustomHeader {...props} isVisible={true} showSearch={false} />,
  }}
/>


      </Drawer.Navigator>
    </>


  );
}

//Define the styles for the components
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  }
});



export default AppStack;
