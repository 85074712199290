import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView, TouchableOpacity,Alert, Image, Modal, ActivityIndicator } from 'react-native';
import { getToken } from '../../../utils/api';
import Constants from 'expo-constants';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Camera } from 'expo-camera';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import PhoneInput from 'react-native-phone-number-input';
import { TextField } from 'react-native-ui-lib';


const OppoFormWizard = ({onSubmit}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const phoneInput = useRef(null);

    const [OppoType, setOppoType] = useState('individual'); // 'company' or 'individual'
    const [mobileValidationStatus, setMobileValidationStatus] = useState(null); // 'valid', 'invalid', or null
    const [OppoData, setOppoData] = useState({
        // Common fields
        mobileNumber: '',
        // Company fields
        companyTitle: '',
        capital: '',
        companyEmail: '',
        companyFiles: [],
        // Individual fields
        name: '',
        surename: '',
        fatherName: '',
        motherName: '',
        dob: '',
        placeOfBirth: '',
        registerNb: '',
        address: '',
        personalEmail: '',
        personalPictureUri: '',
        idFaceUri: '',
        idBackUri: '',
    });
    const apiUrl = Constants.expoConfig.extra.apiUrl;
    const [step1Title, setStep1Title] = useState("");




    const renderStepTitle = () => {
        const titles = {
            0: 'اضافة خصم',
            1: OppoType === 'company' ? 'معلومات الشركة / الجمعية' : 'معلومات الخصم',
            2: OppoType === 'company' ? 'معلومات الممثل القانوني' :'اضافة الصورة والهوية',
            3: OppoType === 'company' ? 'اضافة الصورة والهوية': '',
      
        };
        return (
            <View>
                <Text style={styles.stepTitle}>{titles[currentPage]}</Text>
                <View style={styles.titleUnderline} />
            </View>
        );
    };
   
    const validatePhoneNumber = async () => {
        const isFormatValid = phoneInput.current?.isValidNumber(OppoData.mobileNumber);
    
        if (isFormatValid) {
            try {
                const token = await getToken();
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ method: "check_mobile_opponent", params: { mobile: OppoData.mobileNumber } }),
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const resultText = await response.text();
                console.log("Response text:", resultText);
    
                if (resultText.trim() === 'true') {
                    console.log('Mobile number is valid and does not exist in the database');
                    return 'valid'; // Return validation result
                } else {
                    console.log('Mobile number is valid but already exists in the database');
                    return 'Valid-Existing'; // Return validation result
                }
            } catch (error) {
                console.error("Error in fetching data:", error);
                return 'invalid'; // Return validation result
            }
        } else {
            console.log('Mobile number format is not valid');
            return 'invalid'; // Return validation result
        }
    };
    

    const handleMobileNumberChange = (text) => {
        let formattedText = text.replace(/\D/g, '');
        if (formattedText.length > 2) {
            formattedText = formattedText.substring(0, 2) + '-' + formattedText.substring(2, 8);
        }
        updateField('mobileNumber', formattedText);
    };

  
    const getTextInputStyle = () => {
        switch (mobileValidationStatus) {
            case 'valid':
                return [styles.input, styles.inputValid];
            case 'invalid':
                return [styles.input, styles.inputInvalid];
            default:
                return styles.input;
        }
    };

    
    const captureFromCamera = async (field) => {
        let { status } = await Camera.requestCameraPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera permissions to make this work!');
            return;
        }
    
        let result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
        });
    
        if (!result.canceled && result.assets) {
            handleAddImage(field, result.assets[0].uri);
        }
    };
    
    
  const pickPhoto = async (field) => {
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        quality: 1,
    });

    if (!result.canceled && result.assets) {
        handleAddImage(field, result.assets[0].uri);
    }
};

  
  


   
  
  const handlePress = (field) => {
    Alert.alert(
        'اختر خيارًا',
        'حدد ملفًا من المعرض أو التقط صورة',
        [
            { text: 'إلغاء', style: 'cancel' },
            { text: 'اختر من المعرض', onPress: () => pickPhoto(field) },
            { text: 'التقاط صورة', onPress: () => captureFromCamera(field) },
            // If you have a pickFile function, add it here as well.
        ],
        { cancelable: true }
    );
};

  

  const handleAddImage = (field, uri) => {
    setOppoData({ ...OppoData, [field]: uri });
};

  
  const compressImage = async (uri) => {
    const compressedImage = await ImageManipulator.manipulateAsync(
      uri,
      [],
      { compress: 0.5 }  // You can adjust the compression level as needed. 0.5 means 50% compression.
    );
    return compressedImage.uri;
  }
  
const handleSubmit = async () => {
    setIsLoading(true); // Start loading

    const convertToBase64 = async (uri) => {
        if (!uri) return null;
       // Get the file extension and MIME type
       const fileExtension = uri.split('.').pop();
       let mimeType;
       switch (fileExtension) {
           case 'jpg':
           case 'jpeg':
               mimeType = 'image/jpeg';
               break;
           case 'png':
               mimeType = 'image/png';
               break;
           case 'pdf':
               mimeType = 'application/pdf';
               break;
           default:
               mimeType = 'application/octet-stream';
       }
       const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
       return `data:${mimeType};base64,${data}`;
     
    };

    // Convert image URIs to base64
    const photoBase64 = OppoData.personalPictureUri ? await convertToBase64(OppoData.personalPictureUri) : null;
    const civilIdPhotoBackBase64 = OppoData.civil_id_photo_back ? await convertToBase64(OppoData.civil_id_photo_back) : null;
    const civilIdPhotoFrontBase64 = OppoData.civil_id_photo_front ? await convertToBase64(OppoData.civil_id_photo_front) : null;

    

    const data = {
        method: "insert_opponent",
        params: {
            id: null,
            first_name: OppoData.name,
            middle_name: OppoData.fatherName,
            last_name: OppoData.surename,
            mother_name: OppoData.motherName,
            address: OppoData.address,
            civil_id_photo_back: civilIdPhotoBackBase64,
            civil_id_photo_front: civilIdPhotoFrontBase64,
          //  company_capital: OppoData.capital,
           // company_title: OppoData.companyTitle,
            creation_date: Math.floor(Date.now() / 1000), // Current timestamp
            dob: OppoData.dob,
            email: OppoType === 'company' ? OppoData.companyEmail : OppoData.personalEmail,
            first_name: OppoData.name,
           // is_company: OppoType === 'company' ? "1" : "0",
            mobile: OppoData.mobileNumber,
            photo: photoBase64,
            register_number: OppoData.registerNb,
            register_place: OppoData.placeOfBirth
        }
    };

    try {
        const token = await getToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Server response:", result);
        setIsLoading(false); // Stop loading
        onSubmit(); 

    } catch (error) {
        console.error("Error submitting form:", error);
        setIsLoading(false); // Stop loading
    }
};

// Validation functions
const isMobileNumberValid = () => {
    return mobileValidationStatus === 'valid';
};

const isOppoTypeSelected = () => {
    return OppoType !== null;
};

const isCompanyDetailsValid = () => {
    return OppoType === 'company' ? clientData.companyTitle.trim() !== '' : true;
};

const isIndividualDetailsValid = () => {
    return  (OppoData.name.trim() !== '' && OppoData.surename.trim() !== '');
};


const handleNext = async () => {
    if (currentPage === 0 && OppoData.mobileNumber) { // Updated to mobileNumber
        const validationStatus = await validatePhoneNumber();
        setMobileValidationStatus(validationStatus);
        // Rest of your code
    } else {
        proceedToNextStep();
    }
};




function getNumberAfterPossiblyEliminatingZero(text) {
    let number = text.replace(/^0+/, ''); // Remove leading zeros
    let formattedNumber = number; // Format number as needed
    return { number, formattedNumber };
}


useEffect(() => {
    if (currentPage === 0 && mobileValidationStatus === 'valid') {
        proceedToNextStep();
    } else if (currentPage === 0 && mobileValidationStatus === 'invalid') {
        Alert.alert("خطأ", "رقم الجوال غير صحيح، الرجاء التصحيح");
    }    else if (currentPage === 0 && mobileValidationStatus === 'Valid-Existing') {
        Alert.alert("خطأ", "الرقم مستخدم سابقًا");

    }
}, [mobileValidationStatus, currentPage]);

const proceedToNextStep = () => {

    switch (currentPage) {
   
        case 1:
            if (OppoType === 'company' && !isCompanyDetailsValid()) {
                Alert.alert("خطأ", "الرجاء ادخال اسم الشركة/الجمعية");
                return;
            }
            if (OppoType === 'individual' && !isIndividualDetailsValid()) {
                Alert.alert("خطأ", "الرجاء ادخال اسم وشهرة الخصم");
                return;
            }
            break;

        case 2:
            if (OppoType === 'company' && (!isCompanyDetailsValid() || !isIndividualDetailsValid())) {
                Alert.alert("خطأ", "الرجاء ادخال اسم وشهرة الممثل القانوني للشركة/الجمعية");
                return;
            }
    }

    if (OppoType === 'individual') {
        if (currentPage < 2) {
            setCurrentPage(currentPage + 1);
        } else {
            // Submit the form for individual
            handleSubmit();
        }
    } else if (OppoType === 'company') {
        if (currentPage < 3) {
            setCurrentPage(currentPage + 1);
        } else {
            // Submit the form for company
            handleSubmit();
        }
    }
};


    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const updateField = (field, value) => {
        setOppoData({ ...OppoData, [field]: value });
    };

    const renderStep = () => {
        switch (currentPage) {
            case 0:
            return (
                <View style={styles.stepContainer}>
                                        <Text>للبدء بإضافة خصم جديد يرجى ادخال رقم الجوال للتأكد من عدم وجوده في القائمة</Text>

              

 <View style={styles.phonecontainer}>

 <PhoneInput
                            ref={phoneInput}
                            defaultValue={OppoData.mobile}
                            defaultCode="LB"
                            layout="first"
                            placeholder="ادخل رقم الهاتف"
                            textContainerStyle={[styles.textContainer, {
                               // borderColor: mobileValidationStatus ? 'green' : 'red',
                                direction: 'rtl',
                            }]}
                            containerStyle={{
                                direction: 'rtl'
                            }}
                           // In PhoneInput component
onChangeFormattedText={(text) => {
    const { number, formattedNumber } = getNumberAfterPossiblyEliminatingZero(text);
    setOppoData({ 
        ...OppoData, 
        mobileNumber: formattedNumber // Updated to mobileNumber
    });
    // Rest of your code
}}
                            autoFocus
                        />
    </View>
  {/*
                    <View style={styles.radioContainer}>
                        <TouchableOpacity
                            style={[styles.radio, OppoType === 'individual' && styles.radioSelected]}
                            onPress={() => setOppoType('individual')}
                        >
                            <Text>شخص طبيعي</Text>
                        </TouchableOpacity>
                        
                      <TouchableOpacity
                            style={[styles.radio, OppoType === 'company' && styles.radioSelected]}
                            onPress={() => setOppoType('company')}
                        >
                            <Text>Company</Text>
            </TouchableOpacity>
                    </View>*/}
                </View>
            );
            case 1:
                return OppoType === 'company' ? renderCompanyStep1() : renderIndividualStep1();
                case 2:
                    return OppoType === 'company' ? renderIndividualStep1() : renderIndividualStep2();
                  case 3:
                    return OppoType === 'company' ? renderIndividualStep2() : null;
                    

            default:
                return null;
        }
    };



    
    const renderCompanyStep1 = () => (
        <View style={styles.stepContainer}>
             <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="Company Title"
                value={OppoData.companyTitle}
                onChangeText={(text) => updateField('companyTitle', text)}
            />
              <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="Capital"
                value={OppoData.capital}
                onChangeText={(text) => updateField('capital', text)}
            />
              <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="Company Email"
                value={OppoData.companyEmail}
                onChangeText={(text) => updateField('companyEmail', text)}
            />
        </View>
    );

    const renderIndividualStep1 = () => (
        <View style={styles.stepContainer}>
        <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الاسم الاول"
                value={OppoData.name}
                onChangeText={(text) => updateField('name', text)}
            />
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="الشهرة"
                value={OppoData.surename}
                onChangeText={(text) => updateField('surename', text)}
            />
             <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الاب"
                value={OppoData.fatherName}
                onChangeText={(text) => updateField('fatherName', text)}
            />
  <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="اسم الام وشهرتها"
                value={OppoData.motherName}
                onChangeText={(text) => updateField('motherName', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="محل الولادة"
                value={OppoData.placeOfBirth}
                onChangeText={(text) => updateField('placeOfBirth', text)}
            />
            
            <TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="تاريخ الولادة"
                value={OppoData.dob}
                onChangeText={(text) => updateField('dob', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="رقم السجل"
                value={OppoData.registerNb}
                onChangeText={(text) => updateField('registerNb', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="العنوان"
                value={OppoData.address}
                onChangeText={(text) => updateField('address', text)}
            />

<TextField
                containerStyle={styles.input}
                floatingPlaceholder
                placeholder="البريد الالكتروني"
                value={OppoData.personalEmail}
                onChangeText={(text) => updateField('personalEmail', text)}
            />
            
            
            
                    </View>
    );

    const renderCompanyStep2 = () => (
        <View style={styles.stepContainer}>
            <Text>Upload company files</Text>
            {/* Implement company file upload functionality */}
        </View>
    );

    const renderIndividualStep2 = () => (
        <View style={styles.stepContainer}>
           
    
            <TouchableOpacity onPress={() => handlePress('personalPictureUri')}>
    <View style={styles.circlePlaceholder}>
        {OppoData.personalPictureUri ? 
            <Image source={{ uri: OppoData.personalPictureUri }} style={styles.circleImage} /> :
            <Text style={{ alignSelf: 'center' }}>صورة شخصية</Text>
        }
    </View>
</TouchableOpacity>
    

            <TouchableOpacity onPress={() => handlePress('civil_id_photo_front')}>
                <View style={styles.rectanglePlaceholder} >
                {OppoData.civil_id_photo_front ? 
            <Image source={{ uri: OppoData.civil_id_photo_front }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الامامية)</Text>
}
                </View>

            </TouchableOpacity>
    
            <TouchableOpacity onPress={() => handlePress('civil_id_photo_back')}>
                <View style={styles.rectanglePlaceholder} >
                    {OppoData.civil_id_photo_back ? 
                                <Image source={{ uri: OppoData.civil_id_photo_back }} style={styles.rectanglePlaceholder} /> :

                <Text style={{alignSelf:'center'}}>بطاقة الهوية (الجهة الخلفية)</Text>
                    }
                </View>

            </TouchableOpacity>
        </View>
    );
    

  

    return (
        <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
            {renderStepTitle()}
            {renderStep()}
        </ScrollView>
        <View style={styles.buttonContainer}>
           
            <Button
                title={OppoType === 'individual' ? (currentPage === 2 ? 'حفظ' : 'التالي') : (currentPage === 3 ? 'حفظ' : 'التالي')}
                onPress={handleNext}
               // disabled={mobileValidationStatus === 'invalid' || (currentPage === 0 && mobileValidationStatus !== 'valid')}
            />
             {currentPage > 0 && (
                <Button title="السابق" onPress={handlePrevious} />
            )}
        </View>
        <Modal
        visible={isLoading}
        transparent
        animationType="fade"
    >
        <View style={styles.modalBackground}>
           
            <View style={styles.activityIndicatorWrapper}>
            <Text>جاري الحفظ</Text>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        </View>
    </Modal>
    </View>
    );
    
};

const styles = StyleSheet.create({  textContainer: {
    paddingHorizontal: 10,
    borderWidth: 1, // Add border to see color change
},
    modalBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    circleImage: {
        width: '100%',
        height: '100%',
        borderRadius: 50,
    },
    
    circlePlaceholder: {
        width: 100,
        height: 100,
        borderRadius: 50, // Half of width and height to make it circle
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'
    },
    rectanglePlaceholder: {
        width: 200,
        height: 120,
        backgroundColor: '#ddd',
        marginVertical: 10,
        justifyContent:'center'

    },
    inputValid: {
        borderColor: 'green',
    },
    inputInvalid: {
        borderColor: 'red',
    },
   
    stepContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    input: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    radioContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        marginBottom: 15,
    },
    
    radio: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    radioSelected: {
        backgroundColor: 'lightblue',
    },
    container: {
        flex: 1,
        justifyContent: 'space-between', // This will push the button container to the bottom
    },
    phonecontainer: {
       padding:50,
    marginTop: 10,
    marginBottom:10,
  },
    scrollViewContainer: {
        flexGrow: 1,
        padding: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: '#fff', // or any color that suits your design
        borderTopWidth: 1,
        borderColor: '#ddd',
    },
    stepTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 15,
    },
    titleUnderline: {
        borderBottomWidth: 1,
        borderColor: '#ddd',
        marginVertical: 5,
    }
});

export default OppoFormWizard;
