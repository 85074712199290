import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, FlatList, Image, StyleSheet, Text, Alert, ActivityIndicator} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Camera } from 'expo-camera';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import { fetchFileLink } from '../utils/api';
import * as ImageManipulator from 'expo-image-manipulator';


const FilePhotoPicker = ({ initialUris, onFileChange }) => {
  const [imageUris, setImageUris] = useState(initialUris.map(uriObj => ({
    ...uriObj,
    loading: false
  })));
  const [loading, setLoading] = useState(false);

  // State to track deleted IDs and added URIs
  const [deletedFileIds, setDeletedFileIds] = useState([]);
  const [addedFileUris, setAddedFileUris] = useState([]);
  const isMountedRef = useRef(false);


  useEffect(() => {
    if (isMountedRef.current) return; // Skip if already mounted
    isMountedRef.current = true;

    console.log("Initial URIs: ", initialUris);
    if (initialUris && initialUris.length > 0) {
      setLoading(true);
      fetchFileLinks(initialUris).then(() => setLoading(false));
    }
  }, [initialUris]);


   // Function to fetch all file links for a given array of IDs
const fetchFileLinks = async (fileIds) => {
  // Map fileIds to an array of objects with uri, id, and loading state
  const filesWithLoading = fileIds.map(id => ({ id, uri: '', loading: true }));
  setImageUris(filesWithLoading);

  // Fetch links and update state with loaded files
  const links = await Promise.all(filesWithLoading.map(async (file) => {
    const uri = await fetchFileLink(file.id);
    return { ...file, uri, loading: false };  // Set loading to false once it's fetched
  }));

  setImageUris(links);
};
    



const handleRemoveImage = (item) => {
  if (item.id) {
    // It's an existing file with an ID
    setDeletedFileIds(currentIds => [...currentIds, item.id]); // Store the ID to be deleted
  }
  setImageUris(currentUris => currentUris.filter(currentItem => currentItem.id !== item.id));
};

const handleAddImage = (uriOrUris) => {
  const urisToAdd = Array.isArray(uriOrUris) ? uriOrUris : [uriOrUris];
  const newImages = urisToAdd.map(uri => ({ id: undefined, uri, loading: false }));
  setAddedFileUris(currentUris => [
    ...currentUris,
    ...newImages
  ]);
  setImageUris(currentUris => [
    ...currentUris,
    ...newImages
  ]);

 // console.log('IMAGEURIS: ',imageUris);
};


    
useEffect(() => {
  // You may need to adjust the onFileChange callback to handle this new data structure
  onFileChange(deletedFileIds, addedFileUris.map(file => file.uri));
 // console.log(addedFileUris);
}, [deletedFileIds, addedFileUris]);




const captureFromCamera = async () => {
  let { status } = await Camera.requestCameraPermissionsAsync();
  if (status !== 'granted') {
      alert('Sorry, we need camera permissions to make this work!');
      return;
  }

  let result = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      quality: 1,
  });

  if (!result.canceled && result.assets) {
      const compressedUris = await Promise.all(result.assets.map(async asset => {
          return await compressImage(asset.uri);
      }));
      handleAddImage(compressedUris);
  }
};

  
  const pickPhoto = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        quality: 1,
    });

    if (!result.canceled && result.assets) {
        const compressedUris = await Promise.all(result.assets.map(async asset => {
            return await compressImage(asset.uri);
        }));
        handleAddImage(compressedUris);
    }
};

  
  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
  
    if (result.type !== 'cancel' && result.assets && result.assets.length > 0) {
      handleAddImage(result.assets[0].uri);
    }
  };
  const pickFromGallery = async () => {
    let { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
        return;
    }

    Alert.alert(
        'اختر خيارًا',
        'حدد ملفًا أو صورة',
        [
          { text: 'إلغاء', style: 'cancel' },
            { text: 'اختر صورة', onPress: pickPhoto },
            { text: 'اختر ملف', onPress: pickFile },
          
        ],
        { cancelable: true }
    );
    
};

  const handlePress = () => {
    Alert.alert(
        'اختر خيارًا',
        'حدد ملفًا من المعرض أو التقط صورة',
        [
          { text: 'إلغاء', style: 'cancel' },
            { text: 'اختر من المعرض', onPress: pickFromGallery },
            { text: 'التقاط صورة', onPress: captureFromCamera },
           
        ],
        { cancelable: true }
    );
};


const compressImage = async (uri) => {
  const compressedImage = await ImageManipulator.manipulateAsync(
    uri,
    [],
    { compress: 0.5 }  // You can adjust the compression level as needed. 0.5 means 50% compression.
  );
  return compressedImage.uri;
}

 
const renderItem = ({ item }) => {
  // Check if 'item' is a base64 string for an image or a local file URI
  const isBase64Image = typeof item.uri === 'string' && item.uri.startsWith('data:image');
  const isLocalImage = typeof item.uri === 'string' && (item.uri.startsWith('file://') || item.uri.startsWith('content://'));
  // Determine if the item is any kind of image (either base64 or local)
  const isImage = isBase64Image || isLocalImage;

  return (
    <View style={styles.itemContainer}>
      {item.loading ? (
        <ActivityIndicator size="large" color="#0000ff" />
      ) : isImage ? (
        // If it's an image, set the source uri to the item, whether it's a base64 string or a local URI
        <Image source={{ uri: item.uri }} style={styles.image} />
      ) : (
        // If it's not an image, display an icon or placeholder for other file types
        <Ionicons name="document-text-outline" size={100} color="black" />
      )}
      {!item.loading && (
        <TouchableOpacity onPress={() => handleRemoveImage(item)} style={styles.removeButton}>
        <Ionicons name="trash-bin" size={24} color="red" />
      </TouchableOpacity>
      
      )}
    </View>
  );
};



  
  

  return (
    <View style={{width:'100%'}}>
       <TouchableOpacity style={[styles.iconButton,{width:'100%', justifyContent:'flex-start'}]} onPress={handlePress}>
                        <Ionicons name="image-outline" size={24} color="black" />
                        <Text style={{ marginLeft: 8 }}>اختار ملف او صورة</Text>
                    </TouchableOpacity>
                    {imageUris.length > 0 || loading ? ( // Only display FlatList if there are images or loading is true
        <FlatList
          data={imageUris}
          extraData={imageUris} // Add this line to ensure FlatList re-renders when data changes

          horizontal={true}
          renderItem={renderItem}
          keyExtractor={(item, index) => item + index}
          showsHorizontalScrollIndicator={true}
          contentContainerStyle={styles.flatListContentContainer}
          ListEmptyComponent={() => ( // This will show when the data array is empty
            loading && <ActivityIndicator size="large" color="#0000ff" />
          )}
        />
      ) : null}
    </View>
  );
};


const styles = StyleSheet.create({
    listContainer: {
        // You can set a fixed height or max-height here if you want to restrict the space
        maxHeight: 120, // For example, if your images are 100px high and you want 10px padding
      },
      flatListContentContainer: {
        alignItems: 'center', // This ensures that the items are centered in the container
      },
  button: {
    backgroundColor: '#007bff',
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
  itemContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 5,
  },
  removeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    //width: '100%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10
}
});

export default FilePhotoPicker;
