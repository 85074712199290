import React, { useState, useEffect } from 'react';
import { FlatList, View, Text, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome'; // You can choose another icon set if you prefer.
import { fetchData as reusableFetchData } from '../../../utils/api';
import ImageCarouselModal from '../../../SharedComponents/ImageCarouselModal';
//import { fetchFileLink } from '../../../utils/api';
  import Styles from '../../../Styles/Styles';
  import { Ionicons } from '@expo/vector-icons';



const DocumentExplorer = ({route, navigation}) => {
  const [data, setData] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility


const fetchData = async (method, params) => {
    try {
        const requestBody = {
            method,
            params
        };
        
        // Use the reusable fetchData function
        const json = await reusableFetchData(requestBody);

        //console.log('Parsed JSON:', json);

        // Assuming route.params.id and route.params.lawsuit_info_id are available in this context
        const rowsWithFilesAndMatchingIds = json.filter(row => 
            row.files && 
            row.files.length > 0 && 
            Number(row.lawsuit_id) === Number(route.params.id) && 
            Number(row.lawsuit_info_id) === Number(route.params.lawsuit_info_id)
        );
        
        //console.log('Filtered Rows with matching ids:', rowsWithFilesAndMatchingIds);

        const result = {
            method: method,
            rows: rowsWithFilesAndMatchingIds
        };

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
};


//console.log('Params: ', route)

  useEffect(() => {
    const loadData = async () => {
      const result = await fetchData('select_procedures', {});
      if (result && result.rows) {
        setData(result.rows);
      }
    };

    loadData();
  }, [route.params.id, route.params.lawsuit_info_id]);

  const renderItem = ({ item }) => {
    return (
      <View style={styles.gridItem}>
        <Icon name="file-text" size={60} color="#333" />
        <Text style={styles.fileDescription}>{item.description}</Text>
      </View>
    );
  };
  
  
  const fetchFileLinksForData = async (data) => {
    // Extracting file IDs from the data
    const fileIds = [];
    data.forEach(item => {
        item.files.forEach(fileId => {
            fileIds.push(fileId);
        });
    });
    setFileLinks(fileIds);
    // Fetching file links for the extracted file IDs
    //const links = await Promise.all(fileIds.map(id => fetchFileLink(id)));
    //setFileLinks(links.filter(link => link !== null && link !== undefined));
    // Uncomment the following line if you're using the imagesLoading state
    // setImagesLoading(false);
};


useEffect(() => {
  if (data && data.length > 0) {
    fetchFileLinksForData(data);
  }
}, [data]);






  return (
<View style={{backgroundColor:'white', marginRight: 10, marginLeft:10}}>
    <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
      <Ionicons name="expand-outline" size={18} color="black" style={{marginTop:5, marginLeft:5}} />
    </TouchableOpacity>

                  {/* Step 5: Add a Modal component to your JSX */}
                  <Modal
                    transparent={false}
                    visible={isModalVisible}
                    animationType="slide"
                  >
                    <View style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                    
                    
                    <ImageCarouselModal fileLinks={fileLinks} />

                    <TouchableOpacity
    style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
    onPress={() => setIsModalVisible(false)}
  >
    <Ionicons name="close" size={24} color="black" />
                      
                      </TouchableOpacity>
                    </View>
                  </Modal>

                 
                  <ImageCarouselModal fileLinks={fileLinks} isNested={true}/>

                </View>

 




  );
      }  

const styles = StyleSheet.create({
    gridItem: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        padding: 16,
       // borderBottomWidth: 1,
        borderBottomColor: '#ccc',
      },
      fileDescription: {
        fontSize: 16,
        marginTop: 10,
      },
    fileRow: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 5,
      },
    itemContainer: {
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
    },
    descriptionText: {
      fontSize: 16,
      marginBottom: 8,
    },
    filesContainer: {
      marginLeft: 10,
    },
    filesTitle: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    fileText: {
      fontSize: 14,
      marginLeft: 10,
    },
  });
  
  

export default DocumentExplorer;
