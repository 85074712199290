import React, { useState, useEffect } from 'react';
import { View, Modal, TextInput, Button, Text, StyleSheet } from 'react-native';

const SyndicateModal = ({ visible, onClose, onSubmit, initialData }) => {
  const [description, setDescription] = useState('');
  const [telephone, setTelephone] = useState('');

  useEffect(() => {
    if (initialData) {
      setDescription(initialData.description);
      setTelephone(initialData.phone); // Adjusted to match backend
    } else {
      setDescription('');
      setTelephone('');
    }
  }, [initialData]);

  const handleSubmit = () => {
    onSubmit({
      description,
      telephone,
    });
    onClose();
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >



      
      <View style={styles.modalContainer}>
        
        <View style={styles.modalContent}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>اضافة وتعديل نقابة</Text>
        <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom:20 }} />


          <Text>اسم النقابة</Text>
          <TextInput
            style={styles.input}
            value={description}
            onChangeText={setDescription}
          />
          <Text>رقم الهاتف</Text>
          <TextInput
            style={styles.input}
            value={telephone}
            onChangeText={setTelephone}
          />

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <View style={{ width: 100 }}>
              <Button
                title="الغاء"
                onPress={onClose}
                color="#ff7f50" /* Orange color */
              />
            </View>

            <View style={{ width: 100 }}>
              <Button
                title="تسجيل"
                onPress={handleSubmit}
                color="#28a745" /* Green color */

              />
            </View>


          </View>





         
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
});

export default SyndicateModal;
