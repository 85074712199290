import React, { useState, useEffect } from 'react';
import { Modal, TextInput, Platform, TouchableOpacity, Text, Button, View, StyleSheet, Alert, ActivityIndicator } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { ActionSheet } from 'react-native-ui-lib';
import { Picker } from '@react-native-picker/picker';
import { DELETE_ITEM_API, DELETE_FILE_BY_ID_API, ADD_FILE_API, encodeImagesWithMimeType } from '../../../utils/api';
import { Ionicons } from '@expo/vector-icons';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import * as FileSystem from 'expo-file-system';

const ProcedureEdit = ({isVisible, onClose, onEntryUpdated, initialValue, initialID, initialType, initialDate, initialTime, TypeValues, initialFiles }) => {
  const [value, setValue] = useState(initialValue);
  const [selectedType, setSelectedType] = useState(initialType);
  const [iosPickerVisible, setIosPickerVisible] = useState(false);
  const [date, setDate] = useState(new Date(initialDate));
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [time, setTime] = useState(new Date(initialTime));
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [isTypeActionSheetVisible,setIsTypeActionSheetVisible] = useState(false);
  const [combinedDateTime, setCombinedDateTime] = useState(`${initialDate} ${initialTime}`);
const [selectedID, setSelectedID] = useState(initialID);
const [imageUris, setImageUris] = useState([]);
const [deletedFiles, setDeletedFiles] = useState([]);
const [addedFiles, setAddedFiles] = useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);

 
const formatDate = (dateObj) => {
  let day = dateObj.getDate();
  let month = dateObj.getMonth() + 1; // Months are zero-based
  let year = dateObj.getFullYear();

  // Ensuring two digits for day and month
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return `${year}-${month}-${day}`;
};

const formatTime = (timeObj) => {
  let hours = timeObj.getHours();
  let minutes = timeObj.getMinutes();
  let seconds = timeObj.getSeconds();

  // Ensuring two digits for hours, minutes, and seconds
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

// When you set the combinedDateTime state
useEffect(() => {
  const formattedDate = formatDate(date);
  const formattedTime = formatTime(time);
  const newCombinedDateTime = `${formattedDate} ${formattedTime}`;
  setCombinedDateTime(newCombinedDateTime);
  //console.log(formattedDate);
 //console.log(formattedTime);
}, [date, time]);

  
useEffect(() => {
  //console.log(combinedDateTime);
}, [combinedDateTime]);

  

const handleSubmit = async () => {
  onEntryUpdated(selectedID, value, selectedType, combinedDateTime, deletedFiles, addedFiles);
  //setIsSubmitting(false);

  // Close the modal
  onClose();
 // setIsSubmitting(true);

  // Array to hold all promises for deletion and addition of files
  const fileOperationPromises = [];

  // Add deletion promises to the array
  for (const fileId of deletedFiles) {
    //console.log('Delete:', fileId);
    fileOperationPromises.push(DELETE_FILE_BY_ID_API(
      'delete_procedure_file',      
      {id:fileId}));
  }
  const imagesBase64 = await encodeImagesWithMimeType(addedFiles);
  
  // Add addition promises to the array
  for (const fileUri of addedFiles) {
    fileOperationPromises.push(ADD_FILE_API(
      'insert_procedure_files',
      {id: initialID,
      files:imagesBase64}));
  }

  // Add the update procedure promise to the array
  const updateProcedurePromise = DELETE_ITEM_API(
    'update_procedure',
    { id: selectedID, description: value, type: selectedType, date: combinedDateTime }
   
  );
  fileOperationPromises.push(updateProcedurePromise);

  try {
    // Execute all promises
    const results = await Promise.all(fileOperationPromises);

    // results now contains the outcomes of all the file deletions, additions, and the update procedure
    //console.log("All operations completed successfully:", results);

    // Call the onEntryUpdated with all the necessary arguments
   
  } catch (error) {
    // If there's an error in any operation, handle it
    console.error("An error occurred during the file operation or update process:", error);
    Alert.alert('Error', 'Failed to complete operations');
  }
};








  const openIOSPicker = () => {
    setIosPickerVisible(true);
    onFilesUpdated(deletedFiles, addedFiles); // This is a new prop you'd pass to ProcedureEdit
  };

  return (
    <Modal visible={isVisible} transparent={true} animationType="slide">

<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.7)' }}>
{isSubmitting ? (
        // View when submitting
        <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size="large" color="#0000ff" />
          <Text>Submitting...</Text>
        </View>
      ) : (
            <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> تعديل اجراء</Text>
                    <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />

      <TextInput
        placeholder="Enter text"
        value={value}
        onChangeText={setValue}
        style={styles.inputStyle}
      />

<View style={{ width:'100%'}}>
                    <TouchableOpacity onPress={() => setIsTypeActionSheetVisible(true)} style={styles.iconButton}>
    <Text>{selectedType ? TypeValues.find(item => item.id === selectedType).text : 'نوع الاجراء'}</Text>
</TouchableOpacity>

<ActionSheet
    title="Select a Type"
    message="Please select a type from the list below."
    cancelButtonIndex={TypeValues.length}
    useNativeIOS
    options={[
        ...TypeValues.map((item) => ({
            label: item.text,
            onPress: () => setSelectedType(item.id)
        })),
        { label: 'Cancel', onPress: () => {} }  // You can adjust the cancel action as needed
    ]}
    visible={isTypeActionSheetVisible}
    onDismiss={() => setIsTypeActionSheetVisible(false)}
/>
</View>

      {/* Date Picker */}
      <TouchableOpacity onPress={() => setShowDatePicker(true)} style={styles.iconButton}>
      <Ionicons name="calendar-outline" size={24} color="black" />
        <Text>{date.toDateString()}</Text>
      </TouchableOpacity>
      {showDatePicker && (
        <DateTimePicker
          value={date}
          mode="date"
          display={Platform.OS === 'android' ? 'default' : 'spinner'}
          onChange={(event, selectedDate) => {
            setShowDatePicker(false);
            if (selectedDate) {
              setDate(selectedDate);
            }
          }}
        />
      )}

      {/* Time Picker */}
      <TouchableOpacity onPress={() => setShowTimePicker(true)} style={styles.iconButton}>
      <Ionicons name="time-outline" size={24} color="black" />

        <Text>{time.toTimeString().split(' ')[0]}</Text>
      </TouchableOpacity>
      {showTimePicker && (
        <DateTimePicker
          value={time}
          mode="time"
          display={Platform.OS === 'android' ? 'default' : 'spinner'}
          onChange={(event, selectedTime) => {
            setShowTimePicker(false);
            if (selectedTime) {
              setTime(selectedTime);
            }
          }}
        />
      )}

<FilePhotoPicker
  initialUris={initialFiles || []}
  onFileChange={(deletedIds, addedUris) => {
   setDeletedFiles(deletedIds);
   setAddedFiles(addedUris);
   //console.log('on file change:', deletedIds);
  }}
/>

<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={onClose}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="تعديل" 
      onPress={handleSubmit} 
      color="#28a745" /* Green color */
    />
  </View>

                      
                        </View>



      </View>
      )}
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
    actionButtonDisabled: {
        backgroundColor: '#b0b0b0', // A grayed-out color when disabled
    },
    picker: {
        //  height: 50,
        //width: '40%',
        marginBottom: 10,
        
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    inputStyle: {
        width: '100%',
        marginTop:10,
        padding: 5,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 10
    },
    pickerStyle: {
        height: 50,
        width: '100%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    dateTimePickerContainer: {
        width:'100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        //width: '100%',
        padding: 10,
        borderRadius: 5,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10
    },
    imagePreview: {
        width: 100,
        height: 100,
        margin: 5,
    },
    filePreview: {
        width: 100,
        height: 100,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileName: {
        marginTop: 10,
        fontSize: 12,
    },
    actionButton: {
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'blue',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 5
    },
    iconButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 10,
      marginVertical: 10,
      borderRadius: 10,
      borderColor: '#ccc',
      borderWidth: 1,
      textAlign: 'center',
  },
});



export default ProcedureEdit;
