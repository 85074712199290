  import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, ActivityIndicator, Platform } from 'react-native';
import NavigationStack from './src/Auth/NavigationStack';
import { AuthContext } from './contexts/AuthContext';
import * as LocalAuthentication from 'expo-local-authentication';
import BadgeCountContext from './contexts/BadgeCountContext';
import * as SecureStore from 'expo-secure-store';
import * as Notifications from 'expo-notifications';
import { RemindersProvider, useReminders } from './contexts/RemindersContext';
import { SearchProvider } from './contexts/SearchContext';
import { AppState } from 'react-native';
import { NavigationStateProvider } from './contexts/NavigationStateContext';
import { IndicatorProvider } from './contexts/IndicatorContext';
import { LoadingProvider } from './contexts/LoadingContext';
import AuthStack from './src/Auth/AuthStack';
import * as Font from 'expo-font';
//import AppLoading from 'expo-app-loading';
import * as SplashScreen from 'expo-splash-screen';
import { RefreshProvider } from './contexts/RefreshContext';
import { DataProvider } from './contexts/DataContext';




/*
if (!__DEV__) {
  global.ErrorUtils.setGlobalHandler((error, isFatal) => {
    // Report the error to a server or show a user-friendly error message
    console.error(error, isFatal);
    
    Alert.alert(
      "Unexpected Error Occurred",
      `
      ${isFatal ? "Fatal error occurred" : "Non-fatal error occurred"}.
      Please try again or contact support if the issue persists.
      `,
      [{ text: "OK" }]
    );
  });
}
*/

if (!__DEV__) { // Only disable logs in production
  //console.log = () => {};
}

// Function to load fonts
const fetchFonts = () => {
  return Font.loadAsync({
    'DefFont': require('./assets/Fonts/Changa-VariableFont_wght.ttf'), // Replace with your font file
    // Load other fonts here if needed
  });
};





// Define a Root component that renders either the NavigationStack or LoginScreen component based on authentication status
const Root = () => {
  const [authenticated, setAuthenticated] = useState(false); // State variable to track authentication status
  const [loading, setLoading] = useState(true); // State variable to track loading status

  const param={method: "select_types", params: {select2: true}};
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const { reminders, setReminders } = useReminders();
  const [showLoginScreen, setShowLoginScreen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


// Inside Root component





const sendNotification = async (reminder) => {
  await Notifications.scheduleNotificationAsync({
    content: {
      title: "Reminder!",
      body: `You have a reminder due soon: ${reminder.title}`,
      data: { reminderId: reminder.id },
    },
    trigger: null,
  });
};




// Step 1: Get today's date
const today = new Date();

// Step 2: Format today's date
const formatDate = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};
const date_start = formatDate(today);

// Step 3: Compute date one year later and format it
const oneYearLater = new Date();
oneYearLater.setFullYear(today.getFullYear() + 1);
const date_end = formatDate(oneYearLater);
 
//###############################################



const authenticateUserLocal = async () => {
  try {
    let token;

    if (Platform.OS === 'web') {
      // For web, use localStorage
      token = localStorage.getItem('token');
    } else {
      // For native platforms, use SecureStore (async)
      token = await SecureStore.getItemAsync('token');
    }
          if (!token) {
          //console.log("No token found, user is not authenticated");
          setIsAuthenticated(false);
          setLoading(false);
          return false;  // Indicate that authentication failed
      }

      //console.log("Token found, checking last login time");
      const lastLoginString = await SecureStore.getItemAsync('LastLogin');

      if (lastLoginString) {
          const lastLogin = new Date(lastLoginString);
          const now = new Date();
          const differenceInMinutes = (now - lastLogin) / 60000;

          if (differenceInMinutes > 25) {
              //console.log('Last login was less than 30 minutes ago, authentication failed.');
              setIsAuthenticated(false);
              setLoading(false);
              return false;  // Indicate that authentication failed
          }
      }

      // Proceed with biometric authentication
      const hasHardwareAsync = await LocalAuthentication.hasHardwareAsync();
      const isEnrolledAsync = await LocalAuthentication.isEnrolledAsync();

      if (!hasHardwareAsync || !isEnrolledAsync) {
          //console.log('Biometric authentication not supported or not enrolled, skipping');
         // setIsAuthenticated(true);
          setLoading(false);
          return true;  // Indicate that authentication was successful
      }

      const result = await LocalAuthentication.authenticateAsync();
      if (result.success) {
          //console.log('Biometric Authentication successful!');
          //setIsAuthenticated(true);
          setLoading(false);
          return true;  // Indicate that authentication was successful
      } else {
          //console.log('Biometric Authentication failed!');
          setIsAuthenticated(false);
          setLoading(false);
          return false;  // Indicate that authentication failed
      }

  } catch (error) {
      console.error('Error in the authentication process:', error);
      setIsAuthenticated(false);
      setLoading(false);
      return false;  // Indicate that authentication failed
  }
};



const fetchDataForUser = async () => {
  try {
     
/*
      // ... rest of your data fetching code ...
      const datasetsToFetch = [
        { key: 'select_types', param: { method: "select_types", params: { select2: true } } , doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_phases', param: {method: "select_phases", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_judicial_references', param:{method: "select_judicial_references", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_judicial_regions' , param:{method: "select_judicial_regions", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_users' , param:{method: "select_users", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_clients', param:{method: "select_clients", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: true},
        { key: 'select_opponents', param:{method: "select_opponents", params: {select2: true}}, doubleParseContent: false, alwaysFetchFresh: false},
        {key: 'select_procedure_types' , param:{method: "select_procedure_types", params: {}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_reminder_types' , param:{method: "select_reminder_types", params: {}}, doubleParseContent: false, alwaysFetchFresh: false},
        { key: 'select_lawsuits', param:{method: "select_lawsuits", params: {}}, doubleParseContent: false, alwaysFetchFresh: true},
      //  { key: 'select_reminders', param: {method: "select_reminders", params: {date_start: date_start, date_end: date_end}}, doubleParseContent: false, alwaysFetchFresh: true},
       { key: 'fetch_credentials', param: {method: "fetch_credentials", params: {}}, doubleParseContent: false, alwaysFetchFresh: true},
      ];
      const fetchPromises = datasetsToFetch.map(dataset => fetchAndStoreData(dataset.key, dataset.param, dataset.alwaysFetchFresh));
      await Promise.all(fetchPromises);
      //console.log('Data fetch complete');
      //setLoading(false);
*/
  } catch (error) {
      console.error('Error in the data fetching process:', error);
      setLoading(false);
  } finally {
    setIsAuthenticated(true);
  }
};


//###############################################




{/*
  const fetchAndUpdateReminders = async () => {
    const existingRemindersStr = await AsyncStorage.getItem('select_reminders');
    const existingReminders = existingRemindersStr ? JSON.parse(existingRemindersStr) : [];

    const newReminders = await fetchReminders(date_start, date_end);
    
    const mergedReminders = newReminders.map(newReminder => {
        const existingReminder = existingReminders.find(r => r.id === newReminder.id);
        if (existingReminder) {
            return {
                ...newReminder,
                seen: existingReminder.seen || 'not seen',
                status: existingReminder.status || 'active'
            };
        } else {
            return {
                ...newReminder,
                seen: 'not seen',
                status: 'active'
            };
        }
    });
    
    await AsyncStorage.setItem('select_reminders', JSON.stringify(mergedReminders));

    setReminders(mergedReminders);
    
    const remindersDueSoon = checkRemindersDueSoon(mergedReminders);
    remindersDueSoon.forEach(sendNotification);
   
  };
*/}

  useEffect(() => {
    const initializeAndFetch = async () => {
      // Ensure initializeApp completes before moving on
     // await initializeApp();
      
      // Ensure fetchAndUpdateReminders completes before moving on
     // await fetchAndUpdateReminders();
  
      // Ensure registerBackgroundFetch completes before moving on
      //await registerBackgroundFetch();
  
      setLoading(false);
    };
  
    // Call the async function
    initializeAndFetch();
    
    const handleAppStateChange = async (nextAppState) => {
      if (nextAppState === 'active') {
        let lastLoginString;

        if (Platform.OS === 'web') {
          // For web, use localStorage
          lastLoginString = localStorage.getItem('LastLogin');
        } else {
          // For native platforms, use SecureStore (async)
          lastLoginString = await SecureStore.getItemAsync('LastLogin');
        }
          
        console.log(lastLoginString);
        
        //const lastLoginString = await SecureStore.getItemAsync('LastLogin');
          if (lastLoginString) {
              const lastLogin = new Date(lastLoginString);
              const now = new Date();
              const differenceInMinutes = (now - lastLogin) / 60000; // Convert milliseconds to minutes

              if (differenceInMinutes > 25) {
                  setIsAuthenticated(false);
              }
          }
      }
  };

  const appStateSubscription = AppState.addEventListener('change', handleAppStateChange);
  if (!isAuthenticated) {
    setShowLoginScreen(true);
  }
  return () => {
    appStateSubscription.remove();
  };

 
}, [isAuthenticated]);

  


const onLogin = async () => {
 setIsSubmitting(true);

  const isUserAuthenticatedRemote = await authenticateUserLocal();
  if (isUserAuthenticatedRemote) {
      await fetchDataForUser();
      // Initialize SQLite Database and Tables
      {Platform.OS !=='web' ? initializeDatabase(): null};
       // Close the login modal upon successful login
    setShowLoginScreen(false);
  }
  setIsSubmitting(false);

};

/*
const initializeApp = async () => {
  const isUserAuthenticatedRemote = await authenticateUserLocal();
  if (isUserAuthenticatedRemote) {
      await fetchDataForUser();
      // Initialize SQLite Database and Tables
      initializeDatabase();
  }
};
*/





  




  if (isSubmitting) {
    return (
        <View style={styles.container}>
            <View style={styles.overlay}>
                <Text style={styles.loadingText}>Loading...</Text>
                <ActivityIndicator size="large" color="#00ff00" />
            </View>
        </View>
    );
}

return (
  
  <BadgeCountContext.Provider value={{ badgeCount, setBadgeCount }}>
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <NavigationStateProvider>
      <LoadingProvider>
          <SearchProvider>
              {/*<NavigationStack />
              <Modal
                  visible={showLoginScreen}
                  animationType="slide"
                  transparent={true}  // Set to true if you want a transparent background
              >
                  <LoginScreen onLogin={onLogin} />
</Modal>*/}
 <RefreshProvider>
  <DataProvider>
 {isAuthenticated ? <NavigationStack /> : <AuthStack />}
 </DataProvider>
 </RefreshProvider>
          </SearchProvider>
          </LoadingProvider>
          </NavigationStateProvider>

      </AuthContext.Provider>
  </BadgeCountContext.Provider>
  
); 


};

SplashScreen.preventAutoHideAsync(); // Prevent splash screen from auto-hiding

const App = () => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await fetchFonts();
      } catch (e) {
        console.warn(e);
      } finally {
        setFontLoaded(true);
      }
    }

    prepare();
  }, []);

  useEffect(() => {
    if (fontLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontLoaded]);

  if (!fontLoaded) {
    return null; // Return null or some loading component while fonts are loading
  }

  return (
    <IndicatorProvider>
     
      <RemindersProvider>
     
        <Root />
       
      </RemindersProvider>
    </IndicatorProvider>
  );
};


// Define the styles for the component
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject, // This will make the view cover the entire screen
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    justifyContent: 'center', // Center the loading indicator vertically
    alignItems: 'center', // Center the loading indicator horizontally
  },
  loadingText: {
    color: '#fff', // Set the text color to white
    marginBottom: 20, // Add some margin below the text
  },
});



export default App;
