import React, { useState, useEffect } from 'react';
import { View, Modal, TextInput, Button, Text, StyleSheet, ScrollView, KeyboardAvoidingView } from 'react-native';

const LawyerModal = ({ visible, onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    id: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    mother_name: '',
    register_place: '',
    register_number: '',
    dob: '',
    mobile: '',
    email: '',
    date_of_affiliation: '',
    address: '',
    password: '12345678',
    creation_date: 1718833776,
    financial_acc_nb: '',
    role: 1,
  });

  useEffect(() => {
    if (initialData) {
      setFormData({ ...formData, ...initialData });
    } else {
      setFormData({
        id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        mother_name: '',
        register_place: '',
        register_number: '',
        dob: '',
        mobile: '',
        email: '',
        date_of_affiliation: '',
        address: '',
        password: '12345678',
        creation_date: 1718833776,
        financial_acc_nb: '',
        role: 1,
      });
    }
  }, [initialData]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >
      <KeyboardAvoidingView style={styles.modalContainer} behavior="padding">
        <View style={styles.modalContent}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>اضافة وتعديل محامي</Text>
        <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom:20 }} />
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            <Text>الاسم الاول</Text>
            <TextInput
              style={styles.input}
              value={formData.first_name}
              onChangeText={(value) => handleChange('first_name', value)}
            />
            <Text>اسم الأب</Text>
            <TextInput
              style={styles.input}
              value={formData.middle_name}
              onChangeText={(value) => handleChange('middle_name', value)}
            />
            <Text>الشهرة</Text>
            <TextInput
              style={styles.input}
              value={formData.last_name}
              onChangeText={(value) => handleChange('last_name', value)}
            />
            <Text>اسم الأم</Text>
            <TextInput
              style={styles.input}
              value={formData.mother_name}
              onChangeText={(value) => handleChange('mother_name', value)}
            />
            <Text>محل الولادة</Text>
            <TextInput
              style={styles.input}
              value={formData.register_place}
              onChangeText={(value) => handleChange('register_place', value)}
            />
            <Text>رقم السجل</Text>
            <TextInput
              style={styles.input}
              value={formData.register_number}
              onChangeText={(value) => handleChange('register_number', value)}
            />
            <Text>تاريخ الولادة</Text>
            <TextInput
              style={styles.input}
              value={formData.dob}
              onChangeText={(value) => handleChange('dob', value)}
            />
            <Text>رقم الهاتف الجوال</Text>
            <TextInput
              style={styles.input}
              value={formData.mobile}
              onChangeText={(value) => handleChange('mobile', value)}
            />
            <Text>البريد الالكتروني</Text>
            <TextInput
              style={styles.input}
              value={formData.email}
              onChangeText={(value) => handleChange('email', value)}
            />
            <Text>تاريخ الانتساب</Text>
            <TextInput
              style={styles.input}
              value={formData.date_of_affiliation}
              onChangeText={(value) => handleChange('date_of_affiliation', value)}
            />
            <Text>العنوان</Text>
            <TextInput
              style={styles.input}
              value={formData.address}
              onChangeText={(value) => handleChange('address', value)}
            />
            <Text>كلمة المرور</Text>
            <TextInput
              style={styles.input}
              value={formData.password}
              onChangeText={(value) => handleChange('password', value)}
            />
           
       

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <View style={{ width: 100 }}>
              <Button
                title="الغاء"
                onPress={onClose}
                color="#ff7f50" /* Orange color */
              />
            </View>

            <View style={{ width: 100 }}>
              <Button
                title="تسجيل"
                onPress={handleSubmit}
                color="#28a745" /* Green color */

              />
            </View>


          </View>



          </ScrollView>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    maxHeight: '80%',
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
});

export default LawyerModal;
