import React, { useEffect, useState, useCallback } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useIsFocused } from '@react-navigation/native';
import { fetchData } from '../../../utils/api';
import { FlatList, Text, View, StyleSheet, TouchableOpacity, Button } from 'react-native';
import SupplierModal from '../components/SupplierModal';
import { Ionicons } from '@expo/vector-icons';


const Stack = createStackNavigator();

function MainScreen({ navigation, modalVisible }) {
  const [suppliers, setSuppliers] = useState([]);
  const isFocused = useIsFocused();


  const fetchSuppliers = useCallback(async () => {
    try {
      const data = await fetchData({ method: 'select_suppliers', params: {} });
      setSuppliers(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  }, []);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  useEffect(() => {
    if (!modalVisible && isFocused) {
      fetchSuppliers();
    }
  }, [modalVisible, isFocused, fetchSuppliers]);

  return (
    <View style={styles.container}>
      <FlatList
        data={suppliers}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => navigation.navigate('Second', { supplierId: item.id })}
          >
            <Text style={styles.item}>{item.text}</Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function SecondScreen({ route, navigation }) {
  const { supplierId } = route.params;
  const [supplier, setSupplier] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchSupplier = async () => {
    try {
      const data = await fetchData({ method: 'fetch_supplier', params: { id: supplierId } });
      setSupplier(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching supplier:', error);
    }
  };

  useEffect(() => {
    fetchSupplier();
  }, [supplierId]);

  const handleEdit = () => {
    setModalVisible(true);
  };

  const handleSubmit = async (supplierDetails) => {
    const url = { method: 'update_supplier', params: { ...supplierDetails, id: supplierId } };

    try {
      const response = await fetchData(url);

      if (response === 1) {
        setModalVisible(false);
        fetchSupplier(); // Refresh supplier details
        //navigation.navigate('Main'); // Navigate back to the supplier list
      } else {
        console.error('Failed to update supplier');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!supplier) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.item}>اسم المورد: {supplier.title}</Text>
      <Text style={styles.subItem}>العنوان: {supplier.address}</Text>
      <Text style={styles.subItem}>رقم الهاتف: {supplier.phone}</Text>
      <Text style={styles.subItem}>البريد الالكتروني: {supplier.email}</Text>
     
      <TouchableOpacity onPress={handleEdit}>
          <Ionicons name="create" size={24} color="red" />
        </TouchableOpacity>

      <SupplierModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={(details) => handleSubmit({ ...details })}
        initialData={supplier}
      />
    </View>
  );
}

function SuppliersNavigator({ modalVisible }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Main">
        {(props) => <MainScreen {...props} modalVisible={modalVisible} />}
      </Stack.Screen>
      <Stack.Screen name="Second" component={SecondScreen} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop:10,
   // justifyContent: 'center',
    // alignItems: 'center',
    // padding: 16,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  item: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subItem: {
    fontSize: 14,
    color: '#555',
  },
});

export default SuppliersNavigator;
