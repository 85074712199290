// ProcedureModal.js
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Text, TextInput, TouchableOpacity, ActivityIndicator, StyleSheet, Image, FlatList, View, Alert, Button , Platform} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Ionicons } from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import { Camera } from 'expo-camera';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getToken, formatDateTime, encodeImagesWithMimeType } from '../../../utils/api';
import * as DocumentPicker from 'expo-document-picker';
import { ActionSheet } from 'react-native-ui-lib';
import FilePhotoPicker from '../../../SharedComponents/FilePhotoPicker';
import { useIndicator } from '../../../contexts/IndicatorContext';


const ProcedureModal = ({ modalVisible, setModalVisible, route, onModalSubmit, prefillData = {} }) => {
    //console.log("PrefillData received in ProcedureModal:", prefillData);
    const { showLoading, showSuccess, showError } = useIndicator();
    const [newImageUris, setNewImageUris] = useState([]);

    const [textInputValue1, setTextInputValue1] = useState(prefillData.description || '');
    //const [seletcedType, setSelectedtype] = useState(prefillData.type || '');
    // const [date, setDate] = useState(prefillData.date ? new Date(prefillData.date) : new Date());
    const [lawsuit_id, setLawsuit_id] = useState(prefillData.lawsuit_id || route?.params?.id || null);
    const [lawsuit_info_id, setLawsuit_info_id] = useState(prefillData.lawsuit_info_id || route?.params?.lawsuit_info_id || null);
const [isModalVisible, setIsModalVisible] = useState(modalVisible);


    const [imageUris, setImageUris] = useState([]);
    const [selectedValue, setSelectedValue] = useState('LBP');
    // const [textInputValue1, setTextInputValue1] = useState('');
    const [textInputValue2, setTextInputValue2] = useState('');
    const [date, setDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [time, setTime] = useState(new Date());
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [seletcedType, setSelectedtype] = useState('');
    const [typeValues, setTypeValues] = useState([]);
    const [lawsuits, setLawsuits] = useState([]);
    const [selectedLawsuit, setSelectedLawsuit] = useState('');
    // const [lawsuit_id, setLawsuit_id] = useState(route?.params?.id ?? null);
    // const [lawsuit_info_id, setLawsuit_info_id] = useState(route?.params?.lawsuit_info_id ?? null);

    const [isLawsuitActionSheetVisible, setIsLawsuitActionSheetVisible] = useState(false);
    const [iosPickerVisible, setIosPickerVisible] = useState(false);
    const [isTypeActionSheetVisible, setIsTypeActionSheetVisible] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);



















    const openIOSPicker = () => {
        setIosPickerVisible(true);
    };

    useEffect(() => {
        // Update the state only if prefillData has new and different data
        if (prefillData.description && textInputValue1 !== prefillData.description) {
            setTextInputValue1(prefillData.description);
        }
        if (prefillData.lawsuit_id && lawsuit_id !== prefillData.lawsuit_id) {
            setLawsuit_id(prefillData.lawsuit_id);
        }
        if (prefillData.lawsuit_info_id && lawsuit_info_id !== prefillData.lawsuit_info_id) {
            setLawsuit_info_id(prefillData.lawsuit_info_id);
        }
        // ... similar checks for other fields

    }, [prefillData]); // Dependency on prefillData


    const checkFields = () => {
        // Assuming the following fields are required: textInputValue1, textInputValue2, date, time, selectedValue, seletcedType, and either lawsuit_id or selectedLawsuit (based on route.params.id)
        if (
            textInputValue1 &&
            // textInputValue2 &&
            date &&
            time &&
            // selectedValue &&
            seletcedType &&
            (route?.params?.id || prefillData.lawsuit_id || selectedLawsuit)
        ) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
    };

    useEffect(() => {
        checkFields();
    }, [textInputValue1, date, time, seletcedType, selectedLawsuit, lawsuit_id, route?.params?.id]);



    const handleSelectLawsuit = (item) => {


        setLawsuit_id(item.id);
        setLawsuit_info_id(item.lawsuit_info_id);

    };





    const uploadFiles = async () => {
         // Prepare the data for submission
         // Format the combined date and time
         const combinedDateTime = new Date(date);
         combinedDateTime.setHours(time.getUTCHours());
         combinedDateTime.setMinutes(time.getUTCMinutes());
         combinedDateTime.setSeconds(time.getUTCSeconds());
 
         const formattedDateTime = `${combinedDateTime.toISOString().split('T')[0]} ${String(combinedDateTime.getUTCHours()).padStart(2, '0')}:${String(combinedDateTime.getUTCMinutes()).padStart(2, '0')}:${String(combinedDateTime.getUTCSeconds()).padStart(2, '0')}`;
 
 // Generate a random negative ID
 const randomNegativeId = -Math.floor(Math.random() * 1000000);


  const newDataItem = {
    id: randomNegativeId,
    description: textInputValue1,
    date: formattedDateTime,
    lawsuit_id,
    lawsuit_info_id,
    fee: textInputValue2,
    currency: selectedValue,
    files: '',
    type: seletcedType
} 

onModalSubmit(newDataItem, newImageUris);

       /*
        //console.log('Uploading files...');
        //setIsSubmitting(true);
        showLoading();
        const combinedDateTime = new Date(date);
        combinedDateTime.setHours(time.getUTCHours());
        combinedDateTime.setMinutes(time.getUTCMinutes());
        combinedDateTime.setSeconds(time.getUTCSeconds());

        const formattedDateTime = `${combinedDateTime.toISOString().split('T')[0]} ${String(combinedDateTime.getUTCHours()).padStart(2, '0')}:${String(combinedDateTime.getUTCMinutes()).padStart(2, '0')}:${String(combinedDateTime.getUTCSeconds()).padStart(2, '0')}`;

        ////console.log('ImgURIS:', imageUris);
        const imagesBase64 = await Promise.all(newImageUris.map(async uri => {

            if (!uri) {
                console.warn('Undefined URI found');
                return null;
            }
            // Get the file extension (you should store this info while picking files)
            const fileExtension = uri.split('.').pop();

            // Identify MIME type (this is a basic example; you might need a more comprehensive mapping)
            let mimeType;
            switch (fileExtension) {
                case 'jpg':
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
              
                case 'docx':
                    mimeType =  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                    break;
                default:
                    mimeType = 'application/octet-stream';
            }

            const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            return `data:${mimeType};base64,${data}`;
        }));

        //  const [newId, newLawsuitInfoId] = selectedLawsuit.split(',');
        const newDataItem ={
            id: '',
            description: textInputValue1,   // Assuming this is the description
            date: formattedDateTime,
            lawsuit_id,
            lawsuit_info_id,
            fee: textInputValue2,
            currency: selectedValue,
            files: imagesBase64,
            type: seletcedType
        }
        onModalSubmit(newDataItem);

        const body = JSON.stringify({
            method: 'insert_procedure',
            params: newDataItem
        });

        //console.log('Uploading files - S1...');
        try {
            const token = await getToken();
            //console.log('calling server 1');
            const response = await fetch('https://maktabi.info/includes/model.php', {
                method: 'POST',
                body,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            //console.log('Uploading files - S2...');

            const responseJson = await response.json();
            //console.log('Uploading files - S3...', responseJson);

            if (responseJson && responseJson.id) {
               
                showSuccess();
                resetModal();
                //setIsSubmitting(false);
                //setModalVisible(false);
            } else {
                console.error('Upload failed');
                showError();
            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
        */
    }; 

    const fetchtypes = async () => {

        try {
            const typesJSON = await AsyncStorage.getItem('select_procedure_types');

            if (typesJSON !== null) {
                const types = JSON.parse(typesJSON);
                setTypeValues([...types]);
                //console.log('Procedure types fetched from AsyncStorage:', types);
            } else {
                //console.log('No procedure types data found in AsyncStorage');
                setTypeValues([]);
            }
        } catch (error) {
            console.error('Error fetching procedure types from AsyncStorage:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (modalVisible) {
                    await fetchtypes();
                    if (route?.params?.id === undefined) {
                        await fetchLawsuits();
                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();
    }, [modalVisible, route?.params?.id]);



    const fetchLawsuits = async () => {
        try {
            const lawsuitsJSON = await AsyncStorage.getItem('select_lawsuits');

            if (lawsuitsJSON !== null) {
                const lawsuits = JSON.parse(lawsuitsJSON);
                setLawsuits(lawsuits);
                //console.log('Lawsuits fetched from AsyncStorage:', lawsuits);
            } else {
                //console.log('No lawsuits data found in AsyncStorage');
                setLawsuits([]);
            }
        } catch (error) {
            console.error('Error fetching lawsuits from AsyncStorage:', error);
        }
    };




    const resetModal = () => {
        setTextInputValue1('');  // Reset TextInput1
        setTextInputValue2('');  // Reset TextInput2
        setSelectedValue('LBP'); // Set the default value for the Picker
        setShowDatePicker(false);  // Hide Date Picker
        setShowTimePicker(false);  // Hide Time Picker
        setDate(new Date());  // Reset Date to current date
        setTime(new Date());  // Reset Time to current time
        setImageUris([]);  // Reset images array
        setSelectedLawsuit('');
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={isModalVisible}
            onRequestClose={() => {
                setModalVisible(!isModalVisible);
            }}
        >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.7)' }}>
                {isSubmitting ? (
                    // View when submitting
                    <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <ActivityIndicator size="large" color="#0000ff" />
                        <Text>جاري الحفظ ...</Text>
                    </View>
                ) : (
                    <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>

                        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> اضافة اجراء</Text>
                        <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />


                        {(!route?.params?.id && !prefillData?.lawsuit_id) && (

                            <View style={{ marginTop: 10, width: '100%' }}>
                                <TouchableOpacity onPress={() => setIsLawsuitActionSheetVisible(true)} style={styles.iconButton} >
                                    <Text>{selectedLawsuit ? lawsuits.find(item => item.id === selectedLawsuit).title : 'اختر دعوى'}</Text>
                                </TouchableOpacity>

                                <ActionSheet
                                    title="Select a Lawsuit"
                                    message="Please select a lawsuit from the list below."
                                    cancelButtonIndex={lawsuits.length}
                                    useNativeIOS
                                    options={[
                                        ...lawsuits.map((item) => ({
                                            label: item.title,
                                            onPress: () => {
                                                setSelectedLawsuit(item.id);
                                                handleSelectLawsuit(item);
                                            }
                                        })),
                                        { label: 'Cancel', onPress: () => { } }  // You can adjust the cancel action as needed
                                    ]}
                                    visible={isLawsuitActionSheetVisible}
                                    onDismiss={() => setIsLawsuitActionSheetVisible(false)}
                                />
                            </View>



                        )}


<View style={styles.dateTimePickerContainer}>
                            {/* Date Picker */}
                            <TouchableOpacity style={[styles.iconButton, { width: '60%' }]} onPress={() => Platform.OS === 'android' && setShowDatePicker(true)}>
                                <Ionicons name="calendar-outline" size={24} color="black" />
                                {Platform.OS === 'android' ? (
                                    <Text style={{ marginLeft: 8 }}>
                                        {date.toLocaleDateString()}
                                    </Text>
                                ) : (
                                    <DateTimePicker
                                        value={date}
                                        mode="date"
                                        display="default"
                                        onChange={(event, selectedDate) => {
                                            if (selectedDate) {
                                                setDate(selectedDate);
                                            }
                                        }}
                                    />
                                )}
                            </TouchableOpacity>

                            {Platform.OS === 'android' && showDatePicker && (
                                <DateTimePicker
                                    value={date}
                                    mode="date"
                                    display="default"
                                    onChange={(event, selectedDate) => {
                                        setShowDatePicker(false);
                                        if (selectedDate) {
                                            setDate(selectedDate);
                                        }
                                    }}
                                />
                            )}

                            {/* Time Picker */}
                            <TouchableOpacity style={styles.iconButton} onPress={() => Platform.OS === 'android' && setShowTimePicker(true)}>
                                <Ionicons name="time-outline" size={24} color="black" />
                                {Platform.OS === 'android' ? (
                                    <Text style={{ marginLeft: 8 }}>
                                        {time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </Text>
                                ) : (
                                    <DateTimePicker
                                        value={time}
                                        mode="time"
                                        display="default"
                                        onChange={(event, selectedTime) => {
                                            if (selectedTime) {
                                                setTime(selectedTime);
                                            }
                                        }}
                                    />
                                )}
                            </TouchableOpacity>

                            {Platform.OS === 'android' && showTimePicker && (
                                <DateTimePicker
                                    value={time}
                                    mode="time"
                                    display="default"
                                    onChange={(event, selectedTime) => {
                                        setShowTimePicker(false);
                                        if (selectedTime) {
                                            setTime(selectedTime);
                                            //console.log("Selected Time:", selectedTime);

                                        }
                                    }}
                                />
                            )}
                        </View>



                        <View style={{ width: '100%' }}>
                            <TouchableOpacity onPress={() => setIsTypeActionSheetVisible(true)} style={styles.iconButton}>
                                <Text>{seletcedType ? typeValues.find(item => item.id === seletcedType).text : 'نوع الاجراء'}</Text>
                            </TouchableOpacity>

                            <ActionSheet
                                title="اختر نوع"
                                message="الرجاء اختيار نوع الاجراء من القائمة"
                                cancelButtonIndex={typeValues.length}
                                useNativeIOS
                                options={[
                                    ...typeValues.map((item) => ({
                                        label: item.text,
                                        onPress: () => setSelectedtype(item.id)
                                    })),
                                    { label: 'Cancel', onPress: () => { } }  // You can adjust the cancel action as needed
                                ]}
                                visible={isTypeActionSheetVisible}
                                onDismiss={() => setIsTypeActionSheetVisible(false)}
                            />
                        </View>



                        <TextInput
                            style={styles.inputStyle}
                            placeholder="تفاصيل الاجراء"
                            value={textInputValue1}
                            onChangeText={(text) => {
                                //console.log("TextInput changing to:", text);
                                setTextInputValue1(text);
                            }}
                        />


<FilePhotoPicker
                            initialUris={imageUris}
                            onFileChange={(deletedIds, addedUris) => {
                                setNewImageUris(addedUris);
                            }}
                        />

                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <TextInput
                                style={{
                                    flex: 1,
                                    padding: 10,
                                    borderColor: 'gray',
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    marginBottom: 10
                                }}
                                placeholder="تكاليف الاجراء"
                                value={textInputValue2}
                                keyboardType="numeric"

                                onChangeText={setTextInputValue2}
                            />
                            {
                                Platform.OS === 'android' ? (
                                    <Picker
                                        style={styles.picker}
                                        selectedValue={selectedValue}
                                        onValueChange={(itemValue) => setSelectedValue(itemValue)}
                                    >
                                        <Picker.Item label="LBP" value="lbp" />
                                        <Picker.Item label="USD" value="usd" />
                                        {/* ... other options */}
                                    </Picker>
                                ) : (
                                    <TouchableOpacity onPress={openIOSPicker}>
                                        <Text style={{ marginHorizontal: 20 }}>    {selectedValue ? selectedValue : 'اختر العملة'}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            }
                            {
                                iosPickerVisible && Platform.OS === 'ios' && (
                                    <ActionSheet

                                        title={'اختر العملة'}
                                        message={'الرجاء اختيار العملة'}
                                        visible={iosPickerVisible}
                                        cancelButtonIndex={3}
                                        destructiveButtonIndex={0}
                                        //useNativeIOS
                                        options={[
                                            { label: 'LBP', onPress: () => { setSelectedValue('lbp'); setIosPickerVisible(false) } },
                                            { label: 'USD', onPress: () => { setSelectedValue('usd'); setIosPickerVisible(false) } },
                                            { label: 'الغاء', onPress: () => { setIosPickerVisible(false) } },
                                        ]}
                                        onDismiss={() => setIosPickerVisible(false)} // Close the ActionSheet when dismissed


                                    />




                                )
                            }

                        </View>

                       
                       
                       


                     


<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={() => { setModalVisible(false); resetModal() }}
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
  <Button 
  title="اضافة" 
  onPress={() => { 
    setModalVisible(false); // Hide the modal immediately
    uploadFiles(); // Then start the file upload
  }}
  disabled={!isSaveEnabled}
  color="#28a745" // Green color
/>

  </View>

                      
                        </View>


                    </View>
                )}
            </View>

        </Modal>

    );
};

const styles = StyleSheet.create({
    actionButtonDisabled: {
        backgroundColor: '#b0b0b0', // A grayed-out color when disabled
    },
    picker: {
        //  height: 50,
        width: '40%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    inputStyle: {
        width: '100%',
        padding: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 10
    },
    pickerStyle: {
        height: 50,
        width: '100%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    dateTimePickerContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        //width: '100%',
        padding: 10,
        borderRadius: 5,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10
    },
    imagePreview: {
        width: 100,
        height: 100,
        margin: 5,
    },
    filePreview: {
        width: 100,
        height: 100,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileName: {
        marginTop: 10,
        fontSize: 12,
    },
    actionButton: {
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'blue',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 5
    }
});


export default ProcedureModal;
