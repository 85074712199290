import React, { useState, useEffect } from 'react';
import { View, Modal, TextInput, Button, Text, StyleSheet } from 'react-native';

const SupplierModal = ({ visible, onClose, onSubmit, initialData }) => {
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [checkingAcc, setCheckingAcc] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title);
      setAddress(initialData.address);
      setPhone(initialData.phone);
      setCheckingAcc(initialData.checking_acc_id); // Adjusted to match backend
      setEmail(initialData.email);
    } else {
      setTitle('');
      setAddress('');
      setPhone('');
      setCheckingAcc('');
      setEmail('');
    }
  }, [initialData]);

  const handleSubmit = () => {
    onSubmit({
      title,
      address,
      phone,
      checking_acc: checkingAcc, // Adjusted to match backend
      email,
    });
    onClose();
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}>اضافة وتعديل مورّد</Text>
        <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch', marginBottom:20 }} />
         
          <Text>اسم المورد</Text>
          <TextInput
            style={styles.input}
            value={title}
            onChangeText={setTitle}
          />
          <Text>العنوان</Text>
          <TextInput
            style={styles.input}
            value={address}
            onChangeText={setAddress}
          />
          <Text>رقم الهاتف</Text>
          <TextInput
            style={styles.input}
            value={phone}
            onChangeText={setPhone}
          />
          <Text>رقم الحساب</Text>
          <TextInput
            style={styles.input}
            value={checkingAcc}
            onChangeText={setCheckingAcc}
          />
          <Text>البريد الاكتروني</Text>
          <TextInput
            style={styles.input}
            value={email}
            onChangeText={setEmail}
          />
          
<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <View style={{ width: 100 }}>
              <Button
                title="الغاء"
                onPress={onClose}
                color="#ff7f50" /* Orange color */
              />
            </View>

            <View style={{ width: 100 }}>
              <Button
                title="تسجيل"
                onPress={handleSubmit}
                color="#28a745" /* Green color */

              />
            </View>


          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
});

export default SupplierModal;
