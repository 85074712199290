import React, { createContext, useState } from 'react';

// Create the context with a default value
const NavigationStateContext = createContext({
  currentRoute: null,
  setCurrentRoute: () => {},
});

// Define the provider component
export function NavigationStateProvider({ children }) {
  const [currentRoute, setCurrentRoute] = useState(null);

  return (
    <NavigationStateContext.Provider value={{ currentRoute, setCurrentRoute }}>
      {children}
    </NavigationStateContext.Provider>
  );
}

export default NavigationStateContext;
