import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { I18nManager } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import BadgeCountContext from '../../contexts/BadgeCountContext';
import AuthStack from './AuthStack';
import { AuthContext } from '../../contexts/AuthContext';
import AppStack from '../../Navigators/AppStack';
import Constants from 'expo-constants';
import { getToken } from '../../utils/api';





// Force the layout to be RTL

I18nManager.forceRTL(true);





// Define a Stack Navigator for the Lawsuits screen

// Define the main Navigation Stack using the Navigation Container
export default function NavigationStack() {
  const { isAuthenticated } = useContext(AuthContext);
  const apiUrl = Constants.expoConfig.extra.apiUrl;


  const [data, setData] = useState({ image: '' });
  const param = {
    method: 'fetch_credentials',
    params: {
      //select2:true
    }
  };

  /*
const { badgeCount, setBadgeCount } = useContext(BadgeCountContext);  
 
const fetchTodayRemindersCount = async () => {
  try {
    const today = new Date().toISOString().split('T')[0];
    ////console.log('Today:', today);  // Log today's date

    const remindersJSON = await AsyncStorage.getItem('select_reminders');
    let reminders = [];  // Declare the reminders variable here
    if (remindersJSON) {
      reminders = JSON.parse(remindersJSON);
      ////console.log('All reminders:', reminders);  // Log all reminders
    } else {
      //console.log('No reminders found in AsyncStorage');  // Log if no reminders are found
    }

   const todayReminders = reminders.filter(reminder => reminder.deadline.substring(0, 10) === today);
    ////console.log('Today reminders:', todayReminders);  // Log today's reminders

   setBadgeCount(todayReminders.length);
  } catch (error) {
    console.error('Error fetching today\'s reminders:', error);
  }
};

  

  // Step 3: Use the useEffect hook to call the fetchTodayRemindersCount function when the component mounts
  useEffect(() => {
    fetchTodayRemindersCount();
  }, []);
  
*/
 
  const fetchData = async () => {
    try {
      const token = await getToken();
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(param),
      });

      const json = await response.json();
     // //console.log('JSON response:', json);
      setData({ image: json.photo });
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    };
  }, [isAuthenticated]);
  


  
  return (
    <NavigationContainer>
     <AppStack /> 
    </NavigationContainer>
  );
}

// Define the styles for the components
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
 
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    openButton: {
      backgroundColor: "#F194FF",
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    }
  });
  
