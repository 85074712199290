import React, { useState, useEffect, useContext } from 'react';
import { FlatList, ActivityIndicator, TouchableOpacity, Linking, Modal, Share, Platform, TextInput, Alert, ScrollView, StyleSheet } from 'react-native';
import { Chip, Text, Image, ListItem, Carousel, Spacings, View, ExpandableSection, Switch, Icon, Colors } from 'react-native-ui-lib';
import { Ionicons } from '@expo/vector-icons';
import { fetchData, fetchFileLink, encodeImagesWithMimeType, DELETE_FILE_BY_ID_API, getToken } from '../../../utils/api';
import Styles from '../../../Styles/Styles';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';
import { SearchContext } from '../../../contexts/SearchContext';
import { SpeedDial } from '@rneui/themed';
import { method, result } from 'lodash';
import ImageCarouselModal from '../../../SharedComponents/ImageCarouselModal';
import ClientFormWizard from '../../Parties/components/AddNewClient';
import { useNavigation } from '@react-navigation/native';
import FlipCard from 'react-native-flip-card';
import ImageViewer from 'react-native-image-zoom-viewer';
import * as DocumentPicker from 'expo-document-picker';
import { Camera } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import EditClientModal from '../../Parties/components/EditClientModal';
import CustomChip from '../../../SharedComponents/CustomChip';
import { FlashList } from '@shopify/flash-list';
import AddProcuration from '../../Parties/components/AddProcuration';
import Constants from 'expo-constants';




function LawsuitsDetailsClient({ route, All = false, isNested = true }) {
  const [open, setOpen] = useState(false);
  const [clientLawsuits, setClientLawsuits] = useState({});
  const [isCarouselModalVisible, setIsCarouselModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, lawsuit_info_id } = route?.params ?? {};
  const [activeItem, setActiveItem] = useState(null); // Store the ID of the expanded item
  const [isModalVisible, setIsModalVisible] = useState(false); // Step 2: Create a state variable to control the modal visibility
  const [search, setSearch] = useState('');
  // const [filteredData, setFilteredData] = useState(data);  // Initial data
  const navigation = useNavigation();

  const [fileLinks, setFileLinks] = useState([]);
  const [modalImageIndex, setModalImageIndex] = useState(0);


  const { searchQuery } = useContext(SearchContext);
  const [filteredData, setFilteredData] = useState([]);

  const checkmark = require('../../../assets/briefcase-66-32.png');
  const [isAddNewClientVisible, setisAddNewClientVisible] = useState(false);
  const [SelectedItem, SetSelectedItem] = useState('');
  const [frontImageBase64, setFrontImageBase64] = useState('');
  const [backImageBase64, setBackImageBase64] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [clientData, setClientData] = useState({});
  const [clientImages, setClientImages] = useState({});

  const [isFlipCardModalVisible, setIsFlipCardModalVisible] = useState(false);
  const [selectedClientForModal, setSelectedClientForModal] = useState(null);
  const [procurationFiles, setProcurationFiles] = useState([]);

  const [addProcVisible, SetAddProcVisible] = useState(false);

  console.log('Loadind clients: ');

  const apiUrl = Constants.expoConfig.extra.apiUrl;

//*##### FUNCTIONS

  const fetchImagesForClient = async (client) => {
    const imageIds = JSON.parse(client.images);
    const updatedClient = { ...client };
    for (const key in imageIds) {
      if (imageIds[key] !== -1) {
        try {
          const base64String = await fetchFileLink(imageIds[key]);
          if (base64String) {
            updatedClient[key + '_base64'] = base64String;
          }
        } catch (error) {
          console.error("Error fetching image with ID", imageIds[key], ":", error);
        }
      }
    }
    // Update the specific client in your state
    setData(prevData => prevData.map(item => item.id === client.id ? updatedClient : item));
  };

  const openFlipCardModal = (client, index = 0) => {
    let images = [];

    if (client.civil_id_photo_front_base64 && client.civil_id_photo_front_base64 !== "-1") {
      images.push({ url: client.civil_id_photo_front_base64 });
    }
    if (client.civil_id_photo_back_base64 && client.civil_id_photo_back_base64 !== "-1") {
      images.push({ url: client.civil_id_photo_back_base64 });
    }

    console.log("Images for ImageViewer:", images); // Debugging log
    setModalImageIndex(index); // Set the initial index

    setSelectedClientForModal({ ...client, images });
    setIsFlipCardModalVisible(true);
  };

  const toggleItem = (id) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
      const client = data.find(client => client.id === id);
      if (client) {

        fetchClientLawsuits(client.id || client.client_id);
        fetchImagesForClient(client);
      }
    }
  };


  const fetchClientLawsuits = async (clientId) => {
    try {
      const lawsuits = await fetchDataWithParams('select_lawsuits', { filter: true, client_id: clientId, lawyer_id: -1, opponent_id: -1, phase_id: -1, type_id: -1, j_ref_id: -1, j_region_id: -1, isActive: -1 });
      // console.log(`Lawsuits for client ${clientId}:`, lawsuits);

      setClientLawsuits(prev => ({
        ...prev,
        [clientId]: lawsuits // this assumes that the 'lawsuits' variable is an array of lawsuit objects
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = All
        ? await fetchDataWithParams('select_clients', {})
        : await fetchDataWithParams('select_clients', { group: true, id: id, lawsuit_info_id: lawsuit_info_id });

      //console.log('API CLIENT Response:', response);

      // Sort the data by c_nb in descending order
      const sortedData = response.sort((a, b) => b.c_nb - a.c_nb);

      setData(sortedData);
      setLoading(false);
      /*
          // Fetch the lawsuits for each client
          for (const client of sortedData) {
            All ?
            await fetchClientLawsuits(client.id)
            :
            await fetchClientLawsuits(client.client_id)
          }
          */
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataWithParams = async (method, params) => {
    return fetchData({ method, params });
  };


  const getFileType = (base64Link) => {
    const match = base64Link.match(/data:\s*([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/);
    if (match && match.length > 1) {
      // console.log(match[1]);

      return match[1];
    }
    return null;
  };

  const openFile = async (item) => {
    console.log(item);
    setFileLinks(item);
    setIsCarouselModalVisible(true); // Open the modal
  };

  
  const shareFile = async (item) => {
    try {
      // Function to share an individual image
      const shareIndividualImage = async (imageBase64) => {
        if (imageBase64.includes(";base64,")) {
          const filename = FileSystem.documentDirectory + 'tempImage.jpg';
          await FileSystem.writeAsStringAsync(filename, imageBase64.split(';base64,').pop(), { encoding: FileSystem.EncodingType.Base64 });
          if (await Sharing.isAvailableAsync()) {
            await Sharing.shareAsync(filename);
          } else {
            console.error("Sharing is not available on this platform");
          }
        }
      };

      // Share front image
      await shareIndividualImage(item.civil_id_photo_front_base64);

      // Optional: Delay before sharing the back image
      // await new Promise(resolve => setTimeout(resolve, 1000));

      // Share back image
      await shareIndividualImage(item.civil_id_photo_back_base64);

    } catch (error) {
      console.error("Error sharing file:", error);
    }
  };

  const handlecall = (item) => {
    Linking.openURL(`tel:${item}`);
  };


  const handleWhatsApp = (item) => {
    Linking.openURL(`https://wa.me/${item}?text=`);
  };

  async function shareContact(item) {
    // 1. Create a vCard string
    const vCard = `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${item.first_name}\r\nN:${item.last_name};${item.first_name};;;\r\nTEL:${item.mobile}\r\nEMAIL:${item.email}\r\nEND:VCARD`;


    // 2. Write the vCard string to a file
    const fileName = 'contact.vcf';
    const fileUri = FileSystem.documentDirectory + fileName;

    await FileSystem.writeAsStringAsync(fileUri, vCard);

    // 3. Check if sharing is available
    const isAvailable = await Sharing.isAvailableAsync();
    if (!isAvailable) {
      alert('Uh oh, sharing is not available on your platform');
      return;
    }

    // 4. Share the file
    try {
      await Sharing.shareAsync(fileUri);
    } catch (error) {
      console.error("Error while sharing:", error);
    }
  }

  const onClientFormSubmit = (result) => {
    // console.log("Form submitted with result:", result);
    // Additional actions after form submission
    setisAddNewClientVisible(false); // Close the modal after submission
    fetchClients();
  };

  
  const renderListItem = (item) => {
    //console.log("Rendering item with procurations:", item.procurations);

    return (
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleContainer} key={item.id.toString()}>

        <ExpandableSection
          down
          expanded={activeItem === item.id}
          sectionHeader={
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.CollapsibleHeader}>
              <Image
                source={{ uri: 'https://lh3.googleusercontent.com/-cw77lUnOvmI/AAAAAAAAAAI/AAAAAAAAAAA/WMNck32dKbc/s181-c/104220521160525129167.jpg' }}
                style={{ width: 50, height: 50, borderRadius: 25 }}
              />
              <View style={{ marginLeft: 10, flex: 1 }}>
                <Text grey10 text70>{item.first_name} {item.middle_name} {item.last_name} - ({item.c_nb})</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={[Styles.LawSuiteDetailsClientOpponentStyles.value, { flex: 1 }]}>{item.mobile}</Text>
                  <TouchableOpacity onPress={() => handlecall(item.mobile)}>
                    <Ionicons name="call" size={24} color="blue" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => handleWhatsApp(item.mobile)} style={{ marginLeft: 10 }}>
                    <Ionicons name="logo-whatsapp" size={24} color="green" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => shareContact(item)} style={{ marginLeft: 10 }}>
                    <Ionicons name="share-outline" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          }
          onPress={() => toggleItem(item.id)}
        >
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.container}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => setIsModalVisible(true)}>
                <Ionicons name="expand-outline" size={18} color="black" />
              </TouchableOpacity>

              {!isNested ?
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={() => handleOpenEditModal(item)}>
                    <Ionicons name={isEditMode ? "checkmark" : "create-outline"} size={18} color={isEditMode ? "green" : "red"} />
                  </TouchableOpacity>
                  <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} onPress={() => handleOpenAddProcModal(item)}>
                    <Ionicons name="add" size={18} color="black" />
                  </TouchableOpacity>
                </View>

                :
                null}

            </View>
            <Modal
              transparent={false}
              visible={isModalVisible}
              animationType="slide"
            >
              <ScrollView style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                <View>
                  <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>بطاقة تعريف موكل </Text>
                </View>
                <LawsuitDetailsCLientView item={item} />
                <TouchableOpacity
                  style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
                  onPress={() => setIsModalVisible(false)}
                >
                  <Ionicons name="close" size={24} color="black" />
                </TouchableOpacity>

              </ScrollView>
            </Modal>
            {activeItem === item.id &&
              <LawsuitDetailsCLientView item={item} />
            }
          </View>
        </ExpandableSection>
      </View>
    )
  };

  const handleOpenEditModal = (client) => {
    setClientData(client); // Set the client data to be edited
    setIsEditModalVisible(true);
  };

  const handleOpenAddProcModal = (client) => {
    setClientData(client); // Set the client data to be edited
    SetAddProcVisible(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };


  
  const handleSaveEdit = async (editedData) => {
    // Check if the client's name has changed
    const nameChanged = clientData.first_name !== editedData.first_name ||
      clientData.middle_name !== editedData.middle_name ||
      clientData.last_name !== editedData.last_name;

    if (nameChanged) {
      // Prepare updated lawsuit titles if names have changed
      const updatedLawsuits = prepareUpdatedLawsuits(editedData);
      // Ask user for confirmation to update lawsuit titles
      confirmLawsuitTitleUpdates(updatedLawsuits, editedData);
    } else {
      // Save the client data directly if there's no change in name fields
      saveChanges(editedData);
    }
  };


  const prepareUpdatedLawsuits = (editedData) => {
    return clientLawsuits[clientData.id].map(lawsuit => {
      let updatedTitle = lawsuit.title
        .replace(clientData.first_name, editedData.first_name)
        .replace(clientData.middle_name, editedData.middle_name)
        .replace(clientData.last_name, editedData.last_name);

      return { ...lawsuit, title: updatedTitle };
    });
  };


  const confirmLawsuitTitleUpdates = (updatedLawsuits, editedData) => {
    Alert.alert(
      "تحديث اسماء الدعاوى",
      "لقد تم تعديل اسم الموكل، هل ترغب بتعديل اسماء دعاوى الموكل لتتضمن اسمه المعدل؟",
      [
        {
          text: "No",
          onPress: () => saveChanges(editedData), // Save other edits but not name changes in titles
          style: "cancel"
        },
        { text: "Yes", onPress: () => applyLawsuitTitleChanges(updatedLawsuits, editedData) }
      ],
      { cancelable: false }
    );
  };


  const applyLawsuitTitleChanges = async (updatedLawsuits, editedData) => {
    try {
      // Update each lawsuit with the new title
      for (const lawsuit of updatedLawsuits) {
        // Assuming you have a function to update lawsuits
        await fetchDataWithParams('update_lawsuit', { id: lawsuit.id, lawsuit_info_id: lawsuit.lawsuit_info_id, id_in_phase: lawsuit.id_in_phase, is_active: lawsuit.is_active, topic: lawsuit.topic, user_id: lawsuit.user_id, room: lawsuit.room, title: lawsuit.title, l_nb: lawsuit.l_nb });
        console.log(lawsuit);
      }
      console.log("Lawsuit titles updated successfully.");
    } catch (error) {
      console.error("Failed to update lawsuit titles:", error);
    }

    // Save the edited client data after updating lawsuit titles
    saveChanges(editedData);
  };






  const saveChanges = async (clientData, procurationFiles) => {
    //console.log('CLIENTDATA: ', clientData);
    console.log(procurationFiles);
    try {

      let civilIdPhotoBackBase64 = null;
      let civilIdPhotoFrontBase64 = null;

      const isBase64 = (str) => {
        const base64Pattern = /^(data:image\/[a-zA-Z]+;base64,)?[A-Za-z0-9+/]+={0,2}$/;
        return base64Pattern.test(str);
      };


      // Check and encode civil_id_photo_back
      if (clientData.civil_id_photo_back_base64 && !isBase64(clientData.civil_id_photo_back_base64)) {
        [civilIdPhotoBackBase64] = await encodeImagesWithMimeType(clientData.civil_id_photo_back_base64);
      } else {
        civilIdPhotoBackBase64 = clientData.civil_id_photo_back_base64;
      }

      // Check and encode civil_id_photo_front
      if (clientData.civil_id_photo_front_base64 && !isBase64(clientData.civil_id_photo_front_base64)) {
        [civilIdPhotoFrontBase64] = await encodeImagesWithMimeType(clientData.civil_id_photo_front_base64);
      } else {
        civilIdPhotoFrontBase64 = clientData.civil_id_photo_front_base64;
      }
      // Prepare updateData object
      const updateData = {
        method: "update_client",
        params: {
          ...clientData,
          civil_id_photo_back: civilIdPhotoBackBase64,
          civil_id_photo_front: civilIdPhotoFrontBase64
        },
      };

      // Call the API for updating client data
      const response = await fetchData(updateData);
      // Handle response here


      /*
       
       let procurationFilesBase64 = [];
      
       if (procurationFiles) {
           // Ensure procurationFiles is always treated as an array
           const filesArray = Array.isArray(procurationFiles) ? procurationFiles : [procurationFiles];
       
           // Convert each file to base64 and collect the results
           procurationFilesBase64 = await Promise.all(filesArray.map(fileUri => convertToBase64(fileUri)));
       
           // Filter out any null values that might occur if conversion fails
           procurationFilesBase64 = procurationFilesBase64.filter(file => file != null);
       
       console.log(procurationFiles);
      console.log(procurationFilesBase64);
      
            // Prepare data for the second API call
            const insertClientFilesData = {
              method: "insert_client_files",
              params: {
               
                id: clientData.id,  // Assuming client ID is in clientData
                files: procurationFilesBase64,  
                procurationName: clientData.ProcurationName  // Example procuration name
                
              }
            };
      
            // Call the API for inserting client files
            const fileResponse = await fetchData(insertClientFilesData);
            console.log("Raw API Response:", fileResponse);
      
          
          }
        */

    } catch (error) {
      console.error("Error updating client data:", error);
      // Handle error
    }
    // Exit edit mode
    setIsEditMode(false);
    fetchClients();
  };



  const handleProcurationSubmit = async ({ procurationName, selectedFiles }) => {
    console.log('Procuration Name:', procurationName);
    console.log('Selected Files:', selectedFiles);
    console.log('id:', activeItem);

    const convertToBase64 = async (uri) => {
      if (!uri) return null;

      // Get the file extension and MIME type
      const fileExtension = uri.split('.').pop();
      let mimeType;
      switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        default:
          mimeType = 'application/octet-stream';
      }
      const data = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
      return `data:${mimeType};base64,${data}`;

    };

    let procurationFilesBase64 = [];

    if (selectedFiles) {
      const filesArray = Array.isArray(selectedFiles) ? selectedFiles : [selectedFiles];
      procurationFilesBase64 = await Promise.all(filesArray.map(fileUri => convertToBase64(fileUri)));
      procurationFilesBase64 = procurationFilesBase64.filter(file => file != null);
    }

    // Iterate over each file and make a separate API call with an index appended to the name
    for (const [index, fileBase64] of procurationFilesBase64.entries()) {
      try {
        const token = await getToken();
        const fileNameWithIndex = `${procurationName}_${index}`; // Append index to procurationName
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            method: "insert_client_files",
            params: {
              id: activeItem,
              files: fileBase64,  // Send each file individually
              procurationName: fileNameWithIndex  // Use modified name with index
            }
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();  // or response.json() if the server responds with JSON
          console.log("Server error response for file index " + index + ":", errorText);
        } else {
          // Handle successful response if needed
          console.log(`File uploaded successfully: ${fileNameWithIndex}`);
        }
      } catch (error) {
        console.error("Upload error for file index " + index + ":", error);
      }
    }

    // Once all files have been attempted, perform any final actions
    fetchClients();

    SetAddProcVisible(false);
  };


//*##### LIFECYCLE EVENTS


  useEffect(() => {
    setFilteredData(data);
  }, [data]);


  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        data.filter(item => {
          return item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.middle_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);


  useEffect(() => {

    fetchClients();
  }, [All, id, lawsuit_info_id]); // Add `All` to dependency array if it's a state variable









 


























  const LawsuitDetailsCLientView = ({ item }) => {





    const renderProcurationsItem = ({ item }) => (

      <Chip

        label={item.name}
        onPress={() => openFile(item.file_id)}
        labelStyle={{
          color: 'black',
          fontSize: 16
        }}
        containerStyle={{ borderColor: Colors.orange50, backgroundColor: Colors.orange50, marginVertical: Spacings.s1 }}

      />
    );



    return (
      <View style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>

        <View style={Styles.LawSuiteDetailsClientOpponentStyles.innerContainer}>


          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الرقم الداخلي</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.c_nb}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الاسم الأول</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.first_name}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأب</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.middle_name}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الشهرة</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.last_name}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>اسم الأم</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mother_name}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>محل الولادة</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_place}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم السجل</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.register_number}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>تاريخ الولادة</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.dob}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>رقم الجوال</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.mobile}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>البريد الالكتروني</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.email}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>العنوان</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.address}</Text>
            </View>
          </View>
          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>ملاحظات</Text>
            </View>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.valueBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>{item.notes}</Text>
            </View>


          </View>

          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row1}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>صورة الهوية</Text>
            </View>

            {item.civil_id_photo_front_base64 ? (
              <View style={{ flex: 1, marginTop: 5 }} >
                <FlipCard
                  flip={false}
                  flipHorizontal={true}
                  flipVertical={false}
                  perspective={1000}
                >
                  {/* Front Side */}
                  <View>

                    <Image
                      source={{ uri: item.civil_id_photo_front_base64 }} style={{ width: '100%', height: 100 }} />

                  </View>
                  {/* Back Side */}
                  <View>

                    <Image
                      source={{ uri: item.civil_id_photo_back_base64 }} style={{ width: '100%', height: 100 }} />


                  </View>
                </FlipCard>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <TouchableOpacity
                    style={styles.magnifierIcon}
                    onPress={() => openFlipCardModal(item)}
                  >
                    <Ionicons name="search" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.shareIcon} // Make sure this style aligns with your design
                    onPress={() => shareFile(item)}
                  >
                    <Ionicons name="share-social" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              </View>
            ) :
              <Text>
                لا يوجد
              </Text>}



          </View>



          <View style={Styles.LawSuiteDetailsClientOpponentStyles.row1}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الوكالات</Text>

            </View>
            {item.procurations && item.procurations.length > 0 ? (
              <ImageCarouselModal
                fileLinks={
                  item.procurations.map(procuration => procuration.file_id)
                }
                isNested={true}
              />


            ) : (
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.value}>لا يوجد</Text>
            )}


          </View>
          <View style={[Styles.LawSuiteDetailsClientOpponentStyles.row, { flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }]}>
            <View style={Styles.LawSuiteDetailsClientOpponentStyles.labelBox}>
              <Text style={Styles.LawSuiteDetailsClientOpponentStyles.label}>الدعاوى</Text>
            </View>



            <View style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              // justifyContent: 'space-between',
            }}>


              {!All ?
                clientLawsuits[item.client_id] && clientLawsuits[item.client_id].map((lawsuit, index) => (
                  <CustomChip
                    label={lawsuit.title}
                    iconSource={checkmark}
                    onPress={() => navigation.navigate('Lawsuits', { screen: 'Screen1', params: { id: lawsuit.id, lawsuit_info_id: lawsuit.lawsuit_info_id, fin_id: lawsuit.financial_acc_id, HeaderTitle: lawsuit.title } })}
                    tintColor="white" // Set the icon tint color here
                  />
                  /*
                           <Chip
                           key={index}
                           label={lawsuit.title}
                           iconSource={checkmark}
                           labelStyle ={{maxWidth: '70%'}}
                           iconStyle={{width: 24, height: 24, marginRight:10}}
                                    iconProps={{tintColor: 'blue'}}
                                     borderRadius={22}
                                     containerStyle={{
                                       borderColor: Colors.blue10,
                                      // borderBottomLeftRadius: 0,
                                      // marginLeft: Spacings.s3
                                      marginBottom:10
                                     }}
                                     onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:lawsuit.id, lawsuit_info_id: lawsuit.lawsuit_info_id, fin_id: lawsuit.financial_acc_id, HeaderTitle:lawsuit.title}})} 
                         />
                         */

                ))
                :
                clientLawsuits[item.id] && clientLawsuits[item.id].map((lawsuit, index) => (
                  <CustomChip
                    label={lawsuit.title}
                    iconSource={checkmark}
                    onPress={() => navigation.navigate('Lawsuits', { screen: 'Screen1', params: { id: lawsuit.id, lawsuit_info_id: lawsuit.lawsuit_info_id, fin_id: lawsuit.financial_acc_id, HeaderTitle: lawsuit.title } })}
                    tintColor="white" // Set the icon tint color here
                  />
                  /*
                           <Chip
                           key={index}
                           label={lawsuit.title}
                           iconSource={checkmark}
                           labelStyle ={{maxWidth: '70%'}}
                           iconStyle={{width: 24, height: 24, marginRight:10}}
                                    iconProps={{tintColor: 'blue'}}
                                     borderRadius={22}
                                     containerStyle={{
                                       borderColor: Colors.blue10,
                                      // borderBottomLeftRadius: 0,
                                      // marginLeft: Spacings.s3
                                      marginBottom:10
                                     }}
                                     onPress={() => navigation.navigate('Lawsuits', {screen: 'Screen1', params: {id:lawsuit.id, lawsuit_info_id: lawsuit.lawsuit_info_id, fin_id: lawsuit.financial_acc_id, HeaderTitle:lawsuit.title}})} 
                         />
                              */
                ))}
            </View>
          </View>
        </View>




      </View>
    );
  };







//*##### VIEW

  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        isNested ? (
          <View>
            {data.map(item => renderListItem(item))}
          </View>
        ) : (
          <View style={{ flex: 1 }}>

            <FlatList
              data={filteredData}
              renderItem={({ item }) => renderListItem(item)}
              keyExtractor={item => item.id.toString()}
            // estimatedItemSize={100}

            />
          </View>
        )
      )}

      {isFlipCardModalVisible && selectedClientForModal && selectedClientForModal.images &&
        <Modal
          visible={isFlipCardModalVisible}
          transparent={true}
          onRequestClose={() => setIsFlipCardModalVisible(false)}
        >
          <ImageViewer
            imageUrls={selectedClientForModal.images}
            index={modalImageIndex}
            onSwipeDown={() => setIsFlipCardModalVisible(false)}
            enableSwipeDown={true}
          />
          <TouchableOpacity
            onPress={() => setIsFlipCardModalVisible(false)}
            style={styles.closeButton}
          >
            <Ionicons name="close" size={30} color="#fff" />
          </TouchableOpacity>
        </Modal>
      }


      {addProcVisible &&
        <Modal
          visible={addProcVisible}
          transparent={false}
          onRequestClose={() => SetAddProcVisible(false)}
        >

          <AddProcuration
            onSubmit={handleProcurationSubmit}
            onClose={() => SetAddProcVisible(false)}
            editData={clientData || { procurations: [] }} // Default to an object with an empty `procurations` array

          />

        </Modal>
      }



      {isEditModalVisible &&
        <EditClientModal
          isVisible={isEditModalVisible}
          onClose={handleCloseEditModal}
          clientData={clientData || { procurations: [] }} // Default to an object with an empty `procurations` array
          onSave={handleSaveEdit}
        //Lawsuits={clientLawsuits[clientData.id]}
        />

      }

      {isCarouselModalVisible && <Modal
        transparent={false}
        visible={isCarouselModalVisible}
        animationType="slide"
      >

        <ImageCarouselModal
          fileLinks={fileLinks}
        // onClose={() => setIsCarouselModalVisible(false)}
        />
        <TouchableOpacity
          style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
          onPress={() => setIsCarouselModalVisible(false)}
        >
          <Ionicons name="close" size={24} color="black" />
        </TouchableOpacity>

      </Modal>
      }

      {!isNested ?
        <SpeedDial
          isOpen={open}
          icon={{ name: 'edit', color: '#fff' }}
          openIcon={{ name: 'close', color: '#fff' }}
          onOpen={() => setOpen(!open)}
          onClose={() => setOpen(!open)}
          style={{ position: 'absolute' }}
        >
          <SpeedDial.Action
            icon={{ name: 'create', color: '#fff' }}
            title=" اضافة موكل"
            onPress={() => { setisAddNewClientVisible(true), setOpen(!open) }}
          />

        </SpeedDial>
        :
        null
      }

      {isAddNewClientVisible && <Modal
        transparent={false}
        visible={isAddNewClientVisible}
        animationType="slide"
      >
        <ClientFormWizard onSubmit={onClientFormSubmit} editData={SelectedItem} images={fileLinks} />
        <TouchableOpacity
          style={{ position: 'absolute', top: 10, right: 10 }} // Adjust positioning as needed
          onPress={() => { setisAddNewClientVisible(false); SetSelectedItem('') }}
        >
          <Ionicons name="close" size={24} color="black" />
        </TouchableOpacity>
      </Modal>
}
    </View>



  );

}


const styles = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10, // Adjust as needed
  },
  textInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'grey',
    marginRight: 10, // Space between text input and button
    paddingHorizontal: 10, // Inner spacing of text input
    height: 40, // Adjust as needed
  },
  iconButton: {
    // Style for the file select icon button
    padding: 10, // Adjust as needed
  },
  addButton: {
    // Style for the add new procuration button
    backgroundColor: '#007bff', // Example color
    padding: 10,
    alignItems: 'center',
  },
  addButtonText: {
    color: 'white', // Example text color
    fontSize: 16,
  },
  magnifierIcon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 15,
    padding: 5,
  },
  shareIcon: {
    position: 'absolute',
    right: 50, // Adjust this value based on the size of your icons and desired spacing
    bottom: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 15,
    padding: 5,
  },

  modalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    //flex: 1,
    margin: 10,
    padding: 10
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  imageContainer: {
    position: 'relative', // Required for absolute positioning of children
    width: '100%',
    height: 100,
    marginBottom: 15,
  },
  imageOverlay: {
    position: 'absolute', // Overlay takes the entire container space
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: {
    position: 'absolute',
    top: 5,   // Adjust top and right values as needed
    right: 5,
    // Add additional styling if needed
  },

});

export default LawsuitsDetailsClient;
