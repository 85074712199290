import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import ClientListScreen from '../../src/Parties/screens/ClientListScreen';
import ClientDetailsScreen from '../../src/Parties/screens/ClientDetailsScreen';
import OppoListScreen from './screens/OppoListScreen';
import OppoDetailsScreen from './screens/OppoDetailsScreen';


const Stack = createStackNavigator();

const OppoNavigator = () => (
  <Stack.Navigator
  screenOptions={{
    headerStyle: {
      backgroundColor: '#0C0D07', // Set your desired color here
    },
    headerTintColor: '#fff', // Set the color of the header text and icons
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }}
  >
    <Stack.Screen name="OppoList" component={OppoListScreen} options={{ headerShown: false }} />
    <Stack.Screen name="OppoDetails" component={OppoDetailsScreen} options={{ title: 'بطاقة تعريف موكل ' }} />
  </Stack.Navigator>
);

export default OppoNavigator;
