import React, { useState, useEffect } from 'react';
import { Modal, TextInput, Platform, TouchableOpacity, Text, Button, View, StyleSheet, Alert } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { ActionSheet } from 'react-native-ui-lib';
import { Picker } from '@react-native-picker/picker';
import { DELETE_ITEM_API } from '../../../utils/api';
import { Ionicons } from '@expo/vector-icons';
import { useIndicator } from '../../../contexts/IndicatorContext';


const AccountEdit = ({ isVisible, onClose, onEntryUpdated, initialValue, initialID, initialDate}) => {
  const { showLoading, showSuccess, showError } = useIndicator();

  const [value, setValue] = useState(initialValue);
// const [selectedType, setSelectedType] = useState(initialType);
  const [iosPickerVisible, setIosPickerVisible] = useState(false);
  const [date, setDate] = useState(initialDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
 // const [time, setTime] = useState(initialTime);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [isTypeActionSheetVisible,setIsTypeActionSheetVisible] = useState(false);
 // const [combinedDateTime, setCombinedDateTime] = useState(`${initialDate} ${initialTime}`);
const [selectedID, setSelectedID] = useState(initialID);

useEffect(() => {
  setValue(initialValue);
  setDate(initialDate);
  setSelectedID(initialID);
}, [initialValue, initialDate, initialID]); // Only re-run the effect if these values change

  
  
  const handleSubmit = async () => {
    // Make your API call here
  //console.log(selectedID);
  showLoading();
  onClose(); // Close the modal
  onEntryUpdated(selectedID, value, date); // This will inform the parent component about the update
  try {
    const param = {
      
            id: selectedID, 
            description: value, 
            date_entered: date 
        
    };

    const responseData = await DELETE_ITEM_API('update_transaction', param);

    // Assuming a successful response would include an ID
    if (responseData && responseData.id) {
        console.log("Item updated successfully:", responseData);
        showSuccess();
    } else {
        // If the response is not as expected
        console.error("Transaction update failed:", responseData);
        showError();
    }
} catch (error) {
    console.error("API call failed:", error.message);
    showError();
} finally {
   // onClose(); // Close the modal after processing
}
    
  }; 

  const openIOSPicker = () => {
    setIosPickerVisible(true);
  };

  return (
    <Modal visible={isVisible} transparent={true} animationType="slide">

<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.7)' }}>
            <View style={{ width: 320, borderRadius: 10, backgroundColor: 'white', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, alignSelf: 'flex-start' }}> تعديل قيد</Text>
                    <View style={{ borderBlockColor: '#CAD8DE', borderWidth: 1, flex: 1, alignSelf: 'stretch' }} />

      <TextInput
        placeholder="Enter text"
        value={value}
        onChangeText={setValue}
        style={styles.inputStyle}

      />







      {/* Date Picker */}
      <TouchableOpacity onPress={() => setShowDatePicker(true)}style={styles.iconButton}>
      <Ionicons name="calendar-outline" size={24} color="black" />
        <Text>{date.toDateString()}</Text>
      </TouchableOpacity>
      {showDatePicker && (
        <DateTimePicker
          value={date}
          mode="date"
          display={Platform.OS === 'android' ? 'default' : 'spinner'}
          onChange={(event, selectedDate) => {
            setShowDatePicker(false);
            if (selectedDate) {
              setDate(selectedDate);
            }
          }}
        />
      )}

      
<View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
<View style={{ width: 100 }}> 
    <Button 
      title="الغاء" 
      onPress={onClose} 
      color="#ff7f50" /* Orange color */
    />
  </View>

  <View style={{ width: 100 }}>
    <Button 
      title="تعديل" 
      onPress={handleSubmit} 
      color="#28a745" /* Green color */
    />
  </View>

 

</View>

      </View>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
    actionButtonDisabled: {
        backgroundColor: '#b0b0b0', // A grayed-out color when disabled
    },
    picker: {
        //  height: 50,
        width: '40%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    inputStyle: {
      width: '100%',
      marginTop:10,
      padding: 5,
      borderColor: '#ccc',
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    //width: '100%',
    padding: 10,
    borderRadius: 5,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10
},
    pickerStyle: {
        height: 50,
        width: '100%',
        marginBottom: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5
    },
    dateTimePickerContainer: {
        width:'100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 10,
      marginVertical: 10,
      borderRadius: 10,
      borderColor: '#ccc',
      borderWidth: 1,
      textAlign: 'center',
  },
    imagePreview: {
        width: 100,
        height: 100,
        margin: 5,
    },
    filePreview: {
        width: 100,
        height: 100,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileName: {
        marginTop: 10,
        fontSize: 12,
    },
    actionButton: {
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'blue',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 5
    }
});



export default AccountEdit;
