        import React, { useState, useEffect } from 'react';
        import { StyleSheet, Modal, TouchableOpacity } from 'react-native';
        import { Ionicons } from '@expo/vector-icons';
        import { View, Text, Drawer, Colors } from 'react-native-ui-lib';
        import AccountingModal from '../../Accounting/coomponents/AccountingModal';
import { fetchData } from '../../../utils/api';
import Styles from '../../../Styles/Styles';
import { ScrollView, GestureHandlerRootView } from 'react-native-gesture-handler';


        const TransactionFetcher = ({ route, navigation }) => {
            const [transactions, setTransactions] = useState(null);
            const [error, setError] = useState(null);
            const [usdBalance, setUsdBalance] = useState(0);
    const [lbpBalance, setLbpBalance] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAddViewVisible, setIsAddViewVisible] = useState(false);
    const [initialFrom, setInitialFrom] = useState(null);
    const [initialTo, setInitialTo] = useState(null);
    const [initialFromTitle, setInitialFromTitle] = useState(null);
    const [initialToTitle, setInitialToTitle] = useState(null);
    const fin_id = route.params?.fin_id?.toString();
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [loading, setLoading] = useState(true); // Add this line



    const [selectedFromData, setselectedFromData] = useState({
        "content": {
          "id": "31284",
          "type": "lawsuit",
          "text": "maza"
        },
        "case": "to"
      });

      const [selectedToData, setselectedToData] = useState({
        "content": {
          "id": "31284",
          "type": "lawsuit",
          "text": "maza",
        },
        "case": "to"
      });

           
      const getBackgroundColor = (item) => {
        if (item.to.id === fin_id) {
            return 'red';
        } else {
            return 'green';
        }
    };
    
            useEffect(() => {
                fetchData({method: 'select_transactions', params:{fin_id}})
                .then(data => {
                    setTransactions(data);
                    setLoading(false);  // Set loading to false once data is fetched

                })
                .catch(err => {
                    console.error("Failed to fetch reminders:", err);
                    setError(err);
                    setLoading(false);  // Set loading to false once data is fetched

                  });
            }, [route.params.id, route.params.lawsuit_info_id]);

 
            const Separator = () => {
                return (
                    <View style={{ height: 1, backgroundColor: '#ccc' }} />
                );
            };
            

            useEffect(() => {
                if (transactions) {
                    const fin_id_str = fin_id.toString();

                    const usdTo = transactions.reduce((sum, transaction) => {
                        if (transaction.currency.toLowerCase() === 'usd' && transaction.to.id === fin_id_str) {
                            return sum + parseFloat(transaction.amount);
                        }
                        return sum;
                    }, 0);
                    
                    // Do the same for usdFrom, lbpTo, and lbpFrom
                    
        
                    const usdFrom = transactions.reduce((sum, transaction) => {
                        if (transaction.currency.toLowerCase() === 'usd' && transaction.from.id === fin_id_str) {
                            return sum + parseFloat(transaction.amount);
                        }
                        return sum;
                    }, 0);
        
                    const lbpTo = transactions.reduce((sum, transaction) => {
                        if (transaction.currency.toLowerCase() === 'lbp' && transaction.to.id === fin_id_str) {
                            return sum + parseFloat(transaction.amount);
                        }
                        return sum;
                    }, 0);
        
                    const lbpFrom = transactions.reduce((sum, transaction) => {
                        if (transaction.currency.toLowerCase() === 'lbp' && transaction.from.id === fin_id_str) {
                            return sum + parseFloat(transaction.amount);
                        }
                        return sum;
                    }, 0);
        
                    setUsdBalance(usdFrom - usdTo);
                    setLbpBalance(lbpFrom - lbpTo);
                }
            }, [transactions]);


            
        
            const LawsuiteDetailsAccountingView = ({item}) => {

                return(
                    <Drawer
                    key={item.id.toString()}
                    rightItems={[{ background: 'transparent', onPress: () => {} }]}
                    leftItem={{ text: 'Delete', background: Colors.red30, onPress: () => showDeleteConfirmation(item.id) }}
                >
                    <View centerV padding-s4 bg-white key={item.id.toString()} style={{flexDirection:'row', borderBottomColor:'grey', borderBottomWidth:1}}>
                
                        {/* Date and Description */}
                        <View style={{ flex: 1 }}>
                            <Text style={[styles.date, { fontSize: 12 }]}>{item.date_entered}</Text>
                            <Text style={[styles.description, { fontSize: 16, fontWeight: 'bold' }]}>{item.description}</Text>
                        </View>
                
                        {/* Amount and Currency Box */}
                        <View style={{ padding: 2, backgroundColor: 'white', borderRadius: 5, alignItems: 'center', backgroundColor: getBackgroundColor(item), width:'20%' }}>
                            <Text style={[
                                styles.cell,
                                {
                                    color: 'white',
                                }
                                
                            ]}>
                                {item.to.id === fin_id ? `-${item.amount}` : `${item.amount}`}
                            </Text>
                            <Text style={[styles.cell, {color:'white'}]}>{item.currency}</Text>
                        </View>
                
                    </View>
                </Drawer>

                )
            };



            return (
                <View style={{ flex: 1 }}>
                    {loading ? (
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Text>Loading...</Text>
                        </View>
                    ) : (
                        <>
                            <View style={{backgroundColor:'white', marginRight: 10, marginLeft:10}}>
                                <TouchableOpacity 
                                    style={Styles.LawSuiteDetailsClientOpponentStyles.magnifierIcon} 
                                    onPress={() => setIsModalVisible(true)}
                                >
                                    <Ionicons name="expand-outline" size={18} color="black" style={{marginTop:5, marginLeft:5}} />
                                </TouchableOpacity>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-around', padding: 10, borderBlockColor: 'blue', borderWidth:1, marginRight:10, marginLeft:10 }}>
                                <Text style={{fontWeight:'bold', color: usdBalance < 0 ? 'red' : 'green'}}>رصيد الدولار: {usdBalance} دولار</Text>
                                <Text style={{fontWeight:'bold', color: lbpBalance < 0 ? 'red' : 'green'}}>رصيد الليرة: {lbpBalance} ل.ل</Text>
                            </View>
                            <Modal
                                transparent={false}
                                visible={isModalVisible}
                                animationType="slide"
                            >
                                <GestureHandlerRootView style={{ flex: 1 }}>

                                <ScrollView style={Styles.LawSuiteDetailsClientOpponentStyles.modalContent}>
                                    <Text style={{ fontSize: 20, alignSelf: 'center', fontWeight: 'bold', margin: 20 }}>كشف حساب الدعوى </Text>
                                    {transactions && transactions.map(item => (
                                        <View key={item.id} style={{backgroundColor:'white', marginRight: 10, marginLeft:10, flex:1}}>
                                            <LawsuiteDetailsAccountingView  item={item} />
                                        </View>
                                    ))}
                                    <TouchableOpacity style={Styles.LawSuiteDetailsClientOpponentStyles.closeButton} onPress={() => setIsModalVisible(false)}>
                                        <Text style={Styles.LawSuiteDetailsClientOpponentStyles.closeButtonText}>Close</Text>
                                    </TouchableOpacity>
                                </ScrollView>
                                </GestureHandlerRootView>

                            </Modal>
                            {transactions ? transactions.map((item) => (
                                <View key={item.id} style={{backgroundColor:'white', marginRight: 10, marginLeft:10}}>
                                    <LawsuiteDetailsAccountingView item={item} />
                                </View>
                            )) : (
                                <View style={styles.emptyList}>
                                    <Text>No transactions available.</Text>
                                </View>
                            )}
                        </>
                    )}
                </View>
            );
                            };






            const styles = StyleSheet.create({
            header: {
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 10,
                borderWidth: 1,
                borderColor: 'black',
                backgroundColor: 'lightgray'
            },
            headerText: {
                flex: 1,
                padding: 10,
                borderRightWidth: 1,
                borderColor: 'black'
            },
            fixedView: {
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: 10,
                backgroundColor: 'lightgray',
                alignItems: 'center'
            },
            row: {
                padding: 10,
                borderWidth: 1,
                borderColor: 'black',
                borderRadius: 10,
                marginBottom: 10,
            },
            innerRow: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            date: {
                flex: 1,
            },
            cell: {
                flex: 1,
                textAlign: 'right',
            },
            description: {
                //flexShrink: 1,
                color: 'grey',
                fontSize: 12,
            },
        });
        
        export default TransactionFetcher;
