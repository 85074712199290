import React, { useEffect, useState, useCallback } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useIsFocused } from '@react-navigation/native';

import { FlatList, Text, View, StyleSheet, TouchableOpacity,Button } from 'react-native';
import { fetchData } from '../../../utils/api';
import SyndicateModal from '../components/SyndicateModal';
import { Ionicons } from '@expo/vector-icons';


const Stack = createStackNavigator();

function MainScreen({ navigation, modalVisible }) {
  const [syndicats, setSyndicats] = useState([]);
  const isFocused = useIsFocused();


  const fetchSyndicats = useCallback(async () => {
    try {
      const data = await fetchData({ method: 'select_syndicates', params: {} });
      setSyndicats(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching syndicats:", error);
    }
  }, []);

  useEffect(() => {
    fetchSyndicats();
  }, [fetchSyndicats]);

  useEffect(() => {
    if (!modalVisible && isFocused) {
      fetchSyndicats();
    }
  }, [modalVisible, isFocused, fetchSyndicats]);

  return (
    <View style={styles.container}>
      <FlatList
        data={syndicats}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.itemContainer}
            onPress={() => navigation.navigate('Second', { syndicateId: item.id })}
          >
            <Text style={styles.item}>{item.text}</Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </View>
  );
}

function SecondScreen({ route, navigation }) {
  const { syndicateId } = route.params;
  const [syndicate, setSyndicate] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchSyndicate = async () => {
    try {
      const data = await fetchData({ method: 'fetch_syndicate', params: { id: syndicateId } });
      setSyndicate(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching syndicate:', error);
    }
  };

  useEffect(() => {
    fetchSyndicate();
  }, [syndicateId]);

  const handleEdit = () => {
    setModalVisible(true);
  };

  const handleSubmit = async (syndicateDetails) => {
    const url = { method: 'update_syndicate', params: { ...syndicateDetails, id: syndicateId } };

    try {
      const response = await fetchData(url);

      if (response === 1) {
        setModalVisible(false);
        fetchSyndicate(); // Refresh syndicate details
       // navigation.navigate('Main'); // Navigate back to the syndicate list
      } else {
        console.error('Failed to update syndicate');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!syndicate) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.item}>اسم النقابة: {syndicate.description}</Text>
      <Text style={styles.subItem}>رقم الهاتف: {syndicate.phone}</Text>
     
      <TouchableOpacity onPress={handleEdit}>
          <Ionicons name="create" size={24} color="red" />
        </TouchableOpacity>

      <SyndicateModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={(details) => handleSubmit({ ...details })}
        initialData={syndicate}
      />
    </View>
  );
}

function SyndicatsNavigator({ modalVisible }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Main">
        {(props) => <MainScreen {...props} modalVisible={modalVisible} />}
      </Stack.Screen>
      <Stack.Screen name="Second" component={SecondScreen} />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
    //justifyContent: 'center',
    // alignItems: 'center',
    // padding: 16,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  item: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subItem: {
    fontSize: 14,
    color: '#555',
  },
});

export default SyndicatsNavigator;
